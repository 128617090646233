import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  EmailRule,
  StateStoring,
  Lookup,
  FormItem,
  RequiredRule,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Button as GridButton,
  Scrolling,
  ColumnChooser,
  Format,
} from "devextreme-react/data-grid"
import DataSource from "devextreme/data/data_source"
import notify from "devextreme/ui/notify"
import { FaUserCog } from "react-icons/fa"

import { userDataStore, roleDataStore } from "../shared/ClientStores"
import { hasAccessTo, impersonateUser } from "../../authentication"
import { PageTitle, LockButton, SFaUserNinja } from "../shared/StyledComponents"
import { userLocker } from "../operations/client"
import SEO from "../../seo"
import { convertUtcToLocal } from "../shared/utilities"
import { faUserPlus } from "../shared/icons"

const userDataSource = new DataSource({ store: userDataStore })

const Users = () => {
  const usersGrid = useRef(null)

  const inviteNewUserClickHandler = () => {
    usersGrid.current.instance.addRow()
  }

  const refreshUserGridClickHandler = () => {
    usersGrid.current.instance.refresh(true)
  }

  const lockButtonRenderHandler = ({ data }) => (
    <LockButton lockId={data.lockId} onClick={lockClickHandler(data.id)} />
  )
  const lockClickHandler = id => async () => {
    try {
      await userLocker(id)
      usersGrid.current.instance.refresh(false)
    } catch (error) {
      notify(`Changing lock status failed: ${error.message}`, "error")
    }
  }
  const impersonateButtonRenderHandler = ({ data }) => (
    <SFaUserNinja
      title="Impersonate this user."
      onClick={impersonateClickHandler(data.id)}
    />
  )
  const impersonateClickHandler = id => async () => {
    try {
      await impersonateUser(id)
      if (typeof window !== `undefined`) window.location.replace("/dashboard")
    } catch (error) {
      notify(`Impersonating user failed: ${error.message}`, "error")
    }
  }

  return (
    <>
      <SEO title="Users" />
      <PageTitle icon={FaUserCog}>Users</PageTitle>
      <DataGrid
        allowColumnReordering={true}
        allowEditing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={userDataSource}
        hoverStateEnabled={true}
        ref={usersGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("user.r")}
        onEditorPreparing={e => {
          if (e.parentType === "dataRow" && e.dataField !== "email") {
            return
          }
          if (!e.row.isNewRow) {
            if (e.dataField === "email") {
              e.allowEditing = false
              e.editorOptions.readOnly = true
            }
          }
        }}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "inviteNewUser",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column dataField="email" dataType="string">
          <EmailRule />
          <RequiredRule />
        </Column>
        <Column
          allowEditing={false}
          dataField="emailConfirmed"
          dataType="boolean"
        />
        <Column dataField="firstName" dataType="string" />
        <Column dataField="lastName" dataType="string" />
        <Column dataField="phoneNumber" dataType="string" />
        <Column dataField="isOnline" dataType="boolean" allowEditing={false} />
        <Column
          dataField="lastActivity"
          dataType="datetime"
          allowEditing={false}
        >
          <Format formatter={convertUtcToLocal} />
        </Column>
        <Column
          caption="Roles"
          dataField="roleIds"
          showInColumnChooser={false}
          visible={false}
        >
          <Lookup
            dataSource={roleDataStore}
            displayExpr="name"
            valueExpr="id"
          />
          <FormItem
            editorType="dxTagBox"
            editorOptions={{
              searchEnabled: true,
              showSelectionControls: true,
            }}
          />
        </Column>
        <Column type="buttons" showInColumnChooser={false}>
          <GridButton name="edit" />
          <GridButton name="delete" />
          <GridButton
            render={lockButtonRenderHandler}
            visible={hasAccessTo("user.l")}
          />
          <GridButton
            visible={hasAccessTo("user.impersonate")}
            render={impersonateButtonRenderHandler}
          />
        </Column>
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <Editing
          mode="form"
          useIcons={true}
          allowDeleting={hasAccessTo("user.d")}
          allowUpdating={hasAccessTo("user.u")}
        />
        <FilterPanel visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          visible={true}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="profile-users"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshUserGridClickHandler} />
        </Template>
        <Template name="inviteNewUser">
          <Button
            icon={faUserPlus}
            text="Invite a User"
            visible={hasAccessTo("user.c")}
            onClick={inviteNewUserClickHandler}
          />
        </Template>
      </DataGrid>
    </>
  )
}

export { Users }
export default Users
