import React, { memo, useEffect, useRef, useState } from "react"
import { DataGrid } from "devextreme-react"
import PropTypes from "prop-types"
import {
  Column,
  ColumnChooser,
  ColumnFixing,
  FilterPanel,
  FilterRow,
  Grouping,
  HeaderFilter,
  LoadPanel,
  MasterDetail,
  Pager,
  Scrolling,
  StateStoring,
  GroupPanel,
} from "devextreme-react/data-grid"
import MetaGridLine from "./MetaGridLine"
import ArrayStore from "devextreme/data/array_store"
import MetaCell from "./MetaCell"

const MetaHistory = memo(({ itemId, getHeaders, getLines, storageKey }) => {
  const gridRef = useRef(null)

  const [columns, setColumns] = useState([])
  const [data, setData] = useState(new ArrayStore())
  const [keyValues, setKeyValues] = useState({})

  useEffect(() => {
    const getInfo = async () => {
      try {
        const { columns, data, keyValues } = await getHeaders(itemId)
        setColumns(columns)
        setData(data)
        setKeyValues(keyValues)
      } catch (error) {}
    }
    itemId && getInfo()
    return () => {
      setData([])
    }
  }, [getHeaders, itemId])

  return (
    <DataGrid
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      columnResizingMode="widget"
      dataSource={data}
      height="100%"
      hoverStateEnabled={true}
      rowAlternationEnabled={true}
      showBorders={true}
      ref={gridRef}
    >
      {columns.map(item => {
        const hasKeyValue = Object.keys(keyValues).some(
          key => keyValues && keyValues[key] && keyValues[key][item.dataField]
        )
        if (hasKeyValue)
          return (
            <Column
              key={item.dataField}
              {...item}
              cellRender={data => <MetaCell {...data} keyValues={keyValues} />}
            />
          )
        return <Column key={item.dataField} {...item} />
      })}
      <ColumnChooser
        allowSearch={true}
        enabled={true}
        height="400"
        mode="select"
      />
      <ColumnFixing enabled={true} />
      <FilterPanel visible={true} />
      <FilterRow visible={false} />
      <Grouping contextMenuEnabled={true} />
      <GroupPanel visible={true} />
      <HeaderFilter visible={true} />
      <LoadPanel shading={true} enabled={true} />
      <MasterDetail
        enabled={true}
        component={MasterDetailGrid({
          getLines,
          storageKey,
        })}
      />
      <Pager
        visible={true}
        allowedPageSizes={[10, 20, 50, 100]}
        showPageSizeSelector={true}
        showNavigationButtons={true}
        showInfo={true}
      />
      <StateStoring
        enabled={storageKey ? true : false}
        type="localStorage"
        storageKey={storageKey}
      />
      <Scrolling showScrollbar="always" />
    </DataGrid>
  )
})
MetaHistory.propTypes = {
  storageKey: PropTypes.string,
}
MetaHistory.defaultProps = {}
export { MetaHistory }
export default MetaHistory

const MasterDetailGrid = ({
  getLines,
  storageKey,
  allowDeleting = false,
  allowUpdating = false,
}) => ({ data }) => {
  return (
    <MetaGridLine2
      storageKey={storageKey}
      getLines={getLines}
      rowId={data.key}
      allowDeleting={allowDeleting}
      allowUpdating={allowUpdating}
      setLineValue={data.setValue}
    />
  )
}

const MetaGridLine2 = ({
  storageKey,
  getLines,
  rowId,
  allowDeleting = false,
  allowUpdating = false,
  setLineValue,
}) => {
  const canceled = useRef(false)
  const [columns, setColumns] = useState([])
  const [data, setData] = useState(new ArrayStore())
  const [keyValues, setKeyValues] = useState({})

  useEffect(() => {
    const getInfo = async () => {
      const { columns, data, keyValues } = await getLines(rowId)
      if (!canceled.current) setColumns(columns)
      setData(data)
      setKeyValues(keyValues)
    }
    getInfo()
    return () => (canceled.current = true)
  }, [getLines, rowId])
  return (
    <MetaGridLine
      data={data}
      columns={columns}
      keyValues={keyValues}
      storageKey={storageKey}
      allowDeleting={false && allowDeleting}
      allowUpdating={allowUpdating}
      setLineValue={setLineValue}
    />
  )
}
