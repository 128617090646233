import React from "react"
import { Accordion } from "devextreme-react"
import { Item as AccordionItem } from "devextreme-react/accordion"

import { FaChartBar } from "../fa-icons"
import { hasAccessToAny, hasAccessTo } from "../authentication"
import Messages from "./profile/dashboard-components/Messages"
import ClientSystemStatus from "./profile/dashboard-components/ClientSystemStatus"
import { getAccordionState, setAccordionState } from "./shared/utilities"
import Invoices from "./concur/dashboard-components/Invoices"
import Expenses from "./concur/dashboard-components/Expenses"
import Payments from "./concur/dashboard-components/Payments"
import Vendors from "./concur/dashboard-components/Vendors"
import PurchaseOrder from "./concur/dashboard-components/PurchaseOrder"

const Home = () => {
  const stateKey = "home"

  return (
    <>
      <Accordion
        collapsible={true}
        multiple={true}
        onInitialized={getAccordionState(stateKey)}
        onSelectionChanged={setAccordionState(stateKey)}
        visible={hasAccessToAny(["msg.r", "system-status.r"])}
      >
        <AccordionItem
          icon={FaChartBar}
          title=" DataMap"
          visible={hasAccessToAny(["msg.r", "system-status.r"])}
        >
          {hasAccessTo("msg.r") && <Messages />}
          {hasAccessTo("system-status.r") && <ClientSystemStatus />}
        </AccordionItem>
        <AccordionItem
          icon={FaChartBar}
          title=" Concur"
          visible={hasAccessToAny([
            "concur.vendor.chart",
            "concur.invoice.chart",
            "concur.expense.chart",
            "concur.po.chart",
            "concur.payment.chart",
          ])}
        >
          {hasAccessTo("concur.vendor.chart") && <Vendors />}
          {hasAccessTo("concur.invoice.chart") && <Invoices />}
          {hasAccessTo("concur.expense.chart") && <Expenses />}
          {hasAccessTo("concur.po.chart") && <PurchaseOrder />}
          {hasAccessTo("concur.payment.chart") && <Payments />}
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default Home
