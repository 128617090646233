import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import {
  forceCollide,
  forceLink,
  forceManyBody,
  forceSimulation,
  forceX,
  forceY,
} from "d3-force"
import { select, drag, scaleOrdinal } from "d3"
import styled from "styled-components"
import { isFunction, startCase } from "lodash"
import notify from "devextreme/ui/notify"

import { getPlantById, retryPlantByIds } from "../../operations/client"
import Traits from "./Traits"
import NodeInfo from "./NodeInfo"
import { Button, TabPanel } from "devextreme-react"
import { Item as TabPanelItem } from "devextreme-react/tab-panel"
import Genome from "./Genome"

const W = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
const ButtonsW = styled.div`
  position: absolute;
  top: 0.2rem;
  right: 1rem;
  display: inline-block;
  z-index: 1;
`
const GraphTabW = styled.div`
  display: flex;
  height: 100%;
`
const ReactJsonTabW = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 1rem;
`
const GraphW = styled.div`
  height: 100%;
  width: 70%;
  svg.fixed .circle {
    stroke: #333;
    stroke-width: 1;
  }
`
const TraitsW = styled.div`
  height: 100%;
  width: 30%;
  padding: 1rem;
`

const SelectedNodeInfoW = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 30%;
  transition: transform 1s;
  transform: ${props => `translateX(${props.selectedNode?.logs ? 0 : "110%"})`};
  background-color: rgb(251 251 251);
  box-shadow: 0 0 15px 0px rgb(0, 0, 0, 0.5);
  clip-path: inset(0px 0px 0px -30px);
`

const PlantGraph = ({
  plantId,
  height = 800,
  width = 800,
  nodeConfigs = { fill: "rgb(101 162 28)", r: 65 },
  linkConfigs = { stroke: "rgb(145 69 16)", strokeWidth: 5 },
  onCanvasClick,
  onNodeClick,
  onLoadingFailed,
}) => {
  const simulation = useRef(null)

  const [nodes, setNodes] = useState([])
  const [links, setLinks] = useState([])
  const [traits, setTraits] = useState(null)
  const [selectedNode, setSelectedNode] = useState(null)
  const [version, setVersion] = useState(0)

  const graphHeight = height
  const graphWidth = (70 * width) / 100

  useEffect(() => {
    const getInfo = async () => {
      try {
        const plant = await getPlantById(plantId)
        const { nodes, links, traits } = plant
        setNodes(nodes)
        setLinks(links)
        setTraits(traits)
      } catch (error) {
        if (isFunction(onLoadingFailed)) onLoadingFailed()
        notify(`Fetching Plant Info Failed: ${error.message}`, "error")
      }
    }
    if (plantId !== null) {
      setSelectedNode(null)
      getInfo()
    }
  }, [plantId, onLoadingFailed, version])

  const createNodes = useCallback(() => {
    function clamp(x, lo, hi) {
      return x < lo ? lo : x > hi ? hi : x
    }

    function circleWDragStartHandler() {
      select(this).classed("fixed", true)
    }

    function circleWDragHandler(event, d) {
      d.fx = clamp(event.x, 0, graphWidth)
      d.fy = clamp(event.y, 0, graphHeight)
      simulation.current.alpha(1).restart()
    }

    function circleWDoubleClickHandler(_, d) {
      delete d.fx
      delete d.fy
      select(this).classed("fixed", false)
      simulation.current.alpha(1).restart()
    }

    function circleWClickHandler(event, d) {
      setSelectedNode(d)
      event.stopPropagation()
      if (isFunction(onNodeClick) === true) {
        onNodeClick(event, d)
      }
    }

    function circleWDragEndHandler(_, d) {
      if (d.fx === undefined && d.fy === undefined) {
        select(this).classed("fixed", false)
      }
    }

    const dragFunction = drag()
      .on("start", circleWDragStartHandler)
      .on("drag", circleWDragHandler)
      .on("end", circleWDragEndHandler)

    const svgNodes = select(`.graphW .graph .nodes`)
      .selectAll("svg")
      .data(nodes)
      .join("svg")
      .classed("circleW", true)
      .attr("width", nodeConfigs.r * 2)
      .attr("height", nodeConfigs.r * 2)
      .attr("x", d => d.x - nodeConfigs.r)
      .attr("y", d => d.y - nodeConfigs.r)
      .call(dragFunction)
      .on("dblclick", circleWDoubleClickHandler)
      .on("click", circleWClickHandler)
      .selectAll("circle")
      .data(d => [d])
      .join("circle")
      .classed("circle", true)
      .attr("r", nodeConfigs.r)
      .attr("cx", nodeConfigs.r)
      .attr("cy", nodeConfigs.r)
      .attr("fill", d => {
        if (d.state === "grown") return "rgb(8, 92, 0)"
        if (d.state === "sick") return "rgb(209, 50, 31)"
        return nodeConfigs.fill
      })
      .select(function() {
        return this.parentNode
      })
      .selectAll("text")
      .data(d => [d])
      .join("text")
      .attr("x", nodeConfigs.r)
      .attr("y", nodeConfigs.r)
      .attr("dy", "0.3em")
      .attr("text-anchor", "middle")
      .attr("fill", "rgb(251,251,251)")
      .attr("lengthAdjust", "spacingAndGlyphs")
      .attr("textLength", d => (startCase(d.title).length > 22 ? "90%" : null))
      .text(d => startCase(d.title))

    return svgNodes
  }, [
    nodes,
    nodeConfigs.r,
    nodeConfigs.fill,
    graphHeight,
    graphWidth,
    onNodeClick,
  ])

  const createLinks = useCallback(() => {
    select(`.graphW .graph .links`)
      .selectAll("line")
      .data(links)
      .join("line")
      .attr("stroke", linkConfigs.stroke)
      .attr("stroke-width", linkConfigs.strokeWidth)
      .attr("x1", d => {
        return d.source.x
      })
      .attr("y1", d => {
        return d.source.y
      })
      .attr("x2", d => {
        return d.target.x
      })
      .attr("y2", d => {
        return d.target.y
      })
  }, [links, linkConfigs.stroke, linkConfigs.strokeWidth])

  const tickHandler = useCallback(() => {
    createNodes()
    createLinks()
  }, [createNodes, createLinks])

  const startSimulation = useCallback(() => {
    var yMapper = group => {
      const groups = new Set(nodes.map(item => item.group))
      const rangeStep = graphHeight / groups.size
      const range = []
      for (let i = 1; i <= groups.size; i++) {
        range.push(graphHeight - i * rangeStep + nodeConfigs.r)
      }
      const ordinal = scaleOrdinal()
        .domain([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
        .range(range)
      return ordinal(group)
    }
    nodes.forEach(element => {
      element.x = graphWidth / 2 - nodeConfigs.r
      element.y = graphHeight / 2 - nodeConfigs.r
    })
    const simulation = forceSimulation(nodes)
      .force("charge", forceManyBody().strength(-50))
      .force(
        "y",
        forceY()
          .y(graphHeight / 2)
          .strength(0.1)
          .y(function(d) {
            return yMapper(d.group)
          })
      )
      .force(
        "x",
        forceX()
          .x(graphWidth / 2)
          .strength(0.1)
      )
      .force("collision", forceCollide().radius(nodeConfigs.r * 1.5))
      .force(
        "link",
        forceLink()
          .links(links)
          .distance(nodeConfigs.r * 2)
      )
      .on("tick", tickHandler)
    return simulation
  }, [graphHeight, nodes, graphWidth, links, nodeConfigs.r, tickHandler])

  useEffect(() => {
    const canvasClickHandler = event => {
      setSelectedNode(null)
      if (isFunction(onCanvasClick) === true) {
        onCanvasClick(event)
      }
    }

    const svg = select(`.graphW .graph`).node()
    if (svg === null) {
      select(".graphW")
        .append("svg")
        .on("click", canvasClickHandler)
        .style("background-color", "none")
        .attr("width", "100%")
        .attr("height", "100%")
        .classed("graph", true)
        .append("g")
        .classed("links", true)
        .select(function() {
          return this.parentNode
        })
        .append("g")
        .classed("nodes", true)
    }
    simulation.current = startSimulation()
    return () => {
      select(`.graphW .graph`).remove()
    }
  }, [onCanvasClick, startSimulation])

  const refreshButtonClickHandler = () => {
    setVersion(previousVersion => previousVersion + 1)
  }
  const reTryButtonClickHandler = async () => {
    try {
      await retryPlantByIds([plantId])
      notify("Selected Item Retried Successfully", "success")
    } catch (error) {
      notify(`Retrying Selected Item Failed ${error.message}`, "error")
    }
  }

  return (
    <W>
      <ButtonsW>
        <Button
          hint="Refresh"
          icon="refresh"
          onClick={refreshButtonClickHandler}
        />
        <Button
          icon="fas fa-cloud-download-alt"
          hint="Retry"
          onClick={reTryButtonClickHandler}
        />
      </ButtonsW>
      <TabPanel width="100%" height="100%">
        <TabPanelItem title={"Graph"}>
          <GraphTabW>
            <TraitsW>
              <Traits data={traits}></Traits>
            </TraitsW>
            <GraphW className="graphW"></GraphW>
            <SelectedNodeInfoW selectedNode={selectedNode}>
              <NodeInfo data={selectedNode} />
            </SelectedNodeInfoW>
          </GraphTabW>
        </TabPanelItem>
        <TabPanelItem title={"Information"}>
          <ReactJsonTabW>
            <Genome plantId={plantId} plantVersion={version} />
          </ReactJsonTabW>
        </TabPanelItem>
      </TabPanel>
    </W>
  )
}

export default memo(PlantGraph)
