import { navigate } from "@reach/router"
import { PieChart } from "devextreme-react"
import { Label, Legend, Series, Size } from "devextreme-react/pie-chart"
import React from "react"
import styled from "styled-components"

const W = styled.div`
  path {
    cursor: pointer;
  }
`

const PieChartItem = ({
  id,
  data,
  displayStyle,
  displayPalette,
  groups,
  subGroups,
  title,
}) => {
  const pointClickHandler = ({ target }) => {
    const group = target?.data?.group
    const plantType = target?.data?.plantType
    navigate("/dashboard/shopify/search-results", {
      state: { type: "query", id, plantType, group, title, subGroup: "" },
    })
  }
  const dataSource = groups.map(item => ({
    group: item,
    value: data
      .filter(dataItem => dataItem.group === item)
      .map(item => item.value)
      .reduce((previous, current) => previous + current, 0),
    plantType: data
      .filter(dataItem => dataItem.group === item)
      .map(item => item.plantType)
      .at(0),
  }))
  return (
    <W>
      <PieChart
        type={displayStyle ?? "pie"}
        dataSource={dataSource}
        palette={displayPalette ?? "Soft Pastel"}
        onPointClick={pointClickHandler}
      >
        <Series argumentField="group" valueField="value">
          <Label visible={true} position="inside" />
        </Series>
        <Size height={300} />
        <Legend
          horizontalAlignment="center"
          verticalAlignment="bottom"
          orientation="horizontal"
        />
      </PieChart>
    </W>
  )
}

export default PieChartItem
