import React from "react"
import { Button } from "devextreme-react"
import notify from "devextreme/ui/notify"

import { migrateClientDatabase } from "../../operations/client"
import { faDatabase } from "../../shared/icons"

const DatamapDatabase = () => {
  const onMigrateClickHandler = async () => {
    try {
      await migrateClientDatabase()
      notify("Client database was migrated successfully.", "success")
    } catch (error) {
      notify(`Client database migration failed: ${error.message}`, "error")
    }
  }
  return (
    <>
      <Button
        icon={faDatabase}
        text="Migrate"
        onClick={onMigrateClickHandler}
      />
    </>
  )
}

export default DatamapDatabase
