import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  StateStoring,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  Pager,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"

import { shopifyUserDataStore, clientDataStore } from "../../shared/CoreStore"
import { hasAccessTo } from "../../../authentication"

const Users = () => {
  const usersGrid = useRef(null)

  const refreshUserGridClickHandler = () => {
    usersGrid.current.instance.refresh(true)
  }

  const customizeColumnsHandler = columns => {
    const clientCodeIndex = columns.findIndex(column =>
      column.dataField === "clientCode" ? true : false
    )
    if (clientCodeIndex !== -1) {
      columns[clientCodeIndex].lookup = {
        dataSource: {
          store: clientDataStore,
        },
        displayExpr: "clientCode",
        valueExpr: "clientCode",
      }
      columns[clientCodeIndex].formItem = {
        editorType: "dxSelectBox",
      }
      columns[clientCodeIndex].validationRules = [
        {
          type: "required",
        },
      ]
    }
  }

  return (
    <>
      <DataGrid
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={shopifyUserDataStore}
        hoverStateEnabled={true}
        ref={usersGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("c.shopify.user.r")}
        customizeColumns={customizeColumnsHandler}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Editing
          allowAdding={hasAccessTo("c.shopify.user.c")}
          allowDeleting={hasAccessTo("c.shopify.user.d")}
          allowUpdating={hasAccessTo("c.shopify.user.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
          visible={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          storageKey="core-shopify-users"
          type="localStorage"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshUserGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { Users }
export default Users
