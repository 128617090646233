import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, Popup, Template, TreeList } from "devextreme-react"
import {
  Button as TreeListButton,
  Editing,
  Column,
  StateStoring,
  ColumnChooser,
  ColumnFixing,
  FilterPanel,
  Pager,
  HeaderFilter,
  Format,
  Scrolling,
  SearchPanel,
  FilterRow,
  Paging,
  RemoteOperations,
} from "devextreme-react/tree-list"
import { connect } from "react-redux"

import { messageDataStoreGenerator } from "../../shared/ClientStores"
import { convertUtcToLocal } from "../../shared/utilities"
import { hasAccessTo } from "../../../authentication"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import MessageDetail from "./MessageDetail"

const MessageGrid = ({ timeRange, refreshUuid }) => {
  const messagesGrid = useRef(null)
  const [showDetail, setShowDetail] = useState(false)
  const [messageId, setMessageId] = useState(null)
  const messageGridDataStore = useRef(messageDataStoreGenerator({ timeRange }))

  useEffect(() => {
    if (messagesGrid.current) messagesGrid.current.instance.refresh(true)
  }, [refreshUuid])

  const refreshMessageGridClickHandler = useCallback(() => {
    messagesGrid.current.instance.refresh(true)
  }, [])

  const showDetailClickHandler = useCallback(({ row }) => {
    setMessageId(row.key)
    setShowDetail(true)
  }, [])

  const popupHidingHandler = useCallback(() => {
    setShowDetail(false)
  }, [])

  const toolbarPreparingHandler = useCallback(e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }, [])

  return (
    <>
      <TreeList
        allowColumnReordering={true}
        allowColumnResizing={true}
        cacheEnabled={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={messageGridDataStore.current}
        hoverStateEnabled={true}
        keyExpr="id"
        parentIdExpr="parentId"
        dataStructure="plain"
        ref={messagesGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("msg.r")}
        virtualModeEnabled={true}
        onToolbarPreparing={toolbarPreparingHandler}
      >
        <Column allowEditing={false} dataField="id" dataType="number" />
        <Column dataField="parentId" dataType="number" />
        <Column dataField="enable" dataType="boolean" />
        <Column dataField="development" dataType="boolean" />
        <Column dataField="userId" dataType="string" />
        <Column dataField="consumerId" dataType="string" />
        <Column dataField="topicCode" dataType="string" />
        <Column dataField="priority" dataType="number" />
        <Column dataField="creatorId" dataType="string" />
        <Column dataField="createDate" dataType="datetime">
          <Format formatter={convertUtcToLocal} />
        </Column>
        <Column dataField="scheduleDate" dataType="datetime">
          <Format formatter={convertUtcToLocal} />
        </Column>
        <Column dataField="expiryDate" dataType="datetime">
          <Format formatter={convertUtcToLocal} />
        </Column>
        <Column dataField="jobTimeout" dataType="number" />
        <Column dataField="header" dataType="string" />
        <Column dataField="bodyString" dataType="string" visible={false} />
        <Column dataField="bodyBinary" dataType="string" visible={false} />
        <Column dataField="fetchDate" dataType="datetime">
          <Format formatter={convertUtcToLocal} />
        </Column>
        <Column dataField="processDate" dataType="datetime">
          <Format formatter={convertUtcToLocal} />
        </Column>
        <Column dataField="response" dataType="string" visible={false} />
        <Column dataField="errors" dataType="string" visible={false} />
        <Column dataField="status" dataType="string" />
        <Column dataField="custom" dataType="string" />
        <Column type="buttons">
          <TreeListButton name="edit" />
          <TreeListButton name="delete" />
          <TreeListButton
            name="detail"
            icon="more"
            onClick={showDetailClickHandler}
          />
        </Column>
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          allowUpdating={hasAccessTo("msg.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          visible={true}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Paging enabled={true} />
        <RemoteOperations filtering={false} grouping={false} sorting={false} />
        <Scrolling
          columnRenderingMode="virtual"
          mode="standard"
          rowRenderingMode="standard"
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="profile-messages-grid"
        />
        <SearchPanel visible={true} />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshMessageGridClickHandler} />
        </Template>
      </TreeList>
      <Popup
        title="Message Details"
        visible={showDetail}
        onHiding={popupHidingHandler}
      >
        <MessageDetail messageId={messageId} />
      </Popup>
    </>
  )
}

export { MessageGrid }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(MessageGrid)
