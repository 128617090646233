import React, { useCallback, useEffect, useRef, useState } from "react"
import { DataGrid, Template, Button, Popup } from "devextreme-react"
import {
  ColumnChooser,
  ColumnFixing,
  StateStoring,
  FilterPanel,
  Grouping,
  SearchPanel,
  Pager,
  GroupPanel,
  HeaderFilter,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"
import { connect } from "react-redux"
import notify from "devextreme/ui/notify"

import { TabItemGridW } from "../../shared/StyledComponents"
import { listItemDatamapDataStoreGenerator } from "../../shared/ConcurStore"
import {
  sendListItemsToConcur,
  resetListItemHash,
} from "../../operations/concur"
import { convertUtcToLocal } from "../../shared/utilities"
import { hasAccessTo, hasAccessToAny } from "../../../authentication"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import ItemScheduler from "../../shared/ItemScheduler"
import DatamapListItemHistory from "./DatamapListItemHistory"
import { faCloudUploadAlt, faHistory } from "../../shared/icons"

function DataMapListItems({ refreshUuid, listId, listInfo }) {
  const listItemGrid = useRef(null)
  const [historyListItemId, setHistoryListItemId] = useState(null)

  useEffect(() => {
    listItemGrid.current.instance.refresh(true)
  }, [refreshUuid])

  const refreshButtonClickHandler = useCallback(() => {
    listItemGrid.current.instance.refresh(true)
  }, [])

  const sendToConcurClickHandler = useCallback(async () => {
    try {
      await sendListItemsToConcur(listId)
      notify("Sending list items to Concur was queued successfully.", "success")
    } catch (error) {
      notify(
        `Queuing request to send list items to Concur failed: ${error.message}`,
        "error"
      )
    }
  }, [listId])

  const historyButtonClickHandler = useCallback(({ row }) => {
    setHistoryListItemId(row.data.id)
  }, [])

  const customizeColumnsHandler = useCallback(
    columns => {
      const processDateIndex = columns.findIndex(column =>
        column.dataField === "processDate" ? true : false
      )
      if (processDateIndex !== -1) {
        columns[processDateIndex].dataType = "datetime"
        columns[processDateIndex].format = {
          formatter: convertUtcToLocal,
        }
      }
      const onResetHashClickHandler = async ({ row }) => {
        try {
          await resetListItemHash(listId, row.data.id)
          notify(
            `Forcing to resend list item to Concur was done successfully.`,
            "success"
          )
        } catch (error) {
          notify(
            `Forcing to resend list items to Concur failed: ${error.message}`,
            "error"
          )
        }
      }
      columns.push({
        type: "buttons",
        showInColumnChooser: false,
        visible: hasAccessToAny([
          "concur.listitem.history",
          "concur.listitem.reset.hash",
        ]),
        buttons: [
          {
            cssClass: "dx-link customColumnButton",
            hint: "Show list item history",
            icon: faHistory,
            name: "history",
            visible: hasAccessTo("concur.listitem.history"),
            onClick: historyButtonClickHandler,
          },
          {
            cssClass: "dx-link customColumnButton",
            hint: "Force resend this list item on next try",
            icon: "refresh",
            name: "reset hash",
            visible: hasAccessTo("concur.listitem.reset.hash"),
            onClick: onResetHashClickHandler,
          },
        ],
      })
    },
    [listId, historyButtonClickHandler]
  )

  const popupHidingHandler = useCallback(() => {
    setHistoryListItemId(null)
  }, [])

  const toolbarPreparingHandler = useCallback(e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "sendListItemsToConcur",
    })
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }, [])

  return (
    <>
      <TabItemGridW>
        <Popup
          title="List Item History"
          visible={historyListItemId !== null}
          onHiding={popupHidingHandler}
        >
          <DatamapListItemHistory
            listId={listId}
            listItemId={historyListItemId}
          />
        </Popup>
        <DataGrid
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          columnResizingMode="widget"
          customizeColumns={customizeColumnsHandler}
          dataSource={listItemDatamapDataStoreGenerator(listId)}
          hoverStateEnabled={true}
          ref={listItemGrid}
          rowAlternationEnabled={true}
          showBorders={true}
          onToolbarPreparing={toolbarPreparingHandler}
        >
          <ColumnChooser
            allowSearch={true}
            enabled={true}
            height="400"
            mode="select"
          />
          <ColumnFixing enabled={true} />
          <StateStoring
            enabled={true}
            storageKey="concur-lists-listItems-datamap"
            type="localStorage"
          />
          <FilterPanel visible={true} />
          <FilterRow visible={true} />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling showScrollbar="always" />
          <SearchPanel visible={true} />
          <Pager
            allowedPageSizes={[10, 20, 50, 100]}
            showInfo={true}
            showNavigationButtons={true}
            showPageSizeSelector={true}
            visible={true}
          />
          <Template name="sendListItemsToConcur">
            <Button
              elementAttr={{ id: "sendListItemsToConcur" }}
              hint="Send list items to Concur"
              icon={faCloudUploadAlt}
              text="Send to Concur"
              visible={hasAccessTo("concur.list.send")}
              onClick={sendToConcurClickHandler}
            />
          </Template>
          <Template name="refreshGrid">
            <Button
              hint="Update grid"
              icon="refresh"
              onClick={refreshButtonClickHandler}
            />
          </Template>
        </DataGrid>
      </TabItemGridW>
      <ItemScheduler
        items={[
          {
            body: null,
            method: "POST",
            target: `#sendListItemsToConcur`,
            title: `Send List item to Concur (ConcurId=${listInfo?.concur_Id}, ConcurName=${listInfo?.concur_Name})`,
            url: `/api/v1/concur/lists/${listId}/send-to-concur`,
          },
        ]}
      />
    </>
  )
}

export { DataMapListItems }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(DataMapListItems)
