import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  RequiredRule,
  StateStoring,
  FilterRow,
  ColumnChooser,
  ColumnFixing,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  SearchPanel,
  Scrolling,
  RangeRule,
} from "devextreme-react/data-grid"
import { BiMailSend } from "react-icons/bi"

import { emailNotificationDataStore } from "../shared/CoreStore"
import { hasAccessTo } from "../../authentication"
import { PageTitle } from "../shared/StyledComponents"
import SEO from "../../seo"

const EmailNotification = () => {
  const queryGrid = useRef(null)

  const refreshQueryGridClickHandler = () => {
    queryGrid.current.instance.refresh(true)
  }

  const toolbarPreparingHandler = e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }

  return (
    <>
      <SEO title="Core Email Notification" />
      <PageTitle icon={BiMailSend}>Email Notification</PageTitle>
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={emailNotificationDataStore}
        hoverStateEnabled={true}
        ref={queryGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("c.email-notification.r")}
        onToolbarPreparing={toolbarPreparingHandler}
      >
        <Column dataField="id" dataType="number" allowEditing={false} />
        <Column dataField="title" dataType="string">
          <RequiredRule />
        </Column>
        <Column dataField="jobTimeout" dataType="number">
          <RangeRule max={600} min={0} />
        </Column>
        <Column dataField="priority" dataType="number">
          <RangeRule max={255} min={0} />
        </Column>
        <Column
          dataField="bodyHeader"
          dataType="string"
          formItem={{ colSpan: 2, editorType: "dxTextArea" }}
        />
        <Column
          dataField="body"
          dataType="string"
          formItem={{
            colSpan: 2,
            editorType: "dxTextArea",
          }}
        />
        <Column
          dataField="bodyFooter"
          dataType="string"
          formItem={{ colSpan: 2, editorType: "dxTextArea" }}
        />
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height={400}
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          allowAdding={hasAccessTo("c.email-notification.c")}
          allowDeleting={hasAccessTo("c.email-notification.d")}
          allowUpdating={hasAccessTo("c.email-notification.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter allowSearch={true} visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel width={250} visible={true} />
        <StateStoring
          enabled={true}
          storageKey="core-email-notification"
          type="localStorage"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshQueryGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { EmailNotification }
export default EmailNotification
