import React, { useEffect, useRef, useState } from "react"
import { Chart, PieChart } from "devextreme-react"
import {
  Series as PieChartSeries,
  Label as PieChartLabel,
  Connector as PieChartConnector,
  Legend as PieChartLegend,
  Tooltip as PieChartTooltip,
} from "devextreme-react/pie-chart"
import styled from "styled-components"
import { connect } from "react-redux"
import { groupBy, mapValues } from "lodash"
import DataSource from "devextreme/data/data_source"
import {
  Aggregation,
  ArgumentAxis,
  CommonSeriesSettings,
  Connector,
  Grid,
  Label,
  Legend,
  LoadingIndicator,
  Series,
  SeriesTemplate,
  Tooltip,
  ValueAxis,
  VisualRange,
} from "devextreme-react/chart"
import CountUp from "react-countup"
import { FaFileInvoiceDollar } from "react-icons/fa"

import { calculateVisualRange, convertUtcToLocal } from "../../shared/utilities"
import { allExpenseChartDataStoreGenerator } from "../../shared/ConcurStore"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import { PageTitle } from "../../shared/StyledComponents"

const ExpenseW = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
`
const TotalCountW = styled.div`
  display: flex;
  align-items: center;
  height: 0;
  padding-top: 0.5rem;
`
const TotalCount = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: rgb(8, 31, 59);
`
const ChartsW = styled.div`
  display: flex;
  justify-content: space-between;
`
const PieChartW = styled.div`
  width: 40%;
`
const ChartW = styled.div`
  width: 60%;
  padding-left: 1rem;
`

const Expenses = ({ refreshUuid, timeRange }) => {
  const chartRef = useRef(null)
  const [totalCount, setTotalCount] = useState(0)
  const [pieChartDataSource, setPieChartDataSource] = useState([])
  const [chartDataSource, setChartDataSource] = useState([])

  useEffect(() => {
    const messageDataSource = new DataSource({
      store: allExpenseChartDataStoreGenerator({ timeRange }),
      postProcess: data => {
        setTotalCount(data.length)

        let pieChartDataSource = data.map(item => {
          return { status: item.status, value: 1 }
        })
        pieChartDataSource = groupBy(pieChartDataSource, item => item.status)
        pieChartDataSource = mapValues(pieChartDataSource, item => item.length)
        pieChartDataSource = Object.entries(pieChartDataSource).map(
          ([key, value]) => {
            return { title: key, value: value }
          }
        )
        setPieChartDataSource(pieChartDataSource)

        let chartDataSource = data.map(item => {
          return {
            status: item.status,
            value: 1,
            timestamp: convertUtcToLocal(item.timestamp),
          }
        })
        setChartDataSource(chartDataSource)
        return data
      },
    })
    messageDataSource.load()
    chartRef.current.instance.render()
  }, [timeRange, refreshUuid])

  const customizeTooltipHandler = ({
    valueText,
    argumentText,
    percentText,
  }) => ({
    text: `${argumentText}:${valueText} (${percentText})`,
  })

  return (
    <ExpenseW>
      <PageTitle icon={FaFileInvoiceDollar} variant="subTitle">
        Expenses
      </PageTitle>
      <TotalCountW>
        <TotalCount>
          <CountUp end={totalCount} duration={3} />
        </TotalCount>
      </TotalCountW>
      <ChartsW>
        <PieChartW>
          <PieChart
            dataSource={pieChartDataSource}
            palette="Green Mist"
            type="doughnut"
          >
            <PieChartTooltip
              enabled={true}
              customizeTooltip={customizeTooltipHandler}
            />
            <PieChartSeries argumentField="title" valueField="value">
              <PieChartLabel visible={true} format="fixedPoint">
                <PieChartConnector visible={true} width={1} />
              </PieChartLabel>
            </PieChartSeries>
            <PieChartLegend
              visible={false}
              horizontalAlignment="center"
              verticalAlignment="bottom"
            />
          </PieChart>
        </PieChartW>
        <ChartW>
          <Chart
            dataSource={chartDataSource}
            palette="Green Mist"
            ref={chartRef}
            resizePanesOnZoom={true}
          >
            <ArgumentAxis
              aggregationInterval={timeRange.aggregationInterval}
              argumentType="datetime"
              maxValueMargin={0.1}
              minValueMargin={0.1}
              valueMarginsEnabled={true}
            >
              <Label wordWrap="none" overlappingBehavior="rotate">
                <Connector visible={true} />
              </Label>
              <VisualRange
                defaultStartValue={calculateVisualRange(timeRange)}
              />
            </ArgumentAxis>
            <CommonSeriesSettings
              argumentField="timestamp"
              hoverMode="allArgumentPoints"
              type="bar"
              valueField="value"
            >
              <Aggregation enabled={true} method="count" />
            </CommonSeriesSettings>
            <Legend
              horizontalAlignment="center"
              verticalAlignment="bottom"
              visible={true}
            />
            <LoadingIndicator enabled={true} />
            <SeriesTemplate nameField="status" />
            <Series />
            <Tooltip enabled={true} />
            <ValueAxis
              allowDecimals={false}
              maxValueMargin={0.1}
              minorTickCount={10}
              minValueMargin={0.1}
              showZero={true}
              type="logarithmic"
              valueType="numeric"
              valueMarginsEnabled={true}
            >
              <Grid opacity="0.3" />
            </ValueAxis>
          </Chart>
        </ChartW>
      </ChartsW>
    </ExpenseW>
  )
}

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(Expenses)
