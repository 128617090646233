import React, { useCallback, useRef, useState } from "react"
import { Button, DataGrid, Template } from "devextreme-react"
import {
  Button as GridButton,
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  Grouping,
  HeaderFilter,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
  Sorting,
  StateStoring,
} from "devextreme-react/data-grid"
import styled from "styled-components"

import { datamapQueryDataStore } from "../../shared/ClientStores"
import { hasAccessTo } from "../../../authentication"
import notify from "devextreme/ui/notify"
import { runDatamapQuery } from "../../operations/client"
import { vscServerProcess } from "../../shared/icons"

const ResultGridW = styled.div`
  border-top: 1px solid rgb(221, 221, 221);
  margin-top: 1rem;
  padding-top: 1rem;
`

const DatamapQueries = () => {
  const datamapQueriesGridRef = useRef(null)
  const [queryResults, setQueryResults] = useState(null)

  const refreshQueriesGridClickHandler = useCallback(() => {
    datamapQueriesGridRef.current.instance.refresh(true)
  }, [])

  const toolbarPreparingHandler = useCallback(e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }, [])

  const executeQueryClickHandler = useCallback(async e => {
    const rowId = e.row.data.id
    try {
      setQueryResults(null)
      datamapQueriesGridRef.current.instance.beginCustomLoading()
      const response = await runDatamapQuery(rowId)
      setQueryResults(response.data)
    } catch (error) {
      notify(`Executing selected query failed: ${error.message}`, "error")
    } finally {
      datamapQueriesGridRef.current.instance.endCustomLoading()
    }
  }, [])

  return (
    <>
      <DataGrid
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode="widget"
        dataSource={datamapQueryDataStore}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        ref={datamapQueriesGridRef}
        selection="multiple"
        showBorders={true}
        onToolbarPreparing={toolbarPreparingHandler}
      >
        <Column dataField="id" defaultVisible={false} allowEditing={false} />
        <Column dataField="title" dataType="string">
          <RequiredRule />
        </Column>
        <Column
          dataField="query"
          dataType="string"
          formItem={{
            colSpan: 2,
            editorType: "dxTextArea",
            editorOptions: { autoResizeEnabled: true },
          }}
        >
          <RequiredRule />
        </Column>
        <Column type="buttons" showInColumnChooser={false}>
          <GridButton name="edit" />
          <GridButton name="delete" />
          <GridButton
            cssClass="dx-link customColumnButton"
            name="execute"
            hint={`run this query.`}
            icon={vscServerProcess}
            visible={hasAccessTo("datamap-query.execute")}
            onClick={executeQueryClickHandler}
          />
        </Column>
        <Editing
          allowAdding={hasAccessTo("datamap-query.c")}
          allowDeleting={hasAccessTo("datamap-query.d")}
          allowUpdating={hasAccessTo("datamap-query.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <HeaderFilter allowSearch={true} visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Paging defaultPageSize={10} />
        <Scrolling showScrollbar="always" />
        <SearchPanel width={250} visible={true} />
        <StateStoring
          enabled={true}
          storageKey="profile-developments-datamapQueries"
          type="localStorage"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshQueriesGridClickHandler} />
        </Template>
      </DataGrid>
      {queryResults && (
        <ResultGridW>
          <DataGrid
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
            dataSource={queryResults}
            hoverStateEnabled={true}
            rowAlternationEnabled={true}
            showBorders={true}
          >
            <ColumnChooser
              allowSearch={true}
              enabled={true}
              height="400"
              mode="select"
            />
            <ColumnFixing enabled={true} />
            <Export allowExportSelectedData={true} enabled={true} />
            <FilterPanel visible={true} />
            <FilterRow visible={true} />
            <Grouping contextMenuEnabled={true} />
            <HeaderFilter allowSearch={true} visible={true} />
            <Pager
              visible={true}
              allowedPageSizes={[10, 20, 50, 100]}
              showPageSizeSelector={true}
              showNavigationButtons={true}
              showInfo={true}
            />
            <Paging defaultPageSize={10} />
            <Scrolling showScrollbar="always" />
            <SearchPanel width={250} visible={true} />
            <Selection mode="multiple" />
            <Sorting mode="multiple" />
          </DataGrid>
        </ResultGridW>
      )}
    </>
  )
}

export default DatamapQueries
