import React, { useCallback, useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  StateStoring,
  ColumnChooser,
  ColumnFixing,
  Pager,
  FilterPanel,
  Grouping,
  HeaderFilter,
  SearchPanel,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"

import { resourceApiDataStore } from "../../shared/ClientStores"
import { hasAccessTo } from "../../../authentication"

const ResourceApis = () => {
  const resourceApisGrid = useRef(null)

  const refreshResourceApiGridClickHandler = () => {
    resourceApisGrid.current.instance.refresh(true)
  }

  const toolbarPreparingHandler = useCallback(e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }, [])
  return (
    <>
      <DataGrid
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={resourceApiDataStore}
        hoverStateEnabled={true}
        ref={resourceApisGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("resource.api.r")}
        onToolbarPreparing={toolbarPreparingHandler}
      >
        <Column
          allowEditing={false}
          defaultVisible={false}
          dataField="id"
          dataType="number"
        />
        <Column dataField="title" dataType="string" />
        <Column dataField="method" dataType="string" />
        <Column dataField="url" dataType="string" />
        <Column dataField="body" dataType="string" />
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={hasAccessTo("resource.api.c")}
          allowDeleting={hasAccessTo("resource.api.d")}
          allowUpdating={hasAccessTo("resource.api.u")}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <HeaderFilter visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="profile-schedule-resource-apis"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshResourceApiGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { ResourceApis }
export default ResourceApis
