import React from "react"
import { TabPanel } from "devextreme-react"
import DataSource from "devextreme/data/data_source"
import { FaFileInvoiceDollar } from "react-icons/fa"

import { invoiceDataStore } from "../../shared/ConcurStore"
import Invoice from "./Invoice"
import { PageTitle } from "../../shared/StyledComponents"
import { hasAccessTo } from "../../../authentication"
import SEO from "../../../seo"

function Home() {
  const invoiceDataSource = new DataSource({
    store: invoiceDataStore,
    filter: ["isActive", "=", "true"],
  })
  return (
    <>
      <SEO title="Concur Invoices" />
      <PageTitle icon={FaFileInvoiceDollar}>Invoices</PageTitle>
      <TabPanel
        dataSource={invoiceDataSource}
        itemComponent={Invoice}
        visible={hasAccessTo("concur.invoice.r")}
      />
    </>
  )
}

export default Home
