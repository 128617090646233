import { axios } from "../shared/utilities"

const applicationLogoUploadUrlGenerator = applicationId => {
  return `${process.env.API_URL}/api/v1/core/applications/${applicationId}/logo`
}

const applicationLogoUrlGenerator = applicationId => {
  return `${process.env.API_URL}/api/v1/core/applications/${applicationId}/logo`
}

const clearDataModelDevelopmentData = async applicationId => {
  return await axios(`/api/v1/core/data-models/clear-development`, {
    method: "POST",
    params: { applicationId },
  })
}

const clientLogoUploadUrlGenerator = clientId => {
  return `${process.env.API_URL}/api/v1/core/clients/${clientId}/logo`
}

const clientLogoUrlGenerator = clientId => {
  return `${process.env.API_URL}/api/v1/core/clients/${clientId}/logo`
}
const clientLogScreenshotUrlGenerator = clientLogId => {
  return clientLogId
    ? `${process.env.API_URL}/api/v1/core/client-logs/${clientLogId}/screenshot`
    : null
}

const compileDataModels = async applicationId => {
  return await axios(`/api/v1/core/data-models/compile`, {
    method: "POST",
    params: { applicationId },
  })
}

const getPreviousQueryResult = async id => {
  return await axios(`/api/v1/core/queries/${id}/previous`, {
    method: "GET",
  })
}
const migrateClientDatabases = async () => {
  return await axios("/api/v1/core/clients/database/migrate", {
    method: "POST",
  })
}
const migrateConcurClientDatabases = async () => {
  return await axios("/api/v1/core/concur/database/migrate-clients", {
    method: "POST",
  })
}
const migrateConcurDatamapDatabase = async () => {
  return await axios("/api/v1/core/concur/database/migrate-datamap", {
    method: "POST",
  })
}
const migrateShopifyClientDatabases = async () => {
  return await axios("/api/v1/core/shopify/database/migrate-clients", {
    method: "POST",
  })
}
const migrateShopifyDatamapDatabase = async () => {
  return await axios("/api/v1/core/shopify/database/migrate-datamap", {
    method: "POST",
  })
}
const runEmailNotificationQuery = async ({
  emailNotificationId,
  bodyHeader,
  body,
  bodyFooter,
}) => {
  return await axios(
    `/api/v1/core/email-notifications/${emailNotificationId}/execute`,
    {
      data: { bodyHeader, body, bodyFooter },
      method: "POST",
    }
  )
}
const runQuery = async (id, bodyString) => {
  return await axios(`/api/v1/core/queries/${id}/execute`, {
    headers: {
      "Content-Type": "application/json",
    },
    data: bodyString,
    method: "POST",
  })
}

export {
  applicationLogoUploadUrlGenerator,
  applicationLogoUrlGenerator,
  clearDataModelDevelopmentData,
  clientLogoUploadUrlGenerator,
  clientLogoUrlGenerator,
  clientLogScreenshotUrlGenerator,
  compileDataModels,
  getPreviousQueryResult,
  migrateClientDatabases,
  migrateConcurClientDatabases,
  migrateConcurDatamapDatabase,
  migrateShopifyClientDatabases,
  migrateShopifyDatamapDatabase,
  runEmailNotificationQuery,
  runQuery,
}
