import React, { useState } from "react"
import { Form, LoadPanel } from "devextreme-react"
import { Item, ButtonItem } from "devextreme-react/form"
import { PageTitle, FormW } from "./shared/StyledComponents"
import { useRef } from "react"
import { useEffect } from "react"
import notify from "devextreme/ui/notify"
import { FaUser } from "react-icons/fa"

import { userProfileDataStore } from "./shared/ClientStores"
import SEO from "../seo"

const UserProfile = () => {
  const formRef = useRef(null)
  const formW = useRef(null)
  const [formData, setFormData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        setIsLoading(true)
        const response = await userProfileDataStore.load()
        setFormData(response.data)
      } finally {
        setIsLoading(false)
      }
    }
    getUserProfile()
  }, [setFormData])

  const saveClickHandler = async () => {
    const formData = formRef.current.instance.option("formData")
    try {
      setIsLoading(true)
      await userProfileDataStore.update(formData)
      notify("User profile updated successfully.", "success")
    } catch (e) {
      notify(e.message, "error")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <SEO title="User Profile" />
      <PageTitle icon={FaUser}>User Profile</PageTitle>
      <FormW ref={formW}>
        <Form colCount={2} formData={formData} ref={formRef}>
          <Item
            dataField="userName"
            editorOptions={{
              readOnly: true,
            }}
          />
          <Item dataField="phoneNumber" />
          <Item dataField="firstName" />
          <Item dataField="lastName" />
          <Item
            dataField="email"
            editorOptions={{
              readOnly: true,
            }}
          />
          <Item
            dataField="emailConfirmed"
            editorType="dxCheckBox"
            editorOptions={{
              readOnly: true,
            }}
          />
          <ButtonItem
            horizontalAlignment="left"
            buttonOptions={{
              icon: "save",
              text: "Save",
              onClick: saveClickHandler,
            }}
          />
        </Form>
        <LoadPanel
          visible={isLoading}
          container={formW.current}
          position={{
            of: formW.current,
          }}
        />
      </FormW>
    </>
  )
}

export default UserProfile
