import React, { useRef, useEffect, useCallback } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Column,
  Button as GButton,
  Editing,
  StateStoring,
  SearchPanel,
  GroupPanel,
  ColumnChooser,
  ColumnFixing,
  Grouping,
  Pager,
  HeaderFilter,
  FilterPanel,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"
import { connect } from "react-redux"
import { FaCloudUploadAlt, FaList } from "react-icons/fa"
import { confirm } from "devextreme/ui/dialog"

import { listDataStore } from "../../shared/ConcurStore"
import {
  deleteAllListItems,
  sendListItemsToConcur,
  updateLists,
} from "../../operations/concur"
import { PageTitle } from "../../shared/StyledComponents"
import { hasAccessTo } from "../../../authentication"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import SEO from "../../../seo"
import { faCloudDownloadAlt } from "../../shared/icons"
import ItemScheduler from "../../shared/ItemScheduler"

const Lists = ({ navigate, refreshUuid }) => {
  const listsGrid = useRef(null)

  useEffect(() => {
    listsGrid.current.instance.refresh(true)
  }, [refreshUuid])

  const refreshListGridClickHandler = useCallback(() => {
    listsGrid.current.instance.refresh(true)
  }, [])

  const updateListClickHandler = useCallback(async () => {
    try {
      await updateLists()
      notify("Updating lists was queued successfully.", "success")
    } catch (error) {
      notify(
        `Queuing request to update lists failed: ${error.message}`,
        "error"
      )
    }
  }, [])

  const listItemButtonClickHandler = useCallback(
    e => {
      navigate(`${e.row.data.id}/list-items`)
    },
    [navigate]
  )

  const sendToConcurClickHandler = useCallback(
    id => async () => {
      try {
        await sendListItemsToConcur(id)
        notify(
          "Sending list items to Concur was queued successfully.",
          "success"
        )
      } catch (error) {
        notify(
          `Queuing request to send list items to Concur failed: ${error.message}`,
          "error"
        )
      }
    },
    []
  )

  const sendToConcurButtonRenderHandler = useCallback(
    ({ data }) => (
      <div style={{ display: "inline-block" }}>
        <FaCloudUploadAlt
          onClick={sendToConcurClickHandler(data.id)}
          style={{
            width: "15px",
            height: "15px",
            cursor: "pointer",
            margin: "-8px 3px 0 3px",
            color: "rgb(51 122 183)",
          }}
          title="Send list items to Concur"
          id={`sendToConcur-${data.id}`}
        />
        &nbsp;
        <ItemScheduler
          items={[
            {
              body: null,
              method: "POST",
              target: `#sendToConcur-${data.id}`,
              title: `Send List item to Concur (ConcurId=${data.concur_Id}, ConcurName=${data.concur_Name})`,
              url: `/api/v1/concur/lists/${data.id}/send-to-concur`,
            },
          ]}
        />
      </div>
    ),
    [sendToConcurClickHandler]
  )

  const deleteFromConcurButtonClickHandler = useCallback(async ({ row }) => {
    const result = await confirm("Are you sure?", "Confirm delete")
    try {
      if (result) {
        await deleteAllListItems(row.key)
        notify(
          "Delete all list items of requested list was queued successfully.",
          "success"
        )
      }
    } catch (error) {
      notify(
        `Queuing delete all list items of requested list failed: ${error.message}`,
        "error"
      )
    }
  }, [])

  const toolbarPreparingHandler = useCallback(e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "updateLists",
    })
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }, [])

  return (
    <>
      <SEO title="Concur Lists" />
      <PageTitle icon={FaList}>Lists</PageTitle>
      <DataGrid
        ref={listsGrid}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={listDataStore}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        showBorders={true}
        onToolbarPreparing={toolbarPreparingHandler}
        visible={hasAccessTo("concur.list.r")}
      >
        <Column
          allowEditing={false}
          dataField="id"
          dataType="string"
          visible={false}
        />
        <Column
          allowEditing={false}
          dataField="concur_Name"
          dataType="string"
        />
        <Column allowEditing={false} dataField="concur_Id" dataType="string" />
        <Column
          allowEditing={false}
          dataField="concur_ConnectorId"
          dataType="string"
        />
        <Column
          allowEditing={false}
          dataField="concur_DisplayCodeFirst"
          dataType="boolean"
        />
        <Column
          allowEditing={false}
          dataField="concur_ExternalThreshold"
          dataType="number"
        />
        <Column
          allowEditing={false}
          dataField="concur_IsVendorList"
          dataType="boolean"
        />
        <Column
          allowEditing={false}
          dataField="concur_SearchCriteriaCode"
          dataType="string"
        />
        <Column
          allowEditing={false}
          dataField="isDeleted"
          dataType="boolean"
          visible={false}
        />
        <Column dataField="isActive" dataType="boolean" />

        <Column type="buttons" showInColumnChooser={false}>
          <GButton name="edit" />
          <GButton
            cssClass="dx-link customColumnButton"
            name="sendToConcur"
            visible={hasAccessTo("concur.list.send")}
            render={sendToConcurButtonRenderHandler}
          />
          <GButton
            name="listItem"
            hint="Show list items"
            icon="arrowright"
            onClick={listItemButtonClickHandler}
          />
          <GButton
            name="deleteAllListItems"
            hint="Delete all list items from concur."
            icon="trash"
            visible={hasAccessTo("concur.listitem.cd")}
            onClick={deleteFromConcurButtonClickHandler}
          />
        </Column>
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          mode="cell"
          useIcons={true}
          allowUpdating={hasAccessTo("concur.list.u")}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter allowSearch={true} visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel width={250} visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="concur-lists"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshListGridClickHandler} />
        </Template>
        <Template name="updateLists">
          <Button
            elementAttr={{ id: "refreshLists" }}
            hint="Refetch list from Concur"
            icon={faCloudDownloadAlt}
            text="Update Lists"
            visible={hasAccessTo("concur.list.refresh")}
            onClick={updateListClickHandler}
          />
        </Template>
      </DataGrid>
      <ItemScheduler
        items={[
          {
            body: null,
            method: "POST",
            target: `#refreshLists`,
            title: `Refresh lists`,
            url: `/api/v1/concur/lists/update`,
          },
        ]}
      />
    </>
  )
}

export { Lists }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(Lists)
