import React, { memo, useEffect, useState } from "react"
import { SelectBox } from "devextreme-react"
import { getSageKeyValues } from "../../operations/client"
import DataSource from "devextreme/data/data_source"

const MetaEditCell = memo(props => {
  const [dataSource, setDataSource] = useState([])
  const [value, setValue] = useState(props.value)
  useEffect(() => {
    const getInfo = async () => {
      const response = await getSageKeyValues(
        2,
        props.keyValues[props.data.id][props.column.dataField]
      )
      setDataSource(response)
    }
    if (
      props.keyValues[props.data.id] &&
      props.keyValues[props.data.id][props.column.dataField]
    )
      getInfo()
  }, [props.keyValues, props.data.id, props.column.dataField])
  return (
    <SelectBox
      dataSource={
        new DataSource({
          store: dataSource,
          pageSize: 200,
          paginate: true,
          key: "key",
        })
      }
      displayExpr="value"
      readOnly={!props.column.allowEditing}
      searchEnabled={true}
      value={value}
      valueExpr="key"
      onValueChanged={({ value }) => {
        setValue(value)
        props.setValue(value)
        if (props.onSaveChange !== undefined) {
          props.onSaveChange()
        }
      }}
    />
  )
})

export default MetaEditCell
