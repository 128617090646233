import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  Lookup,
  StateStoring,
  ColumnChooser,
  ColumnFixing,
  Pager,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"

import {
  sageKeyValueDataStore,
  applicationDataStore,
} from "../../shared/ClientStores"
import { hasAccessTo } from "../../../authentication"

const KeyValues = () => {
  const keyValuesGrid = useRef(null)

  const refreshKeyValueGridClickHandler = () => {
    keyValuesGrid.current.instance.refresh(true)
  }

  return (
    <>
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={sageKeyValueDataStore}
        hoverStateEnabled={true}
        ref={keyValuesGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("sage.key-value.r")}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column allowEditing={false} dataField="id" dataType="number" />
        <Column
          dataField="applicationId"
          dataType="number"
          caption="Application"
        >
          <Lookup
            dataSource={{
              store: applicationDataStore,
              map: item => {
                return { ...item, id: item.id.toString() }
              },
            }}
            valueExpr="applicationId"
            displayExpr="title"
          />
        </Column>
        <Column dataField="identifier" dataType="string" />
        <Column dataField="key" dataType="string" />
        <Column dataField="value" dataType="string" />

        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          allowDeleting={hasAccessTo("sage.key-value.d")}
          allowUpdating={hasAccessTo("sage.key-value.u")}
          allowAdding={hasAccessTo("sage.key-value.c")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="profile-sage-keyValues"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshKeyValueGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { KeyValues }
export default KeyValues
