import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, DataGrid, Template } from "devextreme-react"
import {
  ColumnChooser,
  ColumnFixing,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  Grouping,
  HeaderFilter,
  LoadPanel,
  Pager,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid"
import styled from "styled-components"
import { FaProjectDiagram } from "react-icons/fa"
import { useLocation } from "@reach/router"
import notify from "devextreme/ui/notify"
import { connect } from "react-redux"

import PlantGraph from "./PlantGraph"
import {
  getQueryPlants,
  getSearchPlants,
  retryPlantByIds,
} from "../../operations/client"
import { PageTitle } from "../../shared/StyledComponents"
import SEO from "../../../seo"
import SearchPlants from "./SearchPlants"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"

export const W = styled.div`
  width: 200%;
  height: calc(100vh - 10.5rem);
  transition: transform 1s;
  transform: ${props => `translateX(${props.plantId ? "-50%" : 0})`};
  display: flex;
`
export const DataGridW = styled.div`
  width: 50%;
  display: inline-block;
  height: 100%;
`
export const GraphW = styled.div`
  width: 50%;
  display: inline-block;
  height: 100%;
  position: relative;
`
export const BackToGridW = styled.div`
  width: 1rem;
  background-image: linear-gradient(
    to left,
    rgba(251, 251, 251),
    rgb(239, 239, 239)
  );
  display: block;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  opacity: 0.8;
  color: rgb(85, 85, 85);
  justify-content: center;
  padding-left: 5px;
  cursor: pointer;
`
const Title = styled.div`
  display: inline-block;
  margin-right: 1rem;
`
const GroupTitle = styled.div`
  display: inline-block;
`
const TopBarW = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const SubGroupTitle = styled.div`
  display: inline-block;
  font-size: 1rem;
`

const SearchResults = ({ localState, refreshUuid, timeRange }) => {
  const searchResultGrid = useRef(null)
  const graphWRef = useRef(null)

  const { state } = localState ?? useLocation()
  const { plantType, title, type, id, group, subGroup, searchValue } = state

  const [plantId, setPlantId] = useState(null)
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const [dataSource, setDataSource] = useState([])
  const [hasSelected, setHasSelected] = useState(false)
  const [version, setVersion] = useState(0)

  useEffect(() => {
    const getPlants = async () => {
      if (type === "query") {
        try {
          searchResultGrid.current.instance.beginCustomLoading()
          const result = await getQueryPlants(id, group, subGroup, timeRange)
          setDataSource(result)
        } catch (error) {
          notify(`Getting plants failed: ${error.message}`)
        } finally {
          searchResultGrid.current.instance.endCustomLoading()
        }
      } else if (type === "search") {
        try {
          searchResultGrid.current.instance.beginCustomLoading()
          const result = await getSearchPlants(
            plantType,
            searchValue,
            timeRange
          )
          setDataSource(result)
        } catch (error) {
          notify(`Getting plants failed: ${error.message}`, "error")
        } finally {
          searchResultGrid.current.instance.endCustomLoading()
        }
      }
    }
    getPlants()
  }, [
    plantType,
    type,
    id,
    group,
    searchValue,
    subGroup,
    version,
    refreshUuid,
    timeRange,
  ])

  useEffect(() => {
    setWrapperHeight(graphWRef.current.offsetHeight)
    setWrapperWidth(graphWRef.current.offsetWidth)
  }, [plantId])

  useEffect(() => {
    setPlantId(null)
  }, [state])

  const rowClickHandler = ({ key }) => setPlantId(key)

  const backToGridClickHandler = () => setPlantId(null)

  const generateQueryTitle = () => {
    let queryTitle = null
    if (group && subGroup && group !== "" && subGroup !== "") {
      queryTitle = (
        <>
          <Title>{title}</Title>(<GroupTitle>{group}</GroupTitle>&nbsp;- &nbsp;
          <SubGroupTitle>{subGroup}</SubGroupTitle>)
        </>
      )
    } else if (group && group !== "") {
      queryTitle = (
        <>
          <Title>{title}</Title>(<GroupTitle>{group}</GroupTitle>)
        </>
      )
    } else if (subGroup && subGroup !== "") {
      queryTitle = (
        <>
          <Title>{title}</Title>(<SubGroupTitle>{subGroup}</SubGroupTitle>)
        </>
      )
    } else {
      queryTitle = <Title>{title}</Title>
    }
    return queryTitle
  }

  const generateSearchTitle = () => {
    let searchTitle = null
    if (searchValue && searchValue !== "") {
      searchTitle = (
        <>
          <Title>Search Results</Title>(
          <SubGroupTitle>{searchValue}</SubGroupTitle>)
        </>
      )
    } else {
      searchTitle = <Title>{title ?? "Search Results"}</Title>
    }
    return searchTitle
  }
  const retryClickHandler = async () => {
    const keys = searchResultGrid.current.instance.getSelectedRowKeys()
    if (keys.length > 0) {
      try {
        await retryPlantByIds(keys)
        searchResultGrid.current.instance.clearSelection()
        notify(`Retrying Selected Plants Start Successfully`, "success")
      } catch (error) {
        notify(`Retry Selected Plants Failed: ${error.message}`, "error")
      }
    }
  }
  const toolbarPreparingHandler = e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refresh",
    })
    e.toolbarOptions.items.unshift({
      location: "after",
      template: "retry",
    })
  }
  const selectionChangedHandler = ({ selectedRowKeys }) => {
    if (selectedRowKeys.length > 0) {
      setHasSelected(true)
    } else {
      setHasSelected(false)
    }
  }
  const searchClickHandler = () => {
    backToGridClickHandler()
  }
  const refreshClickHandler = useCallback(() => {
    setVersion(previousState => previousState + 1)
  }, [])
  return (
    <>
      <SEO title={title ?? "Search Results"} />
      {searchValue || searchValue === "" ? (
        <PageTitle icon={FaProjectDiagram}>{generateSearchTitle()}</PageTitle>
      ) : (
        <PageTitle icon={FaProjectDiagram}>{generateQueryTitle()}</PageTitle>
      )}
      <TopBarW>
        <Button
          disabled={plantId === null}
          hint="Back To Search Results"
          icon="fas fa-arrow-left"
          onClick={backToGridClickHandler}
          style={{ position: "absolute" }}
        />
        <SearchPlants
          plantType={plantType}
          onSearchClicked={searchClickHandler}
        />
      </TopBarW>
      <W plantId={plantId}>
        <DataGridW>
          <DataGrid
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnAutoWidth={true}
            columnResizingMode="widget"
            dataSource={dataSource}
            height="100%"
            hoverStateEnabled={true}
            keyExpr="id"
            ref={searchResultGrid}
            rowAlternationEnabled={true}
            showBorders={true}
            onRowClick={rowClickHandler}
            onToolbarPreparing={toolbarPreparingHandler}
            onSelectionChanged={selectionChangedHandler}
          >
            <ColumnChooser
              allowSearch={true}
              enabled={true}
              height="400"
              mode="select"
            />
            <ColumnFixing enabled={true} />
            <Editing useIcons={true} />
            <Export
              allowExportSelectedData={true}
              enabled={true}
              fileName={title ?? "result"}
            />
            <FilterPanel visible={true} />
            <FilterRow visible={true} />
            <Grouping contextMenuEnabled={true} />
            <HeaderFilter visible={true} />
            <LoadPanel shading={true} />
            <Pager
              allowedPageSizes={[10, 20, 50, 100]}
              showInfo={true}
              showNavigationButtons={true}
              showPageSizeSelector={true}
              visible={true}
            />
            <Scrolling showScrollbar="always" />
            <SearchPanel visible={true} />
            <Selection
              mode="multiple"
              selectAllMode="page"
              showCheckBoxesMode="always"
            />
            <Template name="refresh">
              <Button icon="refresh" onClick={refreshClickHandler} />
            </Template>
            <Template name="retry">
              <Button
                icon="refresh"
                disabled={hasSelected === false}
                text="Retry Selected"
                onClick={retryClickHandler}
              />
            </Template>
          </DataGrid>
        </DataGridW>
        <GraphW ref={graphWRef}>
          <PlantGraph
            plantId={plantId}
            height={wrapperHeight}
            width={wrapperWidth}
            onLoadingFailed={backToGridClickHandler}
          />
        </GraphW>
      </W>
    </>
  )
}

export { SearchResults }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(SearchResults)
