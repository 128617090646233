import React, { useEffect, useRef } from "react"
import { Chart } from "devextreme-react"
import {
  ArgumentAxis,
  Label,
  Series,
  Legend,
  ValueAxis,
  Grid,
  CommonSeriesSettings,
  SeriesTemplate,
  LoadingIndicator,
  Size,
  Aggregation,
  ZoomAndPan,
  Tooltip,
  VisualRange,
} from "devextreme-react/chart"
import { connect } from "react-redux"
import DataSource from "devextreme/data/data_source"

import { purchaseOrderChartDataStoreGenerator } from "../../shared/ConcurStore"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import { calculateVisualRange, convertUtcToLocal } from "../../shared/utilities"

const PurchaseOrderChart = ({ timeRange, refreshUuid }) => {
  const chartRef = useRef(null)
  const chartDataSource = new DataSource({
    store: purchaseOrderChartDataStoreGenerator({ timeRange }),
    postProcess: data => {
      return data.map(item => {
        return { ...item, timestamp: convertUtcToLocal(item.timestamp) }
      })
    },
  })
  useEffect(() => {
    chartRef.current.instance.render()
  })
  return (
    <>
      <Chart dataSource={chartDataSource} palette="Soft Pastel" ref={chartRef}>
        <ArgumentAxis
          aggregationInterval={timeRange.aggregationInterval}
          argumentType="datetime"
          maxValueMargin={0.1}
          minValueMargin={0.1}
          valueMarginsEnabled={true}
        >
          <Label wordWrap="none" overlappingBehavior="rotate" />
          <VisualRange defaultStartValue={calculateVisualRange(timeRange)} />
        </ArgumentAxis>
        <CommonSeriesSettings
          argumentField="timestamp"
          hoverMode="allArgumentPoints"
          type="bar"
          valueField="id"
        >
          <Aggregation enabled={true} method="count" />
        </CommonSeriesSettings>
        <Legend
          horizontalAlignment="center"
          verticalAlignment="bottom"
          visible={true}
        />
        <LoadingIndicator enabled={true} />
        <Series />
        <SeriesTemplate nameField="status" />
        <Size width="100%" />
        <Tooltip enabled={true} />
        <ValueAxis
          allowDecimals={false}
          maxValueMargin={0.1}
          minValueMargin={0.1}
          showZero={true}
          type="logarithmic"
          valueType="numeric"
          valueMarginsEnabled={true}
        >
          <Grid opacity="0.3" />
        </ValueAxis>
        <ZoomAndPan argumentAxis="both" />
      </Chart>
    </>
  )
}

export { PurchaseOrderChart }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(PurchaseOrderChart)
