import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  invoiceGridDataStoreGenerator,
  invoiceGridHistoryDataStoreGenerator,
  invoiceGridLineDataStoreGenerator,
} from "../../shared/ConcurStore"
import MetaGrid, { MetaImport } from "../../shared/meta-grid"
import { isEmpty, keys } from "lodash"
import { convertUtcToLocal } from "../../shared/utilities"
import { hasAccessTo } from "../../../authentication"
import notify from "devextreme/ui/notify"
import ArrayStore from "devextreme/data/array_store"
import { Popup } from "devextreme-react"
import {
  getInvoiceImportStatus,
  importSelectedInvoiceIntoSageByIds,
  downloadInvoiceJobFile,
  downloadInvoiceImage,
  archiveInvoices,
  importAllInvoicesIntoSage,
  reprocessInvoiceJob,
  deleteInvoices,
  downloadAllInvoiceImages,
} from "../../operations/concur"
import downloadJs from "downloadjs"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import { connect } from "react-redux"
import MetaHistory from "../../shared/meta-grid/MetaHistory"
import ItemScheduler from "../../shared/ItemScheduler"
import { getSageKeyValueByKey } from "../../operations/client"

const getColumns = ({ columns, data }) => {
  columns = columns.map(item => {
    const { attributes, ...rest } = item
    return rest
  })
  const dataColumns = getDataColumns(data)
  const allColumns = [...dataColumns, ...columns]
  return allColumns.map(item => {
    if (item.dataType === "date")
      return { ...item, format: { formatter: convertUtcToLocal } }
    return item
  })
}

const getDataColumns = data => {
  if (isEmpty(data))
    return [
      { dataField: "id", dataType: "number", allowEditing: false },
      { dataField: "importStatus", dataType: "string", allowEditing: false },
      { dataField: "importTimestamp", dataType: "date", allowEditing: false },
      { dataField: "importErrors", dataType: "string", allowEditing: false },
      { dataField: "importedBy", dataType: "string", allowEditing: false },
    ]
  const { fields, keyValues, errors, warnings, root, ...rest } = data[0]
  const dataColumns = keys(rest).map(item => {
    if (item === "id") {
      return { dataField: item, dataType: "number", allowEditing: false }
    } else if (item === "importTimestamp") {
      return { dataField: item, dataType: "date", allowEditing: false }
    } else {
      return { dataField: item, dataType: "string", allowEditing: false }
    }
  })
  return dataColumns
}

const getData = ({ data }) => {
  return new ArrayStore({
    data: data.map(item => {
      const { fields, keyValues, errors, warnings, root, ...rest } = item
      return { ...rest, ...fields }
    }),
    key: "id",
  })
}

const getHeaderFilters = async response => {
  const keyValues = new Set()
  response.data.forEach(item => {
    if (isEmpty(item.keyValues) === false)
      Object.entries(item.keyValues).forEach(([key, value]) => {
        const newItem = JSON.stringify({
          [key]: { identifier: value, key: item.fields[key] },
        })
        if (keyValues.has(newItem) === false) {
          keyValues.add(newItem)
        }
      })
  })
  const headers = {}
  const items = Array.from(keyValues).map(item => JSON.parse(item))
  for await (const item of items) {
    Object.entries(item).forEach(async ([key, value]) => {
      const response = await getSageKeyValueByKey(
        2,
        value.identifier,
        value.key
      )
      if (isEmpty(headers[key])) headers[key] = []
      headers[key].push({
        value: value.key,
        text: response.value,
      })
    })
  }
  return headers
}

const getKeyValueTexts = async response => {
  const keyValues = []
  response.data.forEach(item => {
    if (isEmpty(item.keyValues) === false)
      Object.entries(item.keyValues).forEach(([key, value]) => {
        const newItem = {
          [key]: { identifier: value, key: item.fields[key], rowId: item.id },
        }
        keyValues.push(newItem)
      })
  })
  const keyValueText = {}
  const items = Array.from(keyValues)
  for await (const item of items) {
    Object.entries(item).forEach(async ([key, value]) => {
      const response = await getSageKeyValueByKey(
        2,
        value.identifier,
        value.key
      )
      if (isEmpty(keyValueText[key])) keyValueText[key] = []
      keyValueText[key].push({
        value: value.key,
        text: response.value,
        rowId: value.rowId,
      })
    })
  }
  return keyValueText
}

const getKeyValues = ({ data }) => {
  let localKeyValues = {}
  data.forEach(item => {
    const { id, keyValues } = item
    localKeyValues[id] = keyValues
  })
  return localKeyValues
}

const getErrors = ({ data }) => {
  let localErrors = {}
  data.forEach(item => {
    const { id, errors } = item
    let itemError = {}
    if (!isEmpty(errors)) {
      errors.forEach(item => (itemError[item.field] = item.text))
      localErrors[id] = itemError
    }
  })
  return localErrors
}

const getWarnings = ({ data }) => {
  let localWarnings = {}
  data.forEach(item => {
    const { id, warnings } = item
    let itemWarning = {}
    if (!isEmpty(warnings)) {
      warnings.forEach(item => (itemWarning[item.field] = item.text))
      localWarnings[id] = itemWarning
    }
  })
  return localWarnings
}

const generatePutPayload = (headerRawData, lineRawData, rowData) => {
  const headerRow = headerRawData.data.find(item => item.id === rowData.id)
  const { root } = headerRow
  const fields = Object.keys(headerRow.fields).map(item => {
    const columnAttributes = headerRawData.columns.find(
      column => column.dataField === item
    ).attributes
    return {
      dataField: item,
      value: rowData[item],
      attributes: columnAttributes,
    }
  })
  const { root: children_root } = lineRawData

  const children = lineRawData.data
    .filter(item => {
      if (rowData.dm_lines.map(item => item.id).includes(item.id)) return true
      return false
    })
    .map(lineRow => {
      const { root } = lineRow
      const fields = Object.keys(lineRow.fields).map(item => {
        const columnAttributes = lineRawData.columns.find(
          column => column.dataField === item
        ).attributes
        return {
          dataField: item,
          value: rowData.dm_lines.find(item => item.id === lineRow.id)[item],
          attributes: columnAttributes,
        }
      })
      return { fields, root }
    })

  return { fields, root, children_root, children }
}

const InvoiceGrid = ({ invoiceId, timeRange, refreshUuid }) => {
  const originalResponse = useRef(null)
  const originalLineResponse = useRef(null)

  const [columns, setColumns] = useState([])
  const [data, setData] = useState(new ArrayStore())
  const [isLoading, setIsLoading] = useState(false)
  const [keyValues, setKeyValues] = useState({})
  const [errors, setErrors] = useState({})
  const [warnings, setWarnings] = useState({})
  const [selectedRowsData, setSelectedRowsData] = useState(null)
  const [showArchived, setShowArchived] = useState(false)
  const [showDeleted, setShowDeleted] = useState(false)
  const [itemHistoryId, setItemHistoryId] = useState(null)
  const [headerFilters, setHeaderFilters] = useState(null)
  const [keyValueTexts, setKeyValueTexts] = useState(null)

  const getDataSource = useCallback(
    () =>
      invoiceGridDataStoreGenerator(invoiceId, {
        timeRange,
        showArchived,
        showDeleted,
      }),
    [invoiceId, timeRange, showArchived, showDeleted]
  )

  const getLinesHandler = useCallback(
    async rowId => {
      const dataStore = invoiceGridLineDataStoreGenerator(invoiceId, rowId)
      let columns,
        data,
        keyValues,
        keyValueTexts,
        errors,
        warnings,
        headerFilters
      try {
        const response = (await dataStore.load()).data
        originalLineResponse.current = response
        columns = getColumns(response)
        data = getData(response)
        headerFilters = await getHeaderFilters(response)
        keyValues = getKeyValues(response)
        keyValueTexts = await getKeyValueTexts(response)
        errors = getErrors(response)
        warnings = getWarnings(response)
      } catch (error) {
        notify(`Fetching line info failed: ${error.message}`, "error")
      }
      return {
        columns,
        data,
        headerFilters,
        keyValues,
        keyValueTexts,
        errors,
        warnings,
      }
    },
    [invoiceId]
  )

  const getHistoryHandler = useCallback(
    async rowId => {
      const dataStore = invoiceGridHistoryDataStoreGenerator(invoiceId, rowId)
      let columns, data, keyValues
      try {
        const response = (await dataStore.load()).data
        originalLineResponse.current = response
        columns = getColumns(response)
        data = getData(response)
        keyValues = getKeyValues(response)
      } catch (error) {
        notify(`Fetching invoice history failed: ${error.message}`, "error")
        throw error
      }
      return { columns, data, keyValues }
    },
    [invoiceId]
  )

  const getInfo = useCallback(async () => {
    const dataStore = getDataSource()
    try {
      setIsLoading(true)
      const response = (await dataStore.load()).data
      originalResponse.current = response
      const columns = getColumns(response)
      const data = getData(response)
      const keyValues = getKeyValues(response)
      const errors = getErrors(response)
      const warnings = getWarnings(response)
      const headerFilters = await getHeaderFilters(response)
      const keyValueTexts = await getKeyValueTexts(response)
      setColumns(columns)
      setData(data)
      setKeyValues(keyValues)
      setErrors(errors)
      setWarnings(warnings)
      setHeaderFilters(headerFilters)
      setKeyValueTexts(keyValueTexts)
    } catch (error) {
      notify(`Fetching grid data failed: ${error.message}`, "error")
    }
  }, [getDataSource])

  const rowUpdatingHandler = useCallback(
    async ({ oldData, newData, key }) => {
      const updatedRowData = { ...oldData, ...newData }
      const dataSource = getDataSource()
      const payload = generatePutPayload(
        originalResponse.current,
        originalLineResponse.current,
        updatedRowData
      )
      try {
        await dataSource.update(key, payload)
        await getInfo()
      } catch (error) {
        notify(`Updating grid data failed: ${error.message}`, "error")
      }
    },
    [getDataSource, getInfo]
  )

  const reprocessJobHandler = useCallback(
    async rowId => {
      try {
        await reprocessInvoiceJob(invoiceId, rowId)
        notify(
          `Reprocessing invoice job file was queued successfully`,
          "success"
        )
      } catch (error) {
        notify(
          `Reprocessing invoice job file failed: ${error.message}`,
          "error"
        )
      }
    },
    [invoiceId]
  )

  useEffect(() => {
    getInfo()
  }, [getInfo, invoiceId, timeRange, refreshUuid, showArchived])

  const refreshHandler = useCallback(() => {
    getInfo()
  }, [getInfo])

  const importHandler = useCallback(selectedRowsData => {
    setSelectedRowsData(selectedRowsData)
  }, [])

  const importAllHandler = useCallback(async () => {
    try {
      await importAllInvoicesIntoSage(invoiceId)
      notify("Importing all invoices into Sage queued successfully.", "success")
    } catch (error) {
      notify("Importing all invoices into Sage failed", "error")
    }
  }, [invoiceId])

  const importPopupHidingHandler = useCallback(() => {
    setSelectedRowsData(null)
    getInfo()
  }, [getInfo])

  const popupImportHandler = useCallback(
    async invoiceData =>
      await importSelectedInvoiceIntoSageByIds(invoiceId, invoiceData),
    [invoiceId]
  )

  const popupCheckImportStatusHandler = useCallback(
    async itemId => await getInvoiceImportStatus(invoiceId, itemId),
    [invoiceId]
  )

  const downloadAllImagesHandler = useCallback(async () => {
    try {
      await downloadAllInvoiceImages(invoiceId)
      notify(`Downloading all invoice images queued successfully`, "success")
    } catch (error) {
      notify(`Downloading invoice images failed: ${error.message}`, "error")
    }
  }, [invoiceId])

  const downloadFileHandler = useCallback(
    async rowId => {
      const file = await downloadInvoiceJobFile(rowId)
      let fileExtension
      switch (file.fileType) {
        case "application/zip":
          fileExtension = ".zip"
          break
        case "text/csv":
          fileExtension = ".csv"
          break
        default:
          fileExtension = ""
      }
      downloadJs(
        atob(file.data),
        `invoice-${invoiceId}-${rowId}${fileExtension}`,
        `${file.fileType}`
      )
    },
    [invoiceId]
  )

  const downloadImageHandler = useCallback(async rowId => {
    try {
      const imageUrl = await downloadInvoiceImage(rowId)
      if (imageUrl) {
        window.open(imageUrl, "_blank")
      } else {
        notify(`This invoice doesn't have image`, "error")
      }
    } catch (error) {
      notify(`Downloading invoice image failed: ${error.message}`, "error")
    }
  }, [])

  const archiveItemsHandler = useCallback(
    async rowIds => {
      try {
        await archiveInvoices(invoiceId, rowIds)
        await getInfo()
        notify(`Invoice(s) archived successfully`, "success")
      } catch (error) {
        notify(`Archiving invoice(s) failed: ${error.message}`, "error")
      }
    },
    [invoiceId, getInfo]
  )
  const deleteItemsHandler = useCallback(
    async rowIds => {
      try {
        await deleteInvoices(invoiceId, rowIds)
        await getInfo()
        notify(`Invoice(s) deleted successfully`, "success")
      } catch (error) {
        notify(`Deleting invoice(s) failed: ${error.message}`, "error")
      }
    },
    [invoiceId, getInfo]
  )
  const showArchivedValueChangedHandler = useCallback(showArchived => {
    try {
      setShowArchived(showArchived)
    } catch (error) {
      notify(`Loading Invoices failed: ${error.message}`, "error")
    }
  }, [])
  const showDeletedValueChangedHandler = useCallback(showDeleted => {
    try {
      setShowDeleted(showDeleted)
    } catch (error) {
      notify(`Loading Invoices failed: ${error.message}`, "error")
    }
  }, [])

  const showItemHistoryHandler = useCallback(rowId => {
    setItemHistoryId(rowId)
  }, [])

  const historyPopupHidingHandler = useCallback(() => {
    setItemHistoryId(null)
  }, [])

  return (
    <>
      <Popup
        title="Import into Sage"
        visible={selectedRowsData !== null}
        onHiding={importPopupHidingHandler}
      >
        <MetaImport
          columns={columns}
          data={selectedRowsData}
          keyValues={keyValues}
          onImport={popupImportHandler}
          onCheckImportStatus={popupCheckImportStatusHandler}
        />
      </Popup>
      <Popup
        title="Invoice History"
        visible={itemHistoryId !== null}
        onHiding={historyPopupHidingHandler}
      >
        <MetaHistory
          itemId={itemHistoryId}
          getHeaders={getHistoryHandler}
          getLines={getLinesHandler}
          storageKey={`concur-invoices-${invoiceId}-history-grid`}
        />
      </Popup>
      <MetaGrid
        allowDeleting={hasAccessTo("concur.invoice.grid.d")}
        allowDeletingLines={hasAccessTo("concur.invoice.grid.line.d")}
        allowUpdating={hasAccessTo("concur.invoice.grid.u")}
        hasAccessToImport={hasAccessTo("concur.invoice.import")}
        hasAccessToDownloadFile={hasAccessTo("concur.invoice.file")}
        hasAccessToImage={hasAccessTo("concur.invoice.image")}
        hasAccessToArchive={hasAccessTo("concur.invoice.archive")}
        hasAccessToDelete={hasAccessTo("concur.invoice.grid.delete")}
        hasAccessToHistory={hasAccessTo("concur.invoice.history")}
        hasAccessToReprocessJob={hasAccessTo("concur.invoice.reprocess.job")}
        hasAccessToDownloadAllImage={hasAccessTo(
          "concur.invoice.download.image"
        )}
        columns={columns}
        data={data}
        keyValues={keyValues}
        keyValueTexts={keyValueTexts}
        errors={errors}
        warnings={warnings}
        headerFilters={headerFilters}
        isLoading={isLoading}
        getLines={getLinesHandler}
        storageKey={`concur-invoices-${invoiceId}-grid`}
        tooltipItemName="invoice"
        onRowUpdating={rowUpdatingHandler}
        onRefresh={refreshHandler}
        onImport={importHandler}
        onImportAll={importAllHandler}
        onDownloadAllImages={downloadAllImagesHandler}
        onDownloadFile={downloadFileHandler}
        onDownloadImage={downloadImageHandler}
        onArchiveItems={archiveItemsHandler}
        onDeleteItems={deleteItemsHandler}
        onReprocessJob={reprocessJobHandler}
        onShowArchiveValueChange={showArchivedValueChangedHandler}
        onShowDeletedValueChange={showDeletedValueChangedHandler}
        onShowItemHistory={showItemHistoryHandler}
        showArchived={showArchived}
        showDeleted={showDeleted}
      />
      <ItemScheduler
        items={[
          {
            body: null,
            method: "POST",
            target: "#importAll",
            title: `import all invoices into sage (invoiceId=${invoiceId})`,
            url: `/api/v1/concur/invoices/${invoiceId}/grid-data/import-all-into-sage`,
          },
          {
            body: null,
            method: "POST",
            target: "#downloadAllImages",
            title: `download all invoice images (invoiceId=${invoiceId})`,
            url: `/api/v1/concur/invoices/${invoiceId}/images/download-all`,
          },
        ]}
      />
    </>
  )
}

export { InvoiceGrid }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(InvoiceGrid)
