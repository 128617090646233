import { useCallback, useEffect, useRef } from "react"
import html2canvas from "html2canvas"
import { throttle } from "lodash"
import notify from "devextreme/ui/notify"

import { clientLogDataStore } from "./CoreStore"
import { userGeneralSettingDataStore } from "./ClientStores"

const ErrorLogger = ({
  enabled = true,
  errorThrottling = 10000,
  unHandledRejectionHandlerThrottling = 1000,
}) => {
  const sendErrorScreenshot = useRef(false)
  const unHandledRejectionHandler = useCallback(
    throttle(
      async event => {
        let requestedUrl = event.reason?.config?.url ?? null
        const canvas = sendErrorScreenshot.current
          ? await html2canvas(document.body)
          : null
        const message = {
          location: window.location.href,
          message: `${event.reason.message}${
            requestedUrl ? ` (requestedUrl=${requestedUrl})` : ""
          }`,
          platform: navigator.platform,
          screenshot: canvas ? canvas.toDataURL("image/jpeg", 0.2) : null,
          stack: event.reason.stack,
          type: "unHandledRejectionHandler",
          userAgent: navigator.userAgent,
        }
        try {
          if (requestedUrl !== "/api/v1/client-logs")
            await clientLogDataStore.insert(message)
        } catch (error) {
          notify(`Sending error logs failed: ${error.message}`, "error")
        }
        return true
      },
      unHandledRejectionHandlerThrottling,
      { leading: false }
    ),
    []
  )

  const errorHandler = useCallback(
    throttle(
      async event => {
        let screen =
          document.querySelector("iframe")?.contentWindow?.document?.body ??
          document.body
        const canvas = sendErrorScreenshot.current
          ? await html2canvas(screen)
          : null
        const message = {
          location: window.location.href,
          message: `${event.error?.message} (filename=${event.filename}, lineno=${event.lineno}, colno=${event.colno})`,
          platform: navigator.platform,
          referrer: document.referrer,
          screenshot: canvas ? canvas.toDataURL("image/jpeg", 0.2) : null,
          stack: event.error?.stack,
          type: "error",
          userAgent: navigator.userAgent,
        }
        try {
          await clientLogDataStore.insert(message)
        } catch (error) {
          notify(`Sending error logs failed: ${error.message}`, "error")
        }
        return true
      },
      errorThrottling,
      { leading: false }
    ),
    []
  )

  useEffect(() => {
    const getInfo = async () => {
      try {
        const { data } = await userGeneralSettingDataStore.load()
        if (data?.sendErrors === true) {
          window.addEventListener(
            "unhandledrejection",
            unHandledRejectionHandler,
            true
          )
          window.addEventListener("error", errorHandler, true)
        }
        if (data?.sendErrorScreenshot) {
          sendErrorScreenshot.current = true
        }
      } catch (error) {
        notify(`Getting user settings failed: ${error.message}`, "error")
      }
    }
    enabled && getInfo()
    return () => {
      window.removeEventListener(
        "unhandledrejection",
        unHandledRejectionHandler,
        true
      )
      window.removeEventListener("error", errorHandler, true)
    }
  }, [unHandledRejectionHandler, errorHandler, enabled])

  return null
}
export default ErrorLogger
