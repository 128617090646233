import React from "react"
import styled from "styled-components"
import { Router } from "@reach/router"

import SEO from "../../seo"
import Dashboard from "./dashboard"
import SearchResults from "./dashboard/SearchResults"

const HomeW = styled.div`
  height: 100%;
`
const Home = () => {
  return (
    <>
      <SEO title="Shopify" />
      <HomeW>
        <Router>
          <Dashboard path="/" />
          <SearchResults path="/search-results" />
          <SearchResults
            path="/orders"
            localState={{
              state: {
                type: "search",
                plantType: "shopify_order",
                searchValue: "",
                title: "Shopify Orders",
              },
            }}
          />
          <SearchResults
            path="/inventories"
            localState={{
              state: {
                type: "search",
                plantType: "shopify_inventory",
                searchValue: "",
                title: "Shopify Inventories",
              },
            }}
          />
        </Router>
      </HomeW>
    </>
  )
}

export default Home
