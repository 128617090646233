import React, { useRef, useState } from "react"
import { Button, DataGrid, Template, TextArea } from "devextreme-react"
import {
  Button as GButton,
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  FilterPanel,
  FilterRow,
  FormItem,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  StateStoring,
} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"
import styled from "styled-components"

import { runEmailNotificationQuery } from "../../../operations/client"
import {
  emailNotificationDataStore,
  userDataStore,
} from "../../../shared/ClientStores"
import { VscServerProcess } from "react-icons/vsc"
import ItemScheduler from "../../../shared/ItemScheduler"
import { hasAccessTo } from "../../../../authentication"

const Label = styled.div`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`

const Saved = () => {
  const queriesGridRef = useRef(null)
  const [result, setResult] = useState(null)
  const [rawResult, setRawResult] = useState(null)

  const refreshQueriesGridClickHandler = () => {
    queriesGridRef.current.instance.refresh(true)
  }

  const runButtonRenderHandler = ({ data }) => (
    <div style={{ display: "inline-block" }}>
      <VscServerProcess
        onClick={runEmailNotificationQueryClickHandler(data.id)}
        style={{
          width: "15px",
          height: "15px",
          cursor: "pointer",
          margin: "-8px 3px 0 3px",
          color: "rgb(51 122 183)",
        }}
        title="Run this email notification query"
        id={`emailNotificationQuery-${data.id}`}
      />
      <ItemScheduler
        items={[
          {
            body: null,
            method: "POST",
            target: `#emailNotificationQuery-${data.id}`,
            title: `email notification queries (emailNotificationId=${data.id}) - ${data.title}`,
            url: `/api/v1/email-notifications/${data.id}/execute`,
          },
        ]}
      />
    </div>
  )

  const runEmailNotificationQueryClickHandler = id => async () => {
    try {
      setResult(null)
      setRawResult(null)
      queriesGridRef.current.instance.beginCustomLoading()
      const response = await runEmailNotificationQuery(id)
      const result = response.data
      setResult(result)
      try {
        const rawResult = JSON.stringify(result)
        setRawResult(rawResult)
      } catch (error) {
        setRawResult(result)
      }
      notify(`Query ran successfully`, "success")
    } catch (error) {
      notify(`Executing query failed: ${error.message}`, "error")
    } finally {
      queriesGridRef.current.instance.endCustomLoading()
    }
  }
  const toolbarPreparingHandler = e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }
  return (
    <>
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={emailNotificationDataStore}
        hoverStateEnabled={true}
        ref={queriesGridRef}
        rowAlternationEnabled={true}
        showBorders={true}
        onToolbarPreparing={toolbarPreparingHandler}
      >
        <Column dataField="id" defaultVisible={false} allowEditing={false} />
        <Column dataField="title" dataType="string">
          <RequiredRule />
        </Column>
        <Column dataField="subject" dataType="string" />
        <Column dataField="sendEmptyResult" dataType="boolean" />
        <Column dataField="emptyResultMessage" dataType="string" />
        <Column
          caption="Users"
          dataField="userIds"
          showInColumnChooser={false}
          visible={false}
        >
          <Lookup
            dataSource={userDataStore}
            displayExpr="email"
            valueExpr="id"
          />
          <FormItem
            editorType="dxTagBox"
            editorOptions={{
              searchEnabled: true,
              showSelectionControls: true,
            }}
          />
          <RequiredRule />
        </Column>
        <Column
          caption="Email CC Addresses:"
          dataField="carbonCopies"
          dataType="string"
        />
        <Column
          formItem={{ itemType: "empty" }}
          showInColumnChooser={false}
          visible={false}
        />
        <Column
          dataField="bodyHeader"
          dataType="string"
          formItem={{ colSpan: 2, editorType: "dxTextArea" }}
        />
        <Column
          dataField="body"
          dataType="string"
          formItem={{ colSpan: 2, editorType: "dxTextArea" }}
        />
        <Column
          dataField="bodyFooter"
          dataType="string"
          formItem={{ colSpan: 2, editorType: "dxTextArea" }}
        />
        <Column showInColumnChooser={false} type="buttons">
          <GButton name="delete" />
          <GButton name="edit" />
          <GButton name="run" render={runButtonRenderHandler} />
        </Column>
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          allowDeleting={hasAccessTo("email-notification.d")}
          allowUpdating={hasAccessTo("email-notification.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter allowSearch={true} visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Paging defaultPageSize={10} />
        <Scrolling showScrollbar="always" />
        <SearchPanel width={250} visible={true} />
        <StateStoring
          enabled={true}
          storageKey="profile-developments-email-notification-saved"
          type="localStorage"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshQueriesGridClickHandler} />
        </Template>
      </DataGrid>
      {result && (
        <>
          <Label>Query results:</Label>
          <TextArea
            autoResizeEnabled={true}
            readOnly={true}
            value={rawResult}
          />
        </>
      )}
    </>
  )
}

export default Saved
