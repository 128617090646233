import React from "react"
import { Accordion } from "devextreme-react"
import { FaEnvelope } from "react-icons/fa"

import { Item as AccordionItem } from "devextreme-react/accordion"
import { FaChartBar, FaTable } from "../../../fa-icons"
import { hasAccessTo } from "../../../authentication"
import { PageTitle } from "../../shared/StyledComponents"
import MessageGrid from "./MessageGrid"
import MessageChart from "./MessageChart"
import MessagePivot from "./MessagePivot"
import SEO from "../../../seo"
import { getAccordionState, setAccordionState } from "../../shared/utilities"

const Messages = () => {
  const stateKey = "profile-messages"

  return (
    <>
      <SEO title="Messages" />
      <PageTitle icon={FaEnvelope}>Messages</PageTitle>
      <Accordion
        collapsible={true}
        multiple={true}
        onInitialized={getAccordionState(stateKey)}
        onSelectionChanged={setAccordionState(stateKey)}
      >
        <AccordionItem
          icon={FaChartBar}
          title=" Chart"
          visible={hasAccessTo("msg.chart")}
        >
          <MessageChart />
        </AccordionItem>
        <AccordionItem
          icon={FaChartBar}
          title=" Pivot"
          visible={hasAccessTo("msg.r")}
        >
          <MessagePivot />
        </AccordionItem>
        <AccordionItem
          icon={FaTable}
          title=" Grid"
          visible={hasAccessTo("msg.r")}
        >
          <MessageGrid />
        </AccordionItem>
      </Accordion>
    </>
  )
}

export { Messages }
export default Messages
