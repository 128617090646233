import React from "react"
import Lists from "./Lists"
import ListItems from "./ListItems"
import { Router } from "@reach/router"

const Home = () => {
  return (
    <Router>
      <Lists path="/" />
      <ListItems path=":listId/list-items" />
    </Router>
  )
}

export default Home
