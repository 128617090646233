import React, { useState, useRef } from "react"
import { Toolbar, Button, TextBox, Popover, Form } from "devextreme-react"
import { Button as TextBoxButton } from "devextreme-react/text-box"
import { Item as ToolbarItem } from "devextreme-react/toolbar"
import {
  Item as FormItem,
  ButtonItem,
  RequiredRule,
} from "devextreme-react/form"
import { useNavigate, useLocation } from "@reach/router"
import dayjs from "dayjs"
import { connect } from "react-redux"
import styled from "styled-components"

import {
  dashboardOperations,
  dashboardSelectors,
} from "../../state/ducks/dashboard"
import AutoRefresher from "./AutoRefresher"
import { calculateAggregationInterval } from "./shared/utilities"
import useResponsiveWidth from "./shared/hooks/useResponsiveWidth"
import { ioMdArrowRoundBack } from "./shared/icons"

const ToolbarW = styled.div`
  border-radius: 0.4rem;
`

const listItemDataSource = [
  {
    title: "Last 5 minutes",
    value: 300,
  },
  {
    title: "Last 15 minutes",
    value: 900,
  },
  {
    title: "Last 30 minutes",
    value: 1800,
  },
  { title: "Last 1 hours", value: 3600 },
  { title: "Last 3 hours", value: 10800 },
  { title: "Last 6 hours", value: 21600 },
  {
    title: "Last 12 hours",
    value: 43200,
  },
  {
    title: "Last 24 hours",
    value: 86400,
  },
  { title: "Last 2 days", value: 172800 },
  { title: "Last 7 days", value: 604800 },
  { title: "Last 30 days", value: 2592000 },
  { title: "Last 90 days", value: 7776000 },
  {
    title: "Last 6 months",
    value: dayjs(dayjs() - dayjs().subtract(6, "month")).unix(),
  },
  {
    title: "Last 1 year",
    value: dayjs(dayjs() - dayjs().subtract(1, "year")).unix(),
  },
  {
    title: "Last 2 years",
    value: dayjs(dayjs() - dayjs().subtract(2, "year")).unix(),
  },
  {
    title: "Last 5 years",
    value: dayjs(dayjs() - dayjs().subtract(5, "year")).unix(),
  },
]

const generateText = (from, to, last) => {
  if (last) {
    const index = listItemDataSource.findIndex(item => item.value === last)
    return listItemDataSource[index].title
  } else {
    return (
      dayjs(from).format("MM-DD-YYYY HH:mm:ss") +
      " To " +
      dayjs(to).format("MM-DD-YYYY HH:mm:ss")
    )
  }
}

const Header = ({ drawerOpen, timeRange, setUiDrawerOpen, setTimeRange }) => {
  const { from, to, last } = timeRange
  const formRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const [showPopover, setShowPopover] = useState(false)
  const [text, setText] = useState(generateText(from, to, last))
  const { xs } = useResponsiveWidth()

  const textBoxButtonClickHandler = () => {
    setShowPopover(true)
  }
  const popoverHidingHandler = () => {
    setShowPopover(false)
  }
  const applyTimeRangeClickHandler = () => {
    const { from, to } = formRef.current.instance.option("formData")
    setTimeRange({
      from,
      to,
      last: null,
      aggregationInterval: calculateAggregationInterval(from, to),
    })
    const text =
      dayjs(from).format("MM-DD-YYYY HH:mm:ss") +
      " To " +
      dayjs(to).format("MM-DD-YYYY HH:mm:ss")
    setText(text)
    setShowPopover(false)
  }
  const listItemClickHandler = ({ itemData }) => {
    setTimeRange({
      last: itemData.value,
      aggregationInterval: calculateAggregationInterval(
        null,
        null,
        itemData.value
      ),
    })
    setText(itemData.title)
    setShowPopover(false)
  }
  const menuButtonClickHandler = () => {
    setUiDrawerOpen(true)
  }

  return (
    <ToolbarW>
      <Toolbar
        style={{
          borderRadius: "0.4rem",
          backgroundColor: "transparent",
        }}
      >
        <ToolbarItem location="before" visible={!drawerOpen}>
          <Button
            icon="menu"
            hint="Show sidebar."
            onClick={menuButtonClickHandler}
          />
        </ToolbarItem>
        <ToolbarItem location="before">
          <Button
            icon={ioMdArrowRoundBack}
            hint="Go back"
            text="Back"
            disabled={location.pathname === "/dashboard/"}
            onClick={() => navigate(-1)}
          />
        </ToolbarItem>
        <ToolbarItem location="after">
          <TextBox
            id="toolbarDataRangeTextBox"
            value={text}
            readOnly={true}
            width={xs ? null : "350px"}
          >
            <TextBoxButton
              location="before"
              name="calender"
              options={{
                disabled: false,
                icon: "event",
                onClick: textBoxButtonClickHandler,
              }}
            />
          </TextBox>
        </ToolbarItem>
        <ToolbarItem location="after">
          <AutoRefresher />
        </ToolbarItem>
      </Toolbar>
      <Popover
        visible={showPopover}
        target="#toolbarDataRangeTextBox"
        title="Time range"
        showCloseButton={true}
        showTitle={true}
        onHiding={popoverHidingHandler}
      >
        <Form ref={formRef} formData={{ from, to }} colCount={2} width="500px">
          <FormItem itemType="group">
            <FormItem
              dataField="from"
              editorType="dxDateBox"
              editorOptions={{
                type: "datetime",
                inputAttr: { readonly: true },
              }}
            >
              <RequiredRule />
            </FormItem>
            <FormItem
              dataField="to"
              editorType="dxDateBox"
              editorOptions={{ type: "datetime" }}
            >
              <RequiredRule />
            </FormItem>
            <ButtonItem
              horizontalAlignment="left"
              visible={true}
              buttonOptions={{
                text: "Apply time range",
                onClick: applyTimeRangeClickHandler,
              }}
            />
          </FormItem>
          <FormItem itemType="group">
            <FormItem
              editorType="dxList"
              editorOptions={{
                selectionMode: "single",
                dataSource: listItemDataSource,
                valueExpr: "value",
                displayExpr: "title",
                height: "250px",
                defaultValue: last,
                onItemClick: listItemClickHandler,
              }}
            >
              <RequiredRule />
            </FormItem>
          </FormItem>
        </Form>
      </Popover>
    </ToolbarW>
  )
}
export { Header }

const mapStateToProps = state => ({
  drawerOpen: dashboardSelectors.selectUiDrawerOpen(state),
  timeRange: dashboardSelectors.selectTimeRange(state),
})
const mapDispatchToProps = dispatch => {
  return {
    setTimeRange: timeRange =>
      dispatch(dashboardOperations.setTimeRange(timeRange)),
    setUiDrawerOpen: drawerOpen =>
      dispatch(dashboardOperations.setUiDrawerOpen(drawerOpen)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
