import React from "react"
import { TabPanel } from "devextreme-react"
import DataSource from "devextreme/data/data_source"
import { MdPayment } from "react-icons/md"

import { paymentDataStore } from "../../shared/ConcurStore"
import Payment from "./Payment"
import { PageTitle } from "../../shared/StyledComponents"
import { hasAccessTo } from "../../../authentication"
import SEO from "../../../seo"

function Home() {
  const paymentDataSource = new DataSource({
    store: paymentDataStore,
    filter: ["isActive", "=", "true"],
  })
  return (
    <>
      <SEO title="Concur Payments" />
      <PageTitle icon={MdPayment}>Payments</PageTitle>
      <TabPanel
        dataSource={paymentDataSource}
        itemComponent={Payment}
        visible={hasAccessTo("concur.payment.r")}
      />
    </>
  )
}

export default Home
