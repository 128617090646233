import React, { useEffect, useRef, useState } from "react"
import { DataGrid, Template, Button, Popup } from "devextreme-react"
import {
  ColumnChooser,
  ColumnFixing,
  StateStoring,
  FilterPanel,
  Grouping,
  SearchPanel,
  Pager,
  GroupPanel,
  HeaderFilter,
  FilterRow,
  Scrolling,
  Export,
  Selection,
} from "devextreme-react/data-grid"
import { connect } from "react-redux"
import notify from "devextreme/ui/notify"

import { TabItemGridW } from "../../shared/StyledComponents"
import { vendorDataMapVendorDataStore } from "../../shared/ConcurStore"
import { FaCloudUploadAlt } from "../../../fa-icons"
import { sendVendorsToConcur, resetVendorHash } from "../../operations/concur"
import { convertUtcToLocal } from "../../shared/utilities"
import { hasAccessTo } from "../../../authentication"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import ItemScheduler from "../../shared/ItemScheduler"
import DataMapHistory from "./DataMapHistory"
import { faHistory } from "../../shared/icons"

function DataMapVendors({ refreshUuid }) {
  const vendorGrid = useRef(null)
  const [historyVendorId, setHistoryVendorId] = useState(null)

  useEffect(() => {
    vendorGrid.current.instance.refresh(true)
  }, [refreshUuid])

  const refreshButtonClickHandler = () => {
    vendorGrid.current.instance.refresh(true)
  }
  const sendVendorsToConcurClickHandler = async () => {
    try {
      await sendVendorsToConcur()
      notify("Sending vendors to Concur was queued successfully.", "success")
    } catch (error) {
      notify(`Sending vendors to Concur failed: ${error.message}`, "error")
    }
  }
  const historyButtonClickHandler = ({ row }) => {
    setHistoryVendorId(row.key)
  }
  const customizeColumnsHandler = columns => {
    const processDateIndex = columns.findIndex(column =>
      column.dataField === "processDate" ? true : false
    )
    if (processDateIndex !== -1) {
      columns[processDateIndex].dataType = "datetime"
      columns[processDateIndex].format = {
        formatter: convertUtcToLocal,
      }
    }
    const onResetHashClickHandler = async ({ row }) => {
      try {
        await resetVendorHash({ id: row.key })
        notify(
          `Forcing to resend vendor to Concur was done successfully.`,
          "success"
        )
      } catch (error) {
        notify(
          `Forcing to resend vendors to Concur failed: ${error.message}`,
          "error"
        )
      }
    }
    columns.push({
      type: "buttons",
      showInColumnChooser: false,
      visible: hasAccessTo("concur.vendor.history"),
      buttons: [
        {
          cssClass: "dx-link customColumnButton",
          hint: "Show vendor history",
          icon: faHistory,
          name: "history",
          visible: hasAccessTo("concur.vendor.history"),
          onClick: historyButtonClickHandler,
        },
        {
          cssClass: "dx-link customColumnButton",
          hint: "Force resend this vendor on next try",
          icon: "refresh",
          name: "reset hash",
          visible: hasAccessTo("concur.vendor.reset.hash"),
          onClick: onResetHashClickHandler,
        },
      ],
    })
  }

  const popupHidingHandler = () => {
    setHistoryVendorId(null)
  }
  return (
    <>
      <TabItemGridW>
        <Popup
          title="Vendor History"
          visible={historyVendorId !== null}
          onHiding={popupHidingHandler}
        >
          <DataMapHistory vendorId={historyVendorId} />
        </Popup>
        <DataGrid
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          columnResizingMode="widget"
          customizeColumns={customizeColumnsHandler}
          dataSource={vendorDataMapVendorDataStore}
          hoverStateEnabled={true}
          ref={vendorGrid}
          rowAlternationEnabled={true}
          showBorders={true}
          onToolbarPreparing={e => {
            e.toolbarOptions.items.unshift({
              location: "before",
              template: "sendVendorsToConcur",
            })
            e.toolbarOptions.items.unshift({
              location: "before",
              template: "refreshGrid",
            })
          }}
        >
          <ColumnChooser
            allowSearch={true}
            enabled={true}
            height="400"
            mode="select"
          />
          <ColumnFixing enabled={true} />
          <Export
            allowExportSelectedData={true}
            enabled={true}
            fileName="datamap-vendors"
          />
          <FilterPanel visible={true} />
          <FilterRow visible={true} />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling showScrollbar="always" />
          <SearchPanel visible={true} />
          <Selection
            allowSelectAll={true}
            mode="multiple"
            selectAllMode="page"
            showCheckBoxesMode="always"
          />
          <StateStoring
            enabled={true}
            storageKey="concur-vendors-datamap"
            type="localStorage"
          />
          <Pager
            allowedPageSizes={[10, 20, 50, 100]}
            showInfo={true}
            showNavigationButtons={true}
            showPageSizeSelector={true}
            visible={true}
          />
          <Template name="sendVendorsToConcur">
            <Button
              elementAttr={{ id: "sendVendorsToConcur" }}
              hint="Send vendors to Concur"
              icon={FaCloudUploadAlt}
              text="Send to Concur"
              visible={hasAccessTo("concur.vendor.to.concur")}
              onClick={sendVendorsToConcurClickHandler}
            />
          </Template>
          <Template name="refreshGrid">
            <Button
              hint="Update grid"
              icon="refresh"
              onClick={refreshButtonClickHandler}
            />
          </Template>
        </DataGrid>
      </TabItemGridW>
      <ItemScheduler
        items={[
          {
            body: null,
            method: "POST",
            target: "#sendVendorsToConcur",
            title: "send vendors to concur",
            url: "/api/v1/concur/vendors/send-vendors-to-concur",
          },
        ]}
      />
    </>
  )
}

export { DataMapVendors }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(DataMapVendors)
