import React from "react"
import { TabPanel } from "devextreme-react"
import { Item as TabPanelItem } from "devextreme-react/tab-panel"
import { MdDeveloperMode } from "react-icons/md"

import { PageTitle, TabItemW } from "../../shared/StyledComponents"
import { hasAccessTo, hasAccessToAny } from "../../../authentication"
import LocalSettings from "./LocalSettings"
import SEO from "../../../seo"
import DatamapDatabase from "./DatamapDatabase"
import Queries from "./queries"
import DatamapQueries from "./DatamapQueries"
import EmailNotification from "./email-notification"
import ClientSettings from "./ClientSettings"
import {
  aiOutlineFileSearch,
  biMailSend,
  faCogs,
  faDatabase,
  faUserFriends,
} from "../../shared/icons"

const Developments = () => {
  return (
    <>
      <SEO title="Developments" />
      <PageTitle icon={MdDeveloperMode}>Developments</PageTitle>
      <TabPanel defaultSelectedIndex={0}>
        <TabPanelItem icon={faCogs} title="Local Settings">
          <LocalSettings />
        </TabPanelItem>
        <TabPanelItem
          icon={aiOutlineFileSearch}
          title="Queries"
          visible={hasAccessTo("query.r")}
        >
          <TabItemW>
            <Queries />
          </TabItemW>
        </TabPanelItem>
        <TabPanelItem
          icon={aiOutlineFileSearch}
          title="Datamap Queries"
          visible={hasAccessTo("datamap-query.r")}
        >
          <TabItemW>
            <DatamapQueries />
          </TabItemW>
        </TabPanelItem>
        <TabPanelItem
          icon={biMailSend}
          title="Email Notification"
          visible={hasAccessTo("email-notification.r")}
        >
          <TabItemW>
            <EmailNotification />
          </TabItemW>
        </TabPanelItem>
        <TabPanelItem
          icon={faDatabase}
          title="Datamap Database"
          visible={hasAccessTo("client.migrate")}
        >
          <TabItemW>
            <DatamapDatabase />
          </TabItemW>
        </TabPanelItem>
        <TabPanelItem
          icon={faUserFriends}
          title="Client's Settings"
          visible={hasAccessToAny([
            "client.setting.r",
            "client.connector.download",
          ])}
        >
          <TabItemW>
            <ClientSettings />
          </TabItemW>
        </TabPanelItem>
      </TabPanel>
    </>
  )
}

export default Developments
