import React from "react"
import { Button } from "devextreme-react"
import notify from "devextreme/ui/notify"

import {
  migrateConcurClientDatabases,
  migrateConcurDatamapDatabase,
} from "../../operations/core"

const Database = () => {
  const migrateClientsClickHandler = async () => {
    try {
      await migrateConcurClientDatabases()
      notify("Concur client databases was migrated successfully.", "success")
    } catch (error) {
      notify(
        `Concur client databases migration failed: ${error.message}`,
        "error"
      )
    }
  }
  const migrateDatamapClickHandler = async () => {
    try {
      await migrateConcurDatamapDatabase()
      notify("Concur datamap database was migrated successfully.", "success")
    } catch (error) {
      notify(
        `Concur datamap database migration failed: ${error.message}`,
        "error"
      )
    }
  }
  return (
    <>
      <Button
        text="Migrate Clients"
        onClick={migrateClientsClickHandler}
        style={{ marginRight: "1rem" }}
      />
      <Button text="Migrate Datamap" onClick={migrateDatamapClickHandler} />
    </>
  )
}

export default Database
