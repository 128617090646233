import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Button, DataGrid, Switch, Template } from "devextreme-react"
import PropTypes from "prop-types"
import {
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  FilterPanel,
  FilterRow,
  Grouping,
  HeaderFilter,
  LoadPanel,
  MasterDetail,
  Pager,
  Scrolling,
  SearchPanel,
  Selection,
  Button as GridButton,
  StateStoring,
  Summary,
  TotalItem,
  Popup,
  Sorting,
  Export,
} from "devextreme-react/data-grid"
import { exportDataGrid } from "devextreme/excel_exporter"
import ExcelJS from "exceljs"
import saveAs from "file-saver"

import useResponsiveWidth from "../hooks/useResponsiveWidth"
import MetaGridLine from "./MetaGridLine"
import ArrayStore from "devextreme/data/array_store"
import MetaCell from "./MetaCell"
import MetaEditCell from "./MetaEditCell"
import { FaFileImport } from "../../../fa-icons"
import { dashboardOperations } from "../../../../state/ducks/dashboard"
import { faCloudDownloadAlt, faHistory } from "../icons"
import { getLocalStorageState, setLocalStorageGridState } from "../utilities"
import { getSageKeyValueByKey } from "../../operations/client"

const calculateSortValueHandler = (keyValueTexts, dataField) => row =>
  keyValueTexts[dataField].find(item => item.rowId === row.id)?.text

const MetaGrid = memo(
  ({
    allowDeleting,
    allowDeletingLines,
    allowUpdating,
    columns,
    data,
    errors,
    freezeAutoRefresher,
    getLines,
    hasAccessToArchive,
    hasAccessToDelete,
    hasAccessToDownloadAllImage,
    hasAccessToDownloadFile,
    hasAccessToHistory,
    hasAccessToImage,
    hasAccessToImport,
    hasAccessToReprocessJob,
    headerFilters,
    keyValues,
    keyValueTexts,
    showArchived,
    showDeleted,
    storageKey,
    tooltipItemName,
    warnings,
    onArchiveItems,
    onDeleteItems,
    onDownloadAllImages,
    onDownloadFile,
    onDownloadImage,
    onImport,
    onImportAll,
    onRefresh,
    onRowUpdating,
    onReprocessJob,
    onShowArchiveValueChange,
    onShowDeletedValueChange,
    onShowItemHistory,
  }) => {
    const gridRef = useRef(null)

    const { md, lg } = useResponsiveWidth()

    useEffect(() => {
      return () => {
        freezeAutoRefresher(false)
      }
    }, [freezeAutoRefresher])

    const refreshButtonClickHandler = useCallback(() => {
      onRefresh()
    }, [onRefresh])

    const importButtonClickHandler = useCallback(() => {
      const selectedRowsData = gridRef.current.instance.getSelectedRowsData()
      onImport(selectedRowsData)
      gridRef.current.instance.clearSelection()
    }, [onImport])

    const importAllButtonClickHandler = useCallback(async () => {
      try {
        gridRef.current.instance.beginCustomLoading()
        await onImportAll()
      } catch (error) {
      } finally {
        gridRef.current.instance.endCustomLoading()
      }
    }, [onImportAll])

    const downloadFileClickHandler = useCallback(
      async e => {
        const rowId = e.row.data.id
        try {
          gridRef.current.instance.beginCustomLoading()
          await onDownloadFile(rowId)
        } catch (error) {
        } finally {
          gridRef.current.instance.endCustomLoading()
        }
      },
      [onDownloadFile]
    )

    const downloadImageClickHandler = useCallback(
      async e => {
        const rowId = e.row.data.id
        try {
          gridRef.current.instance.beginCustomLoading()
          await onDownloadImage(rowId)
        } catch (error) {
        } finally {
          gridRef.current.instance.endCustomLoading()
        }
      },
      [onDownloadImage]
    )

    const downloadAllImagesButtonClickHandler = useCallback(async () => {
      try {
        gridRef.current.instance.beginCustomLoading()
        await onDownloadAllImages()
      } catch (error) {
      } finally {
        gridRef.current.instance.endCustomLoading()
      }
    }, [onDownloadAllImages])

    const archiveItemClickHandler = useCallback(
      async e => {
        const rowId = e.row.data.id
        try {
          await onArchiveItems([rowId])
        } catch (error) {
        } finally {
        }
      },
      [onArchiveItems]
    )
    const historyItemClickHandler = useCallback(
      async e => {
        const rowId = e.row.data.id
        onShowItemHistory(rowId)
      },
      [onShowItemHistory]
    )

    const archiveButtonClickHandler = useCallback(async () => {
      const selectedRowsData = gridRef.current.instance.getSelectedRowKeys()
      try {
        gridRef.current.instance.beginCustomLoading()
        await onArchiveItems(selectedRowsData)
      } catch (error) {
      } finally {
        gridRef.current.instance.endCustomLoading()
        gridRef.current.instance.clearSelection()
      }
    }, [onArchiveItems])

    const archiveSwitchValueChangedHandler = useCallback(
      async ({ value }) => {
        try {
          gridRef.current.instance.beginCustomLoading()
          onShowArchiveValueChange(value)
        } catch (error) {
        } finally {
          gridRef.current.instance.endCustomLoading()
        }
      },
      [onShowArchiveValueChange]
    )

    const deleteButtonClickHandler = useCallback(async () => {
      const selectedRowsData = gridRef.current.instance.getSelectedRowKeys()
      try {
        gridRef.current.instance.beginCustomLoading()
        await onDeleteItems(selectedRowsData)
      } catch (error) {
      } finally {
        gridRef.current.instance.endCustomLoading()
        gridRef.current.instance.clearSelection()
      }
    }, [onDeleteItems])

    const deleteSwitchValueChangedHandler = useCallback(
      async ({ value }) => {
        try {
          gridRef.current.instance.beginCustomLoading()
          onShowDeletedValueChange(value)
        } catch (error) {
        } finally {
          gridRef.current.instance.endCustomLoading()
        }
      },
      [onShowDeletedValueChange]
    )

    const toolbarPreparingHandler = useCallback(e => {
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "showDeleted",
      })
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "showArchived",
      })
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "downloadAllImages",
      })
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "delete",
      })
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "archive",
      })
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "importIntoSage",
      })
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "importAllIntoSage",
      })
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "refreshGrid",
      })
    }, [])

    const editingPopupShownHandler = () => {
      freezeAutoRefresher(true)
    }

    const editingPopupHiddenHandler = () => {
      freezeAutoRefresher(false)
    }

    const cellRenderHandler = useCallback(
      props => {
        const id = props.data.id
        const dataField = props.column.dataField
        const text = props.text
        const error = errors && errors[id] && errors[id][dataField]
        const warning = warnings && warnings[id] && warnings[id][dataField]
        const style = {
          backgroundColor: error
            ? "rgb(255 149 146)"
            : warning
            ? "rgb(255 211 146)"
            : null,
        }

        return (
          <div title={error ?? warning} style={style}>
            {text}
          </div>
        )
      },
      [errors, warnings]
    )

    const reprocessItemClickHandler = useCallback(
      async e => {
        const rowId = e.row.data.id
        onReprocessJob(rowId)
      },
      [onReprocessJob]
    )
    const customSaveHandler = useCallback(
      state => {
        setLocalStorageGridState(storageKey, state)
      },
      [storageKey]
    )
    const customLoadHandler = useCallback(() => {
      return Promise.resolve(getLocalStorageState(storageKey))
    }, [storageKey])

    const exportingHandler = useCallback(
      e => {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet("Sheet-1")
        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
          keepColumnWidths: false,
          customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === "data") {
              try {
                if (
                  keyValues[gridCell.data.id] &&
                  keyValues[gridCell.data.id][gridCell.column.dataField]
                ) {
                  getSageKeyValueByKey(
                    2,
                    keyValues[gridCell.data.id][gridCell.column.dataField],
                    gridCell.value
                  ).then(valueText => {
                    excelCell.value = valueText.value
                  })
                } else {
                  excelCell.value = gridCell.value
                }
              } catch (e) {
                excelCell.value = gridCell.value
              }
            }
          },
        }).then(() => {
          workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              `datamap-${tooltipItemName}s.xlsx`
            )
          })
        })
        e.cancel = true
      },
      [keyValues, tooltipItemName]
    )

    return columns.length > 0 ? (
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={data}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        showBorders={true}
        onRowUpdating={onRowUpdating}
        ref={gridRef}
        onExporting={exportingHandler}
        onToolbarPreparing={toolbarPreparingHandler}
      >
        {columns.map(item => {
          const hasKeyValue = Object.keys(keyValues).some(
            key => keyValues && keyValues[key] && keyValues[key][item.dataField]
          )
          if (hasKeyValue)
            return (
              <Column
                key={item.dataField}
                {...item}
                cellRender={data => (
                  <MetaCell
                    {...data}
                    keyValues={keyValues}
                    errors={errors}
                    warnings={warnings}
                  />
                )}
                editCellRender={data => (
                  <MetaEditCell {...data} keyValues={keyValues} />
                )}
                calculateSortValue={calculateSortValueHandler(
                  keyValueTexts,
                  item.dataField
                )}
              >
                <HeaderFilter
                  dataSource={data => {
                    data.dataSource.postProcess = results => {
                      return headerFilters[item.dataField] ?? results
                    }
                  }}
                />
              </Column>
            )
          return (
            <Column
              key={item.dataField}
              {...item}
              cellRender={cellRenderHandler}
            />
          )
        })}
        {columns.length % 2 === 1 && (
          <Column
            visible={false}
            showInColumnChooser={false}
            formItem={{
              colSpan: 1,
              itemType: "empty",
              label: {
                visible: false,
              },
            }}
          />
        )}
        <Column
          dataField="dm_lines"
          visible={false}
          allowEditing={true}
          showInColumnChooser={false}
          editCellComponent={MasterDetailGrid({
            getLines,
            storageKey,
            allowDeleting: allowDeletingLines,
            allowUpdating: true,
          })}
          formItem={{
            colSpan: 2,
            label: {
              location: "top",
              text: "Lines",
            },
          }}
        />
        <Column type="buttons" showInColumnChooser={false}>
          <GridButton name="edit" />
          <GridButton name="delete" />
          <GridButton
            name="downloadFile"
            hint={`Download ${tooltipItemName} file`}
            icon="file"
            visible={hasAccessToDownloadFile}
            onClick={downloadFileClickHandler}
          />
          <GridButton
            name="downloadImage"
            hint={`Download ${tooltipItemName} image`}
            icon="image"
            visible={hasAccessToImage}
            onClick={downloadImageClickHandler}
          />
          <GridButton
            name="archive"
            hint={`Archive selected ${tooltipItemName}`}
            icon="box"
            visible={hasAccessToHistory}
            onClick={archiveItemClickHandler}
          />
          <GridButton
            name="history"
            hint={`Show ${tooltipItemName} history`}
            cssClass="dx-link customColumnButton"
            icon={faHistory}
            visible={hasAccessToArchive}
            onClick={historyItemClickHandler}
          />
          <GridButton
            name="reprocessJob"
            hint={`Reprocess ${tooltipItemName} job file`}
            icon="refresh"
            visible={hasAccessToReprocessJob}
            onClick={reprocessItemClickHandler}
          />
        </Column>
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          mode="popup"
          useIcons={true}
          allowDeleting={false && allowDeleting}
          allowUpdating={allowUpdating}
        >
          <Popup
            onShown={editingPopupShownHandler}
            onHidden={editingPopupHiddenHandler}
          ></Popup>
        </Editing>
        <Export
          allowExportSelectedData={true}
          enabled={true}
          fileName={`datamap-${tooltipItemName}s`}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={false} />
        <Grouping contextMenuEnabled={true} />
        <HeaderFilter visible={true} />
        <LoadPanel shading={true} enabled={true} />
        <MasterDetail
          enabled={true}
          component={MasterDetailGrid({
            getLines,
            storageKey,
          })}
        />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <StateStoring
          enabled={storageKey ? true : false}
          type="custom"
          customLoad={customLoadHandler}
          customSave={customSaveHandler}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={md || lg} />
        <Selection
          allowSelectAll={true}
          mode="multiple"
          selectAllMode="page"
          showCheckBoxesMode="always"
        />
        <Sorting mode="multiple" />
        <Summary>
          {columns
            .filter(item => item.aggregate === true)
            .map(item => (
              <TotalItem
                key={item.dataField}
                column={item.dataField}
                summaryType="sum"
                valueFormat={{ type: "decimal", precision: 2 }}
              />
            ))}
        </Summary>
        <Template name="downloadAllImages">
          <Button
            elementAttr={{ id: "downloadAllImages" }}
            hint={`download all available ${tooltipItemName} images`}
            icon={faCloudDownloadAlt}
            text="Download Images"
            visible={hasAccessToDownloadAllImage}
            onClick={downloadAllImagesButtonClickHandler}
          />
        </Template>
        <Template name="showArchived">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "0.5rem" }}>Archived:</div>
            <Switch
              hint={`show archived ${tooltipItemName}s.`}
              value={showArchived}
              onValueChanged={archiveSwitchValueChangedHandler}
              visible={true}
            />
          </div>
        </Template>
        <Template name="showDeleted">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "0.5rem" }}>Deleted:</div>
            <Switch
              hint={`show deleted ${tooltipItemName}s.`}
              value={showDeleted}
              onValueChanged={deleteSwitchValueChangedHandler}
              visible={true}
            />
          </div>
        </Template>
        <Template name="delete">
          <Button
            hint={`Delete selected ${tooltipItemName}s`}
            icon="remove"
            text="Delete Selected"
            visible={hasAccessToDelete}
            onClick={deleteButtonClickHandler}
          />
        </Template>
        <Template name="archive">
          <Button
            hint={`Archive selected ${tooltipItemName}s`}
            icon="box"
            text="Archive Selected"
            visible={hasAccessToArchive}
            onClick={archiveButtonClickHandler}
          />
        </Template>
        <Template name="importAllIntoSage">
          <Button
            elementAttr={{ id: "importAll" }}
            hint={`Import All ${tooltipItemName}s into Sage`}
            icon={FaFileImport}
            text="Import All"
            visible={hasAccessToImport}
            onClick={importAllButtonClickHandler}
          />
        </Template>
        <Template name="importIntoSage">
          <Button
            hint={`Import selected ${tooltipItemName}s into Sage`}
            icon={FaFileImport}
            text="Import Selected"
            visible={hasAccessToImport}
            onClick={importButtonClickHandler}
          />
        </Template>
        <Template name="refreshGrid">
          <Button
            hint="Update grid"
            icon="refresh"
            onClick={refreshButtonClickHandler}
          />
        </Template>
      </DataGrid>
    ) : null
  }
)
MetaGrid.propTypes = {
  allowDeleting: PropTypes.bool,
  allowUpdating: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  keyValues: PropTypes.object.isRequired,
  storageKey: PropTypes.string,
}
MetaGrid.defaultProps = {
  allowDeleting: false,
  allowUpdating: false,
  hasAccessToImport: false,
}
export { MetaGrid }
const mapDispatchToProps = dispatch => {
  return {
    freezeAutoRefresher: freeze =>
      dispatch(dashboardOperations.freezeAutoRefresher(freeze)),
  }
}
export default connect(null, mapDispatchToProps)(MetaGrid)

const MasterDetailGrid = ({
  getLines,
  storageKey,
  allowDeleting = false,
  allowUpdating = false,
}) => ({ data }) => {
  return (
    <MetaGridLine2
      storageKey={storageKey}
      getLines={getLines}
      rowId={data.key}
      allowDeleting={allowDeleting}
      allowUpdating={allowUpdating}
      setLineValue={data.setValue}
    />
  )
}

const MetaGridLine2 = ({
  storageKey,
  getLines,
  rowId,
  allowDeleting = false,
  allowUpdating = false,
  setLineValue,
}) => {
  const canceled = useRef(false)
  const [columns, setColumns] = useState([])
  const [data, setData] = useState(new ArrayStore())
  const [headerFilters, setHeaderFilters] = useState(null)
  const [keyValues, setKeyValues] = useState({})
  const [keyValueTexts, setKeyValueTexts] = useState(null)
  const [errors, setErrors] = useState({})
  const [warnings, setWarnings] = useState({})

  useEffect(() => {
    const getInfo = async () => {
      const {
        columns,
        data,
        headerFilters,
        keyValues,
        keyValueTexts,
        errors,
        warnings,
      } = await getLines(rowId)
      if (!canceled.current) setColumns(columns)
      setData(data)
      setHeaderFilters(headerFilters)
      setKeyValues(keyValues)
      setKeyValueTexts(keyValueTexts)
      setErrors(errors)
      setWarnings(warnings)
    }
    getInfo()
    return () => (canceled.current = true)
  }, [getLines, rowId])
  return (
    <MetaGridLine
      data={data}
      columns={columns}
      headerFilters={headerFilters}
      keyValues={keyValues}
      keyValueTexts={keyValueTexts}
      errors={errors}
      warnings={warnings}
      storageKey={storageKey}
      allowDeleting={allowDeleting}
      allowUpdating={allowUpdating}
      setLineValue={setLineValue}
    />
  )
}
