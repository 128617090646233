import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "@reach/router"
import { Accordion, Button } from "devextreme-react"
import { connect } from "react-redux"
import { Item as AccordionItem } from "devextreme-react/accordion"
import {
  FaCalendarAlt,
  FaCogs,
  FaCompactDisc,
  FaDatabase,
  FaEnvelope,
  FaEye,
  FaFileAlt,
  FaFileInvoice,
  FaFileInvoiceDollar,
  FaList,
  FaLock,
  FaMapSigns,
  FaRocket,
  FaShapes,
  FaShieldAlt,
  FaStore,
  FaTram,
  FaUserFriends,
  FaUsersCog,
  FaUserTag,
  FaVials,
  FaWarehouse,
  FaShoppingCart,
} from "react-icons/fa"
import {
  MdDeveloperMode,
  MdNotificationsActive,
  MdPayment,
} from "react-icons/md"
import { ImDatabase } from "react-icons/im"
import { FiMonitor } from "react-icons/fi"
import { RiMessageFill } from "react-icons/ri"
import { BiMailSend } from "react-icons/bi"
import notify from "devextreme/ui/notify"

import CustomerSidebar from "./CustomerSidebar"
import dataMapLogo from "../../images/datamap-motion-logo.svg"
import { hasAccessTo, hasAccessToAny } from "../authentication"
import { dashboardOperations } from "../../state/ducks/dashboard"
import useResponsiveWidth from "./shared/hooks/useResponsiveWidth"
import { getAccordionState, setAccordionState } from "./shared/utilities"
import { getShopifyPlantSpecies } from "./operations/shopify"

const SidebarW = styled.div`
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  & .dx-accordion-item-title-caption {
    font-size: 1rem;
  }
  & .dx-state-focused {
    border-color: rgb(221 221 221);
  }
`

const LogoW = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`
const LogoLinkW = styled.a.attrs({ href: "/" })`
  display: flex;
  align-items: center;
`

const Logo = styled.img.attrs({ src: dataMapLogo })`
  width: 40px;
`
const LogoText = styled.div`
  font-size: 1.1rem;
  margin-left: 0.5rem;
  padding-top: 1rem;
  font-weight: bold;
`

const SLink = styled(Link)`
  display: block;
  padding: 0.5rem 0.25rem;
  transition: background-color 0.25s;
  :not(:last-child) {
    border-bottom: 1px solid rgb(221 221 221);
  }
  :hover {
    background-color: rgba(51, 121, 183, 0.1);
  }
`

const Sidebar = ({ setUiDrawerOpen }) => {
  const stateKey = "main-sidebar"

  const { lg } = useResponsiveWidth()

  const [shopifyItems, setShopifyItems] = useState([])

  useEffect(() => {
    if (!lg) setUiDrawerOpen(false)
    else setUiDrawerOpen(true)
  }, [lg, setUiDrawerOpen])

  useEffect(() => {
    const generateUiItems = items => {
      return items.map(item => {
        let icon = FiMonitor
        let title = "Dashboard"
        let link = "shopify"
        if (item === "shopify_order") {
          icon = FaShoppingCart
          title = "Shopify Order"
          link = "shopify/orders"
        }
        if (item === "shopify_inventory") {
          icon = FaWarehouse
          title = "Shopify Inventory"
          link = "shopify/inventories"
        }
        return {
          icon: icon,
          title: title,
          pageLink: link,
          visible: hasAccessTo("shopify"),
        }
      })
    }
    const getShopifyItems = async () => {
      try {
        const species = await getShopifyPlantSpecies()
        setShopifyItems(generateUiItems(["dashboard", ...species]))
      } catch (error) {
        notify(`Getting Shopify Species Failed ${error.message}`, "error")
        setShopifyItems(generateUiItems(["dashboard"]))
      }
    }
    getShopifyItems()
  }, [])

  const menuButtonClickHandler = () => {
    setUiDrawerOpen(false)
  }

  const getLinKElements = items => {
    return items.map(item => {
      const Icon = item.icon ?? null
      if (item.visible)
        return (
          <SLink
            key={item.title}
            to={item.pageLink}
            getProps={({ isCurrent, isPartiallyCurrent }) => {
              if (
                (isPartiallyCurrent && item.title !== "Dashboard") ||
                isCurrent
              ) {
                return {
                  style: {
                    backgroundColor: "rgb(51, 121, 183)",
                    color: "rgb(255,255,255)",
                  },
                }
              }
            }}
            onClick={itemClickHandler}
          >
            <Icon style={{ marginRight: "1rem" }} />
            {item.title}
          </SLink>
        )
      return null
    })
  }

  const itemClickHandler = () => {
    if (!lg) setUiDrawerOpen(false)
  }

  return (
    <SidebarW>
      <LogoW>
        <Button
          icon="menu"
          onClick={menuButtonClickHandler}
          style={{ marginRight: "0.5rem" }}
        />
        <LogoLinkW>
          <Logo />
          <LogoText>DataMap.tools</LogoText>
        </LogoLinkW>
      </LogoW>
      <CustomerSidebar isVisible={true} />
      <Accordion
        collapsible={true}
        multiple={true}
        onInitialized={getAccordionState(stateKey)}
        onSelectionChanged={setAccordionState(stateKey)}
      >
        <AccordionItem
          title="Core"
          visible={hasAccessToAny([
            "c.app.r",
            "c.client.r",
            "c.client.migrate",
            "c.concur.user.r",
            "c.concur.migrate",
            "c.perm.r",
            "c.email-notification.r",
            "c.sage.column.r",
            "c.topic.r",
            "c.std-role.r",
            "c.query.r",
            "c.monitor.client.r",
            "c.notification.r",
            "c.user.r",
            "c.data-model.r",
            "c.shopify.user.r",
            "c.shopify.migrate",
          ])}
        >
          {getLinKElements([
            {
              icon: FaRocket,
              title: "Applications",
              pageLink: "core/applications",
              visible: hasAccessTo("c.app.r"),
            },
            {
              icon: FaUserFriends,
              title: "Clients",
              pageLink: "core/clients",
              visible: hasAccessToAny(["c.client.r", "c.client.migrate"]),
            },
            {
              icon: FaCogs,
              title: "Concur",
              pageLink: "core/concur",
              visible: hasAccessToAny(["c.concur.user.r", "c.concur.migrate"]),
            },
            {
              icon: FaLock,
              title: "Permissions",
              pageLink: "core/permissions",
              visible: hasAccessTo("c.perm.r"),
            },
            {
              icon: BiMailSend,
              title: "Email Notification",
              pageLink: "core/email-notification",
              visible: hasAccessTo("c.email-notification.r"),
            },
            {
              icon: FaCogs,
              title: "Sage",
              pageLink: "core/sage",
              visible: hasAccessToAny(["c.sage.column.r"]),
            },
            {
              icon: RiMessageFill,
              title: "Topics",
              pageLink: "core/topics",
              visible: hasAccessTo("c.topic.r"),
            },
            {
              icon: FaUserTag,
              title: "Standard Roles",
              pageLink: "core/standard-roles",
              visible: hasAccessTo("c.std-role.r"),
            },
            {
              icon: ImDatabase,
              title: "Queries",
              pageLink: "core/queries",
              visible: hasAccessTo("c.query.r"),
            },
            {
              icon: FiMonitor,
              title: "Monitors",
              pageLink: "core/monitors",
              visible: hasAccessToAny(["c.monitor.client.r"]),
            },
            {
              icon: MdNotificationsActive,
              title: "Notifications",
              pageLink: "core/notifications",
              visible: hasAccessToAny(["c.notification.r"]),
            },
            {
              icon: FaUsersCog,
              title: "Users",
              pageLink: "core/users",
              visible: hasAccessTo("c.user.r"),
            },
            {
              icon: FaShapes,
              title: "Data Models",
              pageLink: "core/data-models",
              visible: hasAccessTo("c.data-model.r"),
            },
            {
              icon: FaCogs,
              title: "Shopify",
              pageLink: "core/shopify",
              visible: hasAccessToAny([
                "c.shopify.user.r",
                "c.shopify.migrate",
              ]),
            },
          ])}
        </AccordionItem>
        <AccordionItem
          title="Profile"
          visible={hasAccessToAny([
            "app.r",
            "msg.r",
            "perm.r",
            "role.r",
            "topic.r",
            "user.r",
            "lock.r",
            "query.execute",
            "client.migrate",
            "sage.column.r",
            "sage.key-value.r",
            "schedule.r",
            "schedule.api.r",
            "schedule.priority.r",
            "schedule.timeout.r",
            "schedule.log.r",
            "client.migrate",
            "data-model.r",
            "sourceObject.r",
            "revise.r",
            "test.r",
            "transform.r",
            "translate.r",
          ])}
        >
          {getLinKElements([
            {
              icon: FaRocket,
              title: "Applications",
              pageLink: "profile/applications",
              visible: hasAccessTo("app.r"),
            },
            {
              icon: FaEnvelope,
              title: "Messages",
              pageLink: "profile/messages",
              visible: hasAccessTo("msg.r"),
            },
            {
              icon: FaLock,
              title: "Permissions",
              pageLink: "profile/permissions",
              visible: hasAccessTo("perm.r"),
            },
            {
              icon: FaUserTag,
              title: "Roles",
              pageLink: "profile/roles",
              visible: hasAccessTo("role.r"),
            },
            {
              icon: RiMessageFill,
              title: "Topics",
              pageLink: "profile/topics",
              visible: hasAccessTo("topic.r"),
            },
            {
              icon: FaUsersCog,
              title: "Users",
              pageLink: "profile/users",
              visible: hasAccessTo("user.r"),
            },
            {
              icon: FaShieldAlt,
              title: "Locks",
              pageLink: "profile/locks",
              visible: hasAccessTo("lock.r"),
            },
            {
              icon: MdDeveloperMode,
              title: "Developments",
              pageLink: "profile/developments",
              visible: hasAccessToAny(["query.execute", "client.migrate"]),
            },
            {
              icon: FaDatabase,
              title: "Sage",
              pageLink: "profile/sage",
              visible: hasAccessToAny(["sage.column.r", "sage.key-value.r"]),
            },
            {
              icon: FaCalendarAlt,
              title: "Schedule",
              pageLink: "profile/schedule",
              visible: hasAccessToAny([
                "schedule.r",
                "schedule.api.r",
                "schedule.priority.r",
                "schedule.timeout.r",
                "schedule.log.r",
              ]),
            },
            {
              icon: FaShapes,
              title: "Data Models",
              pageLink: "profile/data-models",
              visible: hasAccessTo("data-model.r"),
            },
            {
              icon: FaCompactDisc,
              title: "Source Objects",
              pageLink: "profile/source-objects",
              visible: hasAccessTo("sourceObject.r"),
            },
            {
              icon: FaEye,
              title: "Revises",
              pageLink: "profile/revises",
              visible: hasAccessTo("revise.r"),
            },
            {
              icon: FaVials,
              title: "Tests",
              pageLink: "profile/tests",
              visible: hasAccessTo("test.r"),
            },
            {
              icon: FaTram,
              title: "Transforms",
              pageLink: "profile/transforms",
              visible: hasAccessTo("transform.r"),
            },
            {
              icon: FaMapSigns,
              title: "Translate Categories",
              pageLink: "profile/translate-categories",
              visible: hasAccessTo("translate.r"),
            },
            {
              icon: FaCogs,
              title: "Settings",
              pageLink: "profile/settings",
            },
          ])}
        </AccordionItem>
        <AccordionItem
          title="Concur"
          visible={hasAccessToAny([
            "concur.extract.r",
            "concur.batch.r",
            "concur.list.r",
            "concur.vendor.r",
            "concur.po.r",
            "concur.expense.r",
            "concur.invoice.r",
            "concur.payment.r",
            "concur.migrate",
          ])}
        >
          {getLinKElements([
            {
              icon: FaFileAlt,
              title: "Extracts",
              pageLink: "concur/extracts",
              visible: hasAccessTo("concur.extract.r"),
            },
            {
              icon: FaFileAlt,
              title: "Batches",
              pageLink: "concur/batches",
              visible: hasAccessTo("concur.batch.r"),
            },
            {
              icon: FaList,
              title: "Lists",
              pageLink: "concur/lists",
              visible: hasAccessTo("concur.list.r"),
            },
            {
              icon: FaStore,
              title: "Vendors",
              pageLink: "concur/vendors",
              visible: hasAccessTo("concur.vendor.r"),
            },
            {
              icon: FaFileInvoice,
              title: "Purchase Orders",
              pageLink: "concur/purchase-orders",
              visible: hasAccessTo("concur.po.r"),
            },
            {
              icon: FaFileInvoiceDollar,
              title: "Expenses",
              pageLink: "concur/expenses",
              visible: hasAccessTo("concur.expense.r"),
            },
            {
              icon: FaFileInvoiceDollar,
              title: "Invoices",
              pageLink: "concur/invoices",
              visible: hasAccessTo("concur.invoice.r"),
            },
            {
              icon: MdPayment,
              title: "Payments",
              pageLink: "concur/payments",
              visible: hasAccessTo("concur.payment.r"),
            },
            {
              icon: MdPayment,
              title: "Sage Payments",
              pageLink: "concur/sage-payments",
              visible: hasAccessTo("concur.sp.r"),
            },
            {
              icon: FaCogs,
              title: "Settings",
              pageLink: "concur/settings",
              visible: hasAccessToAny([
                "concur.vendor.r",
                "concur.invoice.r",
                "concur.expense.r",
                "concur.payment.r",
                "concur.migrate",
              ]),
            },
          ])}
        </AccordionItem>
        <AccordionItem title="Shopify" visible={hasAccessToAny(["shopify"])}>
          {getLinKElements(shopifyItems)}
        </AccordionItem>
      </Accordion>
    </SidebarW>
  )
}

export { Sidebar }

const mapDispatchToProps = dispatch => {
  return {
    setUiDrawerOpen: drawerOpen =>
      dispatch(dashboardOperations.setUiDrawerOpen(drawerOpen)),
  }
}
export default connect(null, mapDispatchToProps)(Sidebar)
