import React, { useCallback, useRef, useState } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Column,
  EmailRule,
  StateStoring,
  RequiredRule,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Scrolling,
  ColumnChooser,
  Format,
  Button as GridButton,
} from "devextreme-react/data-grid"
import DataSource from "devextreme/data/data_source"
import { FaUserCog } from "react-icons/fa"

import {
  userDataStore,
  userClientsDataStoreGenerator,
} from "../shared/CoreStore"
import { hasAccessTo, hasAccessToAny } from "../../authentication"
import { PageTitle } from "../shared/StyledComponents"
import SEO from "../../seo"
import { convertUtcToLocal } from "../shared/utilities"
import PopupDataGrid from "../shared/PopupDataGrid"

const userDataSource = new DataSource({ store: userDataStore })

const Users = () => {
  const usersGrid = useRef(null)
  const [popupVisibility, setPopupVisibility] = useState(false)
  const [popupDataStore, setPopupDataStore] = useState(null)

  const refreshUserGridClickHandler = useCallback(() => {
    usersGrid.current.instance.refresh(true)
  }, [])

  const showClientsClickHandler = useCallback(({ row }) => {
    setPopupDataStore(userClientsDataStoreGenerator(row.key))
    setPopupVisibility(true)
  }, [])

  const popupHidingHandler = useCallback(() => {
    setPopupDataStore([])
    setPopupVisibility(false)
  }, [])

  return (
    <>
      <SEO title="Core Users" />
      <PageTitle icon={FaUserCog}>Users</PageTitle>
      <DataGrid
        allowColumnReordering={true}
        allowEditing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={userDataSource}
        hoverStateEnabled={true}
        ref={usersGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("c.user.r")}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column dataField="email" dataType="string">
          <EmailRule />
          <RequiredRule />
        </Column>
        <Column
          allowEditing={false}
          dataField="emailConfirmed"
          dataType="boolean"
        />
        <Column dataField="firstName" dataType="string" />
        <Column dataField="lastName" dataType="string" />
        <Column dataField="phoneNumber" dataType="string" />
        <Column dataField="isOnline" dataType="boolean" allowEditing={false} />
        <Column
          dataField="lastActivity"
          dataType="datetime"
          allowEditing={false}
        >
          <Format formatter={convertUtcToLocal} />
        </Column>
        <Column
          type="buttons"
          visible={hasAccessToAny(["c.user.d", "c.user.u", "c.client.r"])}
        >
          <GridButton name="edit" />
          <GridButton name="delete" />
          <GridButton
            hint="Show clients"
            icon="user"
            visible={hasAccessTo("c.client.r")}
            onClick={showClientsClickHandler}
          />
        </Column>
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <FilterPanel visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          visible={true}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="core-users"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshUserGridClickHandler} />
        </Template>
      </DataGrid>
      <PopupDataGrid
        dataSource={popupDataStore}
        title="Clients"
        visible={popupVisibility}
        onHiding={popupHidingHandler}
      />
    </>
  )
}

export { Users }
export default Users
