import { Button } from "devextreme-react"
import React, { useState } from "react"
import styled from "styled-components"

import Table from "./Table"
import PieChart from "./PieChart"

const W = styled.div`
  width: ${props => {
    if (props.width >= 12) return "calc(100% - 2rem)"
    return `calc(${(props.width / 12) * 100}% - 2rem)`
  }};
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  margin: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 5px 0px rgb(0, 0, 0, 0.2);
  @media only screen and (max-width: 1800px) {
    width: ${props => {
      if (props.width >= 6) return "calc(100% - 2rem)"
      if (props.width >= 4) return "calc(50% - 2rem)"
      return `calc(33.33% - 2rem)`
    }};
  }
  @media only screen and (max-width: 1200px) {
    width: ${props => {
      if (props.width >= 4) return "calc(100% - 2rem)"
      return `calc(50% - 2rem)`
    }};
  }
  @media only screen and (max-width: 992px) {
    width: ${props => {
      if (props.width >= 3) return "calc(100% - 2rem)"
      return `calc(50% - 2rem)`
    }};
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
const TitleW = styled.div`
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const Title = styled.div``
const Icon = styled.i`
  padding-right: 0.5rem;
`
const ButtonsW = styled.div``

const Scalar = ({ itemData }) => {
  const {
    width,
    title,
    groups,
    subGroups,
    data,
    id,
    icon,
    displayType,
    displayStyle,
    displayPalette,
  } = itemData

  const [type, setType] = useState(displayType)

  const buttonClickHandler = type => () => setType(type)

  const getDisplayObject = () => {
    if (type === "table")
      return (
        <Table
          id={id}
          data={data}
          groups={groups}
          subGroups={subGroups}
          title={title}
        />
      )
    if (type === "pie")
      return (
        <PieChart
          id={id}
          data={data}
          displayStyle={displayStyle}
          displayPalette={displayPalette}
          groups={groups}
          title={title}
          subGroups={subGroups}
        />
      )
  }

  return (
    <>
      <W width={width}>
        <TitleW>
          <Title>
            {icon && <Icon className={`fas fa-${icon}`} />}
            {title}
          </Title>
          <ButtonsW>
            <Button
              hint="Pie Chart"
              icon="fas fa-chart-pie"
              stylingMode="text"
              onClick={buttonClickHandler("pie")}
            />
            <Button
              hint="Table"
              icon="fas fa-table"
              stylingMode="text"
              onClick={buttonClickHandler("table")}
            />
          </ButtonsW>
        </TitleW>
        {getDisplayObject()}
      </W>
    </>
  )
}

export default Scalar
