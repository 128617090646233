import React from "react"
import styled from "styled-components"
import { ShieldFillCheck, ShieldFill } from "@styled-icons/bootstrap"
import { FaUserNinja } from "react-icons/fa"

const PageComponentW = styled.div`
  padding: 1rem;
`
const PageComponentButtonsW = styled.div`
  margin-bottom: 1rem;
  .dx-button {
    margin-right: 1rem;
  }
`

const TabComponentW = styled.div`
  padding: 1rem;
`

const TabComponentButtonsW = styled.div`
  margin-bottom: 1rem;
  .dx-button {
    margin-right: 1rem;
  }
`
const FormW = styled.div`
  min-height: 6rem;
`
const LoaderW = styled.div`
  height: 100%;
`
const FormButtonW = styled.div`
  margin-top: 1rem;
  .dx-button {
    margin-right: 1rem;
  }
`
const TabItemGridW = styled.div`
  height: 100%;
  .dx-datagrid {
    padding: 0.5rem;
  }
`
const TabItemW = styled.div`
  padding: 0.5rem;
`
const SLock = styled(ShieldFillCheck)`
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: -8px 3px 0 3px;
  color: rgb(51 122 183);
`
const SUnLock = styled(ShieldFill)`
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: -8px 3px 0 3px;
  color: rgb(51 122 183);
`
const SFaUserNinja = styled(FaUserNinja)`
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: -8px 3px 0 3px;
  color: rgb(51 122 183);
`
const LockButton = ({ lockId, ...rest }) =>
  lockId ? (
    <SLock title="Unlock this record." {...rest} />
  ) : (
    <SUnLock title="Lock this record." {...rest} />
  )

const TitleW = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  margin-bottom: 1rem;
`
const Title = styled.div`
  font-size: ${p => p.fontSize};
`
const IconW = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
`

const PageTitle = ({ icon, children, variant = "title" }) => {
  let fontSize = "1.3rem"
  let iconSize = "1.5rem"

  if (variant === "subTitle") {
    fontSize = "1.2rem"
    iconSize = "1.3rem"
  }

  const Icon = icon
  if (icon) {
    return (
      <TitleW>
        <IconW>
          <Icon style={{ width: iconSize, height: iconSize }} />
        </IconW>
        <Title fontSize={fontSize}>{children}</Title>
      </TitleW>
    )
  }
  return <Title>{children}</Title>
}
export {
  PageComponentW,
  PageComponentButtonsW,
  PageTitle,
  TabComponentW,
  TabComponentButtonsW,
  FormW,
  LoaderW,
  FormButtonW,
  TabItemGridW,
  TabItemW,
  LockButton,
  SFaUserNinja,
}
