import { Toast } from "devextreme-react"
import { Button } from "devextreme-react"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import md5 from "md5"

import { dashboardSelectors } from "../../../state/ducks/dashboard"
import { notificationDataStore } from "./ClientStores"
import { getCurrentUser } from "../../authentication"

const Notifier = ({ refreshUuid, enabled = true }) => {
  const toastRef = useRef(null)
  const localStorageKey = "notified-items"
  const critical = "critical"
  const warning = "warning"
  const information = "information"

  const [notifications, setNotifications] = useState([])
  const [notification, setNotification] = useState(null)

  useEffect(() => {
    const getInfo = async () => {
      const response = await notificationDataStore.load()
      const notifiedItems = JSON.parse(
        localStorage.getItem(localStorageKey) || "[]"
      )
      const newNotifications = response.filter(
        item => !notifiedItems.includes(md5(JSON.stringify(item)))
      )
      const sortedNotifications = newNotifications
        .sort((a, b) => b.id - a.id)
        .sort((a, b) => {
          if (a.type === critical && b.type === warning) return -1
          if (a.type === warning && b.type === critical) return 1
          if (a.type === critical && b.type === information) return -1
          if (a.type === information && b.type === critical) return 1
          if (a.type === warning && b.type === information) return -1
          if (a.type === information && b.type === warning) return 1

          return 0
        })
      if (sortedNotifications?.length) setNotifications(sortedNotifications)
    }
    enabled && getCurrentUser().client_code !== "" && getInfo()
  }, [enabled, refreshUuid])

  useEffect(() => {
    if (notifications.length > 0) setNotification(notifications[0])
    else setNotification(null)
  }, [notifications])

  const getNotificationType = type => {
    if (type === warning) return warning
    else if (type === critical) return "error"
    return "info"
  }

  const dismissClickHandler = () => {
    toastRef.current.instance.hide()
    let notifiedItems = new Set([
      ...JSON.parse(localStorage.getItem(localStorageKey) || "[]"),
    ])
    notifiedItems.add(md5(JSON.stringify(notification)))
    localStorage.setItem(localStorageKey, JSON.stringify([...notifiedItems]))
  }
  const contentRenderHandler = () => (
    <div
      style={{
        display: "table-cell",
        width: "inherit",
        paddingLeft: "1rem",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <strong style={{ fontSize: "1rem" }}>
            {notification.title ? `${notification.title}: ` : ""}
          </strong>
          <span>{notification.message}</span>
        </div>
        {notification.isClosable && (
          <Button text="Dismiss" onClick={dismissClickHandler} />
        )}
      </div>
    </div>
  )

  const hiddenHandler = () => {
    let remaining = notifications
    if (notifications.length > 0) remaining.shift()
    setNotifications([...remaining])
  }

  return notification ? (
    <>
      <Toast
        closeOnSwipe={false}
        contentRender={contentRenderHandler}
        displayTime={86400000}
        ref={toastRef}
        shading={notification.hasShading}
        type={getNotificationType(notification.type)}
        visible={true}
        onHidden={hiddenHandler}
      />
    </>
  ) : null
}

export { Notifier }
const mapStateToProps = state => ({
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(Notifier)
