import { useEffect, useState } from "react"
import { useWindowSize } from "react-use"

const useResponsiveWidth = () => {
  const [responsiveWidth, setResponsiveWidth] = useState({
    xs: false,
    sm: false,
    md: false,
    lg: false,
  })
  const { width } = useWindowSize(1200, 800)
  useEffect(() => {
    if (width < 768)
      setResponsiveWidth({ xs: true, sm: false, md: false, lg: false })
    else if (width >= 768 && width < 992)
      setResponsiveWidth({ xs: false, sm: true, md: false, lg: false })
    else if (width >= 992 && width < 1200)
      setResponsiveWidth({ xs: false, sm: false, md: true, lg: false })
    else if (width >= 1200)
      setResponsiveWidth({ xs: false, sm: false, md: false, lg: true })
  }, [width])
  return responsiveWidth
}

export default useResponsiveWidth
