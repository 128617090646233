import React, { memo } from "react"
import { Button } from "devextreme-react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const propTypes = {
  to: PropTypes.string.isRequired,
}

const LinkButton = memo(({ to, disabled, ...rest }) => {
  return (
    <>
      {disabled ? (
        <Button disabled={true} {...rest} />
      ) : (
        <Link to={to}>
          <Button {...rest} />
        </Link>
      )}
    </>
  )
})

LinkButton.propTypes = propTypes

export default LinkButton
