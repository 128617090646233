import React, { useEffect, useRef } from "react"
import { Chart } from "devextreme-react"
import {
  Size,
  LoadingIndicator,
  ValueAxis,
  Grid,
  ArgumentAxis,
  Label,
  CommonSeriesSettings,
  SeriesTemplate,
  Series,
  Legend,
  ZoomAndPan,
  Aggregation,
  Tooltip,
  VisualRange,
} from "devextreme-react/chart"
import { connect } from "react-redux"

import { messageDataStoreGenerator } from "../../shared/ClientStores"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import { calculateVisualRange } from "../../shared/utilities"

const MessageChart = ({ timeRange, refreshUuid }) => {
  const chartRef = useRef(null)
  const messageGridDataStore = messageDataStoreGenerator({ timeRange })

  useEffect(() => {
    if (chartRef.current) chartRef.current.instance.refresh()
  }, [refreshUuid])

  return (
    <>
      <Chart
        dataSource={messageGridDataStore}
        palette="Soft Pastel"
        ref={chartRef}
      >
        <ArgumentAxis
          aggregationInterval={timeRange.aggregationInterval}
          argumentType="datetime"
        >
          <Label wordWrap="none" overlappingBehavior="rotate" />
          <VisualRange defaultStartValue={calculateVisualRange(timeRange)} />
        </ArgumentAxis>
        <CommonSeriesSettings
          argumentField="createDate"
          hoverMode="allArgumentPoints"
          type="bar"
          valueField="id"
        >
          <Aggregation enabled={true} method="count" />
        </CommonSeriesSettings>
        <Legend
          visible={true}
          verticalAlignment="bottom"
          horizontalAlignment="center"
        />
        <LoadingIndicator enabled={true} />
        <Series name="topics" />
        <SeriesTemplate nameField="topicCode" />
        <Size width="100%" />
        <Tooltip enabled={true} />
        <ValueAxis
          valueType="numeric"
          allowDecimals={false}
          type="logarithmic"
          showZero={true}
          discreteAxisDivisionMode="betweenLabels"
        >
          <Grid opacity="0.3" />
        </ValueAxis>
        <ZoomAndPan argumentAxis="both" />
      </Chart>
    </>
  )
}

export { MessageChart }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(MessageChart)
