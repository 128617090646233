import React, { memo, useEffect, useState } from "react"
import styled from "styled-components"
import { SelectBox } from "devextreme-react"
import notify from "devextreme/ui/notify"
import { connect } from "react-redux"

import { FaSignOutAlt, FaHouseUser, FaUser } from "../fa-icons"
import LinkButton from "./LinkButton"
import { getCurrentUser, refreshToken, logout } from "../authentication"
import { getClientLogo } from "./operations/client"
import { clientDataStore } from "./shared/ClientStores"
import dataMapLogo from "../../images/datamap-logo.png"
import { Button as SelectBoxButton } from "devextreme-react/select-box"
import { dashboardOperations } from "../../state/ducks/dashboard"
import useResponsiveWidth from "./shared/hooks/useResponsiveWidth"

const CustomerLogoW = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const CustomerLogoAndToolsW = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`
const CustomerLogo = styled.img.attrs(props => ({
  src: props.logo,
  title: props.title,
}))`
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 3px 0px;
  width: ${props => (props.isVisible ? "55%" : "100%")};
  border-radius: 1rem;
  margin-left: 0.5rem;
  transition: ${props => (props.isVisible ? "width 0s" : "width 0.5s")};
`
const CustomerToolsW = styled.div`
  display: ${props => !props.isVisible && "none"};
`
const CustomerLogoTextW = styled.div`
  text-align: center;
  display: ${props => !props.isVisible && "none"};
`
const CustomerLogoName = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1rem;
`
const CustomerLogoUsername = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;
`
const CustomerLogoImpersonatedUsername = styled.div`
  font-size: 0.8rem;
  margin-bottom: 1rem;
`

const CustomerSidebar = memo(({ isVisible, setUiDrawerOpen }) => {
  const {
    client_code,
    sage_edition,
    username,
    impersonateUsername,
  } = getCurrentUser()
  const [customerLogo, setCustomerLogo] = useState(null)
  const [clientCode, setClientCode] = useState(client_code)
  const [clients, setClients] = useState([])

  const { lg } = useResponsiveWidth()

  useEffect(() => {
    const getInfo = async () => {
      const clients = (await clientDataStore.load()).data
      setClients([{ clientCode: "", name: "" }, ...clients])
    }
    getInfo()
  }, [])
  useEffect(() => {
    const getCustomerLogo = async () => {
      const { client_code } = getCurrentUser()
      if (client_code !== "") {
        const logo = await getClientLogo()
        setCustomerLogo("data:image/png;base64," + logo)
      } else {
        setCustomerLogo(dataMapLogo)
      }
    }
    getCustomerLogo()
  }, [setCustomerLogo])

  const refreshTokenClickHandler = async () => {
    try {
      await refreshToken()
      notify("Token refreshed successfully.", "success")
    } catch (error) {
      notify(`Refreshing token failed: ${error.message}`, "error")
    }
  }

  const clientSelectBoxValueChangedHandler = async ({ value }) => {
    const { impersonated } = getCurrentUser() || {}
    try {
      setClientCode(value)
      if (impersonated) logout()
      await refreshToken(value)
      if (typeof window !== `undefined`) window.location.replace("/dashboard")
    } catch (error) {
      setClientCode(clientCode)
      notify(error, "error")
    }
  }
  const closeDrawerHandler = () => {
    if (!lg) setUiDrawerOpen(false)
  }

  return (
    <CustomerLogoW>
      <CustomerLogoAndToolsW>
        <CustomerLogo
          isVisible={isVisible}
          logo={customerLogo}
          title={`${client_code}(${sage_edition})`}
        />
        <CustomerToolsW isVisible={isVisible}>
          <LinkButton
            icon={FaHouseUser}
            to="/dashboard/"
            visible={true}
            hint="dashboard"
            onClick={closeDrawerHandler}
          />
          <LinkButton
            icon={FaUser}
            hint="user profile"
            to="/dashboard/user-profile"
            onClick={closeDrawerHandler}
          />
          <LinkButton icon={FaSignOutAlt} to="/logout" hint="logout" />
        </CustomerToolsW>
      </CustomerLogoAndToolsW>
      <CustomerLogoTextW isVisible={isVisible}>
        <CustomerLogoName>
          <SelectBox
            dataSource={clients}
            value={clientCode}
            deferRendering={false}
            displayExpr="name"
            valueExpr="clientCode"
            placeholder="Select client ..."
            onValueChanged={clientSelectBoxValueChangedHandler}
          >
            <SelectBoxButton name="dropDown" />
            <SelectBoxButton
              name="refreshToken"
              options={{
                icon: "refresh",
                hint: "Refresh token",
                height: "100%",
                onClick: refreshTokenClickHandler,
              }}
            />
          </SelectBox>
        </CustomerLogoName>
        <CustomerLogoUsername>{username}</CustomerLogoUsername>
        {impersonateUsername && (
          <CustomerLogoImpersonatedUsername>
            {`impersonated: ${impersonateUsername}`}
          </CustomerLogoImpersonatedUsername>
        )}
      </CustomerLogoTextW>
    </CustomerLogoW>
  )
})

export { CustomerSidebar }
const mapDispatchToProps = dispatch => {
  return {
    setUiDrawerOpen: drawerOpen =>
      dispatch(dashboardOperations.setUiDrawerOpen(drawerOpen)),
  }
}
export default connect(null, mapDispatchToProps)(CustomerSidebar)
