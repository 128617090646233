import React from "react"
import { TabPanel } from "devextreme-react"
import { Item } from "devextreme-react/tab-panel"
import { FaCogs } from "react-icons/fa"

import { Settings as InvoiceSettings } from "./invoices"
import { Settings as ExpenseSettings } from "./expenses"
import { Settings as PaymentSettings } from "./payments"
import { FaFileInvoiceDollar, FaDatabase } from "../../fa-icons"
import { PageTitle, TabItemW } from "../shared/StyledComponents"
import { hasAccessTo } from "../../authentication"
import Database from "./Database"
import Token from "./Token"
import { mdPayment, siJsonWebTokens } from "../shared/icons"
import SEO from "../../seo"
import { getFirstAccessibleIndex } from "../shared/utilities"

const Settings = () => {
  return (
    <>
      <SEO title="Concur Settings" />
      <PageTitle icon={FaCogs}>Concur Settings</PageTitle>
      <TabPanel
        defaultSelectedIndex={getFirstAccessibleIndex([
          "concur.expense.r",
          "concur.invoice.r",
          "concur.payment.r",
          "concur.migrate",
          "concur.token.r",
        ])}
      >
        <Item
          title="Expenses"
          icon={FaFileInvoiceDollar}
          visible={hasAccessTo("concur.expense.r")}
        >
          <ExpenseSettings />
        </Item>
        <Item
          title="Invoices"
          icon={FaFileInvoiceDollar}
          visible={hasAccessTo("concur.invoice.r")}
        >
          <TabItemW>
            <InvoiceSettings />
          </TabItemW>
        </Item>
        <Item
          title="Payments"
          icon={mdPayment}
          visible={hasAccessTo("concur.payment.r")}
        >
          <TabItemW>
            <PaymentSettings />
          </TabItemW>
        </Item>
        <Item
          title="DataBase"
          icon={FaDatabase}
          visible={hasAccessTo("concur.migrate")}
        >
          <TabItemW>
            <Database />
          </TabItemW>
        </Item>
        <Item
          title="Token"
          icon={siJsonWebTokens}
          visible={hasAccessTo("concur.token.r")}
        >
          <TabItemW>
            <Token />
          </TabItemW>
        </Item>
      </TabPanel>
    </>
  )
}

export default Settings
