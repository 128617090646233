import React, { useRef, useState } from "react"
import { DataGrid, Button, Template, SelectBox } from "devextreme-react"
import {
  Editing,
  Column,
  RequiredRule,
  StateStoring,
  FilterRow,
  Pager,
  SearchPanel,
  Scrolling,
} from "devextreme-react/data-grid"
import { Button as SelectBoxButton } from "devextreme-react/select-box"
import DataSource from "devextreme/data/data_source"
import notify from "devextreme/ui/notify"
import { FaRocket } from "react-icons/fa"

import { applicationDataStore } from "../shared/ClientStores"
import { applicationDataStore as coreApplicationDataStore } from "../shared/CoreStore"
import { hasAccessTo } from "../../authentication"
import { PageTitle } from "../shared/StyledComponents"
import { grInstallOption } from "../shared/icons"
import SEO from "../../seo"

const applicationDataSource = new DataSource({
  store: applicationDataStore,
})
const coreApplicationDataSource = new DataSource({
  store: coreApplicationDataStore,
  postProcess: data => data.filter(item => item.isActive === true),
})

const Applications = () => {
  const applicationGrid = useRef(null)
  const [selectedApplication, setSelectedApplication] = useState(null)

  const selectBoxValueChangeHandler = ({ value }) => {
    setSelectedApplication(value)
  }
  const refreshApplicationGridClickHandler = () => {
    applicationGrid.current.instance.refresh(true)
  }
  const installButtonClickHandler = () => {
    try {
      applicationDataStore.insert({
        applicationId: selectedApplication,
      })
      applicationGrid.current.instance.refresh(true)
      notify("Application installed successfully!", "success")
    } catch (error) {
      notify("Installing new application failed!", "error")
    }
  }
  return (
    <>
      <SEO title="Applications" />
      <PageTitle icon={FaRocket}>Applications</PageTitle>
      <DataGrid
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={applicationDataSource}
        hoverStateEnabled={true}
        ref={applicationGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "installNewApplication",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
        visible={hasAccessTo("app.r")}
      >
        <Column dataField="title" dataType="string" allowEditing={false}>
          <RequiredRule trim={true} />
        </Column>
        <Column dataField="installed" dataType="boolean" allowEditing={false} />
        <Column dataField="isActive" dataType="boolean" />
        <Editing
          mode="cell"
          useIcons={true}
          allowUpdating={hasAccessTo("app.u")}
        />
        <FilterRow visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
          visible={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="profile-applications"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshApplicationGridClickHandler} />
        </Template>
        <Template name="installNewApplication">
          <SelectBox
            dataSource={coreApplicationDataSource}
            valueExpr="id"
            displayExpr="title"
            disabled={!hasAccessTo("app.c")}
            value={selectedApplication}
            onValueChanged={selectBoxValueChangeHandler}
          >
            <SelectBoxButton name="dropDown" />
            <SelectBoxButton
              name="application"
              options={{
                hint: "install application.",
                icon: grInstallOption,
                onClick: installButtonClickHandler,
              }}
            />
          </SelectBox>
        </Template>
      </DataGrid>
    </>
  )
}

export { Applications }
export default Applications
