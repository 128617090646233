import React from "react"
import styled from "styled-components"
import { Router } from "@reach/router"

import Settings from "./Settings"
import Extracts from "./Extracts"
import ExtractJobs from "./ExtractJobs"
import Vendors from "./vendors"
import PurchaseOrders from "./purchase-order"
import SagePayments from "./sage-payments"
import Expenses from "./expenses"
import Payments from "./payments"
import Invoices from "./invoices"
import Batches from "./Batches"
import BatchJobs from "./BatchJobs"
import Lists from "./lists"
import SEO from "../../seo"

const HomeW = styled.div`
  height: 100%;
`
const Home = () => {
  return (
    <>
      <SEO title="Concur" />
      <HomeW>
        <Router>
          <Batches path="batches" />
          <BatchJobs path="batches/:batchId/jobs" />
          <Expenses path="expenses" />
          <Payments path="payments" />
          <Extracts path="extracts" />
          <ExtractJobs path="extracts/:extractId/jobs" />
          <Invoices path="invoices" />
          <Lists path="lists/*" />
          <Settings path="settings" />
          <Vendors path="vendors" />
          <PurchaseOrders path="purchase-orders" />
          <SagePayments path="sage-payments" />
        </Router>
      </HomeW>
    </>
  )
}

export default Home
