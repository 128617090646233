import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  Lookup,
  StateStoring,
  ColumnChooser,
  ColumnFixing,
  Pager,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"
import DataSource from "devextreme/data/data_source"
import { RiMessageFill } from "react-icons/ri"

import { topicDataStore, applicationDataStore } from "../shared/CoreStore"
import { hasAccessTo } from "../../authentication"
import { PageTitle } from "../shared/StyledComponents"
import SEO from "../../seo"

const topicDataSource = new DataSource({ store: topicDataStore })

const Topics = () => {
  const topicsGrid = useRef(null)

  const refreshMessageGridClickHandler = () => {
    topicsGrid.current.instance.refresh(true)
  }

  const toolbarPreparingHandler = e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }
  const editorPreparingHandler = e => {
    if (
      e.value &&
      e.parentType === "dataRow" &&
      e.dataField === "applicationId"
    ) {
      e.editorOptions.value = e.value.toString()
      e.editorOptions.onValueChanged = args => {
        e.setValue(parseInt(args.value))
      }
    }
  }
  return (
    <>
      <SEO title="Core Topics" />
      <PageTitle icon={RiMessageFill}>Topics</PageTitle>
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={topicDataSource}
        hoverStateEnabled={true}
        ref={topicsGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("c.topic.r")}
        onEditorPreparing={editorPreparingHandler}
        onToolbarPreparing={toolbarPreparingHandler}
      >
        <Column dataField="id" allowEditing={false} dataType="number" />
        <Column dataField="code" dataType="string" />
        <Column
          dataField="applicationId"
          dataType="number"
          caption="application"
        >
          <Lookup
            dataSource={{
              store: applicationDataStore,
              map: item => {
                return { ...item, id: item.id.toString() }
              },
            }}
            valueExpr="id"
            displayExpr="title"
          />
        </Column>
        <Column dataField="consumerId" dataType="string" />
        <Column dataField="title" dataType="string" />
        <Column dataField="headerSchema" dataType="string" />
        <Column dataField="bodyStringSchema" dataType="string" />
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          allowAdding={hasAccessTo("c.topic.c")}
          allowDeleting={hasAccessTo("c.topic.d")}
          allowUpdating={hasAccessTo("c.topic.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="core-topics"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshMessageGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { Topics }
export default Topics
