import React, { useEffect, useState } from "react"
import { LoadPanel, ScrollView } from "devextreme-react"
import JsonViewer from "react-json-view"
import notify from "devextreme/ui/notify"

import { getPurchaseOrderStatus } from "../../operations/concur"

const ConcurStatus = ({ purchaseOrderId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(null)

  useEffect(() => {
    const getInfo = async () => {
      try {
        setIsLoading(true)
        const status = await getPurchaseOrderStatus(purchaseOrderId)
        setStatus(status)
      } catch (error) {
        notify(
          `Fetching Concur purchase order status failed: ${error.message}`,
          "error"
        )
      } finally {
        setIsLoading(false)
      }
    }
    if (purchaseOrderId !== null) getInfo()
    return () => {
      setStatus(null)
    }
  }, [purchaseOrderId])
  if (status === null) return <LoadPanel visible={isLoading} />
  return (
    <>
      <ScrollView>
        <JsonViewer displayDataTypes={false} src={status} />
      </ScrollView>
    </>
  )
}

export default ConcurStatus
