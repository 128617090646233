import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  FormItem,
  RequiredRule,
  Lookup,
  StateStoring,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  SearchPanel,
  Button as GridButton,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"
import { FaUserTag } from "react-icons/fa"

import {
  applicationDataStore,
  standardRoleDataStore,
  permissionDataStore,
} from "../shared/CoreStore"
import { hasAccessTo } from "../../authentication"
import { PageTitle } from "../shared/StyledComponents"
import SEO from "../../seo"

const StandardRoles = () => {
  const standardRolesGrid = useRef(null)

  const refreshRoleGridClickHandler = () => {
    standardRolesGrid.current.instance.refresh(true)
  }

  const getFilteredPermissions = options => {
    return {
      store: permissionDataStore,
      postProcess: data => {
        if (options.data)
          return data.filter(
            item =>
              item.applicationId &&
              options.data.applicationId &&
              item.applicationId.toString() ===
                options.data.applicationId.toString()
          )
        return data
      },
    }
  }

  const applicationSetCellValueHandler = function(rowData, value) {
    rowData.permissionIds = []
    this.defaultSetCellValue(rowData, value)
  }

  return (
    <>
      <SEO title="Core Standard Roles" />
      <PageTitle icon={FaUserTag}>Standard Roles</PageTitle>
      <DataGrid
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={standardRoleDataStore}
        hoverStateEnabled={true}
        ref={standardRolesGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("c.std-role.r")}
        onInitNewRow={e => {
          e.data.isActive = false
        }}
        onEditorPreparing={function(e) {
          if (
            e.value &&
            e.parentType === "dataRow" &&
            e.dataField === "applicationId"
          ) {
            e.editorOptions.value = e.value.toString()
            e.editorOptions.onValueChanged = function(args) {
              e.setValue(parseInt(args.value))
            }
          }
        }}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column
          allowEditing={false}
          dataField="id"
          dataType="number"
          visible={false}
        />
        <Column
          caption="Application"
          dataField="applicationId"
          dataType="number"
          setCellValue={applicationSetCellValueHandler}
        >
          <Lookup
            dataSource={{
              store: applicationDataStore,
              map: item => {
                return { ...item, id: item.id.toString() }
              },
            }}
            displayExpr="title"
            valueExpr="id"
          />
          <RequiredRule />
        </Column>
        <Column dataField="title" dataType="string">
          <RequiredRule trim={true} />
        </Column>
        <Column caption="Permissions" dataField="permissionIds" visible={false}>
          <Lookup
            dataSource={getFilteredPermissions}
            displayExpr="title"
            valueExpr="id"
          />
          <FormItem
            editorType="dxTagBox"
            editorOptions={{
              searchEnabled: true,
              showSelectionControls: true,
            }}
          />
          <RequiredRule />
        </Column>
        <Column type="buttons">
          <GridButton name="edit" />
          <GridButton name="delete" />
        </Column>
        <Editing
          allowAdding={hasAccessTo("c.std-role.c")}
          allowDeleting={hasAccessTo("c.std-role.d")}
          allowUpdating={hasAccessTo("c.std-role.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
          visible={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="core-standardRoles"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshRoleGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { StandardRoles }
export default StandardRoles
