import React, { useState } from "react"
import { TextArea, DataGrid, Template, Button } from "devextreme-react"
import notify from "devextreme/ui/notify"
import styled from "styled-components"
import { FaCloudDownloadAlt } from "react-icons/fa"

import { getToken } from "../operations/concur"
import { useRef } from "react"
import {
  ColumnChooser,
  ColumnFixing,
  Editing,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  SearchPanel,
  StateStoring,
  Button as GridButton,
  Column,
  Scrolling,
} from "devextreme-react/data-grid"
import { hasAccessTo, hasAccessToAny } from "../../authentication"
import { tokenDataStore } from "../shared/ConcurStore"

const SFaCloudDownloadAlt = styled(FaCloudDownloadAlt)`
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: -8px 3px 0 3px;
  color: rgb(51 122 183);
`

const Token = () => {
  const tokenGrid = useRef(null)
  const [token, setToken] = useState("")

  const getTokenClickHandler = id => async () => {
    try {
      const response = await getToken(id)
      setToken(response.data)
      const textArea = document.getElementById("textBoxInput")
      textArea.select()
      document.execCommand("copy")
      notify("Concur token copied to clipboard successfully.", "success")
    } catch (error) {
      notify("fetching token failed: " + error.message, "error")
    }
  }
  const refreshExtractGridClickHandler = () => {
    tokenGrid.current.instance.refresh(true)
  }
  const getTokenButtonRenderHandler = ({ data }) => (
    <SFaCloudDownloadAlt onClick={getTokenClickHandler(data.id)} />
  )
  return (
    <>
      <DataGrid
        ref={tokenGrid}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={tokenDataStore}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        showBorders={true}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
        visible={hasAccessTo("concur.token.r")}
      >
        <Column dataField="id" dataType="number" />
        <Column dataField="concur_Id" dataType="string" />
        <Column dataField="concur_Application" dataType="string" />
        <Column dataField="concur_RefreshToken" dataType="string" />
        <Column dataField="concur_RefreshExpiresIn" dataType="number" />
        <Column dataField="concur_GeoLocation" dataType="string" />
        <Column dataField="concur_TokenType" dataType="string" />
        <Column dataField="concur_Scope" dataType="string" />
        <Column dataField="concur_MarketingName" dataType="string" />
        <Column
          type="buttons"
          visible={hasAccessToAny(["concur.token.d", "concur.token.generate"])}
        >
          <GridButton name="delete" />
          <GridButton
            name="getToken"
            render={getTokenButtonRenderHandler}
            visible={hasAccessTo("concur.token.generate")}
          />
        </Column>
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          mode="form"
          useIcons={true}
          allowDeleting={hasAccessTo("concur.token.d")}
          allowUpdating={hasAccessTo("concur.token.u")}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter allowSearch={true} visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel width={250} visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="concur-tokens"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshExtractGridClickHandler} />
        </Template>
      </DataGrid>
      <TextArea
        value={token}
        autoResizeEnabled={true}
        readOnly={true}
        inputAttr={{ id: "textBoxInput" }}
      />
    </>
  )
}

export default Token
