import { Button, Chart, Tooltip, SelectBox } from "devextreme-react"
import {
  Aggregation,
  ArgumentAxis,
  CommonSeriesSettings,
  Connector,
  Grid,
  Label,
  Legend,
  LoadingIndicator,
  Series,
  SeriesTemplate,
  Size,
  Tooltip as ChartTooltip,
  ValueAxis,
  ZoomAndPan,
} from "devextreme-react/chart"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

const W = styled.div`
  width: ${props => {
    if (props.width >= 12) return "calc(100% - 2rem)"
    return `calc(${(props.width / 12) * 100}% - 2rem)`
  }};
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  margin: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 5px 0px rgb(0, 0, 0, 0.2);
  @media only screen and (max-width: 1800px) {
    width: ${props => {
      if (props.width >= 6) return "calc(100% - 2rem)"
      if (props.width >= 4) return "calc(50% - 2rem)"
      return `calc(33.33% - 2rem)`
    }};
  }
  @media only screen and (max-width: 1200px) {
    width: ${props => {
      if (props.width >= 4) return "calc(100% - 2rem)"
      return `calc(50% - 2rem)`
    }};
  }
  @media only screen and (max-width: 992px) {
    width: ${props => {
      if (props.width >= 3) return "calc(100% - 2rem)"
      return `calc(50% - 2rem)`
    }};
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
const TitleW = styled.div`
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const Title = styled.div``
const Icon = styled.i`
  padding-right: 0.5rem;
`
const ButtonsW = styled.div``
const TooltipItemW = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 1rem;
  }
`
const ItemLabel = styled.div`
  margin-right: 2rem;
`

const Timeline = ({ data }) => {
  const {
    id,
    width,
    title,
    groups,
    aggregationInterval,
    aggregationMethod,
    displayStyle,
    displayPalette,
    icon,
    valueAxisType,
  } = data

  const chartRef = useRef(null)

  const [showTooltip, setShowTooltip] = useState(false)
  const [localAggregationInterval, setLocalAggregationInterval] = useState(
    "hour"
  )
  const [localAggregationMethod, setLocalAggregationMethod] = useState("count")
  const [style, setStyle] = useState(displayStyle ?? "bar")
  const [localValueAxisType, setLocalValueAxisType] = useState(null)

  useEffect(() => {
    setLocalAggregationInterval(aggregationInterval)
  }, [aggregationInterval])

  useEffect(() => {
    setLocalAggregationMethod(aggregationMethod)
  }, [aggregationMethod])

  useEffect(() => {
    setLocalValueAxisType(valueAxisType)
  }, [valueAxisType])

  const tooltipHidingHandler = () => setShowTooltip(false)
  const settingButtonClickHandler = () => setShowTooltip(previous => !previous)

  const aggregationIntervalValueChangeHandler = value =>
    setLocalAggregationInterval(value)

  const aggregationMethodValueChangeHandler = value =>
    setLocalAggregationMethod(value)

  const styleValueChangeHandler = value => setStyle(value)

  const valueAxisTypeChangeHandler = value => {
    setLocalValueAxisType(value)
  }

  const resetVisualRangeClickHandler = () => {
    chartRef.current.instance.resetVisualRange()
  }

  return (
    <>
      <Tooltip
        target={`#setting-${id}`}
        position="left"
        visible={showTooltip}
        closeOnOutsideClick={true}
        onHiding={tooltipHidingHandler}
      >
        <TooltipItemW>
          <ItemLabel>Aggregation Interval: </ItemLabel>
          <SelectBox
            dataSource={[
              "minute",
              "hour",
              "day",
              "week",
              "month",
              "quarter",
              "year",
            ]}
            value={localAggregationInterval}
            onValueChange={aggregationIntervalValueChangeHandler}
          />
        </TooltipItemW>
        <TooltipItemW>
          <ItemLabel>Aggregation Method: </ItemLabel>
          <SelectBox
            dataSource={[null, "avg", "count", "max", "min", "sum"]}
            value={localAggregationMethod}
            onValueChange={aggregationMethodValueChangeHandler}
          />
        </TooltipItemW>
        <TooltipItemW>
          <ItemLabel>Chart Type: </ItemLabel>
          <SelectBox
            dataSource={[
              "area",
              "bar",
              "fullstackedarea",
              "fullstackedbar",
              "fullstackedline",
              "fullstackedspline",
              "fullstackedsplinearea",
              "line",
              "scatter",
              "spline",
              "splinearea",
              "stackedarea",
              "stackedbar",
              "stackedline",
              "stackedspline",
              "stackedsplinearea",
              "steparea",
              "stepline",
            ]}
            value={style}
            onValueChange={styleValueChangeHandler}
          />
        </TooltipItemW>
        <TooltipItemW>
          <ItemLabel>Value Axis Type: </ItemLabel>
          <SelectBox
            dataSource={[null, "continuous", "discrete", "logarithmic"]}
            value={localValueAxisType}
            onValueChange={valueAxisTypeChangeHandler}
          />
        </TooltipItemW>
      </Tooltip>
      <W width={width}>
        <TitleW>
          <Title>
            {icon && <Icon className={`fas fa-${icon}`} />}
            {title}
          </Title>
          <ButtonsW>
            <Button
              icon="fas fa-search"
              stylingMode="text"
              hint="Reset Visual Range"
              onClick={resetVisualRangeClickHandler}
            />
            <Button
              id={`setting-${id}`}
              icon="preferences"
              stylingMode="text"
              onClick={settingButtonClickHandler}
            />
          </ButtonsW>
        </TitleW>
        <Chart
          palette={displayPalette ?? "Soft Pastel"}
          dataSource={groups}
          ref={chartRef}
        >
          <ArgumentAxis
            aggregationInterval={localAggregationInterval}
            argumentType="datetime"
            maxValueMargin={0.1}
            minValueMargin={0.1}
            valueMarginsEnabled={true}
          >
            <Label wordWrap="none" overlappingBehavior="rotate">
              <Connector visible={true} />
            </Label>
          </ArgumentAxis>
          <CommonSeriesSettings
            argumentField="timestamp"
            hoverMode="allArgumentPoints"
            type={style}
            valueField="value"
          >
            <Aggregation
              enabled={localAggregationMethod !== null}
              method={localAggregationMethod}
            />
          </CommonSeriesSettings>
          <Legend
            horizontalAlignment="center"
            verticalAlignment="bottom"
            visible={true}
          />
          <LoadingIndicator enabled={true} />
          <SeriesTemplate nameField="group" />
          <Series />
          <ChartTooltip enabled={true} />
          <ValueAxis
            allowDecimals={false}
            maxValueMargin={0.1}
            minorTickCount={10}
            minValueMargin={0.1}
            showZero={true}
            type={localValueAxisType ?? undefined}
            valueType="numeric"
            valueMarginsEnabled={true}
          >
            <Grid opacity="0.3" />
          </ValueAxis>
          <Size height={300} />
          <ChartTooltip
            enabled={true}
            format={{
              type: "fixedPoint",
              precision: 2,
              percentPrecision: 2,
            }}
          />
          <ZoomAndPan
            argumentAxis="both"
            allowMouseWheel={false}
            dragToZoom={true}
          />
        </Chart>
      </W>
    </>
  )
}

export default Timeline
