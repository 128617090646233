import React, { useCallback, useRef, useState } from "react"
import { DataGrid, Button, Template, FileUploader } from "devextreme-react"
import {
  Editing,
  Column,
  StateStoring,
  ColumnChooser,
  Pager,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  FilterRow,
  Scrolling,
  Lookup,
  Button as GridButton,
} from "devextreme-react/data-grid"

import {
  clientDataStore,
  clientApplicationsDataStoreGenerator,
  clientUsersDataStoreGenerator,
} from "../../shared/CoreStore"
import {
  hasAccessTo,
  getBearerToken,
  hasAccessToAny,
} from "../../../authentication"
import {
  clientLogoUploadUrlGenerator,
  clientLogoUrlGenerator,
} from "../../operations/core"
import ImageFetcher from "../../shared/ImageFetcher"
import PopupDataGrid from "../../shared/PopupDataGrid"
import { bsFillGearFill } from "../../shared/icons"

const Clients = () => {
  const clientsGrid = useRef(null)
  const [popupVisibility, setPopupVisibility] = useState(false)
  const [popupDataStore, setPopupDataStore] = useState(null)

  const refreshMessageGridClickHandler = useCallback(() => {
    clientsGrid.current.instance.refresh(true)
  }, [])

  const logoCellRenderHandler = useCallback(({ value }) => {
    return (
      <ImageFetcher
        height="5rem"
        width="5rem"
        url={clientLogoUrlGenerator(value)}
      />
    )
  }, [])

  const websiteCellRenderHandler = useCallback(
    cellInfo => (
      <a
        href={cellInfo.displayValue}
        rel="noreferrer"
        target="_blank"
        title={`go to ${cellInfo.displayValue}`}
      >
        {cellInfo.displayValue}
      </a>
    ),
    []
  )

  const showApplicationsClickHandler = useCallback(({ row }) => {
    setPopupDataStore(clientApplicationsDataStoreGenerator(row.key))
    setPopupVisibility(true)
  }, [])

  const showUsersClickHandler = useCallback(({ row }) => {
    setPopupDataStore(clientUsersDataStoreGenerator(row.key))
    setPopupVisibility(true)
  }, [])

  const applicationsPopupHidingHandler = useCallback(() => {
    setPopupDataStore([])
    setPopupVisibility(false)
  }, [])

  const toolbarPreparingHandler = useCallback(e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }, [])
  return (
    <>
      <DataGrid
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={clientDataStore}
        hoverStateEnabled={true}
        ref={clientsGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("c.client.r")}
        onToolbarPreparing={toolbarPreparingHandler}
      >
        <Column
          allowEditing={false}
          allowFiltering={false}
          allowGrouping={false}
          formItem={{ visible: false }}
          dataField="id"
          dataType="number"
          alignment="left"
          caption="Logo"
          width="6rem"
          cellRender={logoCellRenderHandler}
        />
        <Column
          allowEditing={false}
          dataField="id"
          dataType="number"
          visible={false}
        />
        <Column dataField="clientCode" dataType="string" />
        <Column dataField="name" dataType="string" />
        <Column
          dataField="website"
          dataType="string"
          cellRender={websiteCellRenderHandler}
        />
        <Column dataField="sageEdition" dataType="string">
          <Lookup dataSource={["sage_100", "sage_500", "sage_x3"]} />
        </Column>
        <Column dataField="isActive" dataType="boolean" />
        <Column
          caption="Logo"
          dataField="id"
          editCellRender={LogoUploader}
          showInColumnChooser={false}
          visible={false}
        />
        <Column
          type="buttons"
          visible={hasAccessToAny(["c.client.d", "c.client.u", "c.app.r"])}
        >
          <GridButton name="edit" />
          <GridButton name="delete" />
          <GridButton
            cssClass="dx-link customColumnButton"
            hint="Show applications"
            icon={bsFillGearFill}
            visible={hasAccessTo("c.app.r")}
            onClick={showApplicationsClickHandler}
          />
          <GridButton
            hint="Show Users"
            icon="user"
            visible={hasAccessTo("c.user.r")}
            onClick={showUsersClickHandler}
          />
        </Column>
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <Editing
          allowAdding={hasAccessTo("c.client.c")}
          allowDeleting={hasAccessTo("c.client.d")}
          allowUpdating={hasAccessTo("c.client.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="core-clients"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshMessageGridClickHandler} />
        </Template>
      </DataGrid>
      <PopupDataGrid
        dataSource={popupDataStore}
        title="Applications"
        visible={popupVisibility}
        onHiding={applicationsPopupHidingHandler}
      />
    </>
  )
}

const LogoUploader = ({ value }) => {
  return (
    <FileUploader
      accept="image/png"
      allowedFileExtensions={[".png"]}
      maxFileSize={204800}
      uploadUrl={clientLogoUploadUrlGenerator(value)}
      uploadHeaders={{ Authorization: getBearerToken() }}
    />
  )
}

export { Clients }
export default Clients
