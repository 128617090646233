import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  FormItem,
  RequiredRule,
  Lookup,
  StateStoring,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  Pager,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"

import { concurUserDataStore, clientDataStore } from "../../shared/CoreStore"
import { hasAccessTo } from "../../../authentication"

const Users = () => {
  const usersGrid = useRef(null)
  const refreshUserGridClickHandler = () => {
    usersGrid.current.instance.refresh(true)
  }

  return (
    <>
      <DataGrid
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={concurUserDataStore}
        hoverStateEnabled={true}
        ref={usersGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("c.concur.user.r")}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column dataField="id" dataType="number" />
        <Column dataField="clientCode" dataType="string">
          <Lookup
            dataSource={{
              store: clientDataStore,
            }}
            displayExpr="clientCode"
            valueExpr="clientCode"
          />
          <FormItem editorType="dxSelectBox" />
          <RequiredRule />
        </Column>
        <Column dataField="concur_Id" dataType="string" />
        <Column dataField="concur_UserId" dataType="string" />
        <Column dataField="companyName" dataType="string" />
        <Column dataField="contactName" dataType="string" />
        <Column dataField="emailAddress" dataType="string" />
        <Column dataField="phoneNumber" dataType="string" />
        <Column dataField="concur_Application" dataType="string" />
        <Editing
          allowAdding={hasAccessTo("c.concur.user.c")}
          allowDeleting={hasAccessTo("c.concur.user.d")}
          allowUpdating={hasAccessTo("c.concur.user.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
          visible={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          storageKey="core-concur-users"
          type="localStorage"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshUserGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { Users }
export default Users
