import React, { memo, useEffect, useRef, useState } from "react"
import { getSageKeyValueByKey } from "../../operations/client"

const MetaCell = memo(props => {
  const [value, setValue] = useState("Loading...")
  const canceled = useRef(false)
  const id = props.data.id
  const dataField = props.column.dataField
  const text = props.text
  const keyValue =
    props.keyValues && props.keyValues[id] && props.keyValues[id][dataField]
  const error = props.errors && props.errors[id] && props.errors[id][dataField]
  const warning =
    props.warnings && props.warnings[id] && props.warnings[id][dataField]
  const style = {
    backgroundColor: error
      ? "rgb(255 149 146)"
      : warning
      ? "rgb(255 211 146)"
      : null,
  }
  useEffect(() => {
    props.component.resize()
  })
  useEffect(() => {
    const getInfo = async () => {
      const response = await getSageKeyValueByKey(2, keyValue, text)
      !canceled.current && setValue(response.value)
    }
    if (keyValue) getInfo()
    return () => {
      canceled.current = true
    }
  }, [keyValue, text])

  return (
    <div title={error ?? warning} style={style}>
      {keyValue ? value : text}
    </div>
  )
})

export default MetaCell
