import React from "react"
import { Button } from "devextreme-react"
import notify from "devextreme/ui/notify"

import { migrateConcurDatabase } from "../operations/concur"
import { faDatabase } from "../shared/icons"

const Database = () => {
  const onMigrateClickHandler = async () => {
    try {
      await migrateConcurDatabase()
      notify("Concur database was migrated successfully.", "success")
    } catch (error) {
      notify("Concur database migration failed: " + error.message, "error")
    }
  }
  return (
    <Button icon={faDatabase} text="Migrate" onClick={onMigrateClickHandler} />
  )
}

export default Database
