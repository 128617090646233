import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  StateStoring,
  ColumnChooser,
  ColumnFixing,
  Pager,
  FilterPanel,
  Grouping,
  HeaderFilter,
  SearchPanel,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"

import { resourceTimeoutDataStore } from "../../shared/ClientStores"
import { hasAccessTo } from "../../../authentication"

const ResourceTimeout = () => {
  const resourceTimeoutGrid = useRef(null)

  const refreshResourceTimeoutGridClickHandler = () => {
    resourceTimeoutGrid.current.instance.refresh(true)
  }

  return (
    <>
      <DataGrid
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={resourceTimeoutDataStore}
        hoverStateEnabled={true}
        ref={resourceTimeoutGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("resource.timeout.r")}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column
          allowEditing={false}
          defaultVisible={false}
          dataField="id"
          dataType="number"
        />
        <Column dataField="title" dataType="string" />
        <Column dataField="value" dataType="string" />

        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={hasAccessTo("resource.timeout.c")}
          allowDeleting={hasAccessTo("resource.timeout.d")}
          allowUpdating={hasAccessTo("resource.timeout.u")}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <HeaderFilter visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="profile-schedule-resource-timeouts"
        />
        <Template name="refreshGrid">
          <Button
            icon="refresh"
            onClick={refreshResourceTimeoutGridClickHandler}
          />
        </Template>
      </DataGrid>
    </>
  )
}

export { ResourceTimeout }
export default ResourceTimeout
