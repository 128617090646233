import React, { useEffect, useRef, useState } from "react"
import { DataGrid, Template, Button, Popup } from "devextreme-react"
import {
  ColumnChooser,
  ColumnFixing,
  StateStoring,
  FilterPanel,
  Grouping,
  SearchPanel,
  Pager,
  GroupPanel,
  HeaderFilter,
  FilterRow,
  Scrolling,
  Export,
  Selection,
} from "devextreme-react/data-grid"
import { connect } from "react-redux"
import notify from "devextreme/ui/notify"

import { purchaseOrderDataMapPurchaseOrderDataStore } from "../../shared/ConcurStore"
import { FaCloudUploadAlt } from "../../../fa-icons"
import {
  resetPurchaseOrderHash,
  sendPurchaseOrdersToConcur,
} from "../../operations/concur"
import { convertUtcToLocal } from "../../shared/utilities"
import { hasAccessTo, hasAccessToAny } from "../../../authentication"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import ItemScheduler from "../../shared/ItemScheduler"
import DataMapHistory from "./DataMapHistory"
import { faCloudDownloadAlt, faHistory } from "../../shared/icons"
import ConcurStatus from "./ConcurStatus"

function DataMapPurchaseOrders({ refreshUuid }) {
  const purchaseOrderGrid = useRef(null)
  const [historyPurchaseOrderId, setHistoryPurchaseOrderId] = useState(null)
  const [statusPurchaseOrderId, setStatusPurchaseOrderId] = useState(null)

  useEffect(() => {
    purchaseOrderGrid.current.instance.refresh(true)
  }, [refreshUuid])

  const refreshButtonClickHandler = () => {
    purchaseOrderGrid.current.instance.refresh(true)
  }
  const sendPurchaseOrdersToConcurClickHandler = async () => {
    try {
      await sendPurchaseOrdersToConcur()
      notify(
        "Sending purchaseOrders to Concur was queued successfully.",
        "success"
      )
    } catch (error) {
      notify(
        `Sending purchase orders to Concur failed: ${error.message}`,
        "error"
      )
    }
  }
  const historyButtonClickHandler = ({ row }) => {
    setHistoryPurchaseOrderId(row.key)
  }
  const statusButtonClickHandler = ({ row }) => {
    setStatusPurchaseOrderId(row.key)
  }
  const customizeColumnsHandler = columns => {
    const processDateIndex = columns.findIndex(column =>
      column.dataField === "processDate" ? true : false
    )
    if (processDateIndex !== -1) {
      columns[processDateIndex].dataType = "datetime"
      columns[processDateIndex].format = {
        formatter: convertUtcToLocal,
      }
    }
    const onResetHashClickHandler = async ({ row }) => {
      try {
        await resetPurchaseOrderHash({ id: row.key })
        notify(
          `Forcing to resend purchase order to Concur was done successfully.`,
          "success"
        )
      } catch (error) {
        notify(
          `Forcing to resend purchase order to Concur failed: ${error.message}`,
          "error"
        )
      }
    }
    columns.push({
      type: "buttons",
      showInColumnChooser: false,
      visible: hasAccessToAny(["concur.po.history", "concur.po.status"]),
      buttons: [
        {
          cssClass: "dx-link customColumnButton",
          hint: "Show purchase order history",
          icon: faHistory,
          name: "history",
          visible: hasAccessTo("concur.po.history"),
          onClick: historyButtonClickHandler,
        },
        {
          cssClass: "dx-link customColumnButton",
          hint: "Fetch purchase order status from Concur",
          icon: faCloudDownloadAlt,
          name: "status",
          visible: hasAccessTo("concur.po.status"),
          onClick: statusButtonClickHandler,
        },
        {
          cssClass: "dx-link customColumnButton",
          hint: "Force resend this purchase order on next try",
          icon: "refresh",
          name: "reset hash",
          visible: hasAccessTo("concur.po.reset.hash"),
          onClick: onResetHashClickHandler,
        },
      ],
    })
  }

  const historyPopupHidingHandler = () => {
    setHistoryPurchaseOrderId(null)
  }
  const statusPopupHidingHandler = () => {
    setStatusPurchaseOrderId(null)
  }
  return (
    <>
      <Popup
        title="Purchase Order History"
        visible={historyPurchaseOrderId !== null}
        onHiding={historyPopupHidingHandler}
      >
        <DataMapHistory purchaseOrderId={historyPurchaseOrderId} />
      </Popup>
      <Popup
        title="Concur Purchase Order Status"
        visible={statusPurchaseOrderId !== null}
        onHiding={statusPopupHidingHandler}
      >
        <div style={{ height: "100%" }}>
          <ConcurStatus purchaseOrderId={statusPurchaseOrderId} />
        </div>
      </Popup>
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        customizeColumns={customizeColumnsHandler}
        dataSource={purchaseOrderDataMapPurchaseOrderDataStore}
        hoverStateEnabled={true}
        ref={purchaseOrderGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "sendPurchaseOrdersToConcur",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Export
          fileName="datamap-purchase-orders"
          allowExportSelectedData={true}
          enabled={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <Selection
          allowSelectAll={true}
          mode="multiple"
          selectAllMode="page"
          showCheckBoxesMode="always"
        />
        <StateStoring
          enabled={true}
          storageKey="concur-purchaseOrders-datamap"
          type="localStorage"
        />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
          visible={true}
        />
        <Template name="sendPurchaseOrdersToConcur">
          <Button
            elementAttr={{ id: "sendPurchaseOrdersToConcur" }}
            hint="Send purchaseOrders to Concur"
            icon={FaCloudUploadAlt}
            text="Send To Concur"
            visible={hasAccessTo("concur.po.to.concur")}
            onClick={sendPurchaseOrdersToConcurClickHandler}
          />
        </Template>
        <Template name="refreshGrid">
          <Button
            hint="Update grid"
            icon="refresh"
            onClick={refreshButtonClickHandler}
          />
        </Template>
      </DataGrid>
      <ItemScheduler
        items={[
          {
            body: null,
            method: "POST",
            target: "#sendPurchaseOrdersToConcur",
            title: "send purchase orders to concur",
            url: "/api/v1/concur/purchase-orders/send-to-concur",
          },
        ]}
      />
    </>
  )
}

export { DataMapPurchaseOrders }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(DataMapPurchaseOrders)
