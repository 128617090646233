import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  FormItem,
  Lookup,
  StateStoring,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  SearchPanel,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"
import DataSource from "devextreme/data/data_source"
import { FaShieldAlt } from "react-icons/fa"

import {
  lockDataStore,
  applicationDataStore,
  userDataStore,
  roleDataStore,
  clientDataStore,
} from "../shared/ClientStores"
import { hasAccessTo } from "../../authentication"
import { PageTitle } from "../shared/StyledComponents"
import SEO from "../../seo"

const lockDataSource = new DataSource({
  store: lockDataStore,
})

const Locks = () => {
  const locksGrid = useRef(null)
  const refreshRoleGridClickHandler = () => {
    locksGrid.current.instance.refresh(true)
  }

  return (
    <>
      <SEO title="Locks" />
      <PageTitle icon={FaShieldAlt}>Locks</PageTitle>
      <DataGrid
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={lockDataSource}
        hoverStateEnabled={true}
        ref={locksGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("lock.r")}
        onInitNewRow={e => {
          e.data.isActive = false
        }}
        onEditorPreparing={function(e) {
          if (
            e.value &&
            e.parentType === "dataRow" &&
            (e.dataField === "clientId" || e.dataField === "applicationId")
          ) {
            e.editorOptions.value = e.value.toString()
            e.editorOptions.onValueChanged = function(args) {
              e.setValue(parseInt(args.value))
            }
          }
        }}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column
          allowEditing={false}
          dataField="id"
          dataType="number"
          visible={false}
        />
        <Column
          allowEditing={false}
          caption="Client"
          dataField="clientId"
          dataType="number"
        >
          <Lookup
            dataSource={{
              store: clientDataStore,
              map: item => {
                return { ...item, id: item.id.toString() }
              },
            }}
            displayExpr="name"
            valueExpr="id"
          />
        </Column>
        <Column
          allowEditing={false}
          caption="Application"
          dataField="applicationId"
          dataType="number"
        >
          <Lookup
            dataSource={{
              store: applicationDataStore,
              map: item => {
                return { ...item, applicationId: item.applicationId.toString() }
              },
            }}
            displayExpr="title"
            valueExpr="applicationId"
          />
        </Column>
        <Column allowEditing={false} dataField="table" dataType="string" />
        <Column allowEditing={false} dataField="label" dataType="string" />
        <Column caption="Users" dataField="userIds" visible={false}>
          <Lookup
            dataSource={userDataStore}
            dataType="number"
            displayExpr="userName"
            valueExpr="id"
          />
          <FormItem
            editorType="dxTagBox"
            editorOptions={{
              searchEnabled: true,
              showSelectionControls: true,
            }}
          />
        </Column>
        <Column caption="Roles" dataField="roleIds" visible={false}>
          <Lookup
            dataSource={roleDataStore}
            displayExpr="name"
            valueExpr="id"
          />
          <FormItem
            editorType="dxTagBox"
            editorOptions={{
              searchEnabled: true,
              showSelectionControls: true,
            }}
          />
        </Column>
        <Editing
          allowUpdating={hasAccessTo("lock.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
          visible={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="profile-locks"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshRoleGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { Locks }
export default Locks
