import React from "react"
import { Button } from "devextreme-react"
import notify from "devextreme/ui/notify"

import {
  migrateShopifyClientDatabases,
  migrateShopifyDatamapDatabase,
} from "../../operations/core"

const Database = () => {
  const migrateClientsClickHandler = async () => {
    try {
      await migrateShopifyClientDatabases()
      notify("Shopify client databases was migrated successfully.", "success")
    } catch (error) {
      notify(
        `Shopify client databases migration failed: ${error.message}`,
        "error"
      )
    }
  }
  const migrateDatamapClickHandler = async () => {
    try {
      await migrateShopifyDatamapDatabase()
      notify("Shopify datamap database was migrated successfully.", "success")
    } catch (error) {
      notify(
        `Shopify datamap database migration failed: ${error.message}`,
        "error"
      )
    }
  }
  return (
    <>
      <Button
        text="Migrate Clients"
        onClick={migrateClientsClickHandler}
        style={{ marginRight: "1rem" }}
      />
      <Button text="Migrate Datamap" onClick={migrateDatamapClickHandler} />
    </>
  )
}

export default Database
