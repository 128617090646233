import { Button, CircularGauge, Form, LoadPanel } from "devextreme-react"
import {
  Font,
  Geometry,
  Label,
  Range,
  RangeContainer,
  Scale,
  Title,
} from "devextreme-react/circular-gauge"
import { Item as FormItem } from "devextreme-react/form"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import notify from "devextreme/ui/notify"
import { v4 as uuidv4 } from "uuid"
import { GrSystem } from "react-icons/gr"

import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import {
  getClientSystemStatus,
  updateClientSystemStatus,
} from "../../operations/client"
import { FormW, PageTitle } from "../../shared/StyledComponents"
import { riMailSendFill } from "../../shared/icons"
import ItemScheduler from "../../shared/ItemScheduler"

const ClientSystemStatus = ({ autoRefreshUuid }) => {
  const formW = useRef(null)
  const [internalUuid, setInternalUuid] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [jvmMemory, setJvmMemory] = useState({
    scale: "",
    free: 0,
    max: 100,
    total: 100,
    used: 0,
  })
  const [physicalMemory, setPhysicalMemory] = useState({
    scale: "",
    free: 0,
    max: 100,
    total: 100,
    used: 0,
  })
  const [swapMemory, setSwapMemory] = useState({
    scale: "",
    free: 0,
    max: 100,
    total: 100,
    used: 0,
  })
  const [formData, setFormData] = useState({})

  useEffect(() => {
    const getStatus = async () => {
      try {
        setIsLoading(true)
        const data = await getClientSystemStatus()
        setFormData(data)
        setJvmMemory(calculateJvmMemory(data))
        setPhysicalMemory(calculatePhysicalMemory(data))
        setSwapMemory(calculateSwapMemory(data))
      } catch (error) {
        notify(`Display client system status failed: ${error}`, "error")
      } finally {
        setIsLoading(false)
      }
    }
    getStatus()
  }, [autoRefreshUuid, internalUuid])

  const refreshButtonClickHandler = () => {
    setInternalUuid(uuidv4())
  }
  const updateButtonClickHandler = async () => {
    try {
      setIsLoading(true)
      await updateClientSystemStatus()
      notify(
        `Update client system status request was queued successfully.`,
        "success"
      )
    } catch (error) {
      notify(`Updating client system status failed: ${error}`, "error")
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <>
      <PageTitle icon={GrSystem} variant="subTitle">
        Client System Status
      </PageTitle>
      <FormW ref={formW}>
        <Button
          hint="Refresh status."
          icon="refresh"
          onClick={refreshButtonClickHandler}
        />
        <Button
          elementAttr={{ id: "updateClientSystemStatus" }}
          hint="Update status."
          icon={riMailSendFill}
          onClick={updateButtonClickHandler}
        />
        <Form formData={formData} readOnly={true} colCount={2}>
          <FormItem itemType="group" colCount={3} colSpan={2}>
            <FormItem
              render={() => (
                <CircularGauge value={jvmMemory.used} redrawOnResize={true}>
                  <Geometry startAngle={180} endAngle={0}></Geometry>
                  <Scale startValue={0} endValue={jvmMemory.max}>
                    <Label useRangeColors={true} />
                  </Scale>
                  <RangeContainer palette="pastel">
                    <Range
                      startValue={0}
                      endValue={jvmMemory.total}
                      color="rgb(96, 166, 159)"
                    />
                    <Range
                      startValue={jvmMemory.total}
                      endValue={jvmMemory.max}
                      color="rgb(163, 113, 130)"
                    />
                  </RangeContainer>
                  <Title text={`JVM Memory Size (${jvmMemory.scale})`}>
                    <Font size={16} />
                  </Title>
                </CircularGauge>
              )}
            />
            <FormItem
              render={() => (
                <CircularGauge
                  value={physicalMemory.used}
                  redrawOnResize={true}
                >
                  <Geometry startAngle={180} endAngle={0} />
                  <Scale startValue={0} endValue={physicalMemory.max}>
                    <Label useRangeColors={true} />
                  </Scale>
                  <RangeContainer palette="pastel">
                    <Range
                      startValue={0}
                      endValue={physicalMemory.total}
                      color="rgb(96, 166, 159)"
                    />
                    <Range
                      startValue={physicalMemory.total}
                      endValue={physicalMemory.max}
                      color="rgb(163, 113, 130)"
                    />
                  </RangeContainer>
                  <Title
                    text={`Physical Memory Size (${physicalMemory.scale})`}
                  >
                    <Font size={16} />
                  </Title>
                </CircularGauge>
              )}
            />
            <FormItem
              render={() => (
                <CircularGauge value={swapMemory.used} redrawOnResize={true}>
                  <Geometry startAngle={180} endAngle={0} />
                  <Scale startValue={0} endValue={swapMemory.max}>
                    <Label useRangeColors={true} />
                  </Scale>
                  <RangeContainer palette="pastel">
                    <Range
                      startValue={0}
                      endValue={swapMemory.total}
                      color="rgb(96, 166, 159)"
                    />
                    <Range
                      startValue={swapMemory.total}
                      endValue={swapMemory.max}
                      color="rgb(163, 113, 130)"
                    />
                  </RangeContainer>
                  <Title text={`Swap Memory Size (${swapMemory.scale})`}>
                    <Font size={16} />
                  </Title>
                </CircularGauge>
              )}
            />
          </FormItem>
          <FormItem itemType="group" colCount={2} colSpan={2}>
            <FormItem dataField="kettleVersion" />
            <FormItem dataField="hostName" />
            <FormItem dataField="ipAddress" />
            <FormItem dataField="availableProcessor" />
            <FormItem dataField="datamapDate" />
            <FormItem dataField="clientDate" />
          </FormItem>
        </Form>
        <LoadPanel
          visible={isLoading}
          container={formW.current}
          position={{
            of: formW.current,
          }}
        />
      </FormW>
      <ItemScheduler
        items={[
          {
            body: null,
            method: "POST",
            target: "#updateClientSystemStatus",
            title: "update client system status",
            url: "/api/v1/clients/system-status/update",
          },
        ]}
      />
    </>
  )
}

const convertByte = (bytes, scale) => {
  if (scale === "TB") return bytes / 1099511627776
  if (scale === "GB") return bytes / 1073741824
  if (scale === "MB") return bytes / 1048576
  if (scale === "KB") return bytes / 2014
}

const calculateScale = bytes => {
  if (bytes > 1099511627776) return "TB"
  if (bytes > 1073741824) return "GB"
  if (bytes > 1048576) return "MB"
  if (bytes > 1024) return "KB"
}

const calculateJvmMemory = data => {
  const scale = calculateScale(data.jvmMaxMemory)
  const free = convertByte(data.jvmFreeMemory, scale)
  const max = convertByte(data.jvmMaxMemory, scale)
  const total = convertByte(data.jvmTotalMemory, scale)
  const used = convertByte(data.jvmTotalMemory - data.jvmFreeMemory, scale)
  return { free, max, scale, total, used }
}

const calculatePhysicalMemory = data => {
  const scale = calculateScale(data.totalPhysicalMemorySize)
  const free = convertByte(data.freePhysicalMemorySize, scale)
  const max = convertByte(data.totalPhysicalMemorySize, scale)
  const total = max - max / 5
  const used = convertByte(
    data.totalPhysicalMemorySize - data.freePhysicalMemorySize,
    scale
  )
  return { free, scale, max, total, used }
}

const calculateSwapMemory = data => {
  const scale = calculateScale(data.totalSwapSpaceSize)
  const free = convertByte(data.freeSwapSpaceSize, scale)
  const max = convertByte(data.totalSwapSpaceSize, scale)
  const total = max - max / 5
  const used = convertByte(
    data.totalSwapSpaceSize - data.freeSwapSpaceSize,
    scale
  )
  return { free, scale, max, total, used }
}

export { ClientSystemStatus }
const mapStateToProps = state => ({
  autoRefreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(ClientSystemStatus)
