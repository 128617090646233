import React from "react"
import { TabPanel } from "devextreme-react"
import { Item } from "devextreme-react/tab-panel"
import { FaCogs } from "react-icons/fa"

import { FaFileInvoiceDollar } from "../../../fa-icons"
import { PageTitle, TabItemW } from "../../shared/StyledComponents"
import SEO from "../../../seo"
import General from "./General"

const Settings = () => {
  return (
    <>
      <SEO title="User Settings" />
      <PageTitle icon={FaCogs}>User Settings</PageTitle>
      <TabPanel>
        <Item title="General" icon={FaFileInvoiceDollar}>
          <TabItemW>
            <General />
          </TabItemW>
        </Item>
      </TabPanel>
    </>
  )
}

export default Settings
