import React, { memo, useCallback, useRef } from "react"
import { DataGrid } from "devextreme-react"
import PropTypes from "prop-types"
import {
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  FilterPanel,
  FilterRow,
  Grouping,
  HeaderFilter,
  LoadPanel,
  Pager,
  Scrolling,
  SearchPanel,
  StateStoring,
} from "devextreme-react/data-grid"
import MetaCell from "./MetaCell"
import MetaEditCell from "./MetaEditCell"

const calculateSortValueHandler = (keyValueTexts, dataField) => row =>
  keyValueTexts[dataField].find(item => item.rowId === row.id)?.text

const MetaGridLine = memo(
  ({
    allowDeleting,
    allowUpdating,
    columns,
    data,
    headerFilters,
    storageKey,
    setLineValue,
    keyValues,
    keyValueTexts,
    errors,
    warnings,
  }) => {
    const gridRef = useRef(null)
    const contentReadyHandler = ({ component }) => {
      component
        .getDataSource()
        .store()
        .load()
        .done(allData => {
          if (setLineValue) setLineValue(allData)
        })
    }
    const saveChangeHandler = useCallback(() => {
      gridRef.current.instance.saveEditData()
    }, [])

    const rowRemovingHandler = useCallback(e => {
      if (e.component.totalCount() <= 1) {
        e.cancel = Promise.reject("There should be at least one line.")
      }
    }, [])

    const editorPreparingHandler = useCallback(
      e => {
        if (e.parentType === "dataRow") {
          e.editorOptions.valueChangeEvent = "keyup"
          const standardHandler = e.editorOptions.onValueChanged
          e.editorOptions.onValueChanged = function(e) {
            standardHandler(e)
            saveChangeHandler()
          }
        }
      },
      [saveChangeHandler]
    )

    return (
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={data}
        hoverStateEnabled={true}
        ref={gridRef}
        rowAlternationEnabled={true}
        showBorders={true}
        onContentReady={contentReadyHandler}
        repaintChangesOnly={true}
        onEditorPreparing={editorPreparingHandler}
        onRowRemoving={rowRemovingHandler}
      >
        {columns.map(item => {
          const hasKeyValue = Object.keys(keyValues).some(
            key => keyValues && keyValues[key] && keyValues[key][item.dataField]
          )
          if (hasKeyValue)
            return (
              <Column
                key={item.dataField}
                {...item}
                cellRender={data => (
                  <MetaCell
                    {...data}
                    keyValues={keyValues}
                    errors={errors}
                    warnings={warnings}
                  />
                )}
                editCellRender={data => (
                  <MetaEditCell
                    {...data}
                    keyValues={keyValues}
                    onSaveChange={saveChangeHandler}
                  />
                )}
                calculateSortValue={calculateSortValueHandler(
                  keyValueTexts,
                  item.dataField
                )}
              >
                <HeaderFilter
                  dataSource={data => {
                    data.dataSource.postProcess = results => {
                      return headerFilters[item.dataField] ?? results
                    }
                  }}
                />
              </Column>
            )
          return (
            <Column
              key={item.dataField}
              {...item}
              cellRender={data => (
                <MetaCell {...data} errors={errors} warnings={warnings} />
              )}
            />
          )
        })}
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          mode="cell"
          useIcons={true}
          allowDeleting={allowDeleting}
          allowUpdating={allowUpdating}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={false} />
        <Grouping contextMenuEnabled={true} />
        <HeaderFilter visible={true} />
        <LoadPanel shading={true} enabled={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <StateStoring
          enabled={storageKey ? true : false}
          type="localStorage"
          storageKey={`${storageKey}-lines`}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
      </DataGrid>
    )
  }
)
MetaGridLine.propTypes = {
  addSelection: PropTypes.bool,
  allowDeleting: PropTypes.bool,
  allowUpdating: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  setLineValue: PropTypes.func,
  storageKey: PropTypes.string,
}
MetaGridLine.defaultProps = {
  allowDeleting: false,
  allowUpdating: false,
  keyValues: {},
}

export { MetaGridLine }
export default MetaGridLine
