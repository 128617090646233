import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  Lookup,
  StateStoring,
  ColumnChooser,
  ColumnFixing,
  Pager,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"
import DataSource from "devextreme/data/data_source"

import {
  sageColumnDataStore,
  applicationDataStore,
} from "../../shared/ClientStores"
import { hasAccessTo } from "../../../authentication"
import { moduleDataStore as concurModuleDataStore } from "../../shared/ConcurStore"

const sageColumnDataSource = new DataSource({ store: sageColumnDataStore })

const Columns = () => {
  const sageColumnsGrid = useRef(null)

  const refreshMessageGridClickHandler = () => {
    sageColumnsGrid.current.instance.refresh(true)
  }

  const toolbarPreparingHandler = e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }
  return (
    <>
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={sageColumnDataSource}
        hoverStateEnabled={true}
        ref={sageColumnsGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("sage.column.r")}
        onToolbarPreparing={toolbarPreparingHandler}
      >
        <Column allowEditing={false} dataField="id" dataType="number" />
        <Column
          caption="Application"
          dataField="applicationId"
          dataType="number"
        >
          <Lookup
            dataSource={{
              store: applicationDataStore,
              map: item => {
                return { ...item, id: item.id.toString() }
              },
            }}
            valueExpr="applicationId"
            displayExpr="title"
          />
        </Column>
        <Column caption="Module" dataField="moduleId" dataType="number">
          <Lookup
            dataSource={{
              store: concurModuleDataStore,
              map: item => {
                return { ...item, id: item.id.toString() }
              },
            }}
            valueExpr="id"
            displayExpr="title"
          />
        </Column>
        <Column dataField="dataField" dataType="string" />
        <Column dataField="caption" dataType="string" />
        <Column dataField="dataType" dataType="string">
          <Lookup
            dataSource={[
              "string",
              "number",
              "date",
              "boolean",
              "object",
              "datetime",
            ]}
          />
        </Column>
        <Column dataField="allowEditing" dataType="boolean" />
        <Column dataField="visible" dataType="boolean" />
        <Column dataField="level" dataType="string" />
        <Column dataField="aggregate" dataType="boolean" />
        <Column dataField="attributes" dataType="string" />
        <Column dataField="sequence" dataType="number" />
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          allowDeleting={hasAccessTo("sage.column.d")}
          allowUpdating={hasAccessTo("sage.column.u")}
          allowAdding={hasAccessTo("sage.column.c")}
          mode="cell"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="profile-sage-columns"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshMessageGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { Columns }
export default Columns
