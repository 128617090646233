import React, { useCallback, useEffect, useRef } from "react"
import { Scheduler } from "devextreme-react"

import {
  scheduleDataStore,
  resourcePriorityDataStore,
  resourceTimeoutDataStore,
  resourceApiDataStore,
} from "../../shared/ClientStores"
import { Editing, Resource, View } from "devextreme-react/scheduler"
import { hasAccessTo } from "../../../authentication"

const Calendar = ({ location }) => {
  const schedule = useRef(null)

  useEffect(() => {
    schedule.current.instance.option("dataSource", {
      store: scheduleDataStore,
      filter: ["isActive", "=", true],
    })
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const resourceApiId = searchParams.get("resourceApiId")
    if (resourceApiId)
      schedule.current.instance.showAppointmentPopup(
        {
          resourceApiId: Number(resourceApiId),
          endDate: new Date(),
        },
        true
      )
  }, [location.search])

  const appointmentFormOpeningHandler = useCallback(({ form }) => {
    form.itemOption("mainGroup.resourceApiId", {
      isRequired: true,
      colSpan: 2,
    })
    form.itemOption("mainGroup.resourcePriorityId", "isRequired", true)
    form.itemOption("mainGroup.text", "isRequired", true)
    form.itemOption("mainGroup.endDate", {
      isRequired: false,
      label: { visible: false },
      editorOptions: { visible: false },
    })
    form.itemOption("mainGroup.resourceTimeoutId", "isRequired", true)
  }, [])

  return (
    <>
      <Scheduler
        cellDuration={15}
        firstDayOfWeek={1}
        ref={schedule}
        visible={hasAccessTo("schedule.r")}
        onAppointmentFormOpening={appointmentFormOpeningHandler}
      >
        <Editing
          allowAdding={hasAccessTo("schedule.c")}
          allowDeleting={hasAccessTo("schedule.d")}
          allowUpdating={hasAccessTo("schedule.u")}
        />
        <Resource
          displayExpr="title"
          valueExpr="id"
          fieldExpr="resourceApiId"
          label="Api"
          dataSource={resourceApiDataStore}
        />
        <Resource
          displayExpr="title"
          valueExpr="id"
          fieldExpr="resourcePriorityId"
          label="Priority"
          dataSource={resourcePriorityDataStore}
        />
        <Resource
          displayExpr="title"
          valueExpr="id"
          fieldExpr="resourceTimeoutId"
          label="Timeout"
          dataSource={resourceTimeoutDataStore}
        />
        <View type="day" />
        <View type="week" />
        <View type="month" />
        <View type="timelineDay" groups={["resourceApiId"]} />
        <View type="timelineWeek" groups={["resourceApiId"]} />
        <View type="timelineMonth" groups={["resourceApiId"]} />
        <View type="agenda" />
      </Scheduler>
    </>
  )
}

export { Calendar }
export default Calendar
