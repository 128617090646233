import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  RequiredRule,
  Lookup,
  StateStoring,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  Pager,
  Button as GridButton,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"
import DataSource from "devextreme/data/data_source"
import notify from "devextreme/ui/notify"
import { FaLock } from "react-icons/fa"

import {
  permissionDataStore,
  applicationDataStore,
} from "../shared/ClientStores"
import { hasAccessTo, hasAccessToAny } from "../../authentication"
import { PageTitle, LockButton } from "../shared/StyledComponents"
import { permissionLocker } from "../operations/client"
import SEO from "../../seo"

const permissionDataSource = new DataSource({ store: permissionDataStore })

const Permissions = () => {
  const permissionsGrid = useRef(null)
  const refreshPermissionGridClickHandler = () => {
    permissionsGrid.current.instance.refresh(true)
  }
  const lockButtonRenderHandler = ({ data }) => (
    <LockButton onClick={lockClickHandler(data.id)} lockId={data.lockId} />
  )
  const lockClickHandler = id => async () => {
    try {
      await permissionLocker(id)
      permissionsGrid.current.instance.refresh(false)
    } catch (error) {
      notify(`Changing lock status failed: ${error.message}`, "error")
    }
  }
  return (
    <>
      <SEO title="Permissions" />
      <PageTitle icon={FaLock}>Permissions</PageTitle>
      <DataGrid
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={permissionDataSource}
        hoverStateEnabled={true}
        ref={permissionsGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("perm.r")}
        onEditorPreparing={function(e) {
          if (
            e.value &&
            e.parentType === "dataRow" &&
            e.dataField === "applicationId"
          ) {
            e.editorOptions.value = e.value.toString()
            e.editorOptions.onValueChanged = function(args) {
              e.setValue(parseInt(args.value))
            }
          }
        }}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column
          allowEditing={false}
          caption="Application"
          dataField="applicationId"
          dataType="number"
        >
          <Lookup
            dataSource={{
              store: applicationDataStore,
              map: item => {
                return { ...item, applicationId: item.applicationId.toString() }
              },
            }}
            valueExpr="applicationId"
            displayExpr="title"
          />
          <RequiredRule />
        </Column>
        <Column allowEditing={false} dataField="title" dataType="string">
          <RequiredRule trim={true} />
        </Column>
        <Column dataField="isActive" dataType="boolean" />
        <Column
          type="buttons"
          visible={hasAccessToAny(["perm.d", "perm.u", "perm.l"])}
        >
          <GridButton name="edit" />
          <GridButton name="delete" />
          <GridButton
            render={lockButtonRenderHandler}
            visible={hasAccessTo("perm.l")}
          />
        </Column>
        <Editing
          mode="cell"
          useIcons={true}
          allowUpdating={hasAccessTo("perm.u")}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          visible={true}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="profile-permissions"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshPermissionGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { Permissions }
export default Permissions
