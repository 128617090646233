import { navigate } from "@reach/router"
import React from "react"
import styled from "styled-components"
import { startCase } from "lodash"

const W = styled.div``
const Group = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  :nth-child(2n + 1) {
    background-color: rgb(245 245 245);
  }
  :hover {
    background-color: rgb(245 245 245);
  }
`
const Key = styled.div`
  font-weight: bold;
`
const ValuesW = styled.div`
  display: flex;
`
const Value = styled.div`
  cursor: ${props => (props.clickable ? "pointer" : "cursor")};
  font-weight: bold;
  min-width: 5rem;
  text-align: center;
`

const Table = ({ id, title, groups, subGroups, data }) => {
  const groupClickHandler = (plantType, group, subGroup) => () => {
    navigate("/dashboard/shopify/search-results", {
      state: { type: "query", id, plantType, group, subGroup, title },
    })
  }
  const isTotalClickable = () => {
    const plantTypesSet = new Set()
    data.forEach(item => {
      plantTypesSet.add(item.plantType)
    })
    return plantTypesSet.size === 1
  }
  const getPlantType = () => {
    const plantTypesSet = new Set()
    data.forEach(item => {
      plantTypesSet.add(item.plantType)
    })
    return plantTypesSet.size === 1 ? plantTypesSet.values().next().value : ""
  }
  return (
    <W>
      {subGroups.length > 1 && (
        <Group>
          <Key></Key>
          <ValuesW>
            {subGroups.map((item, index) => (
              <Value key={index}>
                {item === "n/a" ? "N/A" : startCase(item)}
              </Value>
            ))}
            {subGroups.length > 1 && <Value>Total</Value>}
          </ValuesW>
        </Group>
      )}
      {groups.map((group, index) => (
        <Group key={index}>
          <Key>{group}</Key>
          <ValuesW>
            {subGroups.map((subGroup, index) => {
              let item = data.find(
                item => item.group === group && item.subGroup === subGroup
              )
              if (item) {
                return (
                  <Value
                    onClick={groupClickHandler(
                      item?.plantType,
                      group,
                      subGroup
                    )}
                    key={index}
                    clickable
                  >
                    {item?.value}
                  </Value>
                )
              }
              return <Value key={index}>0</Value>
            })}
            {subGroups.length > 1 &&
              (data
                .filter(item => item.group === group)
                .map(item => item.value)
                .reduce((previous, current) => previous + current, 0) === 0 ? (
                <Value>0</Value>
              ) : (
                <Value
                  onClick={groupClickHandler(getPlantType(), group, "")}
                  clickable
                >
                  {data
                    .filter(item => item.group === group)
                    .map(item => item.value)
                    .reduce((previous, current) => previous + current, 0)}
                </Value>
              ))}
          </ValuesW>
        </Group>
      ))}
      {groups.length > 1 && (
        <Group>
          <Key>Total</Key>
          <ValuesW>
            {subGroups.map((subGroup, index) => {
              const sum = data
                .filter(item => item.subGroup === subGroup)
                .map(item => item.value)
                .reduce((previous, current) => previous + current, 0)
              if (sum === 0) {
                return <Value key={index}>0</Value>
              }
              if (isTotalClickable()) {
                return (
                  <Value
                    onClick={groupClickHandler(getPlantType(), "", subGroup)}
                    key={index}
                    clickable
                  >
                    {sum}
                  </Value>
                )
              }
              return <Value key={index}>{sum}</Value>
            })}
            {subGroups.length > 1 &&
              (data
                .map(item => item.value)
                .reduce((previous, current) => previous + current, 0) === 0 ? (
                <Value>0</Value>
              ) : isTotalClickable() ? (
                <Value
                  onClick={groupClickHandler(getPlantType(), "", "")}
                  clickable
                >
                  {data
                    .map(item => item.value)
                    .reduce((previous, current) => previous + current, 0)}
                </Value>
              ) : (
                <Value>
                  {data
                    .map(item => item.value)
                    .reduce((previous, current) => previous + current, 0)}
                </Value>
              ))}
          </ValuesW>
        </Group>
      )}
    </W>
  )
}

export default Table
