import React, { useState } from "react"
import { Button, SelectBox, TextArea } from "devextreme-react"
import notify from "devextreme/ui/notify"
import styled from "styled-components"
import jsesc from "jsesc"
import { vscServerProcess } from "../../../shared/icons"
import { emailNotificationDataStore as coreEmailNotificationDataStore } from "../../../shared/CoreStore"
import { emailNotificationDataStore as clientEmailNotificationDataStore } from "../../../shared/ClientStores"
import { runEmailNotificationQuery } from "../../../operations/core"

const WidgetsW = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Label = styled.div`
  margin-bottom: 0.5rem;
`
const ItemW = styled.div`
  margin-bottom: 1rem;
`
const TextAreaW = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgb(221 221 221);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`

const Templates = () => {
  const [emailNotificationId, setEmailNotificationId] = useState(null)
  const [bodyHeader, setBodyHeader] = useState(null)
  const [body, setBody] = useState(null)
  const [bodyFooter, setBodyFooter] = useState(null)
  const [title, setTitle] = useState(null)
  const [result, setResult] = useState(null)

  const selectBoxValueChangedHandler = async ({ value }) => {
    try {
      const {
        bodyHeader,
        body,
        bodyFooter,
        title,
      } = await coreEmailNotificationDataStore.byKey(value)
      setEmailNotificationId(value)
      setBodyHeader(bodyHeader)
      setBody(body)
      setBodyFooter(bodyFooter)
      setTitle(title)
    } catch (error) {
      setEmailNotificationId(null)
      setBodyHeader(null)
      setBody(null)
      setBodyFooter(null)
      setTitle(null)
      notify(`Fetching query information failed: ${error.message}`, "error")
    }
  }

  const runEmailNotificationQueryClickHandler = async () => {
    try {
      const response = await runEmailNotificationQuery({
        emailNotificationId,
        bodyHeader,
        body,
        bodyFooter,
      })
      const result = response.data
      setResult(result)
      notify(`Query ran successfully`, "success")
    } catch (error) {
      notify(`Executing query failed: ${error.message}`, "error")
    }
  }

  const saveEmailNotificationQueryClickHandler = async () => {
    const data = jsesc(
      { emailNotificationId, title, bodyHeader, body, bodyFooter },
      {
        quotes: "double",
      }
    )
    try {
      await clientEmailNotificationDataStore.insert(data)
      notify(`Query saved successfully`, "success")
    } catch (error) {
      notify(`Saving query failed: ${error.message}`, "error")
    }
  }

  const bodyHeaderValueChangedHandler = ({ value }) => {
    setBodyHeader(value)
  }
  const bodyValueChangedHandler = ({ value }) => {
    setBody(value)
  }
  const bodyFooterValueChangedHandler = ({ value }) => {
    setBodyFooter(value)
  }
  return (
    <>
      <WidgetsW>
        <SelectBox
          dataSource={coreEmailNotificationDataStore}
          displayExpr="title"
          valueExpr="id"
          placeholder="Select template..."
          value={emailNotificationId}
          width="calc(100%)"
          onValueChanged={selectBoxValueChangedHandler}
        />
      </WidgetsW>
      {emailNotificationId !== null && (
        <TextAreaW>
          <ItemW>
            <Label>Body Header:</Label>
            <TextArea
              autoResizeEnabled={true}
              value={bodyHeader}
              onValueChanged={bodyHeaderValueChangedHandler}
            />
          </ItemW>
          <ItemW>
            <Label>Body:</Label>
            <TextArea
              autoResizeEnabled={true}
              value={body}
              onValueChanged={bodyValueChangedHandler}
            />
          </ItemW>
          <ItemW>
            <Label>Body Footer:</Label>
            <TextArea
              autoResizeEnabled={true}
              value={bodyFooter}
              onValueChanged={bodyFooterValueChangedHandler}
            />
          </ItemW>
          <Button
            icon={vscServerProcess}
            onClick={runEmailNotificationQueryClickHandler}
            hint="Run this query"
            text="Run"
            style={{ marginTop: "0.5rem", marginRight: "0.5rem" }}
          />
          <Button
            icon="save"
            onClick={saveEmailNotificationQueryClickHandler}
            hint="Save this query"
            text="Save"
            style={{ marginTop: "0.5rem" }}
          />
        </TextAreaW>
      )}
      {result && (
        <>
          <div dangerouslySetInnerHTML={{ __html: result }} />
        </>
      )}
    </>
  )
}

export default Templates
