import React, { useEffect, useRef, useCallback } from "react"
import { Form, LoadPanel } from "devextreme-react"
import { Item as FormItem } from "devextreme-react/form"
import notify from "devextreme/ui/notify"

import { userGeneralSettingDataStore } from "../../shared/ClientStores"
import { FormW } from "../../shared/StyledComponents"

const General = () => {
  const formRef = useRef(null)
  const formWRef = useRef(null)
  const loadPanelRef = useRef(null)
  const dataLoaded = useRef(false)

  const getFormInfo = useCallback(async () => {
    try {
      loadPanelRef.current.instance.option("visible", true)
      const { data } = await userGeneralSettingDataStore.load()
      if (data?.sendErrors !== true)
        formRef.current.instance.itemOption("sendErrorScreenshot", {
          editorOptions: { disabled: true },
        })
      data && formRef.current.instance.option("formData", data)
      dataLoaded.current = true
    } catch (error) {
      notify(`Getting general settings failed: ${error.message}`, "error")
    } finally {
      loadPanelRef.current.instance.option("visible", false)
    }
  }, [])

  useEffect(() => {
    getFormInfo()
  }, [getFormInfo])

  const fieldDataChangedHandler = async ({ component, dataField, value }) => {
    let update = { [dataField]: value }
    try {
      component.beginUpdate()
      loadPanelRef.current.instance.option("visible", true)
      if (dataField === "sendErrors" && value === true)
        formRef.current.instance.itemOption("sendErrorScreenshot", {
          editorOptions: { disabled: false },
        })
      if (dataField === "sendErrors" && value === false) {
        formRef.current.instance.itemOption("sendErrorScreenshot", {
          editorOptions: { disabled: true },
        })
        update = { ...update, sendErrorScreenshot: false }
      }
      if (dataLoaded.current) await userGeneralSettingDataStore.update(update)
    } catch (error) {
      notify(`Updating general settings failed: ${error.message}`, "error")
    } finally {
      loadPanelRef.current.instance.option("visible", false)
      component.endUpdate()
    }
  }
  return (
    <>
      <FormW ref={formWRef}>
        <Form
          ref={formRef}
          colCount={2}
          onFieldDataChanged={fieldDataChangedHandler}
        >
          <FormItem
            dataField="sendErrors"
            editorType="dxSwitch"
            editorOptions={{
              hint: "Send errors to datamap server automatically.",
            }}
          />
          <FormItem
            dataField="sendErrorScreenshot"
            editorType="dxSwitch"
            editorOptions={{
              hint: "Send errors screenshot to datamap server automatically.",
            }}
          />
        </Form>
        <LoadPanel
          container={formWRef.current}
          position={{
            of: formWRef.current,
            my: "center",
          }}
          ref={loadPanelRef}
          shading={true}
        />
      </FormW>
    </>
  )
}

export default General
