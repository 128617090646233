import React, { useEffect, useRef, useState } from "react"
import { DataGrid, Template, Button, Popup } from "devextreme-react"
import {
  ColumnChooser,
  ColumnFixing,
  StateStoring,
  FilterPanel,
  Grouping,
  SearchPanel,
  Pager,
  GroupPanel,
  HeaderFilter,
  FilterRow,
  Scrolling,
  Column,
  Button as GButton,
  Format,
} from "devextreme-react/data-grid"
import { connect } from "react-redux"

import { TabItemGridW } from "../../shared/StyledComponents"
import { scheduleHistoryDataStoreGenerator } from "../../shared/ClientStores"
import { convertUtcToLocal } from "../../shared/utilities"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import { MessageDetail } from "../messages"

const DataMapHistory = ({ refreshUuid, timeRange, scheduleId }) => {
  const historyGrid = useRef(null)
  const [messageDetailId, setMessageDetailId] = useState(null)

  useEffect(() => {
    historyGrid.current.instance.refresh(true)
  }, [refreshUuid, scheduleId, timeRange])
  useEffect(() => {
    const dataSource =
      scheduleId !== null
        ? scheduleHistoryDataStoreGenerator(scheduleId, { timeRange })
        : []
    historyGrid.current.instance.option("dataSource", dataSource)
  }, [scheduleId, timeRange])

  const refreshButtonClickHandler = () => {
    historyGrid.current.instance.refresh(true)
  }
  const moreButtonClickHandler = ({ row }) => {
    setMessageDetailId(row.data.messageId)
  }
  const messageDetailPopupHidingHandler = () => {
    setMessageDetailId(null)
  }
  return (
    <>
      <Popup
        title="Message Details"
        visible={messageDetailId !== null}
        onHiding={messageDetailPopupHidingHandler}
      >
        <MessageDetail messageId={messageDetailId} />
      </Popup>
      <TabItemGridW>
        <DataGrid
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          columnResizingMode="widget"
          height="100%"
          ref={historyGrid}
          rowAlternationEnabled={true}
          showBorders={true}
          onToolbarPreparing={e => {
            e.toolbarOptions.items.unshift({
              location: "before",
              template: "refreshGrid",
            })
          }}
        >
          <Column dataField="id" dataType="number" defaultVisible={false} />
          <Column dataField="scheduleId" dataType="number" />
          <Column dataField="messageId" dataType="number" />
          <Column dataField="occurrenceDate" dataType="datetime">
            <Format formatter={convertUtcToLocal} />
          </Column>
          <Column dataField="processDate" dataType="datetime">
            <Format formatter={convertUtcToLocal} />
          </Column>
          <Column dataField="status" dataType="string" />
          <Column dataField="errors" dataType="string" defaultVisible={false} />
          <Column type="buttons" showInColumnChooser={false}>
            <GButton
              onClick={moreButtonClickHandler}
              hint="Show message details"
              icon="more"
            />
          </Column>
          <ColumnChooser
            allowSearch={true}
            enabled={true}
            height="400"
            mode="select"
          />
          <ColumnFixing enabled={true} />
          <StateStoring
            enabled={true}
            storageKey="concur-schedule-history"
            type="localStorage"
          />
          <FilterPanel visible={true} />
          <FilterRow visible={true} />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling showScrollbar="always" />
          <SearchPanel visible={true} />
          <Pager
            allowedPageSizes={[10, 20, 50, 100]}
            showInfo={true}
            showNavigationButtons={true}
            showPageSizeSelector={true}
            visible={true}
          />
          <Template name="refreshGrid">
            <Button
              hint="Refresh grid"
              icon="refresh"
              onClick={refreshButtonClickHandler}
            />
          </Template>
        </DataGrid>
      </TabItemGridW>
    </>
  )
}

export { DataMapHistory }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(DataMapHistory)
