import React from "react"
import styled from "styled-components"
import { Router } from "@reach/router"

import Applications from "./Applications"
import Messages from "./messages"
import Permissions from "./Permissions"
import Roles from "./Roles"
import Topics from "./Topics"
import Users from "./Users"
import Developments from "./developments"
import Locks from "./Locks"
import Sage from "./sage"
import Schedule from "./schedule"
import Settings from "./settings"

const HomeW = styled.div`
  height: 100%;
`
const Home = () => {
  return (
    <HomeW>
      <Router>
        <Applications path="applications" />
        <Messages path="messages" />
        <Permissions path="permissions" />
        <Roles path="roles" />
        <Topics path="topics" />
        <Users path="users" />
        <Locks path="locks" />
        <Sage path="sage/**" />
        <Schedule path="schedule/**" />
        <Developments path="developments" />
        <Settings path="settings" />
      </Router>
    </HomeW>
  )
}

export { Home }
export default Home
