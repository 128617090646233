import React, { useEffect, useRef } from "react"
import { Chart, PivotGrid } from "devextreme-react"
import {
  CommonAxisSettings,
  CommonSeriesSettings,
  Grid,
  LoadingIndicator,
  Tooltip,
} from "devextreme-react/chart"
import {
  Export,
  FieldChooser,
  FieldPanel,
  StateStoring,
} from "devextreme-react/pivot-grid"
import { messageDataStoreGenerator } from "../../shared/ClientStores"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import { connect } from "react-redux"

const MessagePivot = ({ timeRange, refreshUuid }) => {
  const chartRef = useRef(null)
  const pivotRef = useRef(null)

  useEffect(() => {
    const dataSource = {
      fields: [
        { dataField: "userId", area: "row" },
        { dataField: "createDate", dataType: "date", area: "column" },
        {
          groupName: "createDate",
          groupInterval: "month",
          visible: true,
        },
        { dataField: "processDate", dataType: "date", isMeasure: false },
        {
          groupName: "processDate",
          groupInterval: "month",
          visible: true,
        },
        { dataField: "scheduleDate", dataType: "date", isMeasure: false },
        {
          groupName: "scheduleDate",
          groupInterval: "month",
          visible: true,
        },
        { dataField: "fetchDate", dataType: "date", isMeasure: false },
        {
          groupName: "fetchDate",
          groupInterval: "month",
          visible: true,
        },
        { dataField: "expiryDate", dataType: "date", isMeasure: false },
        {
          groupName: "expiryDate",
          groupInterval: "month",
          visible: true,
        },
        {
          caption: "Total messages",
          dataField: "status",
          dataType: "string",
          summaryType: "count",
          area: "data",
        },
      ],
      store: messageDataStoreGenerator({ timeRange }),
    }
    pivotRef.current.instance.option("dataSource", dataSource)
    pivotRef.current.instance.bindChart(chartRef.current.instance)
    if (chartRef.current) chartRef.current.instance.refresh()
  }, [timeRange, refreshUuid])
  return (
    <>
      <Chart palette="Soft Pastel" ref={chartRef}>
        <CommonAxisSettings>
          <Grid opacity={0.3} />
        </CommonAxisSettings>
        <CommonSeriesSettings type="bar" />
        <LoadingIndicator enabled={true} />
        <Tooltip enabled={true} />
      </Chart>

      <PivotGrid
        allowExpandAll={true}
        allowFiltering={true}
        allowSorting={true}
        allowSortingBySummary={true}
        ref={pivotRef}
        showBorders={true}
        showColumnGrandTotals={false}
        showColumnTotals={false}
        showRowGrandTotals={false}
        showRowTotals={false}
      >
        <Export enabled={true} />
        <FieldChooser enabled={true} height={"70%"} allowSearch={true} />
        <FieldPanel
          showColumnFields={true}
          showRowFields={true}
          showDataFields={true}
          showFilterFields={true}
          visible={true}
        />
        <StateStoring
          enabled={true}
          storageKey="profile-messages-pivot"
          type="localStorage"
        />
      </PivotGrid>
    </>
  )
}

export { MessagePivot }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(MessagePivot)
