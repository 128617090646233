import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  Accordion,
  Button,
  Form,
  LoadPanel,
  ScrollView,
} from "devextreme-react"
import { Item as AccordionItem } from "devextreme-react/accordion"
import { ButtonItem, GroupItem, SimpleItem } from "devextreme-react/form"
import notify from "devextreme/ui/notify"

import { hasAccessTo } from "../../../authentication"
import { faEye, faEyeSlash } from "../../shared/icons"
import {
  getClientSettings,
  downloadConnector,
  saveClientSettings,
} from "../../operations/client"
import { FormW } from "../../shared/StyledComponents"
import { getAccordionState, setAccordionState } from "../../shared/utilities"
import downloadJs from "downloadjs"

const ClientSettings = () => {
  const stateKey = "profile-development-client-settings"
  const formRef = useRef(null)
  const formW = useRef(null)
  const [pentahoPasswordMode, setPentahoPasswordMode] = useState("password")
  const [sagePasswordMode, setSagePasswordMode] = useState("password")
  const [sqlPasswordMode, setSqlPasswordMode] = useState("password")
  const [localSqlPasswordMode, setLocalSqlPasswordMode] = useState("password")
  const [formData, setFormData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    const getInfo = async () => {
      try {
        setIsLoading(true)
        const formData = await getClientSettings()
        try {
          setFormData(formData)
        } catch (error) {}
      } catch (error) {
        notify(`getting client's settings failed ${error.message}.`, "error")
      } finally {
        setIsLoading(false)
      }
    }
    getInfo()
  }, [])

  const pentahoPasswordEditorOptions = {
    mode: pentahoPasswordMode,
    buttons: [
      {
        name: "password",
        location: "after",
        options: {
          icon: pentahoPasswordMode === "text" ? faEyeSlash : faEye,
          onClick: () =>
            setPentahoPasswordMode(prev =>
              prev === "text" ? "password" : "text"
            ),
        },
      },
    ],
  }
  const sagePasswordEditorOptions = {
    mode: sagePasswordMode,
    buttons: [
      {
        name: "password",
        location: "after",
        options: {
          icon: sagePasswordMode === "text" ? faEyeSlash : faEye,
          onClick: () =>
            setSagePasswordMode(prev =>
              prev === "text" ? "password" : "text"
            ),
        },
      },
    ],
  }
  const sqlPasswordEditorOptions = {
    mode: sqlPasswordMode,
    buttons: [
      {
        name: "password",
        location: "after",
        options: {
          icon: sqlPasswordMode === "text" ? faEyeSlash : faEye,
          onClick: () =>
            setSqlPasswordMode(prev => (prev === "text" ? "password" : "text")),
        },
      },
    ],
  }
  const localSqlPasswordEditorOptions = {
    mode: localSqlPasswordMode,
    buttons: [
      {
        name: "password",
        location: "after",
        options: {
          icon: localSqlPasswordMode === "text" ? faEyeSlash : faEye,
          onClick: () =>
            setLocalSqlPasswordMode(prev =>
              prev === "text" ? "password" : "text"
            ),
        },
      },
    ],
  }

  const saveClickHandler = useCallback(async () => {
    try {
      setIsLoading(true)
      const formData = formRef.current.instance.option("formData")
      await saveClientSettings(formData)
      notify("Client's Settings saved successfully.", "success")
    } catch (error) {
      notify(`Saving client's settings failed: ${error.message}.`, "error")
    } finally {
      setIsLoading(false)
    }
  }, [])

  const downloadConnectorClickHandler = useCallback(async () => {
    try {
      setIsDownloading(true)
      const file = await downloadConnector()
      downloadJs(file, "DataMapConnector", "application/zip")
    } catch (error) {
      notify(`Downloading connector failed: ${error.message}.`, "error")
    } finally {
      setIsDownloading(false)
    }
  }, [])
  const downloadPentahoClickHandler = useCallback(async () => {
    try {
      window.open(
        "https://versaweb.dl.sourceforge.net/project/pentaho/Pentaho%209.1/client-tools/pdi-ce-9.1.0.0-324.zip",
        "_blank"
      )
    } catch (error) {
      notify(`Downloading pentaho failed: ${error.message}.`, "error")
    }
  }, [])
  const downloadJavaClickHandler = useCallback(async () => {
    try {
      window.open("https://java.com/en/download/manual.jsp", "_blank")
    } catch (error) {
      notify(`Downloading java failed: ${error.message}.`, "error")
    }
  }, [])

  return (
    <ScrollView height="83vh">
      <Accordion
        collapsible={true}
        multiple={true}
        onInitialized={getAccordionState(stateKey)}
        onSelectionChanged={setAccordionState(stateKey)}
      >
        <AccordionItem
          title="Files"
          visible={hasAccessTo("client.connector.download")}
        >
          <Button
            disabled={isDownloading}
            icon="download"
            style={{ marginRight: "0.5rem" }}
            text={isDownloading ? "Downloading" : "Connector"}
            onClick={downloadConnectorClickHandler}
          />
          <Button
            icon="download"
            style={{ marginRight: "0.5rem" }}
            text="Pentaho"
            onClick={downloadPentahoClickHandler}
          />
          <Button
            icon="download"
            text="Java"
            onClick={downloadJavaClickHandler}
          />
        </AccordionItem>
        <AccordionItem
          title="Settings"
          visible={hasAccessTo("client.setting.r")}
        >
          <FormW ref={formW}>
            <Form ref={formRef} formData={formData} colCount={2}>
              <GroupItem caption="Client" colSpan={1}>
                <SimpleItem
                  dataField="clientCode"
                  editorOptions={{ readOnly: true }}
                />
                <SimpleItem dataField="name" />
                <SimpleItem
                  dataField="sageEdition"
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: ["sage_100", "sage_500", "sage_x3"],
                  }}
                />
                <SimpleItem dataField="ipAddress" />
                <SimpleItem dataField="website" />
              </GroupItem>
              <GroupItem caption="Connector" colSpan={1}>
                <SimpleItem
                  dataField="pentahoPassword"
                  editorOptions={pentahoPasswordEditorOptions}
                />
                <SimpleItem dataField="connectorHost" />
                <SimpleItem dataField="connectorPath" />
                <SimpleItem dataField="sagePath" />
                <SimpleItem dataField="sageUsername" />
                <SimpleItem
                  dataField="sagePassword"
                  editorOptions={sagePasswordEditorOptions}
                />
              </GroupItem>
              <GroupItem caption="SQL" colSpan={2}>
                <SimpleItem
                  dataField="sqlPassword"
                  editorOptions={sqlPasswordEditorOptions}
                />
                <SimpleItem dataField="localSqlHost" />
                <SimpleItem dataField="localSqlInstance" />
                <SimpleItem dataField="localSqlPort" editorType="dxNumberBox" />
                <SimpleItem dataField="localSqlDatabase" />
                <SimpleItem dataField="localSageDatabase" />
                <SimpleItem dataField="localTestSageDatabase" />
                <SimpleItem dataField="localSqlUsername" />
                <SimpleItem
                  dataField="localSqlPassword"
                  editorOptions={localSqlPasswordEditorOptions}
                />
              </GroupItem>
              <GroupItem colSpan={2}>
                <ButtonItem
                  visible={hasAccessTo("client.setting.u")}
                  horizontalAlignment="left"
                  buttonOptions={{
                    text: "Save",
                    icon: "save",
                    onClick: saveClickHandler,
                  }}
                />
              </GroupItem>
            </Form>
            <LoadPanel
              visible={isLoading}
              container={formW.current}
              position={{
                of: formW.current,
              }}
            />
          </FormW>
        </AccordionItem>
      </Accordion>
    </ScrollView>
  )
}

export default ClientSettings
