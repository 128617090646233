import React, { useCallback, useEffect, useRef } from "react"
import { DataGrid, Template, Button } from "devextreme-react"
import {
  ColumnChooser,
  ColumnFixing,
  StateStoring,
  FilterPanel,
  Grouping,
  SearchPanel,
  Pager,
  GroupPanel,
  HeaderFilter,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"

import { TabItemGridW } from "../../shared/StyledComponents"
import { listItemDatamapHistoryDataStoreGenerator } from "../../shared/ConcurStore"
import { convertUtcToLocal } from "../../shared/utilities"

const DataMapListItemHistory = ({ refreshUuid, listId, listItemId }) => {
  const historyGrid = useRef(null)

  useEffect(() => {
    historyGrid.current.instance.refresh(true)
  }, [refreshUuid])

  const refreshButtonClickHandler = useCallback(() => {
    historyGrid.current.instance.refresh(true)
  }, [])
  const customizeColumnsHandler = useCallback(columns => {
    const processDateIndex = columns.findIndex(column =>
      column.dataField === "processDate" ? true : false
    )
    if (processDateIndex !== -1) {
      columns[processDateIndex].dataType = "datetime"
      columns[processDateIndex].format = {
        formatter: convertUtcToLocal,
      }
    }
  }, [])

  return (
    <>
      <TabItemGridW>
        <DataGrid
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          columnResizingMode="widget"
          customizeColumns={customizeColumnsHandler}
          dataSource={
            listItemId !== null
              ? listItemDatamapHistoryDataStoreGenerator(listId, listItemId)
              : []
          }
          height="100%"
          hoverStateEnabled={true}
          ref={historyGrid}
          rowAlternationEnabled={true}
          showBorders={true}
          onToolbarPreparing={e => {
            e.toolbarOptions.items.unshift({
              location: "before",
              template: "refreshGrid",
            })
          }}
        >
          <ColumnChooser
            allowSearch={true}
            enabled={true}
            height="400"
            mode="select"
          />
          <ColumnFixing enabled={true} />
          <StateStoring
            enabled={true}
            storageKey="concur-lists-listItems-datamap-histories"
            type="localStorage"
          />
          <FilterPanel visible={true} />
          <FilterRow visible={true} />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling showScrollbar="always" />
          <SearchPanel visible={true} />
          <Pager
            allowedPageSizes={[10, 20, 50, 100]}
            showInfo={true}
            showNavigationButtons={true}
            showPageSizeSelector={true}
            visible={true}
          />
          <Template name="refreshGrid">
            <Button
              hint="Update grid"
              icon="refresh"
              onClick={refreshButtonClickHandler}
            />
          </Template>
        </DataGrid>
      </TabItemGridW>
    </>
  )
}

export { DataMapListItemHistory }
export default DataMapListItemHistory
