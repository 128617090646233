import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  FormItem,
  RequiredRule,
  Lookup,
  StateStoring,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  Pager,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"
import DataSource from "devextreme/data/data_source"
import { FaLock } from "react-icons/fa"

import { permissionDataStore, applicationDataStore } from "../shared/CoreStore"
import { hasAccessTo } from "../../authentication"
import { PageTitle } from "../shared/StyledComponents"
import SEO from "../../seo"

const permissionDataSource = new DataSource({ store: permissionDataStore })

const Permissions = () => {
  const permissionsGrid = useRef(null)
  const refreshPermissionGridClickHandler = () => {
    permissionsGrid.current.instance.refresh(true)
  }
  const addNewPermissionClickHandler = () => {
    permissionsGrid.current.instance.addRow()
  }

  return (
    <>
      <SEO title="Core Permissions" />
      <PageTitle icon={FaLock}>Permissions</PageTitle>
      <DataGrid
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={permissionDataSource}
        hoverStateEnabled={true}
        ref={permissionsGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("c.perm.r")}
        onEditorPreparing={function(e) {
          if (
            e.value &&
            e.parentType === "dataRow" &&
            e.dataField === "applicationId"
          ) {
            e.editorOptions.value = e.value.toString()
            e.editorOptions.onValueChanged = function(args) {
              e.setValue(parseInt(args.value))
            }
          }
        }}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "addNewPermission",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column
          dataField="applicationId"
          caption="Application"
          dataType="number"
        >
          <Lookup
            dataSource={{
              store: applicationDataStore,
              map: item => {
                return { ...item, id: item.id.toString() }
              },
            }}
            displayExpr="title"
            valueExpr="id"
          />
          <FormItem editorType="dxSelectBox" />
          <RequiredRule />
        </Column>
        <Column dataField="type" dataType="string" />
        <Column dataField="code" dataType="string" />
        <Column dataField="title" dataType="string">
          <RequiredRule trim={true} />
        </Column>
        <Column dataField="isActive" dataType="boolean" />
        <Editing
          allowDeleting={hasAccessTo("c.perm.d")}
          allowUpdating={hasAccessTo("c.perm.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
          visible={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          storageKey="core-permissions"
          type="localStorage"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshPermissionGridClickHandler} />
        </Template>
        <Template name="addNewPermission">
          <Button
            text="New Permission"
            visible={hasAccessTo("c.perm.c")}
            onClick={addNewPermissionClickHandler}
          />
        </Template>
      </DataGrid>
    </>
  )
}

export { Permissions }
export default Permissions
