import React, { useEffect, useState } from "react"
import { Button, Form, ScrollView } from "devextreme-react"
import { Item as FormItem } from "devextreme-react/form"
import notify from "devextreme/ui/notify"
import downloadJs from "downloadjs"

import {
  getMessageById,
  downloadMessageBodyBinaryById,
} from "../../operations/client"
import { riFileDownloadLine } from "../../shared/icons"

const MessageDetail = ({ messageId }) => {
  const [formData, setFormData] = useState({})
  useEffect(() => {
    const getMessage = async () => {
      try {
        const message = await getMessageById(messageId)
        setFormData(message)
      } catch (error) {}
    }
    if (messageId) getMessage()
    return () => {
      setFormData({})
    }
  }, [messageId])

  const textAreaFocusInHandler = itemId => () => {
    const textArea = document.getElementById(itemId)
    textArea.select()
    document.execCommand("copy")
    notify(`${itemId} copied to clipboard successfully.`, "success")
  }

  const downloadBodyBinaryClickHandler = async () => {
    try {
      const file = await downloadMessageBodyBinaryById(messageId)
      let fileExtension
      switch (file.fileType) {
        case "application/zip":
          fileExtension = ".zip"
          break
        case "text/csv":
          fileExtension = ".csv"
          break
        default:
          fileExtension = ""
      }
      downloadJs(
        atob(file.data),
        `message-${messageId}-body-binary${fileExtension}`,
        `${file.fileType}`
      )
    } catch (error) {
      notify(`Downloading body string failed: ${error}`, "error")
    }
  }
  return (
    <>
      <ScrollView>
        <Form formData={formData} colCount={2} readOnly={true}>
          <FormItem dataField="id" />
          <FormItem dataField="parentId" />
          <FormItem dataField="topicCode" />
          <FormItem dataField="consumerId" />
          <FormItem
            dataField="createDate"
            editorType="dxDateBox"
            editorOptions={{ displayFormat: "MM-dd-yyyy HH:mm:ss" }}
          />
          <FormItem
            dataField="fetchDate"
            editorType="dxDateBox"
            editorOptions={{ displayFormat: "MM-dd-yyyy HH:mm:ss" }}
          />
          <FormItem
            dataField="processDate"
            editorType="dxDateBox"
            editorOptions={{ displayFormat: "MM-dd-yyyy HH:mm:ss" }}
          />
          <FormItem dataField="status" />
          <FormItem dataField="userId" />
          <FormItem dataField="priority" />
          <FormItem dataField="creatorId" />
          <FormItem
            dataField="development"
            editorType="dxDateBox"
            editorOptions={{ displayFormat: "MM-dd-yyyy HH:mm:ss" }}
          />
          <FormItem dataField="enable" />
          <FormItem
            dataField="expiryDate"
            editorType="dxDateBox"
            editorOptions={{ displayFormat: "MM-dd-yyyy HH:mm:ss" }}
          />
          <FormItem dataField="jobTimeout" />
          <FormItem
            dataField="scheduleDate"
            editorType="dxDateBox"
            editorOptions={{ displayFormat: "MM-dd-yyyy HH:mm:ss" }}
          />
          <FormItem
            colSpan={2}
            dataField="header"
            editorType="dxTextArea"
            editorOptions={{
              onFocusIn: textAreaFocusInHandler("header"),
              autoResizeEnabled: true,
              inputAttr: { id: "header" },
            }}
          />
          <FormItem
            colSpan={2}
            dataField="bodyBinary"
            render={() => (
              <Button
                text="Download"
                icon={riFileDownloadLine}
                onClick={downloadBodyBinaryClickHandler}
              />
            )}
          />
          <FormItem
            colSpan={2}
            dataField="bodyString"
            editorType="dxTextArea"
            editorOptions={{
              onFocusIn: textAreaFocusInHandler("bodyString"),
              autoResizeEnabled: true,
              inputAttr: { id: "bodyString" },
            }}
          />
          <FormItem
            colSpan={2}
            dataField="response"
            editorType="dxTextArea"
            editorOptions={{
              onFocusIn: textAreaFocusInHandler("response"),
              autoResizeEnabled: true,
              inputAttr: { id: "response" },
            }}
          />
          <FormItem
            colSpan={2}
            dataField="errors"
            editorType="dxTextArea"
            editorOptions={{
              onFocusIn: textAreaFocusInHandler("errors"),
              autoResizeEnabled: true,
              inputAttr: { id: "errors" },
            }}
          />
          <FormItem
            colSpan={2}
            dataField="custom"
            editorType="dxTextArea"
            editorOptions={{
              onFocusIn: textAreaFocusInHandler("custom"),
              autoResizeEnabled: true,
              inputAttr: { id: "custom" },
            }}
          />
        </Form>
      </ScrollView>
    </>
  )
}
export default MessageDetail
