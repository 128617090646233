import React from "react"
import { DataGrid } from "devextreme-react"

import { TabItemGridW } from "../../shared/StyledComponents"

function SageVendors() {
  return (
    <TabItemGridW>
      <DataGrid></DataGrid>
    </TabItemGridW>
  )
}

export default SageVendors
