import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  FormItem,
  RequiredRule,
  Lookup,
  StateStoring,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  Pager,
  FilterRow,
  Scrolling,
  ColumnChooser,
} from "devextreme-react/data-grid"
import DataSource from "devextreme/data/data_source"
import { MdNotificationsActive } from "react-icons/md"

import {
  notificationDataStore,
  applicationDataStore,
  clientDataStore,
} from "../shared/CoreStore"
import { hasAccessTo } from "../../authentication"
import { PageTitle } from "../shared/StyledComponents"
import SEO from "../../seo"

const notificationDataSource = new DataSource({ store: notificationDataStore })

const Notifications = () => {
  const notificationsGrid = useRef(null)
  const refreshNotificationGridClickHandler = () => {
    notificationsGrid.current.instance.refresh(true)
  }

  return (
    <>
      <SEO title="Core Notifications" />
      <PageTitle icon={MdNotificationsActive}>Notifications</PageTitle>
      <DataGrid
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode="widget"
        dataSource={notificationDataSource}
        hoverStateEnabled={true}
        ref={notificationsGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("c.notification.r")}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column dataField="id" dataType="number" allowEditing={false} />
        <Column
          allowEditing={false}
          showInColumnChooser={false}
          visible={false}
        >
          <FormItem itemType="empty" />
        </Column>
        <Column
          caption="Clients"
          dataField="clientIds"
          showInColumnChooser={false}
          visible={false}
        >
          <Lookup
            dataSource={{
              store: clientDataStore,
            }}
            displayExpr="clientCode"
            valueExpr="id"
          />
          <FormItem
            editorType="dxTagBox"
            editorOptions={{
              searchEnabled: true,
              showSelectionControls: true,
            }}
          />
        </Column>
        <Column
          caption="Applications"
          dataField="applicationIds"
          showInColumnChooser={false}
          visible={false}
        >
          <Lookup
            dataSource={{
              store: applicationDataStore,
            }}
            displayExpr="title"
            valueExpr="id"
          />
          <FormItem
            editorType="dxTagBox"
            editorOptions={{
              searchEnabled: true,
              showSelectionControls: true,
            }}
          />
        </Column>
        <Column dataField="isActive" dataType="boolean" />
        <Column dataField="isClosable" dataType="boolean" />
        <Column dataField="hasShading" dataType="boolean" />
        <Column dataField="type" dataType="string">
          <Lookup dataSource={["information", "warning", "critical"]} />
          <RequiredRule />
        </Column>
        <Column dataField="title" dataType="string" />
        <Column dataField="message" dataType="string">
          <RequiredRule />
        </Column>
        <Column dataField="startDate" dataType="datetime" />
        <Column dataField="endDate" dataType="datetime" />
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <Editing
          allowAdding={hasAccessTo("c.notification.c")}
          allowDeleting={hasAccessTo("c.notification.d")}
          allowUpdating={hasAccessTo("c.notification.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
          visible={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          storageKey="core-notifications"
          type="localStorage"
        />
        <Template name="refreshGrid">
          <Button
            icon="refresh"
            onClick={refreshNotificationGridClickHandler}
          />
        </Template>
      </DataGrid>
    </>
  )
}

export { Notifications }
export default Notifications
