import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import ReactJsonView from "react-json-view"
import { isNumber, isArray } from "lodash"
import notify from "devextreme/ui/notify"

import { getGenomesByPlantId, saveGenome } from "../../operations/client"
import { Button, ScrollView, Slider, TextArea } from "devextreme-react"
import { convertUtcToLocal } from "../../shared/utilities"
import { hasAccessTo } from "../../../authentication"

const W = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const SliderW = styled.div`
  padding-bottom: 1rem;
`
const StepsW = styled.div`
  display: flex;
  justify-content: space-between;
`
const Step = styled.div`
  display: inline-block;
  font-size: 0.7rem;
  min-width: 100px;
  transform: ${props => (props.slant ? "rotate(320deg)" : "rotate(0deg)")};
  padding-top: ${props => (props.slant ? "1.5rem" : "0.5rem")};
  padding-bottom: ${props => (props.slant ? "1rem" : "0")};
`
const TextAreaW = styled.div`
  padding-top: 1rem;
`
const ButtonsW = styled.div`
  padding-top: 1rem;
`
const UserW = styled.div``
const User = styled.div`
  font-size: 0.7rem;
`

const ReactJson = ({ plantId, plantVersion }) => {
  const noteRef = useRef(null)

  const [genome, setGenome] = useState({})
  const [genomes, setGenomes] = useState([])
  const [user, setUser] = useState(null)
  const [note, setNote] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [version, setVersion] = useState(0)
  const [mutated, setMutated] = useState(false)

  useEffect(() => {
    const getGenome = async () => {
      try {
        const result = await getGenomesByPlantId(plantId)
        const { mutated, genomes } = result
        setGenomes(genomes)
        setMutated(false)
        if (mutated === true) {
          setGenome(genomes[genomes.length - 1]?.genome)
          setUser(genomes[genomes.length - 1]?.user)
          setNote(genomes[genomes.length - 1]?.note)
          setSelectedIndex(genomes.length - 1)
        } else {
          setGenome(genomes[0]?.genome)
        }
      } catch (error) {
        notify(`Getting genome failed: ${error.message}`, "error")
      }
    }

    if (isNumber(plantId)) getGenome()
  }, [plantId, version, plantVersion])

  const reactJsonViewAddHandler = e => {
    setMutated(true)
    const { existing_value, name, namespace } = e
    if (isArray(existing_value) && namespace.length === 0) {
      if (existing_value.length > 0) {
        const newValue = [...existing_value, existing_value[0]]
        setGenome({ ...genome, [name]: newValue })
      }
    }
    return false
  }
  const reactJsonViewEditHandler = ({ updated_src }) => {
    setMutated(true)
    setGenome(updated_src)
    return true
  }
  const sliderValueChangeHandler = value => {
    setSelectedIndex(value)
    setGenome(genomes[value]?.genome)
    setUser(genomes[value]?.user)
    setNote(genomes[value]?.note)
    setMutated(false)
  }
  const saveClickHandler = async () => {
    const data = { genome: genome, note: note }
    if (selectedIndex === 0 && mutated === false) {
      data.genome = null
      data.note = null
    }
    try {
      await saveGenome(plantId, data)
      setVersion(previousVersion => previousVersion + 1)
    } catch (error) {
      notify(`Saving genome failed: ${error.message}`, "error")
    }
  }
  const noteValueChangeHandler = value => {
    setNote(value)
  }
  const cancelClickHandler = () => {
    setVersion(previousVersion => previousVersion + 1)
  }
  return (
    <W>
      <SliderW>
        <Slider
          min={0}
          max={genomes.length - 1}
          visible={genomes.length > 1}
          showRange={false}
          onValueChange={sliderValueChangeHandler}
          value={selectedIndex}
        />
        <StepsW>
          {genomes.length >= 2 &&
            genomes.map((item, index, genomes) => (
              <Step index={index} slant={genomes.length > 7} key={index}>
                {item.timestamp ? convertUtcToLocal(item.timestamp) : "Default"}
              </Step>
            ))}
        </StepsW>
      </SliderW>
      <ScrollView width="100%">
        <ReactJsonView
          src={genome ?? {}}
          name={null}
          onEdit={reactJsonViewEditHandler}
          onAdd={
            hasAccessTo("shopify.genome.c")
              ? reactJsonViewAddHandler
              : undefined
          }
          indentWidth={8}
        />
      </ScrollView>
      <TextAreaW>
        <TextArea
          value={note}
          ref={noteRef}
          onValueChange={noteValueChangeHandler}
          placeholder="Note"
        />
        <UserW>{user && <User>Edited By: {user}</User>}</UserW>
      </TextAreaW>
      <ButtonsW>
        <Button
          text="Save"
          style={{ marginRight: "1rem" }}
          onClick={saveClickHandler}
        />
        <Button text="Cancel" onClick={cancelClickHandler} />
      </ButtonsW>
    </W>
  )
}

export default ReactJson
