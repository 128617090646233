import { ScrollView } from "devextreme-react"
import React, { useState } from "react"
import { useEffect } from "react"
import styled from "styled-components"

const W = styled.div`
  height: 100%;
`
const Title = styled.div`
  font-size: 1.2rem;
  padding: 1rem 1rem 0rem 1rem;
`
const LogsW = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
`
const Log = styled.div`
  width: 100%;
  padding: 1rem;
  box-shadow: 0 0 5px 0px rgb(207 207 207);
  :not(:last-child) {
    margin-bottom: 1rem;
  }
  display: flex;
  flex-direction: column;
`
const TypeAndTimestampW = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`
const Type = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  background-color: ${props =>
    props.type === "error" ? "rgb(209, 50, 31)" : "rgb(51, 122, 183)"};
  border-radius: 50%;
  margin-right: 1rem;
`
const Timestamp = styled.div``
const Text = styled.div``

const NodeInfo = ({ data }) => {
  const [logs, setLogs] = useState([])
  const [title, setTitle] = useState("")

  useEffect(() => {
    if (data === null) {
      return
    }
    const { logs, title } = data
    if (logs === null) {
      return
    }
    setLogs(logs)
    setTitle(title)
  }, [data])

  return (
    <W>
      <ScrollView height="100%">
        <Title>{title}</Title>
        <LogsW>
          {logs.map((log, index) => (
            <Log key={index}>
              <TypeAndTimestampW>
                <Type type={log.type}></Type>
                <Timestamp>
                  {`${new Date(log.timestamp).toLocaleDateString(
                    "en-US"
                  )} ${new Date(log.timestamp).toLocaleTimeString("en-US")}`}
                </Timestamp>
              </TypeAndTimestampW>
              <Text>{log.text}</Text>
            </Log>
          ))}
        </LogsW>
      </ScrollView>
    </W>
  )
}

export default NodeInfo
