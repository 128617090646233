import React from "react"
import { Button } from "devextreme-react"
import notify from "devextreme/ui/notify"

import { migrateClientDatabases } from "../../operations/core"

const Database = () => {
  const migrateClientDatabasesClickHandler = async () => {
    try {
      await migrateClientDatabases()
      notify("Client databases was migrated successfully.", "success")
    } catch (error) {
      notify(`Client databases migration failed: ${error.message}`, "error")
    }
  }
  return (
    <>
      <Button
        text="Migrate Clients"
        onClick={migrateClientDatabasesClickHandler}
      />
    </>
  )
}

export default Database
