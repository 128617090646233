import React, { useRef, useEffect, useCallback } from "react"
import { TreeList, Button, Template } from "devextreme-react"
import {
  Column,
  StateStoring,
  SearchPanel,
  ColumnChooser,
  ColumnFixing,
  FilterPanel,
  Pager,
  HeaderFilter,
  FilterRow,
  Scrolling,
  Button as GButton,
} from "devextreme-react/tree-list"
import { connect } from "react-redux"
import notify from "devextreme/ui/notify"
import { confirm } from "devextreme/ui/dialog"

import { listItemDataStoreGenerator } from "../../shared/ConcurStore"
import { deleteAListItem, updateListItems } from "../../operations/concur"
import { hasAccessTo } from "../../../authentication"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import { faCloudDownloadAlt } from "../../shared/icons"
import ItemScheduler from "../../shared/ItemScheduler"

const ConcurListItems = ({ listId, listInfo, refreshUuid }) => {
  const listItemsGrid = useRef(null)
  const listItemDataStore = listItemDataStoreGenerator(listId)

  useEffect(() => {
    listItemsGrid.current.instance.refresh(true)
  }, [refreshUuid])

  const refreshListItemGridClickHandler = useCallback(() => {
    listItemsGrid.current.instance.refresh(true)
  }, [])

  const updateListItemClickHandler = useCallback(async () => {
    try {
      await updateListItems(listId)
      notify("Updating list items was queued successfully.", "success")
    } catch (error) {
      notify(`updating list items failed: ${error.message}`, "error")
    }
  }, [listId])

  const toolbarPreparingHandler = useCallback(e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "updateListItems",
    })
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }, [])

  const nodesInitializedHandler = useCallback(
    ({ component }) => component.pageIndex(0),
    []
  )

  const deleteFromConcurButtonClickHandler = useCallback(
    async ({ row }) => {
      const result = await confirm("Are you sure?", "Confirm delete")
      try {
        if (result) {
          await deleteAListItem(listId, row.data.id)
          notify("Deleting list item was queued successfully.", "success")
        }
      } catch (error) {
        notify(`Queuing delete list item failed: ${error.message}`, "error")
      }
    },
    [listId]
  )

  return (
    <>
      <TreeList
        allowColumnReordering={true}
        allowColumnResizing={true}
        cacheEnabled={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={listItemDataStore}
        hoverStateEnabled={true}
        keyExpr="concur_Id"
        parentIdExpr="concur_ParentId"
        dataStructure="plain"
        ref={listItemsGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        height={`67vh`}
        visible={hasAccessTo("concur.listitem.r")}
        virtualModeEnabled={true}
        onToolbarPreparing={toolbarPreparingHandler}
        onNodesInitialized={nodesInitializedHandler}
      >
        <Column dataField="id" datatype="number" defaultVisible={false} />
        <Column dataField="concur_Name" datatype="string" />
        <Column dataField="concur_Id" datatype="string" />
        <Column dataField="concur_ParentId" datatype="string" />
        <Column dataField="concur_Level1Code" datatype="string" />
        <Column dataField="concur_Level2Code" datatype="string" />
        <Column dataField="concur_Level3Code" datatype="string" />
        <Column dataField="concur_Level4Code" datatype="string" />
        <Column dataField="concur_Level5Code" datatype="string" />
        <Column dataField="concur_Level6Code" datatype="string" />
        <Column dataField="concur_Level7Code" datatype="string" />
        <Column dataField="concur_Level8Code" datatype="string" />
        <Column dataField="concur_Level9Code" datatype="string" />
        <Column dataField="concur_Level10Code" datatype="string" />
        <Column
          dataField="isDeleted"
          datatype="boolean"
          defaultVisible={false}
        />
        <Column
          type="buttons"
          showInColumnChooser={false}
          visible={hasAccessTo("concur.listitem.cd")}
        >
          <GButton
            name="deleteAllListItems"
            hint="Delete all list items from concur."
            icon="trash"
            visible={hasAccessTo("concur.listitem.cd")}
            onClick={deleteFromConcurButtonClickHandler}
          />
        </Column>
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel width={250} visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="concur-lists-listItems-concur"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshListItemGridClickHandler} />
        </Template>
        <Template name="updateListItems">
          <Button
            elementAttr={{ id: "refreshListItems" }}
            hint="Refetch list items from Concur"
            icon={faCloudDownloadAlt}
            text="Update ListItems"
            visible={hasAccessTo("concur.listitem.refresh")}
            onClick={updateListItemClickHandler}
          />
        </Template>
      </TreeList>
      <ItemScheduler
        items={[
          {
            body: null,
            method: "POST",
            target: `#refreshListItems`,
            title: `Refresh list items (ConcurId=${listInfo?.concur_Id}, ConcurName=${listInfo?.concur_Name})`,
            url: `/api/v1/concur/lists/${listId}/list-items/update`,
          },
        ]}
      />
    </>
  )
}

export { ConcurListItems }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(ConcurListItems)
