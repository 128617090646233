import React, { useRef, useState } from "react"
import { DataGrid, Button, Template, Popup } from "devextreme-react"
import {
  Editing,
  Column,
  Lookup,
  StateStoring,
  ColumnChooser,
  ColumnFixing,
  Pager,
  FilterPanel,
  Grouping,
  HeaderFilter,
  SearchPanel,
  FilterRow,
  Scrolling,
  RequiredRule,
  Button as GButton,
} from "devextreme-react/data-grid"
import dayjs from "dayjs"
import dayjsUtcPlugin from "dayjs/plugin/utc"

import {
  scheduleDataStore,
  resourceApiDataStore,
  resourcePriorityDataStore,
  resourceTimeoutDataStore,
} from "../../shared/ClientStores"
import { hasAccessTo } from "../../../authentication"
import { faHistory } from "../../shared/icons"
import ScheduleHistory from "./ScheduleHistory"

const Schedules = () => {
  const schedulesGrid = useRef(null)
  const [scheduleHistoryId, setScheduleHistoryId] = useState(null)

  const refreshMessageGridClickHandler = () => {
    schedulesGrid.current.instance.refresh(true)
  }

  const startDateSetCellValueHandler = function(rowData, value) {
    rowData.endDate = dayjs
      .extend(dayjsUtcPlugin)
      .utc(value)
      .add(15, "minute")
      .format()
    this.defaultSetCellValue(rowData, value)
  }

  const historyButtonClickHandler = ({ row }) => {
    setScheduleHistoryId(row.key)
  }

  const popupHidingHandler = () => {
    setScheduleHistoryId(null)
  }

  return (
    <>
      <Popup
        title="Schedule History"
        visible={scheduleHistoryId !== null}
        onHiding={popupHidingHandler}
      >
        <ScheduleHistory scheduleId={scheduleHistoryId} />
      </Popup>
      <DataGrid
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={scheduleDataStore}
        hoverStateEnabled={true}
        ref={schedulesGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("schedule.r")}
        onEditorPreparing={function(e) {
          if (
            e.value &&
            e.parentType === "dataRow" &&
            (e.dataField === "resourceApiId" ||
              e.dataField === "resourcePriorityId" ||
              e.dataField === "resourceTimeoutId")
          ) {
            e.editorOptions.value = e.value.toString()
            e.editorOptions.onValueChanged = function(args) {
              e.setValue(parseInt(args.value))
            }
          }
        }}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column
          allowEditing={false}
          defaultVisible={false}
          dataField="id"
          dataType="number"
        />
        <Column type="buttons">
          <GButton name="delete" />
          <GButton name="edit" />
          <GButton
            cssClass="dx-link customColumnButton"
            hint="Show schedule history"
            icon={faHistory}
            name="history"
            onClick={historyButtonClickHandler}
            visible={hasAccessTo("schedule.history")}
          />
        </Column>
        <Column dataField="text" dataType="string">
          <RequiredRule />
        </Column>
        <Column dataField="isActive" dataType="boolean" />
        <Column dataField="allDay" dataType="boolean" />
        <Column dataField="description" dataType="string" />
        <Column
          dataField="startDate"
          dataType="datetime"
          setCellValue={startDateSetCellValueHandler}
        >
          <RequiredRule />
        </Column>
        <Column dataField="endDate" dataType="datetime" allowEditing={false} />
        <Column dataField="recurrenceRule" dataType="string" />
        <Column
          defaultVisible={false}
          dataField="recurrenceException"
          dataType="string"
        />
        <Column caption="Api" dataField="resourceApiId" dataType="number">
          <Lookup
            dataSource={{
              store: resourceApiDataStore,
              map: item => {
                return { ...item, id: item.id.toString() }
              },
            }}
            valueExpr="id"
            displayExpr="title"
          />
          <RequiredRule />
        </Column>
        <Column
          caption="Priority"
          dataField="resourcePriorityId"
          dataType="number"
        >
          <Lookup
            dataSource={{
              store: resourcePriorityDataStore,
              map: item => {
                return { ...item, id: item.id.toString() }
              },
            }}
            valueExpr="id"
            displayExpr="title"
          />
          <RequiredRule />
        </Column>
        <Column
          caption="Timeout"
          dataField="resourceTimeoutId"
          dataType="number"
        >
          <Lookup
            dataSource={{
              store: resourceTimeoutDataStore,
              map: item => {
                return { ...item, id: item.id.toString() }
              },
            }}
            valueExpr="id"
            displayExpr="title"
          />
          <RequiredRule />
        </Column>

        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={hasAccessTo("schedule.c")}
          allowDeleting={hasAccessTo("schedule.d")}
          allowUpdating={hasAccessTo("schedule.u")}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <HeaderFilter visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="profile-schedule-schedules"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshMessageGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { Schedules }
export default Schedules
