import React from "react"
import styled from "styled-components"
import { Router } from "@reach/router"

import Applications from "./Applications"
import Clients from "./clients"
import Concur from "./concur"
import Monitors from "./monitors"
import Notifications from "./Notifications"
import Permissions from "./Permissions"
import Queries from "./Queries"
import Sage from "./sage"
import StandardRoles from "./StandardRoles"
import Shopify from "./shopify"
import Topics from "./Topics"
import Users from "./Users"
import EmailNotification from "./EmailNotification"

const HomeW = styled.div`
  height: 100%;
`
const Home = () => {
  return (
    <HomeW>
      <Router>
        <Applications path="applications" />
        <Clients path="clients/**" />
        <Concur path="concur/**" />
        <EmailNotification path="email-notification" />
        <Monitors path="monitors/**" />
        <Notifications path="notifications" />
        <Permissions path="permissions" />
        <Queries path="queries" />
        <Sage path="sage/**" />
        <Shopify path="shopify/**" />
        <StandardRoles path="standard-roles" />
        <Topics path="topics" />
        <Users path="users" />
      </Router>
    </HomeW>
  )
}

export { Home }
export default Home
