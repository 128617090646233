import React from "react"
import { TabPanel } from "devextreme-react"
import { Item as TabPanelItem } from "devextreme-react/tab-panel"
import { FaCalendarAlt } from "react-icons/fa"

import { PageTitle } from "../../shared/StyledComponents"
import { hasAccessTo } from "../../../authentication"
import { getFirstAccessibleIndex } from "../../shared/utilities"
import Calendar from "./Calendar"
import Schedules from "./Schedules"
import ResourceApis from "./ResourceApis"
import ResourcePriorities from "./ResourcePriorities"
import ResourceTimeouts from "./ResourceTimeouts"
import ScheduleLogs from "./ScheduleLogs"
import SEO from "../../../seo"
import {
  aiFillApi,
  aiFillSchedule,
  faCalendar,
  faClock,
  grOrderedList,
  grCatalog,
} from "../../shared/icons"

const Home = ({ location }) => {
  return (
    <>
      <SEO title="Schedules" />
      <PageTitle icon={FaCalendarAlt}>Schedule</PageTitle>
      <TabPanel
        defaultSelectedIndex={getFirstAccessibleIndex([
          "schedule.r",
          "schedule.r",
          "resource.api.r",
          "resource.priority.r",
          "resource.timeout.r",
          "schedule.log.r",
        ])}
      >
        <TabPanelItem
          icon={faCalendar}
          title="Calendar"
          visible={hasAccessTo("schedule.r")}
        >
          <Calendar location={location} />
        </TabPanelItem>
        <TabPanelItem
          icon={aiFillSchedule}
          title="Schedules"
          visible={hasAccessTo("schedule.r")}
        >
          <Schedules />
        </TabPanelItem>
        <TabPanelItem
          icon={aiFillApi}
          title="Apis"
          visible={hasAccessTo("resource.api.r")}
        >
          <ResourceApis />
        </TabPanelItem>
        <TabPanelItem
          icon={grOrderedList}
          title="Priorities"
          visible={hasAccessTo("resource.priority.r")}
        >
          <ResourcePriorities />
        </TabPanelItem>
        <TabPanelItem
          icon={faClock}
          title="Timeouts"
          visible={hasAccessTo("resource.timeout.r")}
        >
          <ResourceTimeouts />
        </TabPanelItem>
        <TabPanelItem
          icon={grCatalog}
          title="Logs"
          visible={hasAccessTo("schedule.log.r")}
        >
          <ScheduleLogs />
        </TabPanelItem>
      </TabPanel>
    </>
  )
}

export { Home }
export default Home
