import React from "react"
import { Accordion } from "devextreme-react"
import { Item as AccordionItem } from "devextreme-react/accordion"
import styled from "styled-components"
import { FaFileInvoice } from "react-icons/fa"

import DataMap from "./DataMap"
import { FaChartBar, FaTable } from "../../../fa-icons"
import VendorChart from "./PurchaseOrderChart"
import { hasAccessTo } from "../../../authentication"
import { PageTitle } from "../../shared/StyledComponents"
import SEO from "../../../seo"
import { getAccordionState, setAccordionState } from "../../shared/utilities"

const SettingsW = styled.div`
  margin: 1rem 0;
`

const PurchaseOrders = () => {
  const stateKey = "concur-purchase-orders"
  return (
    <>
      <SEO title="Concur Purchase Orders" />
      <PageTitle icon={FaFileInvoice}>Purchase Orders</PageTitle>
      <SettingsW>
        <Accordion
          collapsible={true}
          multiple={true}
          onInitialized={getAccordionState(stateKey)}
          onSelectionChanged={setAccordionState(stateKey)}
        >
          <AccordionItem
            icon={FaChartBar}
            title=" Chart"
            visible={hasAccessTo("concur.po.chart")}
          >
            <VendorChart />
          </AccordionItem>
          <AccordionItem
            icon={FaTable}
            title=" Grid"
            visible={hasAccessTo("concur.po.r")}
          >
            <DataMap />
          </AccordionItem>
        </Accordion>
      </SettingsW>
    </>
  )
}

export default PurchaseOrders
