import React, { useRef } from "react"
import { DataGrid, Button, Template, Form } from "devextreme-react"
import {
  Editing,
  Column,
  StateStoring,
  ColumnChooser,
  ColumnFixing,
  Pager,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  FilterRow,
  Scrolling,
  MasterDetail,
  Lookup,
  Format,
} from "devextreme-react/data-grid"
import { Item as FormItem } from "devextreme-react/form"

import { clientDataStore, clientLogDataStore } from "../../shared/CoreStore"
import { hasAccessTo } from "../../../authentication"
import { clientLogScreenshotUrlGenerator } from "../../operations/core"
import ImageFetcher from "../../shared/ImageFetcher"
import { convertUtcToLocal } from "../../shared/utilities"

const Clients = () => {
  const clientsGrid = useRef(null)

  const refreshClientGridClickHandler = () => {
    clientsGrid.current.instance.refresh(true)
  }

  const screenshotCellRenderHandler = ({ value }) => {
    return (
      <ImageFetcher
        height="4rem"
        prependData={null}
        width="6rem"
        url={clientLogScreenshotUrlGenerator(value)}
      />
    )
  }

  const toolbarPreparingHandler = e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }
  return (
    <>
      <DataGrid
        columnResizingMode="widget"
        dataSource={clientLogDataStore}
        hoverStateEnabled={true}
        ref={clientsGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        onToolbarPreparing={toolbarPreparingHandler}
        onContentReady={function(e) {
          e.component.updateDimensions()
        }}
      >
        <Column
          allowEditing={false}
          dataField="id"
          dataType="number"
          visible={false}
        />
        <Column dataField="clientId" dataType="string" caption="Client Code">
          <Lookup
            dataSource={{
              store: clientDataStore,
            }}
            valueExpr="id"
            displayExpr="clientCode"
          />
        </Column>
        <Column dataField="username" dataType="string" />
        <Column dataField="location" dataType="string" />
        <Column dataField="referrer" dataType="string" />
        <Column dataField="message" dataType="string" />
        <Column dataField="platform" dataType="string" />
        <Column dataField="stack" dataType="string" />
        <Column dataField="type" dataType="string" />
        <Column dataField="userAgent" dataType="string" />
        <Column dataField="timestamp" dataType="datetime">
          <Format formatter={convertUtcToLocal} />
        </Column>
        <Column
          allowEditing={false}
          allowFiltering={false}
          allowGrouping={false}
          formItem={{ visible: false }}
          dataField="id"
          dataType="number"
          alignment="left"
          caption="Screenshot"
          width="7rem"
          cellRender={screenshotCellRenderHandler}
        />
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          allowDeleting={hasAccessTo("c.monitor.client.d")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <MasterDetail component={ClientLogoDetail} enabled={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="core-monitors-clients"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshClientGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

const ClientLogoDetail = ({ data }) => {
  return (
    <>
      <div
        style={{
          height: "30rem",
          display: "flex",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <ImageFetcher
          prependData={null}
          height="30rem"
          url={clientLogScreenshotUrlGenerator(data.key)}
        />
      </div>
      <Form formData={data.data} readOnly={true}>
        <FormItem dataField="id" />
        <FormItem dataField="clientId" />
        <FormItem dataField="username" />
        <FormItem dataField="location" />
        <FormItem dataField="referrer" />
        <FormItem dataField="message" />
        <FormItem dataField="platform" />
        <FormItem dataField="stack" editorType="dxTextArea" />
        <FormItem dataField="type" />
        <FormItem dataField="userAgent" />
      </Form>
    </>
  )
}

export { Clients }
export default Clients
