import React, { useEffect, useRef, useState } from "react"
import { Form, LoadPanel, TabPanel } from "devextreme-react"
import { Item as TabPanelItem } from "devextreme-react/tab-panel"
import ConcurListItems from "./ConcurListItems"
import SEO from "../../../seo"
import { FormW, TabItemW } from "../../shared/StyledComponents"
import { Item as FormItem } from "devextreme-react/form"
import { listDataStore } from "../../shared/ConcurStore"
import notify from "devextreme/ui/notify"
import { hasAccessTo } from "../../../authentication"
import { DataMapListItems } from "./DatamapListItems"

const ListItems = ({ listId }) => {
  const formW = useRef(null)
  const listForm = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [listInfo, setListInfo] = useState(null)

  useEffect(() => {
    const getListInfo = async () => {
      setIsLoading(true)
      try {
        let response = await listDataStore.byKey(listId)
        listForm.current.instance.option("formData", response.data)
        setListInfo(response.data)
      } catch (error) {
        notify(`fetching list information failed: ${error.message}`, "error")
      } finally {
        setIsLoading(false)
      }
    }
    getListInfo()
  }, [listId])

  return (
    <>
      <SEO title="Concur List Items" />
      <FormW ref={formW}>
        <Form
          readOnly={true}
          ref={listForm}
          visible={hasAccessTo("concur.list.r")}
        >
          <FormItem itemType="group" caption="List information" colCount={3}>
            <FormItem dataField="concur_Name" />
            <FormItem dataField="concur_Id" />
            <FormItem dataField="concur_ConnectorId" />
            <FormItem dataField="concur_SearchCriteriaCode" />
            <FormItem dataField="concur_ExternalThreshold" />
            <FormItem
              dataField="concur_DisplayCodeFirst"
              editorType="dxCheckBox"
            />
            <FormItem dataField="concur_IsVendorList" editorType="dxCheckBox" />
            <FormItem dataField="isDeleted" editorType="dxCheckBox" />
            <FormItem dataField="isActive" editorType="dxCheckBox" />
          </FormItem>
        </Form>
        <LoadPanel
          visible={isLoading}
          container={formW.current}
          position={{
            of: formW.current,
          }}
        />
      </FormW>
      <TabPanel>
        <TabPanelItem title="Concur">
          <TabItemW>
            <ConcurListItems listId={listId} listInfo={listInfo} />
          </TabItemW>
        </TabPanelItem>
        <TabPanelItem title="Datamap">
          <TabItemW>
            <DataMapListItems listId={listId} listInfo={listInfo} />
          </TabItemW>
        </TabPanelItem>
      </TabPanel>
    </>
  )
}

export default ListItems
