import React, { useRef, useEffect } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Column,
  Button as GButton,
  Editing,
  StateStoring,
  SearchPanel,
  GroupPanel,
  RequiredRule,
  ColumnChooser,
  ColumnFixing,
  Grouping,
  Pager,
  HeaderFilter,
  FilterRow,
  FilterPanel,
  Scrolling,
} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"
import { connect } from "react-redux"
import { FaFileAlt } from "react-icons/fa"

import { batchDataStore } from "../shared/ConcurStore"
import { updateBatches } from "../operations/concur"
import { PageTitle } from "../shared/StyledComponents"
import { hasAccessTo } from "../../authentication"
import { dashboardSelectors } from "../../../state/ducks/dashboard"
import ItemScheduler from "../shared/ItemScheduler"
import SEO from "../../seo"
import { faCloudDownloadAlt } from "../shared/icons"

const Batches = ({ navigate, refreshUuid }) => {
  const batchesGrid = useRef(null)

  useEffect(() => {
    batchesGrid.current.instance.refresh(true)
  }, [refreshUuid])

  const refreshExtractGridClickHandler = () => {
    batchesGrid.current.instance.refresh(true)
  }

  const updateExtractClickHandler = async () => {
    try {
      await updateBatches()
      notify("Updating batch list was queued successfully.", "success")
    } catch (e) {
      notify(e.message, "error")
    }
  }

  const gridRowClickHandler = e => {
    navigate(`${e.data.id}/jobs`)
  }
  const jobButtonClickHandler = e => {
    navigate(`${e.row.data.id}/jobs`)
  }

  return (
    <>
      <SEO title="Concur Batches" />
      <PageTitle icon={FaFileAlt}>Batches</PageTitle>
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={batchDataStore}
        hoverStateEnabled={true}
        ref={batchesGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        onRowClick={gridRowClickHandler}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "updateBatches",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
        visible={hasAccessTo("concur.batch.r")}
      >
        <Column dataField="label">
          <RequiredRule />
        </Column>
        <Column
          dataField="concur_BatchId"
          allowEditing={false}
          defaultVisible={false}
        />
        <Column
          allowEditing={false}
          dataField="concur_BatchName"
          dataType="string"
        />
        <Column
          allowEditing={false}
          dataField="concur_BatchTotal"
          dataType="number"
        />
        <Column
          allowEditing={false}
          dataField="concur_Currency"
          dataType="string"
        />
        <Column
          allowEditing={false}
          dataField="concur_Count"
          dataType="number"
        />
        <Column
          allowEditing={false}
          dataField="concur_Type"
          dataType="string"
        />
        <Column
          allowEditing={false}
          dataField="concur_PaymentMethod"
          dataType="string"
        />
        <Column
          allowEditing={false}
          dataField="concur_UrlId"
          dataType="string"
        />
        <Column dataField="isActive" dataType="boolean" filterValue={true} />
        <Column type="buttons" showInColumnChooser={false}>
          <GButton name="delete" />
          <GButton name="edit" />
          <GButton
            name="jobs"
            icon="arrowright"
            onClick={jobButtonClickHandler}
          />
        </Column>
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          mode="form"
          useIcons={true}
          allowDeleting={hasAccessTo("concur.batch.d")}
          allowUpdating={hasAccessTo("concur.batch.u")}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel width={250} visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="concur-batches"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshExtractGridClickHandler} />
        </Template>
        <Template name="updateBatches">
          <Button
            elementAttr={{ id: "updateBatches" }}
            text="Update Batches"
            icon={faCloudDownloadAlt}
            visible={hasAccessTo("concur.batch.refresh")}
            onClick={updateExtractClickHandler}
          />
        </Template>
      </DataGrid>
      <ItemScheduler
        items={[
          {
            body: null,
            method: "POST",
            target: "#updateBatches",
            title: "update batches",
            url: "/api/v1/concur/batches/update",
          },
        ]}
      />
    </>
  )
}

export { Batches }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(Batches)
