import React from "react"
import Columns from "./Columns"
import { TabPanel } from "devextreme-react"
import { Item as TabPanelItem } from "devextreme-react/tab-panel"
import { FaDatabase } from "react-icons/fa"

import { PageTitle } from "../../shared/StyledComponents"
import KeyValues from "./KeyValues"
import { hasAccessTo } from "../../../authentication"
import { getFirstAccessibleIndex } from "../../shared/utilities"
import SEO from "../../../seo"
import { faColumns, faKey } from "../../shared/icons"

const Home = () => {
  return (
    <>
      <SEO title="Sage" />
      <PageTitle icon={FaDatabase}>Sage</PageTitle>
      <TabPanel
        defaultSelectedIndex={getFirstAccessibleIndex([
          "sage.column.r",
          "sage.key-value.r",
        ])}
      >
        <TabPanelItem
          icon={faColumns}
          title="Columns"
          visible={hasAccessTo("sage.column.r")}
        >
          <Columns />
        </TabPanelItem>
        <TabPanelItem
          icon={faKey}
          title="Key Values"
          visible={hasAccessTo("sage.key-value.r")}
        >
          <KeyValues />
        </TabPanelItem>
      </TabPanel>
    </>
  )
}

export { Home }
export default Home
