import React, { useEffect } from "react"
import { Router } from "@reach/router"
import styled from "styled-components"
import { createGlobalStyle } from "styled-components"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import { Drawer, ScrollView } from "devextreme-react"

import "devextreme/dist/css/dx.common.css"
import "devextreme/dist/css/dx.light.css"

import "@fortawesome/fontawesome-free/css/all.css"

import SEO from "../seo"
import Header from "./Header"
import Sidebar from "./Sidebar"
import { refreshToken, getCurrentUser } from "../authentication"
import { dashboardSelectors } from "../../state/ducks/dashboard"
import useResponsiveWidth from "./shared/hooks/useResponsiveWidth"
import Notifier from "./shared/Notifier"
import ErrorLogger from "./shared/ErrorLogger"
import useUserActivityLogger from "./shared/hooks/useUserActivityLogger"

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body {
    color: rgb(8, 31, 58);
    background-color: rgb(251, 251, 251);
    font-family: "Montserrat-Regular", sans-serif;
    margin:0
  }
  img {
    max-width: 100%;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  .customColumnButton{
    font-size:14px !important;
  }
  .dx-scrollable-scrollbar.dx-widget.dx-scrollbar-horizontal.dx-scrollbar-hoverable{
    height:15px;
    .dx-scrollable-scroll{
    height:15px;
    }
  }
`

const ContentW = styled.div`
  margin: 0.5rem;
  background-color: ${props => (props.lg ? "transparent" : "white")};
`
const Main = styled.main`
  margin-top: 0.5rem;
`
const SidebarWrapper = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  height: 100%;
  margin: 0.5rem 0 0.5rem 0.5rem;
`

const Layout = ({ children, drawerOpen }) => {
  useUserActivityLogger()
  const { lg } = useResponsiveWidth()

  useEffect(() => {
    const checkToken = async () => {
      try {
        const { client_code } = getCurrentUser()
        await refreshToken(client_code)
      } catch (error) {
        navigate("/logout")
      }
    }
    checkToken()
  }, [])
  const sideBarGenerator = () => {
    return (
      <SidebarWrapper>
        <ScrollView height="100vh" useNative={false} showScrollbar="onHover">
          <Router basepath="/dashboard">
            <Sidebar path="/*" />
          </Router>
        </ScrollView>
      </SidebarWrapper>
    )
  }
  return (
    <>
      <GlobalStyle />
      <SEO title="Dashboard" />
      <Notifier enabled={true} />
      <ErrorLogger enabled={true} />
      <Drawer
        opened={drawerOpen}
        openedStateMode={lg ? "shrink" : "shrink"}
        position="left"
        revealMode={lg ? "expand" : "expand"}
        maxSize={300}
        render={sideBarGenerator}
        height="100vh"
      >
        <ContentW lg={lg}>
          <ScrollView height="100vh" useNative={false} showScrollbar="onHover">
            <Header />
            <Main>{children}</Main>
          </ScrollView>
        </ContentW>
      </Drawer>
    </>
  )
}

export { Layout }

const mapStateToProps = state => ({
  drawerOpen: dashboardSelectors.selectUiDrawerOpen(state),
})
export default connect(mapStateToProps)(Layout)
