import CustomStore from "devextreme/data/custom_store"
import Query from "devextreme/data/query"
import { axios } from "./utilities"

const applicationDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/core/applications")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/applications/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/core/applications/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/core/applications`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/core/applications/${key}`, {
      method: "DELETE",
    })
  },
})
const applicationClientsDataStoreGenerator = applicationId =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(`/api/v1/core/applications/${applicationId}/clients`)
    },
  })
const clientApplicationsDataStoreGenerator = clientId =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(`/api/v1/core/clients/${clientId}/applications`)
    },
  })
const clientDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/core/clients")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/clients/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/core/clients/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/core/clients`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/core/clients/${key}`, {
      method: "DELETE",
    })
  },
})
const clientLogDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/core/client-logs")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/client-logs/${key}`)
    return response.data
  },
  insert: async value => {
    return await axios(`/api/v1/core/client-logs`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/core/client-logs/${key}`, {
      method: "DELETE",
    })
  },
})
const clientUsersDataStoreGenerator = clientId =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(`/api/v1/core/clients/${clientId}/users`)
    },
  })
const concurUserDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/core/concur/users")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/concur/users/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/core/concur/users/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/core/concur/users`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/core/concur/users/${key}`, {
      method: "DELETE",
    })
  },
})
const dataModelDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/core/data-models")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/data-models/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/core/data-models/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/core/data-models`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/core/data-models/${key}`, {
      method: "DELETE",
    })
  },
})
const emailNotificationDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/core/email-notifications")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/email-notifications/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/core/email-notifications/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/core/email-notifications`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/core/email-notifications/${key}`, {
      method: "DELETE",
    })
  },
})
const notificationDataStore = new CustomStore({
  key: "id",
  load: async loadOptions => {
    const response = await axios("/api/v1/core/notifications")
    if (loadOptions.searchValue)
      return Query(response.data)
        .filter([
          loadOptions.searchExpr,
          loadOptions.searchOperation,
          loadOptions.searchValue,
        ])
        .toArray()
    return response.data
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/notifications/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/core/notifications/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/core/notifications`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/core/notifications/${key}`, {
      method: "DELETE",
    })
  },
})
const permissionDataStore = new CustomStore({
  key: "id",
  load: async loadOptions => {
    const response = await axios("/api/v1/core/permissions")
    if (loadOptions.searchValue)
      return Query(response.data)
        .filter([
          loadOptions.searchExpr,
          loadOptions.searchOperation,
          loadOptions.searchValue,
        ])
        .toArray()
    return response.data
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/permissions/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/core/permissions/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/core/permissions`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/core/permissions/${key}`, {
      method: "DELETE",
    })
  },
})
const queryTemplateDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/core/queries")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/queries/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/core/queries/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/core/queries`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/core/queries/${key}`, {
      method: "DELETE",
    })
  },
})
const sageColumnDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/core/sage/columns")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/sage/columns/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/core/sage/columns/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/core/sage/columns`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/core/sage/columns/${key}`, {
      method: "DELETE",
    })
  },
})
const shopifyUserDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/core/shopify/users")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/shopify/users/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/core/shopify/users/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/core/shopify/users`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/core/shopify/users/${key}`, {
      method: "DELETE",
    })
  },
})
const standardRoleDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/core/standard-roles")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/standard-roles/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/core/standard-roles/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/core/standard-roles`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/core/standard-roles/${key}`, {
      method: "DELETE",
    })
  },
})
const topicDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/core/topics")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/topics/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/core/topics/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/core/topics`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/core/topics/${key}`, {
      method: "DELETE",
    })
  },
})
const userClientsDataStoreGenerator = userId =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(`/api/v1/core/users/${userId}/clients`)
    },
  })
const userDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/core/users")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/core/users/${key}`)
    return response.data
  },
})

export {
  applicationDataStore,
  applicationClientsDataStoreGenerator,
  clientDataStore,
  clientApplicationsDataStoreGenerator,
  clientLogDataStore,
  clientUsersDataStoreGenerator,
  concurUserDataStore,
  dataModelDataStore,
  emailNotificationDataStore,
  notificationDataStore,
  permissionDataStore,
  queryTemplateDataStore,
  sageColumnDataStore,
  shopifyUserDataStore,
  standardRoleDataStore,
  topicDataStore,
  userClientsDataStoreGenerator,
  userDataStore,
}
