import React from "react"
import { Chart } from "devextreme-react"
import {
  ArgumentAxis,
  Label,
  Series,
  Legend,
  ValueAxis,
  Grid,
  CommonSeriesSettings,
  SeriesTemplate,
  LoadingIndicator,
  Size,
  Aggregation,
  ZoomAndPan,
  Tooltip,
  VisualRange,
} from "devextreme-react/chart"
import { connect } from "react-redux"

import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import {
  invoiceChartDataStoreGenerator,
  allInvoiceChartDataStoreGenerator,
} from "../../shared/ConcurStore"
import { calculateVisualRange } from "../../shared/utilities"

const InvoiceChart = ({ invoiceId, timeRange, refreshUuid }) => {
  const ConcurInvoiceChartDataStore = invoiceId
    ? invoiceChartDataStoreGenerator(invoiceId, { timeRange })
    : allInvoiceChartDataStoreGenerator({ timeRange })

  return (
    <Chart dataSource={ConcurInvoiceChartDataStore} palette="Soft Pastel">
      <ArgumentAxis
        aggregationInterval={timeRange.aggregationInterval}
        argumentType="datetime"
        maxValueMargin={0.1}
        minValueMargin={0.1}
        valueMarginsEnabled={true}
      >
        <Label wordWrap="none" overlappingBehavior="rotate" />
        <VisualRange defaultStartValue={calculateVisualRange(timeRange)} />
      </ArgumentAxis>
      <CommonSeriesSettings
        argumentField="timestamp"
        hoverMode="allArgumentPoints"
        type="bar"
        valueField="id"
      >
        <Aggregation enabled={true} method="count" />
      </CommonSeriesSettings>
      <Legend
        horizontalAlignment="center"
        verticalAlignment="bottom"
        visible={true}
      />
      <LoadingIndicator enabled={true} />
      <Series />
      <SeriesTemplate nameField="status" />
      <Size width="100%" />
      <Tooltip enabled={true} />
      <ValueAxis
        allowDecimals={false}
        maxValueMargin={0.1}
        minValueMargin={0.1}
        showZero={true}
        type="logarithmic"
        valueMarginsEnabled={true}
        valueType="numeric"
      >
        <Grid opacity="0.3" />
      </ValueAxis>
      <ZoomAndPan argumentAxis="both" />
    </Chart>
  )
}

export { InvoiceChart }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(InvoiceChart)
