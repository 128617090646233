import React, { useEffect, useState } from "react"
import styled from "styled-components"
import notify from "devextreme/ui/notify"
import { connect } from "react-redux"

import { getDashboard } from "../../operations/client"
import Scalar from "./Scalar"
import Timeline from "./Timeline"
import SEO from "../../../seo"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"

const W = styled.div``
const ItemsW = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
const Dashboard = ({ timeRange, refreshUuid }) => {
  const [dashboard, setDashboard] = useState([])

  useEffect(() => {
    const getDashboardInfo = async () => {
      try {
        const result = await getDashboard("shopify", timeRange)
        setDashboard(result?.dashboard)
      } catch (error) {
        notify(`Getting dashboard items failed: ${error.message}`, "error")
      }
    }
    getDashboardInfo()
  }, [refreshUuid, timeRange])

  const getItems = () => {
    const items = dashboard.map(item => {
      if (item.type === "scalar") {
        return <Scalar itemData={item} key={item.id} />
      }
      if (item.type === "pieChart") {
        return <Scalar itemData={item} key={item.id} />
      }
      if (item.type === "timeline") {
        return <Timeline data={item} key={item.id} />
      }
      return null
    })
    return items.filter(item => item !== null)
  }

  return (
    <>
      <SEO title="Shopify Dashboard" />
      <W>
        <ItemsW>{getItems()}</ItemsW>
      </W>
    </>
  )
}

export { Dashboard }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(Dashboard)
