import React from "react"
import { TabPanel } from "devextreme-react"
import { Item as TabPanelItem } from "devextreme-react/tab-panel"
import { FaCogs } from "react-icons/fa"

import { hasAccessTo } from "../../../authentication"
import { PageTitle, TabItemW } from "../../shared/StyledComponents"
import { getFirstAccessibleIndex } from "../../shared/utilities"
import Database from "./Database"
import Users from "./Users"
import SEO from "../../../seo"
import { faDatabase, faUsersCog } from "../../shared/icons"

const Home = () => {
  return (
    <>
      <SEO title="Core Concur" />
      <PageTitle icon={FaCogs}>Concur</PageTitle>
      <TabPanel
        defaultSelectedIndex={getFirstAccessibleIndex([
          "c.concur.user.r",
          "c.concur.migrate",
        ])}
      >
        <TabPanelItem
          icon={faUsersCog}
          title="Users"
          visible={hasAccessTo("c.concur.user.r")}
        >
          <Users />
        </TabPanelItem>
        <TabPanelItem
          icon={faDatabase}
          title="Database"
          visible={hasAccessTo("c.concur.migrate")}
        >
          <TabItemW>
            <Database />
          </TabItemW>
        </TabPanelItem>
      </TabPanel>
    </>
  )
}

export { Home }
export default Home
