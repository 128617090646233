import React, { memo, useCallback, useRef } from "react"
import {
  Button,
  DataGrid,
  Template,
  TextBox,
  Validator,
} from "devextreme-react"
import {
  Column,
  ColumnChooser,
  ColumnFixing,
  FilterPanel,
  FilterRow,
  Grouping,
  HeaderFilter,
  LoadPanel,
  Paging,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"
import { NumericRule, StringLengthRule } from "devextreme-react/validator"
import { isEmpty } from "lodash"

import { FaFileImport } from "../../../fa-icons"
import { sleep } from "../utilities"
import { getCurrentUser } from "../../../authentication"
import MetaCell from "./MetaCell"

const MetaImport = memo(
  ({ columns, data, keyValues, onImport, onCheckImportStatus }) => {
    const gridRef = useRef(null)
    const batchNumberValidatorRef = useRef(null)
    const importButtonRef = useRef(null)

    const batchNumber = useRef("")

    const getSageVersion = () => {
      const { sage_edition } = getCurrentUser()
      return sage_edition
    }

    const checkingImportStateHandler = useCallback(async () => {
      await sleep(5000)
      const items = gridRef.current.instance.getDataSource().items()
      try {
        await Promise.all(
          items.map(async item => {
            const response = (await onCheckImportStatus(item.id)).data
            const transformResponse = {
              ...response,
              importStatus:
                response.importStatus === null
                  ? "PENDING"
                  : response.importStatus,
              importTimestamp: response.importTimestamp,
            }
            gridRef.current.instance
              .getDataSource()
              .store()
              .push([
                {
                  type: "update",
                  key: item.id,
                  data: transformResponse,
                },
              ])
          })
        )
      } catch (error) {
        notify(`Checking expense status failed: ${error.message}`, "error")
      }

      const remainingItems = gridRef.current.instance
        .getDataSource()
        .items()
        .filter(item => item.importStatus === "PENDING")
      if (!isEmpty(remainingItems)) {
        await checkingImportStateHandler()
      }
    }, [onCheckImportStatus])

    const importClickHandler = useCallback(async () => {
      const allItems = gridRef.current.instance.getDataSource().items()
      const allItemsWithPendingStatus = allItems.map(item => ({
        ...item,
        importStatus: "PENDING",
        importTimestamp: null,
        importErrors: "",
      }))
      allItemsWithPendingStatus.forEach(item => {
        gridRef.current.instance
          .getDataSource()
          .store()
          .push([{ type: "update", data: item, key: item.id }])
      })
      const selectedItemIds = allItems.map(item => item.id)
      try {
        gridRef.current.instance.beginCustomLoading()
        await onImport({
          batchNumber: batchNumber.current === "" ? null : batchNumber.current,
          ids: selectedItemIds,
        })
        await checkingImportStateHandler()
        gridRef.current && gridRef.current.instance.endCustomLoading()
      } catch (error) {
        gridRef.current && gridRef.current.instance.endCustomLoading()
      } finally {
      }
    }, [onImport, checkingImportStateHandler])

    const toolbarPreparingHandler = useCallback(
      e => {
        if (data !== null) {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "importIntoSage",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "batchNumber",
          })
        }
      },
      [data]
    )
    return (
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={data}
        height="100%"
        highlightChanges={true}
        hoverStateEnabled={true}
        keyExpr="id"
        ref={gridRef}
        repaintChangesOnly={true}
        rowAlternationEnabled={true}
        showBorders={true}
        onToolbarPreparing={toolbarPreparingHandler}
      >
        {columns.map(item => {
          const hasKeyValue = Object.keys(keyValues).some(
            key => keyValues && keyValues[key] && keyValues[key][item.dataField]
          )
          if (hasKeyValue)
            return (
              <Column
                key={item.dataField}
                {...item}
                cellRender={data => (
                  <MetaCell {...data} keyValues={keyValues} />
                )}
              />
            )
          return <Column key={item.dataField} {...item} />
        })}
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <FilterPanel visible={true} />
        <FilterRow visible={false} />
        <Grouping contextMenuEnabled={true} />
        <HeaderFilter visible={true} />
        <LoadPanel shading={true} enabled={true} />
        <Paging enabled={false} />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <Template name="batchNumber">
          <TextBox
            hint="Batch number of selected items."
            placeholder="Batch Number..."
            valueChangeEvent="keyup"
            visible={getSageVersion() !== "sage_x3"}
            onValueChanged={({ value }) => {
              batchNumber.current = value
              let validationResult = batchNumberValidatorRef.current.instance.validate()
              if (validationResult.isValid) {
                importButtonRef.current.instance.option("disabled", false)
              } else {
                importButtonRef.current.instance.option("disabled", true)
              }
            }}
          >
            {getSageVersion() === "sage_500" && (
              <Validator name="Batch Number" ref={batchNumberValidatorRef}>
                <NumericRule ignoreEmptyValue={true} />
                <StringLengthRule max={7} />
              </Validator>
            )}
            {getSageVersion() === "sage_100" && (
              <Validator name="Batch Number" ref={batchNumberValidatorRef}>
                <StringLengthRule
                  max={5}
                  min={5}
                  message="The length of Batch Number must be 5 character"
                  ignoreEmptyValue={true}
                />
              </Validator>
            )}
          </TextBox>
        </Template>
        <Template name="importIntoSage">
          <Button
            hint="Import selected items into Sage"
            icon={FaFileImport}
            ref={importButtonRef}
            text="Import into Sage"
            onClick={importClickHandler}
          />
        </Template>
      </DataGrid>
    )
  }
)

export default MetaImport
