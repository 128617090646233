import {
  faBell,
  faSignOutAlt,
  faUser,
  faChartBar,
  faCogs,
  faTable,
  faCloudDownloadAlt,
  faHome,
  faFileInvoiceDollar,
  faFileAlt,
  faStore,
  faFileImport,
  faCloudUploadAlt,
  faHouseUser,
  faRocket,
  faEnvelope,
  faUserTag,
  faUsersCog,
  faDatabase,
  faUserFriends,
  faShieldAlt,
  faVials,
} from "@fortawesome/free-solid-svg-icons"
import { icon } from "@fortawesome/fontawesome-svg-core"

export const FaBell = icon(faBell).html[0]
export const FaSignOutAlt = icon(faSignOutAlt).html[0]
export const FaUser = icon(faUser).html[0]
export const FaChartBar = icon(faChartBar).html[0]
export const FaCogs = icon(faCogs).html[0]
export const FaTable = icon(faTable).html[0]
export const FaCloudDownloadAlt = icon(faCloudDownloadAlt).html[0]
export const FaCloudUploadAlt = icon(faCloudUploadAlt).html[0]
export const FaHome = icon(faHome).html[0]
export const FaFileInvoiceDollar = icon(faFileInvoiceDollar).html[0]
export const FaFileAlt = icon(faFileAlt).html[0]
export const FaStore = icon(faStore).html[0]
export const FaFileImport = icon(faFileImport).html[0]
export const FaHouseUser = icon(faHouseUser).html[0]
export const FaRocket = icon(faRocket).html[0]
export const FaEnvelop = icon(faEnvelope).html[0]
export const FaUserTag = icon(faUserTag).html[0]
export const FaUsersCog = icon(faUsersCog).html[0]
export const FaDatabase = icon(faDatabase).html[0]
export const FaUserFriends = icon(faUserFriends).html[0]
export const FaShieldAlt = icon(faShieldAlt).html[0]
export const FaVials = icon(faVials).html[0]
