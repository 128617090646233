import React from "react"
import { TabPanel } from "devextreme-react"

import { Item } from "devextreme-react/tab-panel"
import Templates from "./Templates"
import Saved from "./Saved"
import { TabItemW } from "../../../shared/StyledComponents"
import { hasAccessTo } from "../../../../authentication"

const Home = () => {
  return (
    <TabPanel>
      <Item title="Templates" visible={hasAccessTo("email-notification.r")}>
        <TabItemW>
          <Templates />
        </TabItemW>
      </Item>
      <Item title="Saved" visible={hasAccessTo("email-notification.r")}>
        <TabItemW>
          <Saved />
        </TabItemW>
      </Item>
    </TabPanel>
  )
}

export default Home
