import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  RequiredRule,
  StateStoring,
  Button as GridButton,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"

import { TabItemGridW, LockButton } from "../../shared/StyledComponents"
import { paymentDataStore } from "../../shared/ConcurStore"
import { hasAccessTo } from "../../../authentication"
import { paymentLocker } from "../../operations/concur"
import { faPlus } from "../../shared/icons"

const Settings = () => {
  const paymentGrid = useRef(null)

  const addNewPaymentClickHandler = () => {
    paymentGrid.current.instance.addRow()
  }
  const refreshPaymentGridClickHandler = () => {
    paymentGrid.current.instance.refresh(true)
  }

  const lockButtonRenderHandler = ({ data }) => (
    <LockButton onClick={lockClickHandler(data.id)} lockId={data.lockId} />
  )
  const lockClickHandler = id => async () => {
    try {
      await paymentLocker(id)
      paymentGrid.current.instance.refresh(false)
    } catch (error) {
      notify(`Changing lock status failed: ${error.message}`, "error")
    }
  }
  return (
    <TabItemGridW>
      <DataGrid
        dataSource={paymentDataStore}
        columnAutoWidth={true}
        columnResizingMode="widget"
        hoverStateEnabled={true}
        ref={paymentGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("concur.payment.r")}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "addNewPayment",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column dataField="id" dataType="number" allowEditing={false} />
        <Column dataField="title" dataType="string">
          <RequiredRule trim={true} />
        </Column>
        <Column dataField="isActive" dataType="boolean" />
        <Column type="buttons">
          <GridButton name="edit" />
          <GridButton name="delete" />
          <GridButton
            render={lockButtonRenderHandler}
            visible={hasAccessTo("concur.payment.l")}
          />
        </Column>
        <FilterRow visible={true} />
        <Editing
          mode="form"
          useIcons={true}
          allowDeleting={hasAccessTo("concur.payment.d")}
          allowUpdating={hasAccessTo("concur.payment.u")}
        />
        <Scrolling showScrollbar="always" />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="concur-settings-payments"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshPaymentGridClickHandler} />
        </Template>
        <Template name="addNewPayment">
          <Button
            icon={faPlus}
            text="Add Payment Module"
            onClick={addNewPaymentClickHandler}
            visible={hasAccessTo("concur.payment.c")}
          />
        </Template>
      </DataGrid>
    </TabItemGridW>
  )
}

export default Settings
