import { TabPanel } from "devextreme-react"
import { Item as TabPanelItem } from "devextreme-react/tab-panel"
import React from "react"
import { FiMonitor } from "react-icons/fi"

import { hasAccessTo } from "../../../authentication"
import { PageTitle, TabItemW } from "../../shared/StyledComponents"
import { getFirstAccessibleIndex } from "../../shared/utilities"
import ClientLogs from "./ClientLogs"
import SEO from "../../../seo"
import { grCatalog } from "../../shared/icons"

const Home = () => {
  return (
    <>
      <SEO title="Monitoring" />
      <PageTitle icon={FiMonitor}>Monitoring</PageTitle>
      <TabPanel
        defaultSelectedIndex={getFirstAccessibleIndex(["c.monitor.client.r"])}
      >
        <TabPanelItem
          icon={grCatalog}
          title="Client Logs"
          visible={hasAccessTo("c.monitor.client.r")}
        >
          <TabItemW>
            <ClientLogs />
          </TabItemW>
        </TabPanelItem>
      </TabPanel>
    </>
  )
}

export { Home }
export default Home
