import React, { useRef, useCallback, useState } from "react"
import { DataGrid, Button, Template, FileUploader } from "devextreme-react"
import {
  Editing,
  Column,
  RequiredRule,
  StateStoring,
  FilterRow,
  Pager,
  SearchPanel,
  ColumnChooser,
  Scrolling,
  Button as GridButton,
} from "devextreme-react/data-grid"
import DataSource from "devextreme/data/data_source"
import { FaRocket } from "react-icons/fa"

import {
  applicationDataStore,
  applicationClientsDataStoreGenerator,
} from "../shared/CoreStore"
import {
  getBearerToken,
  hasAccessTo,
  hasAccessToAny,
} from "../../authentication"
import { PageTitle } from "../shared/StyledComponents"
import {
  applicationLogoUploadUrlGenerator,
  applicationLogoUrlGenerator,
} from "../operations/core"
import ImageFetcher from "../shared/ImageFetcher"
import SEO from "../../seo"
import PopupDataGrid from "../shared/PopupDataGrid"

const applicationDataSource = new DataSource({ store: applicationDataStore })

const Applications = () => {
  const applicationGrid = useRef(null)
  const [popupVisibility, setPopupVisibility] = useState(false)
  const [popupDataStore, setPopupDataStore] = useState(null)

  const refreshApplicationGridClickHandler = useCallback(() => {
    applicationGrid.current.instance.refresh(true)
  }, [])

  const LogoCellRenderHandler = useCallback(({ value }) => {
    return (
      <ImageFetcher
        height="5rem"
        url={applicationLogoUrlGenerator(value)}
        width="5rem"
      />
    )
  }, [])

  const websiteCellRenderHandler = useCallback(
    cellInfo => (
      <a
        href={cellInfo.displayValue}
        rel="noreferrer"
        target="_blank"
        title={`go to ${cellInfo.displayValue}`}
      >
        {cellInfo.displayValue}
      </a>
    ),
    []
  )

  const toolbarPreparingHandler = useCallback(e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }, [])

  const showClientsClickHandler = useCallback(({ row }) => {
    setPopupDataStore(applicationClientsDataStoreGenerator(row.key))
    setPopupVisibility(true)
  }, [])

  const clientsPopupHidingHandler = useCallback(() => {
    setPopupVisibility(false)
  }, [])

  return (
    <>
      <SEO title="Core Applications" />
      <PageTitle icon={FaRocket}>Applications</PageTitle>
      {hasAccessTo("c.app.r") && (
        <DataGrid
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnAutoWidth={true}
          columnResizingMode="widget"
          dataSource={applicationDataSource}
          hoverStateEnabled={true}
          ref={applicationGrid}
          rowAlternationEnabled={true}
          showBorders={true}
          onToolbarPreparing={toolbarPreparingHandler}
        >
          <Column
            alignment="left"
            allowEditing={false}
            allowFiltering={false}
            allowGrouping={false}
            caption="Logo"
            cellRender={LogoCellRenderHandler}
            dataField="id"
            dataType="number"
            formItem={{ visible: false }}
            width="6rem"
          />
          <Column dataField="id" dataType="number" allowEditing={false} />
          <Column dataField="title" dataType="string">
            <RequiredRule trim={true} />
          </Column>
          <Column dataField="isActive" dataType="boolean" />
          <Column
            dataField="website"
            dataType="string"
            cellRender={websiteCellRenderHandler}
          />
          <Column
            caption="Logo"
            dataField="id"
            editCellRender={LogoUploader}
            showInColumnChooser={false}
            visible={false}
          />
          <Column
            type="buttons"
            visible={hasAccessToAny(["c.app.d", "c.app.u", "c.client.r"])}
          >
            <GridButton name="edit" />
            <GridButton name="delete" />
            <GridButton
              hint="Show clients"
              icon="user"
              visible={hasAccessTo("c.client.r")}
              onClick={showClientsClickHandler}
            />
          </Column>
          <ColumnChooser
            allowSearch={true}
            enabled={true}
            mode="select"
            height="75vh"
          />
          <Editing
            allowAdding={hasAccessTo("c.app.c")}
            allowDeleting={hasAccessTo("c.app.d")}
            allowUpdating={hasAccessTo("c.app.u")}
            mode="form"
            useIcons={true}
          />
          <FilterRow visible={true} />
          <Pager
            allowedPageSizes={[5, 10, 20, 50, 100]}
            showInfo={true}
            showNavigationButtons={true}
            showPageSizeSelector={true}
            visible={true}
          />
          <Scrolling showScrollbar="always" />
          <SearchPanel visible={true} />
          <StateStoring
            enabled={true}
            storageKey="core-applications"
            type="localStorage"
          />
          <Template name="refreshGrid">
            <Button
              icon="refresh"
              onClick={refreshApplicationGridClickHandler}
            />
          </Template>
        </DataGrid>
      )}
      <PopupDataGrid
        dataSource={popupDataStore}
        title="Clients"
        visible={popupVisibility}
        onHiding={clientsPopupHidingHandler}
      />
    </>
  )
}

const LogoUploader = ({ value }) => {
  return (
    <FileUploader
      accept="image/png"
      allowedFileExtensions={[".png"]}
      maxFileSize={204800}
      uploadUrl={applicationLogoUploadUrlGenerator(value)}
      uploadHeaders={{ Authorization: getBearerToken() }}
    />
  )
}

export { Applications }
export default Applications
