import { axios } from "../shared/utilities"

const updateBatches = async () => {
  return await axios("/api/v1/concur/batches/update", { method: "POST" })
}

const closeBatches = async batchId => {
  return await axios(`/api/v1/concur/batches/${batchId}/close`, {
    method: "POST",
  })
}

const downloadBatchJobFile = async (batchId, jobId) => {
  const response = await axios.get(
    `/api/v1/concur/batches/${batchId}/jobs/${jobId}/file`
  )
  return response.data
}
const downloadExpenseJobFile = async expenseId => {
  const response = await axios.get(`/api/v1/concur/expenses/files/${expenseId}`)
  return response.data
}
const downloadInvoiceJobFile = async invoiceId => {
  const response = await axios.get(`/api/v1/concur/invoices/files/${invoiceId}`)
  return response.data
}
const downloadPaymentJobFile = async paymentId => {
  const response = await axios.get(`/api/v1/concur/payments/files/${paymentId}`)
  return response.data
}
const downloadExpenseImage = async expenseId => {
  const response = await axios(`/api/v1/concur/expenses/images/${expenseId}`, {
    method: "POST",
  })
  return response.data
}
const downloadAllInvoiceImages = async invoiceId => {
  const response = await axios(
    `/api/v1/concur/invoices/${invoiceId}/images/download-all`,
    {
      method: "POST",
    }
  )
  return response.data
}
const downloadAllExpenseImages = async expenseId => {
  const response = await axios(
    `/api/v1/concur/expenses/${expenseId}/images/download-all`,
    {
      method: "POST",
    }
  )
  return response.data
}
const downloadInvoiceImage = async invoiceId => {
  const response = await axios(`/api/v1/concur/invoices/images/${invoiceId}`, {
    method: "POST",
  })
  return response.data
}
const reprocessExpenseJob = async (expenseId, rowId) => {
  return await axios(
    `/api/v1/concur/expenses/${expenseId}/grid-data/${rowId}/reprocess-job`,
    {
      method: "POST",
    }
  )
}
const reprocessInvoiceJob = async (invoiceId, rowId) => {
  return await axios(
    `/api/v1/concur/invoices/${invoiceId}/grid-data/${rowId}/reprocess-job`,
    {
      method: "POST",
    }
  )
}
const reprocessPaymentJob = async (paymentId, rowId) => {
  return await axios(
    `/api/v1/concur/payments/${paymentId}/grid-data/${rowId}/reprocess-job`,
    {
      method: "POST",
    }
  )
}
const downloadPaymentImage = async paymentId => {
  const response = await axios(`/api/v1/concur/payments/images/${paymentId}`, {
    method: "POST",
  })
  return response.data
}
const archiveExpenses = async (expenseId, rowIds) => {
  return await axios(`/api/v1/concur/expenses/${expenseId}/grid-data/archive`, {
    method: "POST",
    data: rowIds,
  })
}
const deleteExpenses = async (expenseId, rowIds) => {
  return await axios(`/api/v1/concur/expenses/${expenseId}/grid-data/delete`, {
    method: "POST",
    data: rowIds,
  })
}
const archiveInvoices = async (invoiceId, rowIds) => {
  return await axios(`/api/v1/concur/invoices/${invoiceId}/grid-data/archive`, {
    method: "POST",
    data: rowIds,
  })
}
const deleteInvoices = async (invoiceId, rowIds) => {
  return await axios(`/api/v1/concur/invoices/${invoiceId}/grid-data/delete`, {
    method: "POST",
    data: rowIds,
  })
}
const archivePayments = async (paymentId, rowIds) => {
  return await axios(`/api/v1/concur/payments/${paymentId}/grid-data/archive`, {
    method: "POST",
    data: rowIds,
  })
}
const deletePayments = async (paymentId, rowIds) => {
  return await axios(`/api/v1/concur/payments/${paymentId}/grid-data/delete`, {
    method: "POST",
    data: rowIds,
  })
}
const updateBatchJobs = async batch => {
  const response = await axios(`/api/v1/concur/batches/${batch}/update-jobs`, {
    method: "POST",
  })
  return response.data
}
const fetchBatchJob = async (batchId, jobId) => {
  return await axios(`/api/v1/concur/batches/${batchId}/jobs/${jobId}/fetch`, {
    method: "POST",
  })
}

const fetchBatchJobs = async batchId => {
  return await axios(`/api/v1/concur/batches/${batchId}/jobs/fetch`, {
    method: "POST",
  })
}
const processBatchJob = async (batchId, jobId) => {
  return await axios(
    `/api/v1/concur/batches/${batchId}/jobs/${jobId}/process`,
    {
      method: "POST",
    }
  )
}
const processBatchJobs = async batchId => {
  return await axios(`/api/v1/concur/batches/${batchId}/jobs/process`, {
    method: "POST",
  })
}

const updateExtracts = async () => {
  return await axios("/api/v1/concur/extracts/update", { method: "POST" })
}

const closeExtracts = async extractId => {
  return await axios(`/api/v1/concur/extracts/${extractId}/close`, {
    method: "POST",
  })
}

const downloadExtractJobFile = async (extractId, jobId) => {
  const response = await axios.get(
    `/api/v1/concur/extracts/${extractId}/jobs/${jobId}/file`
  )
  return response.data
}

const updateExtractJobs = async extractId => {
  const response = await axios(
    `/api/v1/concur/extracts/${extractId}/update-jobs`,
    {
      method: "POST",
    }
  )
  return response.data
}

const autoProcessExtractJobs = async extractId => {
  return await axios(
    `/api/v1/concur/extracts/${extractId}/jobs/schedule-auto-process`,
    {
      method: "POST",
    }
  )
}
const autoProcessExtractJobsStatus = async (extractId, referenceId) => {
  return await axios(
    `/api/v1/concur/extracts/${extractId}/jobs/schedule-auto-process/${referenceId}/status`,
    {
      method: "GET",
    }
  )
}
const fetchExtractJob = async (extractId, jobId) => {
  return await axios(
    `/api/v1/concur/extracts/${extractId}/jobs/${jobId}/fetch`,
    {
      method: "POST",
    }
  )
}
const fetchExtractJobs = async extractId => {
  return await axios(`/api/v1/concur/extracts/${extractId}/jobs/fetch`, {
    method: "POST",
  })
}
const processExtractJob = async (extractId, jobId) => {
  return await axios(
    `/api/v1/concur/extracts/${extractId}/jobs/${jobId}/process`,
    {
      method: "POST",
    }
  )
}
const processExtractJobs = async extractId => {
  return await axios(`/api/v1/concur/extracts/${extractId}/jobs/process`, {
    method: "POST",
  })
}

const fetchVendorsFromConcur = async payload => {
  return await axios("/api/v1/concur/vendors/fetch-concur-vendors", {
    method: "POST",
    data: payload,
  })
}

const sendVendorsToConcur = async () => {
  return await axios("/api/v1/concur/vendors/send-vendors-to-concur", {
    method: "POST",
  })
}

const resetVendorHash = async payload => {
  return await axios("/api/v1/concur/vendors/reset-hash", {
    method: "POST",
    data: payload,
  })
}

const importAllExpensesIntoSage = async expenseId => {
  return await axios(
    `/api/v1/concur/expenses/${expenseId}/grid-data/import-all-into-sage`,
    {
      method: "POST",
    }
  )
}
const importSelectedExpenseIntoSageByIds = async (
  expenseId,
  selectedExpenses
) => {
  return await axios(
    `/api/v1/concur/expenses/${expenseId}/grid-data/import-into-sage`,
    {
      method: "POST",
      data: selectedExpenses,
    }
  )
}
const importAllInvoicesIntoSage = async invoiceId => {
  return await axios(
    `/api/v1/concur/invoices/${invoiceId}/grid-data/import-all-into-sage`,
    {
      method: "POST",
    }
  )
}
const importSelectedInvoiceIntoSageByIds = async (
  invoiceId,
  selectedInvoices
) => {
  return await axios(
    `/api/v1/concur/invoices/${invoiceId}/grid-data/import-into-sage`,
    {
      method: "POST",
      data: selectedInvoices,
    }
  )
}

const importAllPaymentsIntoSage = async paymentId => {
  return await axios(
    `/api/v1/concur/payments/${paymentId}/grid-data/import-all-into-sage`,
    {
      method: "POST",
    }
  )
}
const importSelectedPaymentIntoSageByIds = async (
  paymentId,
  selectedPayments
) => {
  return await axios(
    `/api/v1/concur/payments/${paymentId}/grid-data/import-into-sage`,
    {
      method: "POST",
      data: selectedPayments,
    }
  )
}
const migrateConcurDatabase = async () => {
  return await axios(`/api/v1/concur/database/migrate`, {
    method: "POST",
  })
}

const invoiceLocker = async id => {
  return await axios(`/api/v1/concur/invoices/${id}/lock`, {
    method: "POST",
  })
}
const expenseLocker = async id => {
  return await axios(`/api/v1/concur/expenses/${id}/lock`, {
    method: "POST",
  })
}
const paymentLocker = async id => {
  return await axios(`/api/v1/concur/payments/${id}/lock`, {
    method: "POST",
  })
}
const getToken = async id => {
  return await axios(`/api/v1/concur/tokens/${id}/generate`, {
    method: "POST",
  })
}

const getPurchaseOrderStatus = async Id => {
  const response = await axios(
    `/api/v1/concur/purchase-orders/${Id}/concur-status`,
    { method: "POST" }
  )
  return response.data
}
const sendPurchaseOrdersToConcur = async () => {
  return await axios("/api/v1/concur/purchase-orders/send-to-concur", {
    method: "POST",
  })
}
const resetPurchaseOrderHash = async payload => {
  return await axios("/api/v1/concur/purchase-orders/reset-hash", {
    method: "POST",
    data: payload,
  })
}

const sendSagePaymentsToConcur = async () => {
  return await axios("/api/v1/concur/sage-payments/send-to-concur", {
    method: "POST",
  })
}
const resetSagePaymentHash = async payload => {
  return await axios("/api/v1/concur/sage-payments/reset-hash", {
    method: "POST",
    data: payload,
  })
}

const updateLists = async () => {
  return await axios("/api/v1/concur/lists/update", { method: "POST" })
}

const updateListItems = async listId =>
  await axios(`/api/v1/concur/lists/${listId}/list-items/update`, {
    method: "POST",
  })

const sendListItemsToConcur = async id => {
  return await axios(`/api/v1/concur/lists/${id}/send-to-concur`, {
    method: "POST",
  })
}

const deleteAllListItems = async listId => {
  return await axios(`/api/v1/concur/lists/${listId}/delete`, {
    method: "POST",
  })
}

const deleteAListItem = async (listId, listItemId) => {
  return await axios(
    `/api/v1/concur/lists/${listId}/list-items/${listItemId}/delete`,
    {
      method: "POST",
    }
  )
}

const resetListItemHash = async (listId, listItemId) => {
  return await axios(
    `/api/v1/concur/lists/${listId}/datamap-list-items/${listItemId}/reset-hash`,
    {
      method: "POST",
    }
  )
}

const getExpenseImportStatus = async (expenseId, rowId) =>
  await axios(
    `/api/v1/concur/expenses/${expenseId}/grid-data/${rowId}/import-status`,
    {
      method: "GET",
    }
  )
const getInvoiceImportStatus = async (invoiceId, rowId) =>
  await axios(
    `/api/v1/concur/invoices/${invoiceId}/grid-data/${rowId}/import-status`,
    {
      method: "GET",
    }
  )
const getPaymentImportStatus = async (paymentId, rowId) =>
  await axios(
    `/api/v1/concur/payments/${paymentId}/grid-data/${rowId}/import-status`,
    {
      method: "GET",
    }
  )
export {
  archiveExpenses,
  deleteExpenses,
  archiveInvoices,
  deleteInvoices,
  archivePayments,
  deletePayments,
  updateBatches,
  closeBatches,
  downloadAllInvoiceImages,
  downloadAllExpenseImages,
  downloadBatchJobFile,
  downloadExpenseJobFile,
  downloadInvoiceJobFile,
  downloadPaymentJobFile,
  downloadExpenseImage,
  downloadInvoiceImage,
  downloadPaymentImage,
  reprocessExpenseJob,
  reprocessInvoiceJob,
  reprocessPaymentJob,
  updateBatchJobs,
  fetchBatchJob,
  fetchBatchJobs,
  processBatchJob,
  processBatchJobs,
  updateExtracts,
  closeExtracts,
  downloadExtractJobFile,
  updateExtractJobs,
  autoProcessExtractJobs,
  autoProcessExtractJobsStatus,
  fetchExtractJob,
  fetchExtractJobs,
  processExtractJob,
  processExtractJobs,
  fetchVendorsFromConcur,
  sendVendorsToConcur,
  resetVendorHash,
  importAllExpensesIntoSage,
  importSelectedExpenseIntoSageByIds,
  importAllInvoicesIntoSage,
  importSelectedInvoiceIntoSageByIds,
  importAllPaymentsIntoSage,
  importSelectedPaymentIntoSageByIds,
  migrateConcurDatabase,
  expenseLocker,
  invoiceLocker,
  paymentLocker,
  getToken,
  getPurchaseOrderStatus,
  sendPurchaseOrdersToConcur,
  resetPurchaseOrderHash,
  sendSagePaymentsToConcur,
  resetSagePaymentHash,
  updateLists,
  updateListItems,
  sendListItemsToConcur,
  deleteAllListItems,
  deleteAListItem,
  resetListItemHash,
  getExpenseImportStatus,
  getInvoiceImportStatus,
  getPaymentImportStatus,
}
