import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  RequiredRule,
  StateStoring,
  Button as GridButton,
  Lookup,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"

import { TabItemGridW, LockButton } from "../../shared/StyledComponents"
import { invoiceDataStore } from "../../shared/ConcurStore"
import { hasAccessTo } from "../../../authentication"
import { invoiceLocker } from "../../operations/concur"
import { faPlus } from "../../shared/icons"

const Settings = () => {
  const invoiceGrid = useRef(null)

  const addNewInvoiceClickHandler = () => {
    invoiceGrid.current.instance.addRow()
  }
  const refreshInvoiceGridClickHandler = () => {
    invoiceGrid.current.instance.refresh(true)
  }

  const lockButtonRenderHandler = ({ data }) => (
    <LockButton onClick={lockClickHandler(data.id)} lockId={data.lockId} />
  )
  const lockClickHandler = id => async () => {
    try {
      await invoiceLocker(id)
      invoiceGrid.current.instance.refresh(false)
    } catch (error) {
      notify(`Changing lock status failed: ${error.message}`, "error")
    }
  }

  return (
    <TabItemGridW>
      <DataGrid
        dataSource={invoiceDataStore}
        columnAutoWidth={true}
        columnResizingMode="widget"
        hoverStateEnabled={true}
        ref={invoiceGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("concur.invoice.r")}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "addNewInvoice",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column dataField="id" dataType="number" allowEditing={false} />
        <Column dataField="title" dataType="string">
          <RequiredRule trim={true} />
        </Column>
        <Column dataField="moduleType" dataType="string" caption="type">
          <Lookup dataSource={["ap-invoice", "po-invoice"]} />
          <RequiredRule trim={true} />
        </Column>
        <Column dataField="isActive" dataType="boolean" />
        <Column type="buttons">
          <GridButton name="edit" />
          <GridButton name="delete" />
          <GridButton
            render={lockButtonRenderHandler}
            visible={hasAccessTo("concur.invoice.l")}
          />
        </Column>
        <Editing
          mode="form"
          useIcons={true}
          allowDeleting={hasAccessTo("concur.invoice.d")}
          allowUpdating={hasAccessTo("concur.invoice.u")}
        />
        <FilterRow visible={true} />
        <Scrolling showScrollbar="always" />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="concur-settings-invoices"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshInvoiceGridClickHandler} />
        </Template>
        <Template name="addNewInvoice">
          <Button
            icon={faPlus}
            text="Add Invoice Module"
            onClick={addNewInvoiceClickHandler}
            visible={hasAccessTo("concur.invoice.c")}
          />
        </Template>
      </DataGrid>
    </TabItemGridW>
  )
}

export default Settings
