import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  StateStoring,
  ColumnChooser,
  ColumnFixing,
  Pager,
  FilterPanel,
  Grouping,
  HeaderFilter,
  SearchPanel,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"

import { scheduleLogDataStore } from "../../shared/ClientStores"
import { hasAccessTo } from "../../../authentication"

const ScheduleLogs = () => {
  const resourceScheduleLogGrid = useRef(null)

  const refreshScheduleLogGridClickHandler = () => {
    resourceScheduleLogGrid.current.instance.refresh(true)
  }

  return (
    <>
      <DataGrid
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={scheduleLogDataStore}
        hoverStateEnabled={true}
        ref={resourceScheduleLogGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("schedule.log.r")}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column dataField="id" dataType="number" defaultVisible={false} />
        <Column dataField="scheduleId" dataType="number" />
        <Column dataField="messageId" dataType="number" />
        <Column dataField="status" dataType="string" />
        <Column dataField="errors" dataType="string" />

        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={hasAccessTo("schedule.log.c")}
          allowDeleting={hasAccessTo("schedule.log.d")}
          allowUpdating={hasAccessTo("schedule.log.u")}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <HeaderFilter visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="profile-schedule-logs"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshScheduleLogGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { ScheduleLogs }
export default ScheduleLogs
