import React from "react"
import { TabPanel } from "devextreme-react"
import DataSource from "devextreme/data/data_source"
import { FaFileInvoiceDollar } from "react-icons/fa"

import { expenseDataStore } from "../../shared/ConcurStore"
import Expense from "./Expense"
import { PageTitle } from "../../shared/StyledComponents"
import { hasAccessTo } from "../../../authentication"
import SEO from "../../../seo"

function Home() {
  const expenseDataSource = new DataSource({
    store: expenseDataStore,
    filter: ["isActive", "=", "true"],
  })
  return (
    <>
      <SEO title="Concur Expenses" />
      <PageTitle icon={FaFileInvoiceDollar}>Expenses</PageTitle>
      <TabPanel
        dataSource={expenseDataSource}
        itemComponent={Expense}
        visible={hasAccessTo("concur.expense.r")}
      />
    </>
  )
}

export default Home
