import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  FormItem,
  RequiredRule,
  Lookup,
  StateStoring,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  SearchPanel,
  Button as GridButton,
  FilterRow,
} from "devextreme-react/data-grid"
import DataSource from "devextreme/data/data_source"
import notify from "devextreme/ui/notify"
import { FaUserTag } from "react-icons/fa"

import {
  applicationDataStore,
  roleDataStore,
  permissionDataStore,
} from "../shared/ClientStores"
import { hasAccessTo } from "../../authentication"
import { PageTitle, LockButton } from "../shared/StyledComponents"
import { roleLocker } from "../operations/client"
import SEO from "../../seo"
import { faPlus } from "../shared/icons"

const roleDataSource = new DataSource({ store: roleDataStore })

const Roles = () => {
  const rolesGrid = useRef(null)

  const addNewRoleClickHandler = () => {
    rolesGrid.current.instance.addRow()
  }
  const refreshRoleGridClickHandler = () => {
    rolesGrid.current.instance.refresh(true)
  }

  const getFilteredPermissions = options => {
    return {
      store: permissionDataStore,
      postProcess: data => {
        if (options.data)
          return data.filter(
            item =>
              item.applicationId &&
              options.data.applicationId &&
              item.applicationId.toString() ===
                options.data.applicationId.toString()
          )
        return data
      },
    }
  }

  const applicationSetCellValueHandler = function(rowData, value) {
    rowData.permissionIds = []
    this.defaultSetCellValue(rowData, value)
  }
  const lockButtonRenderHandler = ({ data }) => (
    <LockButton onClick={lockClickHandler(data.id)} lockId={data.lockId} />
  )
  const lockClickHandler = id => async () => {
    try {
      await roleLocker(id)
      rolesGrid.current.instance.refresh(false)
    } catch (error) {
      notify(`Changing lock status failed: ${error.message}`, "error")
    }
  }

  const editorPreparingHandler = e => {
    if (
      e.value &&
      e.parentType === "dataRow" &&
      e.dataField === "applicationId"
    ) {
      e.editorOptions.value = e.value.toString()
      e.editorOptions.onValueChanged = args => {
        e.setValue(parseInt(args.value))
      }
    }
  }
  const toolbarPreparingHandler = e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "addNewRole",
    })
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }
  const initNewRowHandler = e => {
    e.data.isActive = false
  }

  return (
    <>
      <SEO title="Roles" />
      <PageTitle icon={FaUserTag}>Roles</PageTitle>
      <DataGrid
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={roleDataSource}
        hoverStateEnabled={true}
        ref={rolesGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("role.r")}
        onInitNewRow={initNewRowHandler}
        onEditorPreparing={editorPreparingHandler}
        onToolbarPreparing={toolbarPreparingHandler}
      >
        <Column
          caption="Application"
          dataField="applicationId"
          dataType="number"
          setCellValue={applicationSetCellValueHandler}
        >
          <Lookup
            dataSource={{
              store: applicationDataStore,
              map: item => {
                return { ...item, applicationId: item.applicationId.toString() }
              },
            }}
            displayExpr="title"
            valueExpr="applicationId"
          />
          <RequiredRule />
        </Column>
        <Column caption="Title" dataField="name" dataType="string">
          <RequiredRule trim={true} />
        </Column>
        <Column dataField="isActive" dataType="boolean" />
        <Column caption="Permissions" dataField="permissionIds" visible={false}>
          <Lookup
            dataSource={getFilteredPermissions}
            displayExpr="title"
            valueExpr="permissionId"
          />
          <FormItem
            editorType="dxTagBox"
            editorOptions={{
              searchEnabled: true,
              showSelectionControls: true,
            }}
          />
          <RequiredRule />
        </Column>
        <Column type="buttons">
          <GridButton name="edit" />
          <GridButton name="delete" />
          <GridButton
            render={lockButtonRenderHandler}
            visible={hasAccessTo("role.l")}
          />
        </Column>
        <Editing
          allowDeleting={hasAccessTo("role.d")}
          allowUpdating={hasAccessTo("role.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
          visible={true}
        />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="profile-roles"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshRoleGridClickHandler} />
        </Template>
        <Template name="addNewRole">
          <Button
            icon={faPlus}
            text="Add New Role"
            visible={hasAccessTo("role.c")}
            onClick={addNewRoleClickHandler}
          />
        </Template>
      </DataGrid>
    </>
  )
}

export { Roles }
export default Roles
