import React from "react"
import { SelectBox, ProgressBar } from "devextreme-react"
import { Button as SelectBoxButton } from "devextreme-react/select-box"
import { connect } from "react-redux"
import { useState } from "react"
import { useTimeoutFn, useIdle } from "react-use"

import {
  dashboardOperations,
  dashboardSelectors,
} from "../../state/ducks/dashboard"
import styled from "styled-components"
import useResponsiveWidth from "./shared/hooks/useResponsiveWidth"

const AutoRefreshW = styled.div`
  display: flex;
  flex-direction: column;
  .dx-widget.dx-progressbar {
    line-height: 0;
  }
  .dx-widget.dx-progressbar .dx-progressbar-container {
    height: 1px;
  }
`

const refreshSelectBoxDataSource = [
  { title: "off", value: 0 },
  { title: "5 seconds", value: 5 },
  { title: "10 seconds", value: 10 },
  { title: "30 seconds", value: 30 },
  { title: "1 minute", value: 60 },
  { title: "5 minutes", value: 300 },
  { title: "15 minutes", value: 900 },
  { title: "30 minutes", value: 1800 },
  { title: "1 hour", value: 3600 },
  { title: "2 hours", value: 7400 },
  { title: "1 day", value: 86400 },
]
function AutoRefresher({
  freeze,
  interval,
  refreshUuid,
  setAutoRefreshInterval,
}) {
  const [counter, setCounter] = useState(0)
  const isIdle = useIdle(300000)
  const { xs } = useResponsiveWidth()

  const checkRefresh = () => {
    if (!freeze && !isIdle && interval && isReady()) {
      if (counter >= interval) {
        refreshUuid()
        setCounter(0)
      } else {
        setCounter(counter + 1)
      }
    }
    reset()
  }
  const [isReady, , reset] = useTimeoutFn(checkRefresh, 1000)

  const refreshButtonClickHandler = () => {
    refreshUuid()
    setCounter(0)
  }
  const selectBoxValueChangedHandler = ({ value }) => {
    setAutoRefreshInterval(value)
    setCounter(0)
  }
  return (
    <AutoRefreshW>
      <SelectBox
        dataSource={refreshSelectBoxDataSource}
        hint="Page refresh interval"
        defaultValue={interval}
        displayExpr="title"
        valueExpr="value"
        onValueChanged={selectBoxValueChangedHandler}
        width={xs ? null : "200px"}
      >
        <SelectBoxButton name="dropDown" />
        <SelectBoxButton
          name="downloadVendors"
          options={{
            icon: "refresh",
            hint: "Refresh",
            height: "100%",
            onClick: refreshButtonClickHandler,
          }}
        />
      </SelectBox>
      <ProgressBar
        min={0}
        max={interval}
        value={counter}
        showStatus={false}
        visible={interval > 0}
      />
    </AutoRefreshW>
  )
}

export { AutoRefresher as AutoRefreshItem }

const mapStateToProps = state => ({
  interval: dashboardSelectors.selectAutoRefreshInterval(state),
  freeze: dashboardSelectors.selectAutoRefreshFreeze(state),
})

const mapDispatchToProps = dispatch => {
  return {
    refreshUuid: () => dispatch(dashboardOperations.refreshUuid()),
    setAutoRefreshInterval: interval =>
      dispatch(dashboardOperations.setAutoRefreshInterval(interval)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AutoRefresher)
