import React, { useRef, useState, useEffect } from "react"
import { DataGrid, Button, Template, Form, LoadPanel } from "devextreme-react"
import {
  Column,
  Button as GButton,
  Editing,
  StateStoring,
  SearchPanel,
  ColumnChooser,
  ColumnFixing,
  FilterPanel,
  Grouping,
  Pager,
  Format,
  HeaderFilter,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid"
import DataSource from "devextreme/data/data_source"
import downloadJs from "downloadjs"
import { Item } from "devextreme-react/form"
import { connect } from "react-redux"
import notify from "devextreme/ui/notify"
import { FaFile } from "react-icons/fa"

import { extractJobDataStoreGenerator } from "../shared/ConcurStore"
import { extractDataStore } from "../shared/ConcurStore"
import {
  autoProcessExtractJobs,
  closeExtracts,
  downloadExtractJobFile,
  fetchExtractJob,
  fetchExtractJobs,
  processExtractJob,
  processExtractJobs,
  updateExtractJobs,
} from "../operations/concur"
import { FormW, PageTitle } from "../shared/StyledComponents"
import { convertUtcToLocal } from "../shared/utilities"
import { hasAccessTo } from "../../authentication"
import { dashboardSelectors } from "../../../state/ducks/dashboard"
import ItemScheduler from "../shared/ItemScheduler"
import useResponsiveWidth from "../shared/hooks/useResponsiveWidth"
import SEO from "../../seo"
import {
  bsFillGearFill,
  faCloudDownloadAlt,
  grRefresh,
  vscNewFile,
} from "../shared/icons"

const ExtractJobs = ({ extractId, refreshUuid }) => {
  const formW = useRef(null)
  const extractForm = useRef(null)
  const jobsGrid = useRef(null)
  const concurJobDataSourceRef = useRef(
    new DataSource({
      store: extractJobDataStoreGenerator(extractId),
    })
  )
  const [isLoading, setIsLoading] = useState(false)
  const { md, lg } = useResponsiveWidth()

  useEffect(() => {
    jobsGrid.current.instance.refresh(true)
  }, [refreshUuid])

  const concurJobDataSource = concurJobDataSourceRef.current
  useEffect(() => {
    const getExtractInfo = async () => {
      setIsLoading(true)
      try {
        let response = await extractDataStore.byKey(extractId)
        extractForm.current.instance.option("formData", response.data)
      } finally {
        setIsLoading(false)
      }
    }
    getExtractInfo()
  }, [extractId])

  const refreshJobGridClickHandler = () => {
    jobsGrid.current.instance.refresh(true)
  }
  const updateJobClickHandler = async () => {
    try {
      await updateExtractJobs(extractId)
      notify("Updating extract jobs was queued successfully.", "success")
    } catch (error) {
      notify(`Updating extract jobs failed: ${error.message}`, "error")
    }
  }
  const autoProcessClickHandler = async () => {
    try {
      await autoProcessExtractJobs(extractId)
      notify(`Auto processing extract job queued successfully`, "success")
    } catch (error) {
      notify(`Auto processing extract job failed: ${error.message}`, "error")
    }
  }
  const requestNewBatchClickHandler = async () => {
    try {
      await closeExtracts(extractId)
      notify("Closing extract job was queued successfully.", "success")
    } catch (error) {
      notify(`Closing extract job failed: ${error.message}`, "error")
    }
  }
  const jobButtonDownloadClickHandler = async e => {
    const jobId = e.row.data.id
    try {
      const file = await downloadExtractJobFile(extractId, jobId)
      let fileExtension
      switch (file.fileType) {
        case "application/zip":
          fileExtension = ".zip"
          break
        case "text/csv":
          fileExtension = ".csv"
          break
        default:
          fileExtension = ""
      }
      downloadJs(
        atob(file.data),
        `extract-${extractId}-job-${jobId}${fileExtension}`,
        `${file.fileType}`
      )
    } catch (error) {
      notify(`Downloading extract job file failed: ${error.message}`, "error")
    }
  }
  const jobButtonFetchClickHandler = async e => {
    const jobId = e.row.data.id
    try {
      await fetchExtractJob(extractId, jobId)
      notify("Fetch extract job file was queued successfully.", "success")
    } catch (error) {
      notify(`Fetching extract job file failed: ${error.message}`, "error")
    }
  }
  const fetchBatchesClickHandler = async () => {
    try {
      await fetchExtractJobs(extractId)
      notify("Fetch extract job files was queued successfully.", "success")
    } catch (error) {
      notify(`Fetching extract job files failed: ${error.message}`, "error")
    }
  }
  const jobButtonProcessClickHandler = async e => {
    const jobId = e.row.data.id
    try {
      await processExtractJob(extractId, jobId)
      notify("Process extract job file was queued successfully.", "success")
    } catch (error) {
      notify(`Processing extract job file failed: ${error.message}`, "error")
    }
  }
  const processBatchesClickHandler = async () => {
    try {
      await processExtractJobs(extractId)
      notify("Process extract job files was queued successfully.", "success")
    } catch (error) {
      notify(`Process extract job files failed: ${error.message}`, "error")
    }
  }
  const calculateStatusCellValue = ({ concur_Status }) => {
    switch (Number(concur_Status)) {
      case 0:
        return 0
      case 1:
        return "QUEUED"
      case 2:
        return "COMPLETE"
      default:
        return "UNKNOWN"
    }
  }

  return (
    <>
      <SEO title="Concur Extract Jobs" />
      <FormW ref={formW}>
        <Form
          readOnly={true}
          ref={extractForm}
          visible={hasAccessTo("concur.extract.r")}
        >
          <Item itemType="group" caption="Extract information" colCount={2}>
            <Item dataField="label" />
            <Item dataField="concur_Name" />
            <Item dataField="concur_Id" />
            <Item dataField="isActive" editorType="dxCheckBox" />
          </Item>
        </Form>
        <LoadPanel
          visible={isLoading}
          container={formW.current}
          position={{
            of: formW.current,
          }}
        />
      </FormW>
      <PageTitle icon={FaFile} variant="subTitle">
        Jobs
      </PageTitle>
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={concurJobDataSource}
        hoverStateEnabled={true}
        ref={jobsGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("concur.extract.job.r")}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "processBatches",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "fetchBatches",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "updateBatches",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "requestNewBatch",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "autoProcess",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column
          dataField="concur_Id"
          dataType="number"
          defaultVisible={false}
        />
        <Column dataField="concur_StartTime" dataType="datetime">
          <Format formatter={convertUtcToLocal} />
        </Column>
        <Column dataField="concur_StopTime" dataType="datetime">
          <Format formatter={convertUtcToLocal} />
        </Column>
        <Column
          dataField="concur_Status"
          calculateCellValue={calculateStatusCellValue}
          dataType="string"
        />
        <Column type="buttons" showInColumnChooser={false}>
          <GButton
            name="download"
            hint="Download job file."
            icon="file"
            visible={hasAccessTo("concur.extract.job.file")}
            onClick={jobButtonDownloadClickHandler}
          />
          <GButton
            name="fetch"
            hint="Fetch job file from Concur."
            icon="download"
            visible={hasAccessTo("concur.extract.job.fetch")}
            onClick={jobButtonFetchClickHandler}
          />
          <GButton
            name="process"
            hint="Process job file."
            icon="refresh"
            visible={hasAccessTo("concur.extract.job.process")}
            onClick={jobButtonProcessClickHandler}
          />
        </Column>
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing mode="row" useIcons={true} allowDeleting={true} />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <HeaderFilter visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel width={250} visible={md || lg} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="concur-extract-jobs"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshJobGridClickHandler} />
        </Template>
        <Template name="autoProcess">
          <Button
            elementAttr={{ id: "autoProcess" }}
            icon={bsFillGearFill}
            text="Auto Process"
            visible={hasAccessTo("concur.extract.auto")}
            onClick={autoProcessClickHandler}
          />
        </Template>
        <Template name="requestNewBatch">
          <Button
            elementAttr={{ id: "requestNewBatch" }}
            icon={vscNewFile}
            text="Request New Batch"
            visible={hasAccessTo("concur.extract.close")}
            onClick={requestNewBatchClickHandler}
          />
        </Template>
        <Template name="updateBatches">
          <Button
            elementAttr={{ id: "updateBatches" }}
            icon={grRefresh}
            text="Update Batches"
            visible={hasAccessTo("concur.extract.status")}
            onClick={updateJobClickHandler}
          />
        </Template>
        <Template name="fetchBatches">
          <Button
            elementAttr={{ id: "fetchBatches" }}
            icon={faCloudDownloadAlt}
            text="Fetch Batches"
            visible={hasAccessTo("concur.extract.job.fetch")}
            onClick={fetchBatchesClickHandler}
          />
        </Template>
        <Template name="processBatches">
          <Button
            elementAttr={{ id: "processBatches" }}
            icon="refresh"
            text="Process Batches"
            visible={hasAccessTo("concur.extract.job.process")}
            onClick={processBatchesClickHandler}
          />
        </Template>
      </DataGrid>
      <ItemScheduler
        items={[
          {
            body: null,
            method: "POST",
            target: "#autoProcess",
            title: `auto process an extract (extractId=${extractId})`,
            url: `/api/v1/concur/extracts/${extractId}/jobs/schedule-auto-process`,
          },
          {
            body: null,
            method: "POST",
            target: "#requestNewBatch",
            title: `request new batch (extractId=${extractId})`,
            url: `/api/v1/concur/extracts/${extractId}/close`,
          },
          {
            body: null,
            method: "POST",
            target: "#updateBatches",
            title: `update batches (extractId=${extractId})`,
            url: `/api/v1/concur/extracts/${extractId}/update-jobs`,
          },
          {
            body: null,
            method: "POST",
            target: "#fetchBatches",
            title: `fetch batches (extractId=${extractId})`,
            url: `/api/v1/concur/extracts/${extractId}/jobs/fetch`,
          },
          {
            body: null,
            method: "POST",
            target: "#processBatches",
            title: `process batches (extractId=${extractId})`,
            url: `/api/v1/concur/extracts/${extractId}/jobs/process`,
          },
        ]}
      />
    </>
  )
}

export { ExtractJobs }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(ExtractJobs)
