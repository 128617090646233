import React, { useState } from "react"
import {
  Button,
  SelectBox,
  CheckBox,
  TextArea,
  DataGrid,
} from "devextreme-react"
import {
  ColumnChooser,
  ColumnFixing,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"
import styled from "styled-components"
import jsesc from "jsesc"

import { runQuery } from "../../../operations/core"
import { vscServerProcess } from "../../../shared/icons"
import { queryTemplateDataStore } from "../../../shared/CoreStore"
import { queryDataStore } from "../../../shared/ClientStores"

const WidgetsW = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const RawResultW = styled.div`
  width: 10rem;
  text-align: right;
`
const Label = styled.div`
  font-size: 1rem;
  margin-bottom: 0.5rem;
`
const TextAreaW = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Templates = () => {
  const [queryId, setQueryId] = useState(null)
  const [showRawData, setShowRawData] = useState(false)
  const [bodyString, setBodyString] = useState(null)
  const [title, setTitle] = useState(null)
  const [result, setResult] = useState(null)

  const selectBoxValueChangedHandler = async ({ value }) => {
    try {
      const { bodyString, title } = await queryTemplateDataStore.byKey(value)
      setQueryId(value)
      setBodyString(bodyString)
      setTitle(title)
    } catch (error) {
      setQueryId(null)
      setBodyString(null)
      setTitle(null)
      notify(`Fetching query information failed: ${error.message}`, "error")
    }
  }
  const checkBoxValueChangedHandler = ({ value }) => {
    setShowRawData(value)
  }
  const runQueryClickHandler = async () => {
    try {
      const response = await runQuery(queryId, bodyString)
      const result = response.data
      setResult(result)
      notify(`Query ran successfully`, "success")
    } catch (error) {
      notify(`Executing query failed: ${error.message}`, "error")
    }
  }
  const saveQueryClickHandler = async () => {
    const data = jsesc(
      { title: title, bodyString: bodyString, queryId: queryId },
      {
        quotes: "double",
      }
    )
    try {
      await queryDataStore.insert(data)
      notify(`Query saved successfully`, "success")
    } catch (error) {
      notify(`Saving query failed: ${error.message}`, "error")
    }
  }

  const queryValueChangedHandler = ({ value }) => {
    setBodyString(value)
  }
  return (
    <>
      <WidgetsW>
        <SelectBox
          dataSource={queryTemplateDataStore}
          displayExpr="title"
          valueExpr="id"
          placeholder="Select template..."
          value={queryId}
          width="calc(100% - 10rem)"
          onValueChanged={selectBoxValueChangedHandler}
        />
        <RawResultW>
          Raw Result:
          <CheckBox
            hint="Show raw data."
            style={{ marginLeft: "1rem" }}
            value={showRawData}
            onValueChanged={checkBoxValueChangedHandler}
          />
        </RawResultW>
      </WidgetsW>
      {queryId !== null && (
        <TextAreaW>
          <Label>Query:</Label>
          <TextArea
            autoResizeEnabled={true}
            value={bodyString}
            onValueChanged={queryValueChangedHandler}
          ></TextArea>
          <Button
            icon={vscServerProcess}
            onClick={runQueryClickHandler}
            hint="Run this query"
            text="Run"
            style={{ marginTop: "0.5rem", marginRight: "0.5rem" }}
          />
          <Button
            icon="save"
            onClick={saveQueryClickHandler}
            hint="Save this query"
            text="Save"
            style={{ marginTop: "0.5rem" }}
          />
        </TextAreaW>
      )}
      {result &&
        (showRawData ? (
          <>
            <Label>Query results:</Label>
            <TextArea
              autoResizeEnabled={true}
              readOnly={true}
              value={JSON.stringify(result)}
            />
          </>
        ) : (
          <>
            <Label>Query results:</Label>
            <DataGrid
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              columnResizingMode="widget"
              dataSource={result}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
              showBorders={true}
            >
              <ColumnChooser
                allowSearch={true}
                enabled={true}
                height="400"
                mode="select"
              />
              <ColumnFixing enabled={true} />
              <FilterPanel visible={true} />
              <FilterRow visible={true} />
              <Grouping contextMenuEnabled={true} />
              <GroupPanel visible={true} />
              <HeaderFilter allowSearch={true} visible={true} />
              <Pager
                visible={true}
                allowedPageSizes={[10, 20, 50, 100]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                showInfo={true}
              />
              <Scrolling showScrollbar="always" />
              <SearchPanel width={250} visible={true} />
            </DataGrid>
          </>
        ))}
    </>
  )
}

export default Templates
