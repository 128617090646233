import React, { useCallback, useEffect, useState } from "react"
import { DataGrid } from "devextreme-react"
import {
  Button as GridButton,
  Column,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  Pager,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid"

const LocalSettings = () => {
  const [items, setItems] = useState([])

  const loadLocalStorage = useCallback(() => {
    const {
      token,
      isNewCustomer,
      "redux-state": reduxState,
      ...rest
    } = localStorage
    const items = Object.keys(rest).map(item => ({ storageKey: item }))
    setItems(items)
  }, [])

  useEffect(() => {
    loadLocalStorage()
  }, [loadLocalStorage])
  return (
    <>
      <DataGrid
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={items}
        hoverStateEnabled={true}
        key="storageKey"
        rowAlternationEnabled={true}
        showBorders={true}
      >
        <Column dataField="storageKey" />
        <Column type="buttons">
          <GridButton
            name="deleteItem"
            icon="trash"
            onClick={data => {
              localStorage.removeItem(data.row.data.storageKey)
              loadLocalStorage()
            }}
          />
        </Column>
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
          visible={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
      </DataGrid>
    </>
  )
}

export { LocalSettings }
export default LocalSettings
