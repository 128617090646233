import { Form, ScrollView } from "devextreme-react"
import React from "react"
import { mapValues } from "lodash"
import { convertUtcToLocal } from "../../shared/utilities"

const Traits = ({ data }) => {
  const modifiedDate = mapValues(data, value => {
    if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/.test(value)) {
      return convertUtcToLocal(value)
    }
    return value
  })
  return (
    <ScrollView>
      <Form formData={modifiedDate} readOnly={true} />
    </ScrollView>
  )
}

export default Traits
