import { axios } from "../shared/utilities"

export const loadShopifyOrders = async () => {
  return await axios("/api/v1/shopify/shopify-orders/load", { method: "POST" })
}

export const getShopifyPlantSpecies = async () => {
  const result = await axios("/api/v1/plants/shopify/species")
  return result.data
}
