import React, { useEffect, useState, memo } from "react"
import { ContextMenu } from "devextreme-react"
import { Item as ContextMenuItem } from "devextreme-react/context-menu"
import { navigate } from "@reach/router"
import PropTypes from "prop-types"
import notify from "devextreme/ui/notify"
import { v4 as uuidv4 } from "uuid"

import { resourceApiDataStore } from "./ClientStores"
import { hasAccessTo, hasAccessToAny } from "../../authentication"

const ItemScheduler = memo(({ items }) => {
  const [apis, setApis] = useState([])
  const [uuid, setUuid] = useState(null)
  const addText = "Add To Schedulable Apis"
  const removeText = "Remove From Schedulable Apis"
  const scheduleText = "Schedule This Action"
  const position = { my: "top left", at: "center" }
  useEffect(() => {
    const getInfo = async () => {
      const apis = (await resourceApiDataStore.load()).data
      setApis(apis)
    }
    if (hasAccessTo("resource.api.r")) getInfo()
  }, [setApis, uuid])
  const itemClickHandler = (itemId, item) => async ({ itemData }) => {
    if (itemData.text === addText) {
      try {
        const { target, ...newItem } = item
        await resourceApiDataStore.insert(newItem)
        notify(`Api added to schedulable apis successfully.`, "success")
        setUuid(uuidv4())
      } catch (error) {
        notify(`Adding api failed: ${error.message}`, "error")
      }
    } else if (itemData.text === removeText)
      try {
        await resourceApiDataStore.remove(itemId)
        setUuid(uuidv4())
        notify(`Api deleted from schedulable apis successfully.`, "success")
      } catch (error) {
        notify(`Deleting api failed: ${error.message}`, "error")
      }
    else if (itemData.text === scheduleText)
      navigate(`/dashboard/profile/schedule?resourceApiId=${itemId}`)
  }
  const getItemId = item => {
    const api = apis.find(
      api =>
        api.title === item.title &&
        api.method === item.method &&
        api.url === item.url &&
        api.body === item.body
    )
    if (api) return api.id
    return null
  }

  return (
    <>
      {items.map((item, index) => {
        const itemId = getItemId(item)
        if (itemId && hasAccessToAny(["resource.api.d", "schedule.c"]))
          return (
            <ContextMenu
              key={index}
              target={item.target}
              position={position}
              onItemClick={itemClickHandler(itemId)}
            >
              <ContextMenuItem
                icon="clock"
                text={scheduleText}
                visible={hasAccessTo("schedule.c")}
              />
              <ContextMenuItem
                icon="minus"
                text={removeText}
                visible={hasAccessTo("resource.api.d")}
              />
            </ContextMenu>
          )
        if (hasAccessTo("resource.api.c"))
          return (
            <ContextMenu
              key={index}
              target={item.target}
              position={position}
              onItemClick={itemClickHandler(itemId, item)}
            >
              <ContextMenuItem icon="plus" text={addText} />
            </ContextMenu>
          )
        return null
      })}
    </>
  )
})

ItemScheduler.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
      method: PropTypes.string,
      url: PropTypes.string,
      body: PropTypes.string,
    })
  ).isRequired,
}

export default ItemScheduler
