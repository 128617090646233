import { useEffect } from "react"
import { useIdle, useTimeoutFn } from "react-use"
import notify from "devextreme/ui/notify"

import { userActivityLogger } from "../../operations/client"

const useUserActivityLogger = (sendStatusInterval = 60000) => {
  const isIdle = useIdle(sendStatusInterval - 1000)
  useEffect(() => {
    userActivityLogger()
  }, [])
  const checkStatus = async () => {
    if (!isIdle && isReady()) {
      try {
        await userActivityLogger()
      } catch (error) {
        notify(`Logging user activity failed: ${error.message}`, "error")
      }
    }
    reset()
  }
  const [isReady, , reset] = useTimeoutFn(checkStatus, sendStatusInterval)
  return null
}

export default useUserActivityLogger
