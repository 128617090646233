import React from "react"
import styled from "styled-components"
import { MdPayment } from "react-icons/md"

import DataMap from "./DataMap"
import { PageTitle } from "../../shared/StyledComponents"
import SEO from "../../../seo"

const W = styled.div`
  margin: 1rem;
`

const SagePayments = () => {
  return (
    <>
      <SEO title="Concur Sage Payments" />
      <PageTitle icon={MdPayment}>Sage Payments</PageTitle>
      <W>
        <DataMap />
      </W>
    </>
  )
}

export default SagePayments
