import React from "react"
import { Accordion } from "devextreme-react"
import { Item as AccordionItem } from "devextreme-react/accordion"

import { FaTable, FaChartBar } from "../../../fa-icons"
import InvoiceGrid from "./InvoiceGrid"
import InvoiceChart from "./InvoiceChart"
import { hasAccessTo } from "../../../authentication"
import { getAccordionState, setAccordionState } from "../../shared/utilities"

const Invoice = ({ data }) => {
  const stateKey = "concur-invoices"
  const invoiceId = data.id

  return (
    <Accordion
      collapsible={true}
      multiple={true}
      onInitialized={getAccordionState(stateKey)}
      onSelectionChanged={setAccordionState(stateKey)}
    >
      <AccordionItem
        icon={FaChartBar}
        title=" Chart"
        visible={hasAccessTo("concur.invoice.chart")}
      >
        <InvoiceChart invoiceId={invoiceId} />
      </AccordionItem>
      <AccordionItem
        icon={FaTable}
        title=" Grid"
        visible={hasAccessTo("concur.invoice.grid.r")}
      >
        <InvoiceGrid invoiceId={invoiceId} />
      </AccordionItem>
    </Accordion>
  )
}

export default Invoice
