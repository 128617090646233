import CustomStore from "devextreme/data/custom_store"
import Query from "devextreme/data/query"
import { axios } from "./utilities"

import { timeRangeToParam } from "./utilities"
import dayjs from "dayjs"
import dayjsUtcPlugin from "dayjs/plugin/utc"

const applicationDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/applications")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/applications/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/applications/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/applications`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/applications/${key}`, {
      method: "DELETE",
    })
  },
})
const clientDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/clients")
  },
  byKey: async key => {
    if (key === "") return { clientCode: "", name: "" }
    let response = await axios(`/api/v1/clients/${key}`)
    return response.data
  },
})
const datamapQueryDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/datamap-queries")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/datamap-queries/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/datamap-queries/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/datamap-queries`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/datamap-queries/${key}`, {
      method: "DELETE",
    })
  },
})
const dataModelDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/data-models")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/data-models/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/data-models/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/data-models`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/data-models/${key}`, {
      method: "DELETE",
    })
  },
})
const emailNotificationDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/email-notifications")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/email-notifications/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/email-notifications/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/email-notifications`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/email-notifications/${key}`, {
      method: "DELETE",
    })
  },
})
const lockDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/locks")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/locks/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/locks/${key}`, { method: "PUT", data: value })
  },
  insert: async value => {
    return await axios(`/api/v1/locks`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/locks/${key}`, {
      method: "DELETE",
    })
  },
})
const messageDataStoreGenerator = ({ timeRange }) => {
  const params = timeRangeToParam(timeRange, true)
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(`/api/v1/messages${params}`)
    },
    byKey: async key => {
      const response = await axios(`/api/v1/messages/${key}`)
      return response.data
    },
    update: async (key, value) => {
      return await axios(`/api/v1/messages/${key}`, {
        method: "PUT",
        data: value,
      })
    },
    remove: async key => {
      return await axios(`/api/v1/messages/${key}`, {
        method: "DELETE",
      })
    },
  })
}

const notificationDataStore = new CustomStore({
  key: "id",
  load: async () => {
    const response = await axios("/api/v1/notifications")
    return response.data
  },
  byKey: async key => {
    const response = await axios(`/api/v1/notifications/${key}`)
    return response.data
  },
})

const permissionDataStore = new CustomStore({
  key: "id",
  load: async loadOptions => {
    const response = await axios("/api/v1/permissions")
    if (loadOptions.searchValue)
      return Query(response.data)
        .filter([
          loadOptions.searchExpr,
          loadOptions.searchOperation,
          loadOptions.searchValue,
        ])
        .toArray()
    return response.data
  },
  byKey: async key => {
    const response = await axios(`/api/v1/permissions/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/permissions/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/permissions`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/permissions/${key}`, {
      method: "DELETE",
    })
  },
})
const queryDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/queries")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/queries/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/queries/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/queries`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/queries/${key}`, {
      method: "DELETE",
    })
  },
})
const resourceApiDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/resource/apis")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/resource/apis/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/resource/apis/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/resource/apis`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/resource/apis/${key}`, {
      method: "DELETE",
    })
  },
})
const resourcePriorityDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/resource/priorities")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/resource/priorities/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/resource/priorities/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/resource/priorities`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/resource/priorities/${key}`, {
      method: "DELETE",
    })
  },
})
const resourceTimeoutDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/resource/timeouts")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/resource/timeouts/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/resource/timeouts/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/resource/timeouts`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/resource/timeouts/${key}`, {
      method: "DELETE",
    })
  },
})
const reviseDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/revises")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/revises/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/revises/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/revises`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/revises/${key}`, {
      method: "DELETE",
    })
  },
})
const roleDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/roles")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/roles/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/roles/${key}`, { method: "PUT", data: value })
  },
  insert: async value => {
    return await axios(`/api/v1/roles`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/roles/${key}`, {
      method: "DELETE",
    })
  },
})
const sageColumnDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/sage/columns")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/sage/columns/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/sage/columns/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/sage/columns`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/sage/columns/${key}`, {
      method: "DELETE",
    })
  },
})
const sageKeyValueDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/sage/key-values")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/sage/key-values/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/sage/key-values/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/sage/key-values`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/sage/key-values/${key}`, {
      method: "DELETE",
    })
  },
})

const scheduleDataStore = new CustomStore({
  key: "id",
  load: async loadOptions => {
    const { filter } = loadOptions
    if (filter) {
      const response = await axios(`/api/v1/schedules`)
      return response.data.filter(item => item[filter[0]] === filter[2])
    }
    return await axios(`/api/v1/schedules`)
  },
  byKey: async key => {
    return await axios(`/api/v1/schedules/${key}`)
  },
  update: async (key, value) => {
    if (value.startDate) {
      value = {
        ...value,
        endDate: dayjs
          .extend(dayjsUtcPlugin)
          .utc(value.startDate)
          .add(15, "minute")
          .format(),
      }
    }
    return await axios(`/api/v1/schedules/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    const { id, ...newValue } = {
      ...value,
      endDate: dayjs
        .extend(dayjsUtcPlugin)
        .utc(value.startDate)
        .add(15, "minute")
        .format(),
    }
    return await axios(`/api/v1/schedules`, {
      method: "POST",
      data: newValue,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/schedules/${key}`, {
      method: "DELETE",
    })
  },
})
const scheduleHistoryDataStoreGenerator = (scheduleId, { timeRange }) => {
  const params = timeRangeToParam(timeRange, true)
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(`/api/v1/schedules/${scheduleId}/histories${params}`)
    },
  })
}
const scheduleLogDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios(`/api/v1/schedule-logs`)
  },
  byKey: async key => {
    return await axios(`/api/v1/schedule-logs/${key}`)
  },
  update: async (key, value) => {
    return await axios(`/api/v1/schedule-logs/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/schedule-logs`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/schedule-logs/${key}`, {
      method: "DELETE",
    })
  },
})

const schemaDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios(`/api/v1/schemas`)
  },
  byKey: async key => {
    return await axios(`/api/v1/schemas/${key}`)
  },
  update: async (key, value) => {
    return await axios(`/api/v1/schemas/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/schemas`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/schemas/${key}`, {
      method: "DELETE",
    })
  },
})

const sourceObjectDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/source-objects")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/source-objects/${key}`)
    return response.data
  },
})

const testDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/tests")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/tests/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/tests/${key}`, { method: "PUT", data: value })
  },
  insert: async value => {
    return await axios(`/api/v1/tests`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/tests/${key}`, {
      method: "DELETE",
    })
  },
})

const topicDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/topics")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/topics/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/topics/${key}`, {
      method: "PUT",
      data: value,
    })
  },
})
const transformDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/transforms")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/transforms/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/transforms/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/transforms`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/transforms/${key}`, {
      method: "DELETE",
    })
  },
})

const translateCategoryDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/translate-categories")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/translate-categories/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/translate-categories/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/translate-categories`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/translate-categories/${key}`, {
      method: "DELETE",
    })
  },
})

const translateDataStoreGenerator = translateCategoryId =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/translates?translateCategoryId=${translateCategoryId}`
      )
    },
    byKey: async key => {
      const response = await axios(`/api/v1/translates/${key}`)
      return response.data
    },
    update: async (key, value) => {
      return await axios(`/api/v1/translates/${key}`, {
        method: "PUT",
        data: value,
      })
    },
    insert: async value => {
      return await axios(`/api/v1/translates`, { method: "POST", data: value })
    },
    remove: async key => {
      return await axios(`/api/v1/translates/${key}`, {
        method: "DELETE",
      })
    },
  })

const userDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/users")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/users/${key}`)
    return response.data
  },
  update: async (key, value) => {
    return await axios(`/api/v1/users/${key}`, { method: "PUT", data: value })
  },
  insert: async value => {
    return await axios(`/api/v1/users`, { method: "POST", data: value })
  },
  remove: async key => {
    return await axios(`/api/v1/users/${key}`, {
      method: "DELETE",
    })
  },
})

const userGeneralSettingDataStore = new CustomStore({
  load: async () => {
    return await axios("/api/v1/users/settings/general")
  },
  update: async value => {
    return await axios(`/api/v1/users/settings/general`, {
      method: "PUT",
      data: value,
    })
  },
})
const userProfileDataStore = new CustomStore({
  load: async () => {
    return await axios("/api/v1/users/my-profile")
  },
  update: async value => {
    return await axios(`/api/v1/users/my-profile`, {
      method: "PUT",
      data: value,
    })
  },
})

export {
  applicationDataStore,
  clientDataStore,
  datamapQueryDataStore,
  dataModelDataStore,
  emailNotificationDataStore,
  lockDataStore,
  messageDataStoreGenerator,
  notificationDataStore,
  permissionDataStore,
  queryDataStore,
  roleDataStore,
  resourcePriorityDataStore,
  resourceTimeoutDataStore,
  resourceApiDataStore,
  reviseDataStore,
  sageColumnDataStore,
  sageKeyValueDataStore,
  scheduleDataStore,
  scheduleHistoryDataStoreGenerator,
  scheduleLogDataStore,
  schemaDataStore,
  sourceObjectDataStore,
  testDataStore,
  topicDataStore,
  transformDataStore,
  translateCategoryDataStore,
  translateDataStoreGenerator,
  userDataStore,
  userGeneralSettingDataStore,
  userProfileDataStore,
}
