import React from "react"
import { TabPanel, Accordion } from "devextreme-react"
import { Item } from "devextreme-react/tab-panel"
import { Item as AccordionItem } from "devextreme-react/accordion"
import styled from "styled-components"
import { FaStore } from "react-icons/fa"

import DataMap from "./DataMap"
import Sage from "./Sage"
import Concur from "./Concur"
import { FaChartBar, FaTable } from "../../../fa-icons"
import VendorChart from "./VendorChart"
import { hasAccessTo, hasAccessToAny } from "../../../authentication"
import { PageTitle } from "../../shared/StyledComponents"
import SEO from "../../../seo"
import { getAccordionState, setAccordionState } from "../../shared/utilities"

const SettingsW = styled.div`
  margin: 1rem 0;
`

const Vendors = () => {
  const stateKey = "concur-vendors"
  return (
    <>
      <SEO title="Concur Vendors" />
      <PageTitle icon={FaStore}>Vendors</PageTitle>
      <SettingsW>
        <Accordion
          collapsible={true}
          multiple={true}
          onInitialized={getAccordionState(stateKey)}
          onSelectionChanged={setAccordionState(stateKey)}
        >
          <AccordionItem
            icon={FaChartBar}
            title=" Chart"
            visible={hasAccessTo("concur.vendor.chart")}
          >
            <VendorChart />
          </AccordionItem>
          <AccordionItem
            icon={FaTable}
            title=" Grids"
            visible={hasAccessToAny([
              "concur.vendor.r",
              "concur.vendor.concur.r",
            ])}
          >
            <TabPanel>
              <Item
                title="Vendor Upload Status"
                visible={hasAccessTo("concur.vendor.r")}
              >
                <DataMap />
              </Item>
              <Item
                title="Concur"
                visible={hasAccessTo("concur.vendor.concur.r")}
              >
                <Concur />
              </Item>
              <Item title="Sage" visible={hasAccessTo("concur.vendors.sage.r")}>
                <Sage />
              </Item>
            </TabPanel>
          </AccordionItem>
        </Accordion>
      </SettingsW>
    </>
  )
}

export default Vendors
