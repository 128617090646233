import React, { useEffect, useState } from "react"
import { axios } from "../shared/utilities"
import styled from "styled-components"

const SImage = styled.img.attrs(({ image, prependData }) => ({
  src: `${(prependData ?? "") + image}`,
}))`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`

const ImageFetcher = ({
  url,
  prependData = "data:image/png;base64,",
  ...rest
}) => {
  const [image, setImage] = useState(null)
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios(url, { method: "GET" })
        setImage(response.data)
      } catch (error) {}
    }
    url && fetchImage()
  }, [url])
  return image ? (
    <SImage image={image} prependData={prependData} {...rest} />
  ) : null
}

export { ImageFetcher }
export default ImageFetcher
