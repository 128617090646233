import { TabPanel } from "devextreme-react"
import { Item as TabPanelItem } from "devextreme-react/tab-panel"
import React from "react"
import { FaUserFriends } from "react-icons/fa"

import { hasAccessTo } from "../../../authentication"
import { PageTitle, TabItemW } from "../../shared/StyledComponents"
import { getFirstAccessibleIndex } from "../../shared/utilities"
import Database from "./Database"
import Clients from "./Clients"
import SEO from "../../../seo"
import { faDatabase, faUserFriends } from "../../shared/icons"

const Home = () => {
  return (
    <>
      <SEO title="Core Clients" />
      <PageTitle icon={FaUserFriends}>Clients</PageTitle>
      <TabPanel
        defaultSelectedIndex={getFirstAccessibleIndex([
          "c.client.r",
          "c.client.migrate",
        ])}
      >
        <TabPanelItem
          icon={faUserFriends}
          title="Clients"
          visible={hasAccessTo("c.client.r")}
        >
          <Clients />
        </TabPanelItem>
        <TabPanelItem
          icon={faDatabase}
          title="Database"
          visible={hasAccessTo("c.client.migrate")}
        >
          <TabItemW>
            <Database />
          </TabItemW>
        </TabPanelItem>
      </TabPanel>
    </>
  )
}

export { Home }
export default Home
