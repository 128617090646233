import React from "react"
import ReactDOMServer from "react-dom/server"
import { SiJsonwebtokens } from "react-icons/si"
import {
  GrOrderedList,
  GrInstallOption,
  GrCatalog,
  GrRefresh,
} from "react-icons/gr"
import {
  FaFileInvoice,
  FaFileInvoiceDollar,
  FaCalendarAlt,
  FaHistory,
  FaEye,
  FaEyeSlash,
  FaCloudDownloadAlt,
  FaTrash,
  FaCloudUploadAlt,
  FaDatabase,
  FaColumns,
  FaKey,
  FaCalendar,
  FaClock,
  FaRocket,
  FaCogs,
  FaUserFriends,
  FaUsersCog,
  FaUserPlus,
  FaPlus,
} from "react-icons/fa"
import { VscServerProcess, VscNewFile } from "react-icons/vsc"
import {
  MdDeveloperMode,
  MdNotificationsActive,
  MdPayment,
} from "react-icons/md"
import { ImDatabase } from "react-icons/im"
import { FaList } from "react-icons/fa"
import { RiMailSendFill, RiFileDownloadLine } from "react-icons/ri"
import { BiDetail, BiMailSend } from "react-icons/bi"
import { BsFillGearFill } from "react-icons/bs"
import { AiFillSchedule, AiFillApi, AiOutlineFileSearch } from "react-icons/ai"
import { IoMdArrowRoundBack } from "react-icons/io"

export const siJsonWebTokens = ReactDOMServer.renderToString(
  <SiJsonwebtokens />
)
export const grInstallOption = ReactDOMServer.renderToString(
  <GrInstallOption />
)
export const faFileInvoice = ReactDOMServer.renderToString(<FaFileInvoice />)
export const vscServerProcess = ReactDOMServer.renderToString(
  <VscServerProcess />
)
export const mdDeveloperMode = ReactDOMServer.renderToString(
  <MdDeveloperMode />
)
export const imDatabase = ReactDOMServer.renderToString(<ImDatabase />)
export const faList = ReactDOMServer.renderToString(<FaList />)
export const riMailSendFill = ReactDOMServer.renderToString(<RiMailSendFill />)
export const biDetail = ReactDOMServer.renderToString(<BiDetail />)
export const riFileDownloadLine = ReactDOMServer.renderToString(
  <RiFileDownloadLine />
)
export const faCalendarAlt = ReactDOMServer.renderToString(<FaCalendarAlt />)
export const faHistory = ReactDOMServer.renderToString(<FaHistory />)
export const faEye = ReactDOMServer.renderToString(<FaEye />)
export const faEyeSlash = ReactDOMServer.renderToString(<FaEyeSlash />)
export const faCloudDownloadAlt = ReactDOMServer.renderToString(
  <FaCloudDownloadAlt />
)
export const faCloudUploadAlt = ReactDOMServer.renderToString(
  <FaCloudUploadAlt fontSize={16} />
)
export const mdNotificationsActive = ReactDOMServer.renderToString(
  <MdNotificationsActive />
)
export const faTrash = ReactDOMServer.renderToString(<FaTrash />)
export const mdPayment = ReactDOMServer.renderToString(<MdPayment />)
export const bsFillGearFill = ReactDOMServer.renderToString(<BsFillGearFill />)
export const faDatabase = ReactDOMServer.renderToString(<FaDatabase />)
export const faColumns = ReactDOMServer.renderToString(<FaColumns />)
export const faKey = ReactDOMServer.renderToString(<FaKey />)
export const faCalendar = ReactDOMServer.renderToString(<FaCalendar />)
export const aiFillSchedule = ReactDOMServer.renderToString(<AiFillSchedule />)
export const aiFillApi = ReactDOMServer.renderToString(<AiFillApi />)
export const grOrderedList = ReactDOMServer.renderToString(<GrOrderedList />)
export const faClock = ReactDOMServer.renderToString(<FaClock />)
export const grCatalog = ReactDOMServer.renderToString(<GrCatalog />)
export const faFileInvoiceDollar = ReactDOMServer.renderToString(
  <FaFileInvoiceDollar />
)
export const vscNewFile = ReactDOMServer.renderToString(<VscNewFile />)
export const grRefresh = ReactDOMServer.renderToString(<GrRefresh />)
export const faRocket = ReactDOMServer.renderToString(<FaRocket />)
export const ioMdArrowRoundBack = ReactDOMServer.renderToString(
  <IoMdArrowRoundBack />
)
export const faCogs = ReactDOMServer.renderToString(<FaCogs />)
export const faUserFriends = ReactDOMServer.renderToString(<FaUserFriends />)
export const aiOutlineFileSearch = ReactDOMServer.renderToString(
  <AiOutlineFileSearch />
)
export const faUsersCog = ReactDOMServer.renderToString(<FaUsersCog />)
export const faUserPlus = ReactDOMServer.renderToString(<FaUserPlus />)
export const faPlus = ReactDOMServer.renderToString(<FaPlus />)
export const biMailSend = ReactDOMServer.renderToString(<BiMailSend />)
