import { axios, sleep, timeRangeToParam } from "../shared/utilities"
const sageKeyValuesCache = {}
const sageKeyValuesCacheStatus = {}

const downloadMessageBodyBinaryById = async messageId => {
  let response = await axios(`/api/v1/messages/${messageId}/body-binary`)
  return response.data
}
const downloadConnector = async () => {
  const response = await axios(`/api/v1/clients/download-connector`, {
    responseType: "arraybuffer",
  })
  return response.data
}

const getClientLogo = async () => {
  const response = await axios("/api/v1/clients/profile/logo")
  return response.data
}
const getClientSettings = async () => {
  const response = await axios("/api/v1/clients/settings")
  return response.data
}
const getClientSystemStatus = async () => {
  const response = await axios("/api/v1/clients/system-status")
  return response.data
}

const getDashboard = async (applicationName, timeRange) => {
  let params = timeRangeToParam(timeRange, true)
  const response = await axios(`/api/v1/dashboards/${applicationName}${params}`)
  return response.data
}

const getGenomesByPlantId = async plantId => {
  const result = await axios(`/api/v1/plants/${plantId}/genomes`)
  return result.data
}

const getGenomeUIByPlantId = async plantId => {
  const result = await axios(`/api/v1/plants/${plantId}/genome/ui`)
  return result.data
}

const getUnhealthyPlants = async type => {
  const result = await axios(`/api/v1/plants/unhealthy?type=${type}`)
  return result.data
}

const getMessageById = async messageId => {
  const response = await axios(`/api/v1/messages/${messageId}?allFields=true`)
  return response.data
}

const getPlantById = async plantId => {
  const result = await axios(`/api/v1/plants/${plantId}`)
  return result.data
}

const getPlantByIds = async plantIds => {
  const result = await axios(`/api/v1/plants/get-plants`, {
    data: plantIds,
    method: "POST",
  })
  return result.data
}

const getQueryPlants = async (queryId, group, subGroup, timeRange) => {
  let params = timeRangeToParam(timeRange, false)
  const result = await axios(
    `/api/v1/plants/query?id=${queryId}&group=${encodeURI(
      group
    )}&subGroup=${encodeURI(subGroup)}&${params}`
  )
  return result.data
}

const getSageKeyValues = async (applicationId, identifier) => {
  const url = `/api/v1/sage/key-values?applicationId=${encodeURIComponent(
    applicationId
  )}&identifier=${encodeURIComponent(identifier)}`
  if (sageKeyValuesCache[url]) {
    return sageKeyValuesCache[url]
  }
  sageKeyValuesCacheStatus[url] = "loading"
  const response = await axios(url)
  sageKeyValuesCache[url] = response.data
  sageKeyValuesCacheStatus[url] = "loaded"
  return response.data
}
const getSageKeyValueByKey = async (applicationId, identifier, key) => {
  const url = `/api/v1/sage/key-values?applicationId=${encodeURIComponent(
    applicationId
  )}&identifier=${encodeURIComponent(identifier)}`
  if (sageKeyValuesCache[url]) {
    return (
      sageKeyValuesCache[url].find(item => item.key === key) ?? {
        key: key,
        value: "",
      }
    )
  }
  while (sageKeyValuesCacheStatus[url] === "loading") {
    await sleep(1000)
  }
  await getSageKeyValues(applicationId, identifier)
  return (
    sageKeyValuesCache[url].find(item => item.key === key) ?? {
      key: key,
      value: "",
    }
  )
}

const getSearchPlants = async (plantType, query, timeRange) => {
  let params = timeRangeToParam(timeRange, false)
  const result = await axios(
    `/api/v1/plants/search?plantType=${plantType}&query=${encodeURI(
      query
    )}&${params}`
  )
  return result.data
}

const migrateClientDatabase = async () => {
  return await axios(`/api/v1/clients/database/migrate`, {
    method: "POST",
  })
}

const permissionLocker = async id => {
  return await axios(`/api/v1/permissions/${id}/lock`, {
    method: "POST",
  })
}

const retryPlantByIds = async ids => {
  return await axios(`/api/v1/plants/retry`, {
    method: "POST",
    data: ids,
  })
}

const roleLocker = async id => {
  return await axios(`/api/v1/roles/${id}/lock`, {
    method: "POST",
  })
}

const runDatamapQuery = async id => {
  return await axios(`/api/v1/datamap-queries/${id}/execute`, {
    method: "POST",
  })
}

const runEmailNotificationQuery = async id => {
  return await axios(`/api/v1/email-notifications/${id}/execute`, {
    method: "POST",
  })
}

const runQuery = async id => {
  return await axios(`/api/v1/queries/${id}/execute`, {
    method: "POST",
  })
}

const saveClientSettings = async formData => {
  return await axios(`/api/v1/clients/settings`, {
    method: "PUT",
    data: formData,
  })
}

const saveGenome = async (plantId, data) => {
  return await axios(`/api/v1/plants/${plantId}/genomes`, {
    method: "POST",
    data: data,
  })
}

const findPlants = async (type, searchValue) => {
  const result = await axios(
    `/api/v1/plants/find?type=${type}&value=${searchValue}`
  )
  return result.data
}

const topicLocker = async id => {
  return await axios(`/api/v1/topics/${id}/lock`, {
    method: "POST",
  })
}

const updateClientSystemStatus = async () => {
  await axios("/api/v1/clients/system-status/update", {
    method: "POST",
  })
}
const userActivityLogger = async () => {
  return await axios(`/api/v1/users/log-activity`, {
    method: "POST",
  })
}
const userLocker = async id => {
  return await axios(`/api/v1/users/${id}/lock`, {
    method: "POST",
  })
}

export {
  downloadMessageBodyBinaryById,
  getClientLogo,
  getClientSettings,
  getClientSystemStatus,
  getUnhealthyPlants,
  downloadConnector,
  getDashboard,
  getGenomesByPlantId,
  getGenomeUIByPlantId,
  getPlantById,
  getPlantByIds,
  getQueryPlants,
  getMessageById,
  getSageKeyValues,
  getSageKeyValueByKey,
  getSearchPlants,
  migrateClientDatabase,
  permissionLocker,
  retryPlantByIds,
  roleLocker,
  runDatamapQuery,
  runEmailNotificationQuery,
  runQuery,
  saveClientSettings,
  saveGenome,
  findPlants,
  topicLocker,
  updateClientSystemStatus,
  userActivityLogger,
  userLocker,
}
