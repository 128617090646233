import React, { useState, useRef, useEffect } from "react"
import {
  DataGrid,
  Template,
  SelectBox,
  Button,
  TextBox,
} from "devextreme-react"
import { Button as SelectBoxButton } from "devextreme-react/select-box"
import {
  SearchPanel,
  StateStoring,
  ColumnChooser,
  ColumnFixing,
  Pager,
  FilterPanel,
  Grouping,
  HeaderFilter,
  FilterRow,
  Scrolling,
  Editing,
  Selection,
  Export,
} from "devextreme-react/data-grid"
import { connect } from "react-redux"
import notify from "devextreme/ui/notify"
import { confirm } from "devextreme/ui/dialog"
import { isEmpty } from "lodash"

import { TabItemGridW } from "../../shared/StyledComponents"
import { vendorConcurVendorDataStore } from "../../shared/ConcurStore"
import { fetchVendorsFromConcur } from "../../operations/concur"
import { FaCloudDownloadAlt } from "../../../fa-icons"
import { hasAccessTo } from "../../../authentication"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"

function ConcurVendors({ refreshUuid }) {
  const vendorGrid = useRef(null)
  const vendorCode = useRef(null)
  const addressCode = useRef(null)
  const vendorCount = useRef(100)
  const [hasSelectedRow, setHasSelectedRow] = useState(false)

  useEffect(() => {
    vendorGrid.current.instance.refresh(true)
  }, [refreshUuid])

  const selectVendorCountChangeValueHandler = e => {
    vendorCount.current = e.value
  }
  const downloadButtonClickHandler = async () => {
    try {
      await fetchVendorsFromConcur({
        vendorCount: vendorCount.current,
        vendorCode: vendorCode.current,
        addressCode: addressCode.current,
      })
      notify("Fetching vendors from Concur was queued successfully.", "success")
    } catch (error) {
      notify(`Fetching vendors from Concur failed: ${error.message}`, "error")
    }
  }
  const refreshButtonClickHandler = () => {
    vendorGrid.current.instance.refresh(true)
  }

  const deleteButtonClickHandler = async () => {
    const confirmed = await confirm(
      "Are you sure you want to delete these records?",
      "Delete vendors"
    )
    if (confirmed) {
      const selectedVendors = vendorGrid.current.instance.getSelectedRowKeys()
      try {
        await vendorGrid.current.instance
          .getDataSource()
          .store()
          .remove(selectedVendors)
        vendorGrid.current.instance.clearSelection()
        notify(
          "Deleting selected vendors from Concur was queued successfully.",
          "success"
        )
      } catch (error) {
        notify(`Deleting vendors from Concur failed: ${error.message}`, "error")
      }
    }
  }

  const vendorCodeValueChangeHandler = ({ value }) => {
    if (value !== "") vendorCode.current = value
    else vendorCode.current = null
  }
  const addressCodeValueChangeHandler = ({ value }) => {
    if (value !== "") addressCode.current = value
    else addressCode.current = null
  }

  return (
    <TabItemGridW>
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        dataSource={vendorConcurVendorDataStore}
        columnAutoWidth={true}
        columnResizingMode="widget"
        hoverStateEnabled={true}
        ref={vendorGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        onSelectionChanged={({ selectedRowKeys }) => {
          if (isEmpty(selectedRowKeys)) setHasSelectedRow(false)
          else setHasSelectedRow(true)
        }}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "deleteSelectedVendors",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "downloadVendors",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "addressCode",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "vendorCode",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          allowDeleting={hasAccessTo("concur.vendor.concur.d")}
          useIcons={true}
        />
        <Export
          allowExportSelectedData={true}
          enabled={true}
          fileName="datamap-concur-vendors"
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="concur-vendors-concur"
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <HeaderFilter visible={true} />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <Selection
          allowSelectAll={true}
          mode="multiple"
          selectAllMode="all"
          showCheckBoxesMode="always"
        />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          showNavigationButtons={true}
          showInfo={true}
          showPageSizeSelector={true}
          visible={true}
        />
        <Template name="deleteSelectedVendors">
          <Button
            disabled={!hasSelectedRow}
            hint="Delete selected vendors"
            icon="trash"
            text="Delete Vendors"
            visible={hasAccessTo("concur.vendor.concur.d")}
            onClick={deleteButtonClickHandler}
          />
        </Template>
        <Template name="downloadVendors">
          {hasAccessTo("concur.vendor.concur.fetch") && (
            <SelectBox
              dataSource={[
                { value: 0, title: "All vendors" },
                { value: 25, title: "25 vendors" },
                { value: 50, title: "50 vendors" },
                { value: 100, title: "100 vendors" },
                { value: 200, title: "200 vendors" },
                { value: 400, title: "400 vendors" },
                { value: 800, title: "800 vendors" },
                { value: 1000, title: "1000 vendors" },
              ]}
              defaultValue={100}
              displayExpr="title"
              valueExpr="value"
              width={300}
              onValueChanged={selectVendorCountChangeValueHandler}
            >
              <SelectBoxButton name="dropDown" />
              <SelectBoxButton
                name="downloadVendors"
                options={{
                  icon: FaCloudDownloadAlt,
                  height: "100%",
                  hint: "Download vendors",
                  onClick: downloadButtonClickHandler,
                }}
              />
            </SelectBox>
          )}
        </Template>
        <Template name="addressCode">
          <TextBox
            hint="Address code"
            placeholder="Address Code..."
            valueChangeEvent="keyup"
            onValueChanged={addressCodeValueChangeHandler}
          />
        </Template>
        <Template name="vendorCode">
          <TextBox
            hint="Vendor code"
            placeholder="Vendor Code..."
            valueChangeEvent="keyup"
            onValueChanged={vendorCodeValueChangeHandler}
          />
        </Template>
        <Template name="refreshGrid">
          <Button
            hint="Update grid"
            icon="refresh"
            onClick={refreshButtonClickHandler}
          />
        </Template>
      </DataGrid>
    </TabItemGridW>
  )
}

export { ConcurVendors }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(ConcurVendors)
