import React, { useState } from "react"
import { Button, TextBox } from "devextreme-react"
import styled from "styled-components"
import { navigate } from "@reach/router"
import { isFunction } from "lodash"

const W = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
`
const SearchItemsW = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
`
const SearchPlants = ({ plantType, onSearchClicked }) => {
  const [searchValue, setSearchValue] = useState("")

  const search = async () => {
    navigate("/dashboard/shopify/search-results", {
      state: { type: "search", searchValue, plantType },
    })
    if (isFunction(onSearchClicked)) {
      onSearchClicked()
    }
  }
  const searchTextValueChangedHandler = ({ value }) => {
    setSearchValue(value)
  }

  return (
    <W>
      <SearchItemsW>
        <TextBox
          placeholder="Search text ..."
          style={{
            flexGrow: 4,
            borderWidth: "1px 1px 1px 1px",
            borderColor: "rgb(221,221,221)",
          }}
          valueChangeEvent="input"
          onValueChanged={searchTextValueChangedHandler}
        />
        <Button
          icon="fas fa-search"
          style={{ border: 0 }}
          type="default"
          onClick={search}
        ></Button>
      </SearchItemsW>
    </W>
  )
}

export default SearchPlants
