import CustomStore from "devextreme/data/custom_store"
import { axios } from "./utilities"
import { isArray } from "lodash"
import camelcaseKeys from "camelcase-keys"

import { timeRangeToParam } from "./utilities"

const allExpenseChartDataStoreGenerator = ({ timeRange }) => {
  const params = timeRangeToParam(timeRange, true)
  return new CustomStore({
    key: "id",
    load: async () => {
      const response = await axios(
        `/api/v1/concur/expenses/chart-data${params}`
      )
      return await response.data
    },
  })
}
const allInvoiceChartDataStoreGenerator = ({ timeRange }) => {
  const params = timeRangeToParam(timeRange, true)
  return new CustomStore({
    key: "id",
    load: async () => {
      const response = await axios(
        `/api/v1/concur/invoices/chart-data${params}`
      )
      return await response.data
    },
  })
}
const allPaymentChartDataStoreGenerator = ({ timeRange }) => {
  const params = timeRangeToParam(timeRange, true)
  return new CustomStore({
    key: "id",
    load: async () => {
      const response = await axios(
        `/api/v1/concur/payments/chart-data${params}`
      )
      return await response.data
    },
  })
}
const batchDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/concur/batches")
  },
  byKey: async key => {
    return await axios(`/api/v1/concur/batches/${key}`)
  },
  update: async (key, value) => {
    return await axios(`/api/v1/concur/batches/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/concur/batches/${key}`, {
      method: "DELETE",
    })
  },
})
const batchJobDataStoreGenerator = batchId => {
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(`/api/v1/concur/batches/${batchId}/jobs`)
    },
    byKey: async key => {
      return await axios(`/api/v1/concur/batches/${batchId}/jobs/${key}`)
    },
    remove: async key => {
      return await axios(`/api/v1/concur/batches/${batchId}/jobs/${key}`, {
        method: "DELETE",
      })
    },
  })
}
const expenseChartDataStoreGenerator = (expenseId, { timeRange }) => {
  const params = timeRangeToParam(timeRange, true)
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/expenses/${expenseId}/chart-data${params}`
      )
    },
  })
}
const expenseGridDataStoreGenerator = (
  expenseId,
  { timeRange, showArchived = false, showDeleted = false }
) => {
  let params = timeRangeToParam(timeRange, true)
  if (showArchived) params += "&showArchived=true"
  if (showDeleted) params += "&showDeleted=true"
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/expenses/${expenseId}/grid-data${params}`
      )
    },
    update: async (key, value) => {
      return await axios(
        `/api/v1/concur/expenses/${expenseId}/grid-data/${key}`,
        {
          method: "PUT",
          data: value,
        }
      )
    },
    remove: async key => {
      return await axios(
        `/api/v1/concur/expenses/${expenseId}/grid-data/${key}`,
        {
          method: "DELETE",
        }
      )
    },
  })
}
const expenseDataStore = new CustomStore({
  key: "id",
  load: async loadOptions => {
    let param = "?"
    const { filter } = loadOptions
    if (filter) {
      param = param + filter[0] + "=" + filter[2]
    }
    return await axios(`/api/v1/concur/expenses${encodeURI(param)}`)
  },
  byKey: async key => {
    return await axios(`/api/v1/concur/expenses/${key}`)
  },
  update: async (key, value) => {
    return await axios(`/api/v1/concur/expenses/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/concur/expenses`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/concur/expenses/${key}`, {
      method: "DELETE",
    })
  },
})
const expenseGridHistoryDataStoreGenerator = (expenseId, rowId) =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/expenses/${expenseId}/grid-data/${rowId}/histories`
      )
    },
  })
const expenseGridLineDataStoreGenerator = (expenseId, lineId) =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/expenses/${expenseId}/grid-data/${lineId}/lines`
      )
    },
  })
const extractDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/concur/extracts")
  },
  byKey: async key => {
    return await axios(`/api/v1/concur/extracts/${key}`)
  },
  update: async (key, value) => {
    return await axios(`/api/v1/concur/extracts/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/concur/extracts/${key}`, {
      method: "DELETE",
    })
  },
})
const extractJobDataStoreGenerator = extractId => {
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(`/api/v1/concur/extracts/${extractId}/jobs`)
    },
    byKey: async key => {
      return await axios(`/api/v1/concur/extracts/${extractId}/jobs/${key}`)
    },
    remove: async key => {
      return await axios(`/api/v1/concur/extracts/${extractId}/jobs/${key}`, {
        method: "DELETE",
      })
    },
  })
}
const invoiceChartDataStoreGenerator = (invoiceId, { timeRange }) => {
  const params = timeRangeToParam(timeRange, true)
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/invoices/${invoiceId}/chart-data${params}`
      )
    },
  })
}
const invoiceDataStore = new CustomStore({
  key: "id",
  load: async loadOptions => {
    let param = "?"
    const { filter } = loadOptions
    if (filter) {
      param = param + filter[0] + "=" + filter[2]
    }
    return await axios(`/api/v1/concur/invoices${encodeURI(param)}`)
  },
  byKey: async key => {
    return await axios(`/api/v1/concur/invoices/${key}`)
  },
  update: async (key, value) => {
    return await axios(`/api/v1/concur/invoices/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/concur/invoices`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/concur/invoices/${key}`, {
      method: "DELETE",
    })
  },
})
const invoiceGridDataStoreGenerator = (
  invoiceId,
  { timeRange, showArchived = false, showDeleted = false }
) => {
  let params = timeRangeToParam(timeRange, true)
  if (showArchived) params += "&showArchived=true"
  if (showDeleted) params += "&showDeleted=true"
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/invoices/${invoiceId}/grid-data${params}`
      )
    },
    update: async (key, value) => {
      return await axios(
        `/api/v1/concur/invoices/${invoiceId}/grid-data/${key}`,
        {
          method: "PUT",
          data: value,
        }
      )
    },
    remove: async key => {
      return await axios(
        `/api/v1/concur/invoices/${invoiceId}/grid-data/${key}`,
        {
          method: "DELETE",
        }
      )
    },
  })
}

const invoiceGridHistoryDataStoreGenerator = (invoiceId, rowId) =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/invoices/${invoiceId}/grid-data/${rowId}/histories`
      )
    },
  })

const invoiceGridLineDataStoreGenerator = (invoiceId, lineId) =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/invoices/${invoiceId}/grid-data/${lineId}/lines`
      )
    },
  })

const listDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/concur/lists")
  },
  byKey: async key => {
    return await axios(`/api/v1/concur/lists/${key}`)
  },
  update: async (key, value) => {
    return await axios(`/api/v1/concur/lists/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/concur/lists/${key}`, {
      method: "DELETE",
    })
  },
})

const listItemDataStoreGenerator = listId =>
  new CustomStore({
    key: "concur_Id",
    load: async () => {
      return await axios(`/api/v1/concur/lists/${listId}/list-items`)
    },
    byKey: async key => {
      return await axios(`/api/v1/concur/lists/${listId}/list-items/${key}`)
    },
    remove: async key => {
      return await axios(`/api/v1/concur/lists/${listId}/list-items/${key}`, {
        method: "DELETE",
      })
    },
  })

const listItemDatamapDataStoreGenerator = listId =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(`/api/v1/concur/lists/${listId}/datamap-list-items`)
    },
    byKey: async key => {
      return await axios(
        `/api/v1/concur/lists/${listId}/datamap-list-items/${key}`
      )
    },
  })

const listItemDatamapHistoryDataStoreGenerator = (listId, listItemGridId) =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/lists/${listId}/datamap-list-items/${listItemGridId}/histories`
      )
    },
  })

const moduleDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/concur/modules")
  },
  byKey: async key => {
    const response = await axios(`/api/v1/concur/modules/${key}`)
    return response.data
  },
})

const paymentChartDataStoreGenerator = (paymentId, { timeRange }) => {
  const params = timeRangeToParam(timeRange, true)
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/payments/${paymentId}/chart-data${params}`
      )
    },
  })
}
const paymentGridDataStoreGenerator = (
  paymentId,
  { timeRange, showArchived = false, showDeleted = false }
) => {
  let params = timeRangeToParam(timeRange, true)
  if (showArchived) params += "&showArchived=true"
  if (showDeleted) params += "&showDeleted=true"
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/payments/${paymentId}/grid-data${params}`
      )
    },
    update: async (key, value) => {
      return await axios(
        `/api/v1/concur/payments/${paymentId}/grid-data/${key}`,
        {
          method: "PUT",
          data: value,
        }
      )
    },
    remove: async key => {
      return await axios(
        `/api/v1/concur/payments/${paymentId}/grid-data/${key}`,
        {
          method: "DELETE",
        }
      )
    },
  })
}
const paymentDataStore = new CustomStore({
  key: "id",
  load: async loadOptions => {
    let param = "?"
    const { filter } = loadOptions
    if (filter) {
      param = param + filter[0] + "=" + filter[2]
    }
    return await axios(`/api/v1/concur/payments${encodeURI(param)}`)
  },
  byKey: async key => {
    return await axios(`/api/v1/concur/payments/${key}`)
  },
  update: async (key, value) => {
    return await axios(`/api/v1/concur/payments/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/concur/payments`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/concur/payments/${key}`, {
      method: "DELETE",
    })
  },
})
const paymentGridHistoryDataStoreGenerator = (paymentId, rowId) =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/payments/${paymentId}/grid-data/${rowId}/histories`
      )
    },
  })
const paymentGridLineDataStoreGenerator = (paymentId, lineId) =>
  new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/payments/${paymentId}/grid-data/${lineId}/lines`
      )
    },
  })

const vendorChartDataStoreGenerator = ({ timeRange }) => {
  const params = timeRangeToParam(timeRange, true)
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(`/api/v1/concur/vendors/chart-data${params}`)
    },
  })
}
const vendorConcurVendorDataStore = new CustomStore({
  key: ["VendorCode", "AddressCode"],
  load: async () => {
    const response = await axios("/api/v1/concur/vendors/concur-vendors")
    return await response.data.Vendor
  },
  remove: async key => {
    if (!isArray(key)) key = [key]
    key = key.map(item => camelcaseKeys(item))
    return await axios(`/api/v1/concur/vendors/concur-vendors/delete`, {
      data: key,
      method: "POST",
    })
  },
})
const vendorDataMapVendorDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/concur/vendors/datamap-vendors")
  },
})
const vendorDataMapHistoryDataStoreGenerator = vendorGridId => {
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/vendors/datamap-vendors/${vendorGridId}/histories`
      )
    },
  })
}

const purchaseOrderChartDataStoreGenerator = ({ timeRange }) => {
  const params = timeRangeToParam(timeRange, true)
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(`/api/v1/concur/purchase-orders/chart-data${params}`)
    },
  })
}
const purchaseOrderDataMapPurchaseOrderDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/concur/purchase-orders/datamap-purchase-orders")
  },
})
const purchaseOrderDataMapHistoryDataStoreGenerator = purchaseOrderGridId => {
  return new CustomStore({
    key: "id",
    load: async () => {
      return await axios(
        `/api/v1/concur/purchase-orders/datamap-purchase-orders/${purchaseOrderGridId}/histories`
      )
    },
  })
}

const sagePaymentDataMapSagePaymentDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios("/api/v1/concur/sage-payments/datamap-sage-payments")
  },
})

const tokenDataStore = new CustomStore({
  key: "id",
  load: async () => {
    return await axios(`/api/v1/concur/tokens`)
  },
  byKey: async key => {
    return await axios(`/api/v1/concur/tokens/${key}`)
  },
  update: async (key, value) => {
    return await axios(`/api/v1/concur/tokens/${key}`, {
      method: "PUT",
      data: value,
    })
  },
  insert: async value => {
    return await axios(`/api/v1/concur/tokens`, {
      method: "POST",
      data: value,
    })
  },
  remove: async key => {
    return await axios(`/api/v1/concur/tokens/${key}`, {
      method: "DELETE",
    })
  },
})

export {
  allExpenseChartDataStoreGenerator,
  allInvoiceChartDataStoreGenerator,
  allPaymentChartDataStoreGenerator,
  batchDataStore,
  batchJobDataStoreGenerator,
  expenseChartDataStoreGenerator,
  expenseGridDataStoreGenerator,
  expenseDataStore,
  expenseGridHistoryDataStoreGenerator,
  expenseGridLineDataStoreGenerator,
  extractDataStore,
  extractJobDataStoreGenerator,
  invoiceChartDataStoreGenerator,
  invoiceDataStore,
  listDataStore,
  listItemDataStoreGenerator,
  listItemDatamapDataStoreGenerator,
  listItemDatamapHistoryDataStoreGenerator,
  moduleDataStore,
  invoiceGridDataStoreGenerator,
  vendorChartDataStoreGenerator,
  invoiceGridHistoryDataStoreGenerator,
  invoiceGridLineDataStoreGenerator,
  vendorConcurVendorDataStore,
  vendorDataMapVendorDataStore,
  vendorDataMapHistoryDataStoreGenerator,
  tokenDataStore,
  purchaseOrderChartDataStoreGenerator,
  purchaseOrderDataMapPurchaseOrderDataStore,
  purchaseOrderDataMapHistoryDataStoreGenerator,
  sagePaymentDataMapSagePaymentDataStore,
  paymentChartDataStoreGenerator,
  paymentGridDataStoreGenerator,
  paymentDataStore,
  paymentGridHistoryDataStoreGenerator,
  paymentGridLineDataStoreGenerator,
}
