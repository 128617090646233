import React, { useEffect } from "react"
import { Router } from "@reach/router"
import { navigate } from "gatsby"

import { getCurrentUser, isLoggedIn } from "../components/authentication"

import Layout from "../components/dashboard/Layout"
import Home from "../components/dashboard/Home"
import Core from "../components/dashboard/core/Home"
import Concur from "../components/dashboard/concur/Home"
import Profile from "../components/dashboard/profile/Home"
import Shopify from "../components/dashboard/shopify/Home"
import UserProfile from "../components/dashboard/UserProfile"

const Dashboard = () => {
  useEffect(() => {
    const checkLoginStatus = async () => {
      if (!(await isLoggedIn())) {
        navigate("/login")
      }
    }
    checkLoginStatus()
  })
  if (getCurrentUser() === null) return null

  return (
    <>
      <Layout>
        <Router basepath="/dashboard" style={{ height: "100%" }}>
          <Home path="/" />
          <Concur path="concur/*" />
          <Core path="core/*" />
          <Profile path="profile/*" />
          <Shopify path="shopify/*" />
          <UserProfile path="user-profile" />
        </Router>
      </Layout>
    </>
  )
}

export default Dashboard
