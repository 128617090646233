import React, { useEffect, useRef } from "react"
import { DataGrid, Template, Button } from "devextreme-react"
import {
  ColumnChooser,
  ColumnFixing,
  StateStoring,
  FilterPanel,
  Grouping,
  SearchPanel,
  Pager,
  GroupPanel,
  HeaderFilter,
  FilterRow,
  Scrolling,
  Export,
} from "devextreme-react/data-grid"
import { connect } from "react-redux"
import notify from "devextreme/ui/notify"

import { sagePaymentDataMapSagePaymentDataStore } from "../../shared/ConcurStore"
import { FaCloudUploadAlt } from "../../../fa-icons"
import {
  resetSagePaymentHash,
  sendSagePaymentsToConcur,
} from "../../operations/concur"
import { convertUtcToLocal } from "../../shared/utilities"
import { hasAccessTo } from "../../../authentication"
import { dashboardSelectors } from "../../../../state/ducks/dashboard"
import ItemScheduler from "../../shared/ItemScheduler"

function DataMapSagePayments({ refreshUuid }) {
  const sagePaymentGrid = useRef(null)

  useEffect(() => {
    sagePaymentGrid.current.instance.refresh(true)
  }, [refreshUuid])

  const refreshButtonClickHandler = () => {
    sagePaymentGrid.current.instance.refresh(true)
  }
  const sendSagePaymentsToConcurClickHandler = async () => {
    try {
      await sendSagePaymentsToConcur()
      notify(
        "Sending sage payments to Concur was queued successfully.",
        "success"
      )
    } catch (error) {
      notify(
        `Sending sage payments to Concur failed: ${error.message}`,
        "error"
      )
    }
  }
  const customizeColumnsHandler = columns => {
    const processDateIndex = columns.findIndex(column =>
      column.dataField === "processDate" ? true : false
    )
    if (processDateIndex !== -1) {
      columns[processDateIndex].dataType = "datetime"
      columns[processDateIndex].format = {
        formatter: convertUtcToLocal,
      }
    }
    const onResetHashClickHandler = async ({ row }) => {
      try {
        await resetSagePaymentHash({ id: row.key })
        notify(
          `Forcing to resend sage payment to Concur was done successfully.`,
          "success"
        )
      } catch (error) {
        notify(
          `Forcing to resend sage payment to Concur failed: ${error.message}`,
          "error"
        )
      }
    }
    columns.push({
      type: "buttons",
      showInColumnChooser: false,
      buttons: [
        {
          cssClass: "dx-link customColumnButton",
          hint: "Force resend this sage payment on next try",
          icon: "refresh",
          name: "reset hash",
          visible: hasAccessTo("concur.sp.reset.hash"),
          onClick: onResetHashClickHandler,
        },
      ],
    })
  }
  return (
    <>
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        customizeColumns={customizeColumnsHandler}
        dataSource={sagePaymentDataMapSagePaymentDataStore}
        hoverStateEnabled={true}
        ref={sagePaymentGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "sendSagePaymentsToConcur",
          })
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Export
          fileName="datamap-sage-payments"
          allowExportSelectedData={true}
          enabled={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter visible={true} />
        <Scrolling showScrollbar="always" />
        <SearchPanel visible={true} />
        <StateStoring
          enabled={true}
          storageKey="concur-sagePayments-datamap"
          type="localStorage"
        />
        <Pager
          allowedPageSizes={[10, 20, 50, 100]}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
          visible={true}
        />
        <Template name="sendSagePaymentsToConcur">
          <Button
            elementAttr={{ id: "sendSagePaymentsToConcur" }}
            hint="Send sagePayments to Concur"
            icon={FaCloudUploadAlt}
            text="Send To Concur"
            visible={hasAccessTo("concur.sp.to.concur")}
            onClick={sendSagePaymentsToConcurClickHandler}
          />
        </Template>
        <Template name="refreshGrid">
          <Button
            hint="Update grid"
            icon="refresh"
            onClick={refreshButtonClickHandler}
          />
        </Template>
      </DataGrid>
      <ItemScheduler
        items={[
          {
            body: null,
            method: "POST",
            target: "#sendSagePaymentsToConcur",
            title: "send sage payments to concur",
            url: "/api/v1/concur/sage-payments/send-to-concur",
          },
        ]}
      />
    </>
  )
}

export { DataMapSagePayments }

const mapStateToProps = state => ({
  timeRange: dashboardSelectors.selectTimeRange(state),
  refreshUuid: dashboardSelectors.selectAutoRefreshUuid(state),
})
export default connect(mapStateToProps, null)(DataMapSagePayments)
