import React, { useRef, useState } from "react"
import {
  Button,
  CheckBox,
  DataGrid,
  Template,
  TextArea,
} from "devextreme-react"
import {
  Button as GButton,
  Column,
  ColumnChooser,
  ColumnFixing,
  Editing,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  StateStoring,
} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"
import styled from "styled-components"

import { runQuery } from "../../../operations/client"
import { queryDataStore as ClientQueryDataStore } from "../../../shared/ClientStores"
import { VscServerProcess } from "react-icons/vsc"
import ItemScheduler from "../../../shared/ItemScheduler"
import { hasAccessTo } from "../../../../authentication"

const Label = styled.div`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`

const Saved = () => {
  const queriesGridRef = useRef(null)
  const [result, setResult] = useState(null)
  const [rawResult, setRawResult] = useState(null)
  const [showRawResult, setShowRawResult] = useState(false)

  const checkBoxValueChangedHandler = ({ value }) => {
    setShowRawResult(value)
  }

  const refreshQueriesGridClickHandler = () => {
    queriesGridRef.current.instance.refresh(true)
  }

  const runButtonRenderHandler = ({ data }) => (
    <div style={{ display: "inline-block" }}>
      <VscServerProcess
        onClick={runQueryClickHandler(data.id)}
        style={{
          width: "15px",
          height: "15px",
          cursor: "pointer",
          margin: "-8px 3px 0 3px",
          color: "rgb(51 122 183)",
        }}
        title="Run this query"
        id={`runQuery-${data.id}`}
      />
      <ItemScheduler
        items={[
          {
            body: null,
            method: "POST",
            target: `#runQuery-${data.id}`,
            title: `run queries (queryId=${data.id}) - ${data.title}`,
            url: `/api/v1/queries/${data.id}/execute`,
          },
        ]}
      />
    </div>
  )

  const runQueryClickHandler = id => async () => {
    try {
      setResult(null)
      setRawResult(null)
      queriesGridRef.current.instance.beginCustomLoading()
      const response = await runQuery(id)
      const result = response.data
      setResult(result)
      try {
        const rawResult = JSON.stringify(result)
        setRawResult(rawResult)
      } catch (error) {
        setRawResult(result)
      }
      notify(`Query ran successfully`, "success")
    } catch (error) {
      notify(`Executing query failed: ${error.message}`, "error")
    } finally {
      queriesGridRef.current.instance.endCustomLoading()
    }
  }
  const editorPreparingHandler = e => {
    if (e.value && e.parentType === "dataRow" && e.dataField === "bodyString") {
      e.editorName = "dxTextArea"
    }
  }
  const toolbarPreparingHandler = e => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "showRawResult",
    })
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "refreshGrid",
    })
  }
  return (
    <>
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={ClientQueryDataStore}
        hoverStateEnabled={true}
        ref={queriesGridRef}
        rowAlternationEnabled={true}
        showBorders={true}
        onEditorPreparing={editorPreparingHandler}
        onToolbarPreparing={toolbarPreparingHandler}
      >
        <Column dataField="id" defaultVisible={false} allowEditing={false} />
        <Column dataField="title" dataType="string" />
        <Column
          dataField="bodyString"
          dataType="string"
          formItem={{ colSpan: 2 }}
        />
        <Column type="buttons">
          <GButton name="delete" />
          <GButton name="edit" />
          <GButton name="run" render={runButtonRenderHandler} />
        </Column>
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          allowDeleting={hasAccessTo("query.d")}
          allowUpdating={hasAccessTo("query.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter allowSearch={true} visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Paging defaultPageSize={10} />
        <Scrolling showScrollbar="always" />
        <SearchPanel width={250} visible={true} />
        <StateStoring
          enabled={true}
          storageKey="profile-developments-queries-saved"
          type="localStorage"
        />
        <Template name="showRawResult">
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>Raw Result:</span>
            <CheckBox
              hint="Show raw data."
              style={{ marginLeft: "0.5rem" }}
              value={showRawResult}
              onValueChanged={checkBoxValueChangedHandler}
            />
          </div>
        </Template>
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshQueriesGridClickHandler} />
        </Template>
      </DataGrid>
      {result &&
        (showRawResult ? (
          <>
            <Label>Query results:</Label>
            <TextArea
              autoResizeEnabled={true}
              readOnly={true}
              value={rawResult}
            />
          </>
        ) : (
          <>
            <Label>Query results:</Label>
            <DataGrid
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              columnResizingMode="widget"
              dataSource={result}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
              showBorders={true}
            >
              <ColumnChooser
                allowSearch={true}
                enabled={true}
                height="400"
                mode="select"
              />
              <ColumnFixing enabled={true} />
              <FilterPanel visible={true} />
              <FilterRow visible={true} />
              <Grouping contextMenuEnabled={true} />
              <GroupPanel visible={true} />
              <HeaderFilter allowSearch={true} visible={true} />
              <Pager
                visible={true}
                allowedPageSizes={[10, 20, 50, 100]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                showInfo={true}
              />
              <Scrolling showScrollbar="always" />
              <SearchPanel width={250} visible={true} />
            </DataGrid>
          </>
        ))}
    </>
  )
}

export default Saved
