import React, { useRef } from "react"
import { DataGrid, Button, Template } from "devextreme-react"
import {
  Editing,
  Column,
  RequiredRule,
  StateStoring,
  FilterRow,
  ColumnChooser,
  ColumnFixing,
  FilterPanel,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  SearchPanel,
  Lookup,
  Scrolling,
} from "devextreme-react/data-grid"
import { ImDatabase } from "react-icons/im"

import { queryTemplateDataStore, topicDataStore } from "../shared/CoreStore"
import { hasAccessTo } from "../../authentication"
import { PageTitle } from "../shared/StyledComponents"
import SEO from "../../seo"

const Queries = () => {
  const queryGrid = useRef(null)

  const refreshQueryGridClickHandler = () => {
    queryGrid.current.instance.refresh(true)
  }

  return (
    <>
      <SEO title="Core Queries" />
      <PageTitle icon={ImDatabase}>Queries</PageTitle>
      <DataGrid
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={queryTemplateDataStore}
        hoverStateEnabled={true}
        ref={queryGrid}
        rowAlternationEnabled={true}
        showBorders={true}
        visible={hasAccessTo("query.r")}
        onEditorPreparing={function(e) {
          if (
            e.value &&
            e.parentType === "dataRow" &&
            e.dataField === "topicId"
          ) {
            e.editorOptions.value = e.value.toString()
            e.editorOptions.onValueChanged = function(args) {
              e.setValue(parseInt(args.value))
            }
          }
        }}
        onToolbarPreparing={e => {
          e.toolbarOptions.items.unshift({
            location: "before",
            template: "refreshGrid",
          })
        }}
      >
        <Column dataField="id" dataType="number" allowEditing={false} />
        <Column dataField="topicId" dataType="number">
          <Lookup
            dataSource={{
              store: topicDataStore,
              map: item => {
                return { ...item, id: item.id.toString() }
              },
            }}
            displayExpr="title"
            valueExpr="id"
          />
          <RequiredRule />
        </Column>
        <Column dataField="title" dataType="string">
          <RequiredRule trim={true} />
        </Column>
        <Column dataField="isActive" dataType="boolean" />
        <Column dataField="consumerId" dataType="string" />
        <Column dataField="priority" dataType="string" />
        <Column dataField="jobTimeout" dataType="number" />
        <Column dataField="header" dataType="string" />
        <Column dataField="bodyString" dataType="string" />
        <ColumnChooser
          allowSearch={true}
          enabled={true}
          height="400"
          mode="select"
        />
        <ColumnFixing enabled={true} />
        <Editing
          allowAdding={hasAccessTo("c.query.c")}
          allowDeleting={hasAccessTo("c.query.d")}
          allowUpdating={hasAccessTo("c.query.u")}
          mode="form"
          useIcons={true}
        />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={true} />
        <HeaderFilter allowSearch={true} visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
        />
        <Scrolling showScrollbar="always" />
        <SearchPanel width={250} visible={true} />
        <StateStoring
          enabled={true}
          storageKey="core-queries"
          type="localStorage"
        />
        <Template name="refreshGrid">
          <Button icon="refresh" onClick={refreshQueryGridClickHandler} />
        </Template>
      </DataGrid>
    </>
  )
}

export { Queries }
export default Queries
