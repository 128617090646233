import { DataGrid, Popup } from "devextreme-react"
import React, { useCallback } from "react"

const PopupDataGrid = ({ dataSource, title, visible, onHiding }) => {
  const onHidingHandler = useCallback(() => {
    onHiding()
  }, [onHiding])

  return (
    <Popup title={title} visible={visible} onHiding={onHidingHandler}>
      <DataGrid
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnAutoWidth={true}
        columnResizingMode="widget"
        dataSource={dataSource}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        showBorders={true}
      />
    </Popup>
  )
}

export default PopupDataGrid
